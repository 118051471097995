import { ThemeName, Theme } from './themes/types';
import { theme as greenTheme } from './themes/green';
import { theme as redTheme } from './themes/red';
import { theme as blueTheme } from './themes/blue';

export const themes: Record<ThemeName, Theme> = {
  red: redTheme,
  green: greenTheme,
  blue: blueTheme,
};
