import React, { useEffect, useRef, useState } from 'react';
import { FlatList, Image, View, Text, StyleSheet } from 'react-native';
import { lgBreakpoint, smBreakpoint } from '../../constants';

import { useDimensions } from '../../context/DimensionsContext';
import { useThemeState } from '../../context/ThemeContext';
import { GTAmerica, GTAmericaCompressed, mdbreakpoint, PanamaBold } from '../../libs/constants';
import { ProductAsset } from '../../screens/BuildAChairScreen';
import { HoverableLink } from './HoverableLink';
import { TouchableOpacity } from './TouchableOpacity';

interface Props {
  assets: ProductAsset[];
}

export const ImageCarousel: React.FC<Props> = React.memo(({ assets }) => {
  const { width, height } = useDimensions();
  const { theme } = useThemeState();
  const greenArrow = require('@happymedium/components/assets/images/arrow-right-green@300x.png');
  const flatListRef = useRef<FlatList>(null);
  console.log('WIDTH', width);
  // const [file, setFile] = useState<File>(null);
  // const [fileRN, setFileRN] = useState<ImageInfo>(null);
  const getItemLayout = (data: unknown, index: number) => ({
    length: width,
    offset: width * index,
    index,
  });
  const scrollToEnd = () => {
    if (flatListRef && flatListRef.current) {
      flatListRef.current.scrollToEnd({ animated: true });
    }
  };
  const scrollToStart = () => {
    if (flatListRef && flatListRef.current) {
      flatListRef.current.scrollToIndex({ index: 0, animated: true });
    }
  };

  const styles = StyleSheet.create({
    text: {
      color: theme.foregroundColor,
    },
    secondaryHeader: {
      fontFamily: GTAmericaCompressed,
      textTransform: 'uppercase',
      color: theme.foregroundColor,
      fontSize: width < smBreakpoint ? 20 : width < mdbreakpoint ? 24 : 28,
    },
    body: {
      fontFamily: GTAmerica,
      fontWeight: '300',
      fontSize: width < smBreakpoint ? 16 : width < mdbreakpoint ? 16 : 18,
      letterSpacing: 1.07,
    },
    infoText: {
      fontFamily: GTAmericaCompressed,
      fontSize: width < smBreakpoint ? 14 : width < mdbreakpoint ? 16 : 18,
      letterSpacing: 1.02,
    },
    header: {
      fontFamily: PanamaBold,
      color: theme.foregroundColor,
      textTransform: 'uppercase',
      alignSelf: 'center',
      fontSize: width < smBreakpoint ? 34 : width < mdbreakpoint ? 42 : 48,
      letterSpacing: 1.02,
      lineHeight: width < smBreakpoint ? 38 : width < mdbreakpoint ? 48 : 54,
      textAlign: 'center',
    },
    h2: {
      fontSize: width < smBreakpoint ? 28 : width < mdbreakpoint ? 34 : 40,
    },
    h3: {
      fontSize: width < smBreakpoint ? 22 : width < mdbreakpoint ? 24 : 28,
    },
    pushButton: {
      borderWidth: 1,
      borderColor: theme.foregroundColor,
      borderRadius: 5,
                padding: 10,
    }
  });

  useEffect(() => {
    scrollToStart();
  }, [assets]);

  // const whRatio = assetWidth / assetHeight;
  // if (!assets || !assets.length) {
  //   return (
  //     <View sx={{ height: height * 0.8, borderWidth: 1 }}>
  //       {admin && (
  //         <View sx={{ position: 'absolute', bottom: 10, right: 10 }}>
  //           <ImagePicker setFileRN={setFileRN} setFile={setFile} />
  //         </View>
  //       )}
  //     </View>
  //   );
  // }

  return (
    <View style={{ maxWidth: '100%' }}>
      <FlatList<ProductAsset>
        data={assets}
        ref={flatListRef}
        snapToAlignment="center"
        snapToInterval={width}
        decelerationRate={'fast'}
        showsHorizontalScrollIndicator={false}
        pagingEnabled
        getItemLayout={getItemLayout}
        horizontal
        renderItem={(i) => (
          <View style={{ justifyContent: 'center' }}>
            <TouchableOpacity
              onPress={() => flatListRef.current?.scrollToIndex({ index: (i.index - 1), animated: true})}
              style={{
                position: 'absolute',
                left: width < smBreakpoint ? 5 : width < mdbreakpoint ? 10 : width < lgBreakpoint ? 25 : 100,
                marginTop: 'auto',
                marginBottom: 'auto',
                zIndex: 10,
              }}
              disabled={i.index === 0}
            >
              {/* <Text
                style={{
                  fontSize: 48,
                  color: i.index === 0 ? 'gray' : theme.backgroundColor,
                }}
              >
                {'<'}
              </Text> */}
              <Image style={{height: 20, width: 24, opacity: i.index === 0 ? 0.5 : 1 , transform: [{ rotate: '180deg'}]}} source={greenArrow} />
            </TouchableOpacity>
            <Image
              key={`${i.item.id}/${i.item.url}`}
              resizeMode={'contain'}
              style={{
                height: width * (i.item.height / i.item.width),
                // height: 'auto',
                width,
                maxHeight: height,
              }}
              source={{ uri: i.item.url }}
            />
            {i.item.caption && <Text style={[styles.text, styles.body, {fontSize: width < smBreakpoint ? 10 : 12, letterSpacing: 1, marginTop: 5, alignSelf: "center"}]}>{i.item.caption}</Text>}
            <TouchableOpacity
              onPress={() => flatListRef.current?.scrollToIndex({ index: (i.index + 1), animated: true})}
              style={{
                position: 'absolute',
                right: width < smBreakpoint ? 5 : width < mdbreakpoint ? 10 : width < lgBreakpoint ? 25 : 100,
                marginTop: 'auto',
                marginBottom: 'auto',
                zIndex: 10,
              }}
              disabled={i.index === assets.length - 1}
            >
              {/* <Text
                style={{
                  fontSize: 48,
                  color: i.index === assets.length - 1 ? 'gray' : theme.backgroundColor,
                }}
              >
                {'>'}
              </Text> */}
              <Image style={{height: 20, width: 24, opacity: i.index === assets.length - 1 ? 0.5 : 1}} source={greenArrow} />
            </TouchableOpacity>
          </View>
        )}
      />
    </View>
  );
});
