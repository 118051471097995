import { StaticThemeColors } from './themes/types';
import { redBrand, greenBrand, blueBrand, whiteBrand } from '../libs/constants';

export function getStaticColors(): StaticThemeColors {
  return {
    red: redBrand,
    green: greenBrand,
    blue: blueBrand,
    white: whiteBrand,
  };
}
