// import Expo from 'expo';
import React, { useRef, useState } from 'react';
import {
  StyleSheet,
  View,
  ImageSourcePropType,
  Image,
  Text,
} from 'react-native';
import { GLView } from 'expo-gl';

import { Sketch } from '../../libs/sketch/Sketch';
import { useThemeState } from '../../context/ThemeContext';
import { PanamaBold } from '../../libs/constants';
import { DrawingPrompts } from './DrawingPrompts';
import { UpdateArtSubmissionForm } from '../forms/UpdateArtSubmissionForm';

interface SketchProps {}

export const Draw: React.FC<SketchProps> = React.memo((props: SketchProps) => {
  const { theme } = useThemeState();
  const [image, setImage] = useState<ImageSourcePropType | null>(null);
  // const [strokeColor, setStrokeColor] = useState(Math.random() * 0xffffff);
  // let strokeColor = Math.floor(Math.random() * 0xffffff);
  const [strokeWidth, setStrokeWidth] = useState(Math.random() * 30 + 10);
  const [submittedImage, setSubmittedImage] = useState<string | null>(null);
  const [submittedImageID, setSubmittedImageID] = useState<string | null>(null);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const cursor = require(`@happymedium/components/assets/images/logo-${
    theme.logoColor
  }@200x.png`);

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      borderTopWidth: 1,
      borderColor: theme.foregroundColor,
    },
    sketch: {
      flex: 1,
      borderColor: theme.foregroundColor,
      borderWidth: 1,
      marginHorizontal: 25,
      cursor: 'crosshair',
    },
    submittedImage: {
      height: 550,
      borderColor: theme.foregroundColor,
      borderWidth: 1,
      marginHorizontal: 25,
      backgroundColor: 'white',
    },
    sketchContainer: {
      height: 700,
    },
    image: {
      flex: 1,
    },
    imageContainer: {
      height: 700,
      width: 400,
      borderTopWidth: 4,
      borderTopColor: '#E44262',
    },
    label: {
      width: '100%',
      padding: 5,
      alignItems: 'center',
    },
    button: {
      // position: 'absolute',
      // bottom: 8,
      // left: 8,
      zIndex: 1,
      padding: 12,
      minWidth: 56,
      minHeight: 48,
    },
    draw: {
      fontFamily: PanamaBold,
      fontSize: 28,
      letterSpacing: 1.3,
      color: theme.foregroundColor,
    },
    headerRow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-evenly',
      marginHorizontal: 25,
      marginTop: 30,
      marginBottom: 15,
    },
    refreshIcon: {
      height: 18,
      width: 20,
    },
    masterpiece: {
      fontFamily: PanamaBold,
      fontSize: 28,
      letterSpacing: 1.3,
      color: theme.foregroundColor,
      textAlign: 'center',
    },
    masterpieceContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      marginHorizontal: 35,
      marginTop: 30,
      marginBottom: 15,
    },
  });

  const onChangeAsync = async (url: string, id: string) => {
    // const dataUrl = document.getElementById('one');
    // const uuuRL = (dataUrl as HTMLCanvasElement).toDataURL();
    setSubmittedImage(url);
    setSubmittedImageID(id);
  };
  const sketchRef = useRef<GLView>(null);

  const onReady = () => {
    console.log('ready!');
  };

  const changeColor = () => {
    console.log('changeColor');
    // strokeColor = 0xff0f0d;
  };

  return (
    <View style={styles.container}>
      <View style={styles.container}>
        {!submittedImage ? (
          <DrawingPrompts />
        ) : (
          <View style={styles.masterpieceContainer}>
            <Text style={styles.masterpiece}>Your Masterpiece</Text>
          </View>
        )}

        <View style={styles.sketchContainer}>
          {!submittedImage ? (
            <Sketch
              ref={sketchRef}
              style={styles.sketch}
              // strokeColor={strokeColor}
              strokeWidth={strokeWidth}
              strokeAlpha={1}
              onChange={onChangeAsync}
              onReady={onReady}
              // initialLines={lines}
            />
          ) : (
            <>
              <Image
                style={styles.submittedImage}
                source={submittedImage as any}
              />

              <UpdateArtSubmissionForm
                setFormSubmitted={setFormSubmitted}
                id={submittedImageID!}
              />
            </>
          )}
        </View>
      </View>
    </View>
  );
});
