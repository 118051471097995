import React from 'react';
import {
  ScrollView,
  View,
  Text,
  ImageSourcePropType,
  TouchableOpacity,
  StyleSheet,
  Image,
} from 'react-native';
import { Hoverable } from 'react-native-web-hooks';

import { useThemeState } from '../context/ThemeContext';
import { useDimensions } from '../context/DimensionsContext';
import {
  GTAmericaCompressed,
  greenBrand,
  blackBrand,
  redBrand,
  wineBrand,
} from '../libs/constants';
import { CuratorHeader } from '../components/curator/CuratorHeader';
import { lgBreakpoint, smBreakpoint } from '../constants';
import { PhoneNumberSignupForm } from '../components/forms/PhoneNumberSignupForm';
import { HoverableLink } from '../components/common/HoverableLink';

interface Props {}

export const EventOne: React.FC<Props> = () => {
  const { theme } = useThemeState();
  const { width } = useDimensions();
  const measuringTape = require('@happymedium/components/assets/images/blue-white-measure-tape-long-horizontal.png');
  const styles = StyleSheet.create({
    infoText: {
      fontFamily: GTAmericaCompressed,
      fontWeight: '500',
      fontSize: 20,
      color: blackBrand,
    },
  });

  return (
    <ScrollView
      style={{
        flex: 1,
        backgroundColor: greenBrand,
      }}
    >
      <CuratorHeader
        backgroundColor={greenBrand}
        hideThemePicker={true}
        logoColor={'black'}
        linkColor={blackBrand}
        isRow={true}
      />
      <View
        style={{
          flex: 1,
          alignItems: 'center',
          alignContent: 'center',
          justifyContent: 'center',
          backgroundColor: greenBrand,
          flexDirection: width > lgBreakpoint ? 'row' : 'column',
          paddingTop: width < lgBreakpoint ? 0 : 50,
        }}
      >
        <View>
          <View
            style={{
              backgroundColor: redBrand,
              paddingVertical: 5,
              // alignItems: 'center',
              maxWidth: '100vw',
              width: 500,
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          >
            <Text
              style={[
                styles.infoText,
                {
                  textTransform: 'uppercase',
                  fontSize: 24,
                  fontWeight: '700',
                  color: wineBrand,
                  textAlign: 'center',
                },
              ]}
            >
              Sold Out
            </Text>
          </View>
          {/* <Text
            style={[styles.infoText, { marginBottom: 20, textAlign: 'center' }]}
          >
            DM us on{' '}
            <HoverableLink
              linkUrl="https://www.instagram.com/gethappymedium/"
              textStyle={styles.infoText}
            >
              Instagram
            </HoverableLink>{' '}
            to reserve your seat.
          </Text> */}
          <Image
            source={
              'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/011/Stripo+Resized.jpg' as ImageSourcePropType
            }
            style={{
              height: width < smBreakpoint ? 540 : 718,
              width: 500,
              maxWidth: '100vw',
              maxHeight: '100vh',
              marginHorizontal: 10,
              resizeMode: 'contain',
            }}
          />
        </View>
      </View>
    </ScrollView>
  );
};
