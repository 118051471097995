// import 'pixi.js-legacy';
import * as PIXI from 'pixi.js';
import { ExpoWebGLRenderingContext } from 'expo-gl';

export const pixiApp = new PIXI.Application();

interface PixiAppOptions {
  autoStart?: boolean;
  width?: number;
  height?: number;
  view?: HTMLCanvasElement;
  transparent?: boolean;
  autoDensity?: boolean;
  antialias?: boolean;
  preserveDrawingBuffer?: boolean;
  resolution?: number;
  forceCanvas?: boolean;
  backgroundColor?: number;
  clearBeforeRender?: boolean;
  forceFXAA?: boolean;
  powerPreference?: string;
  sharedTicker?: boolean;
  sharedLoader?: boolean;
  resizeTo?: Window | HTMLElement;
}

interface Props extends PixiAppOptions {
  context: ExpoWebGLRenderingContext;
}

export const createPixiApp = (props: Props) => {
  const {
    context,
    width,
    height,
    backgroundColor = 0xffffff,
    transparent = true,
    preserveDrawingBuffer,
  } = props;

  const app = new PIXI.Application({
    width,
    height,
    backgroundColor,
    // forceCanvas: true,
    antialias: true,
    // view: context.canvas ? (context.canvas as any) : undefined,
    transparent,
    preserveDrawingBuffer,
  });
  app.ticker.add(() => {
    // console.log('TICKER', app.ticker);
    context.endFrameEXP();
  });
  return app;
};
