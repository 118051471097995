import React from 'react';
import { View, Image } from 'react-native';

import { NewSubscribeForm } from '../components/forms/NewSubscribeForm';
import { RouteComponentProps } from '../navigation';

interface Props {}

export const NewsletterSignupScreen: React.FC<RouteComponentProps<{
  email: string;
}>> = (props: RouteComponentProps<{ email: string }>) => {
  const {
    match: {
      params: { email },
    },
  } = props;
  

  return (
    <View>
      <NewSubscribeForm email={email} />
    </View>
  );
};
