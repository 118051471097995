import React, { useEffect, useState } from 'react';
import { View, Text, Button } from 'react-native';
import { useMutation } from '@apollo/react-hooks';

import { RouteComponentProps } from '../navigation';
import { UnsubscribeNewsletterDocument } from '../components/ApolloComponents';

export const UnsubscribeScreen: React.FC<RouteComponentProps<{
  email: string;
}>> = (props: RouteComponentProps<{ email: string }>) => {
  const {
    match: {
      params: { email },
    },
  } = props;

  const [success, setSuccess] = useState(false);

  const [unsubscribe] = useMutation(UnsubscribeNewsletterDocument);

  const unsub = async () => {
    if (!email) {
      return;
    }
    const res = await unsubscribe({
      variables: {
        email,
      },
    });
    console.log('res', res.data.unsubscribe.success);

    setSuccess(res.data.unsubscribe.success);
    console.log('success', success);
  };

  useEffect(() => {
    unsub();
  }, []);

  return (
    <View>
      <Text>{success ? `${email} is unsubscribed` : '...'}</Text>
    </View>
  );
};
