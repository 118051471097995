import React, { useState, useEffect, useRef } from 'react';
import { View, FlatList, FlatListProps, Text, Image, Animated } from 'react-native';

import { CuratedActivity } from './CuratedActivity';
import { Activity } from '../ApolloComponents';
import { Filter, filterArray } from '../../utils/shared';
import { useThemeState } from '../../context/ThemeContext';
import { StyleSheet } from 'react-native';
import { useDimensions } from '../../context/DimensionsContext';
import { GTAmerica } from '../../libs/constants';

interface Props {
  activities: Activity[];
  selectedFilters: string[];
  activityTypes: string[];
}

export const Activities: React.FC<Props> = ({
  activities,
  selectedFilters,
  activityTypes,
}) => {
  const lgBreakpoint = 1180;
  const mdBreakpoint = 860;
  const smBreakpoint = 420;
  const greenSquiggle = require(`@happymedium/components/assets/images/squiggles-green.png`);
  const redSquiggle = require(`@happymedium/components/assets/images/squiggles-red.png`);
  const blueSquiggle = require(`@happymedium/components/assets/images/squiggles-blue.png`);

  const fadeAnim = useRef(new Animated.Value(0)).current

  useEffect(() => {
    Animated.timing(
      fadeAnim,
      {
        toValue: 1,
        duration: 2000
      }
    ).start()
  }, [fadeAnim])

  const filter: Filter<Activity> = {
    activityType: (activity) =>
      activity.activityType.find((x) =>
        selectedFilters.includes(x.toLowerCase())
      ),
  };

  const { theme } = useThemeState();
  const { width } = useDimensions();

  const squiggle = require(`@happymedium/components/assets/images/squiggles-${
    theme.logoColor
  }.png`);

  useEffect(() => {
    if (selectedFilters.length) {
      setActivitiesToShow(filterArray<Activity>(activities, filter));
    } else if (
      !selectedFilters.length ||
      activityTypes.length === selectedFilters.length
    ) {
      setActivitiesToShow(activities);
    }
  }, [selectedFilters]);

  const [activitiesToShow, setActivitiesToShow] = useState(() => {
    if (
      !selectedFilters.length ||
      activityTypes.length === selectedFilters.length
    ) {
      return activities;
    }
  });

  const styles = StyleSheet.create({
    separator: {
      height: width < smBreakpoint ? 21 : width < mdBreakpoint ? 29 : 33,
      width: width < smBreakpoint ? 81 : width < mdBreakpoint ? 106 : 123,
      alignSelf: 'center',
    },
  });
  // type Separators = greenSquiggle | 'redSquiggle' | 'blueSquiggle'
  const separators = [greenSquiggle, redSquiggle, blueSquiggle];
  const [separatorIndex, _setSeparatorIndex] = useState(0);
  // const [separator, setSepator] = useState(separators[separatorIndex]);

  const separatorIndexRef = React.useRef(separatorIndex);
  const setSeparatorIndex = (separatorIndexState: number) => {
    separatorIndexRef.current = separatorIndexState;
    _setSeparatorIndex(separatorIndexState);
  };

  const renderSeparator = (obj: any, index: any) => {
    // while (separatorIndex <= separators.length) {
    // if (separatorIndexRef.current < separators.length - 1) {
    //   setSeparatorIndex(separatorIndex + 1);
    // } else if (separatorIndexRef.current === separators.length - 1) {
    //   setSeparatorIndex(1);
    // }
    // }

    return (
      <View>
        <Image style={styles.separator} source={squiggle} />
      </View>
    );
  };

  const renderItem: FlatListProps<Partial<Activity>>['renderItem'] = ({
    item: activity,
    index,
  }) => {
    return <CuratedActivity index={index} activity={activity} />;
  };

  const listEmptyComponent = (
    <View style={{ minHeight: '100vh' }}>
      <Text
        style={{
          minHeight: '100%',
          fontFamily: GTAmerica,
          fontSize: 16,
          color: theme.foregroundColor,
        }}
      >
        No Activities to show
      </Text>
    </View>
  );

  const keyExtractor: FlatListProps<Partial<Activity>>['keyExtractor'] = (
    activity
  ) => {
    return `activity-card-${activity.id}`;
  };
  return (
    <>
      <Animated.View
        style={{
          height: '100%',
          minHeight: '100%',
          paddingBottom: 100,
          backgroundColor: theme.backgroundColor,
          opacity: (fadeAnim as unknown) as number
        }}
      >
        <FlatList
          keyExtractor={keyExtractor}
          renderItem={renderItem}
          data={activitiesToShow?.sort((a, b) => {
            // return a.rank === null ? -1 : b.rank === null ? 1 : a.rank!.toString().localeCompare(b.rank!.toString());
            return ((b.rank != null) as any) - ((a.rank != null) as any) || a.rank! - b.rank!;
          })}
          ItemSeparatorComponent={renderSeparator}
          ListEmptyComponent={listEmptyComponent}
        />
      </Animated.View>
    </>
  );
};
