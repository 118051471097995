import { greenBrand, whiteBrand } from '../../libs/constants';
import { getStaticColors } from '../colors';
import { Theme } from './types';

export const theme: Theme = {
  primaryForegroundColor: greenBrand,
  foregroundColor: greenBrand,
  primaryBackgroundColor: whiteBrand,
  backgroundColor: whiteBrand,
  logoColor: 'green',
  id: 'green',
  displayName: 'Green',
  ...getStaticColors(),
};
