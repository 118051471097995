import React from 'react';
import { View, Image, ImageURISource, StyleSheet, Text } from 'react-native';
import { Hoverable } from 'react-native-web-hooks';

import {
  greenBrand,
  PanamaBold,
  GTAmerica,
  GTAmericaCompressed,
} from '../../../libs/constants';
import { useDimensions } from '../../../context/DimensionsContext';
import { mdBreakpoint, smBreakpoint, lgBreakpoint } from '../../../constants';
import { TouchableOpacity } from '../../common/TouchableOpacity';

interface SalonIssueOneProps {}

export const SalonIssueFive: React.FC<SalonIssueOneProps> = () => {
  const { width } = useDimensions();
  const blueSquiggle = require('@happymedium/components/assets/images/squiggles-blue.png');
  const redSquiggle = require('@happymedium/components/assets/images/squiggles-red.png');

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      flexDirection: 'column',
    },
    innerContainer: {
      maxWidth: width < 900 ? '85%' : 700,
      alignSelf: 'center',
    },
    imageContainer: {
      alignItems: 'center',
      marginVertical:
        width < smBreakpoint ? 20 : width < mdBreakpoint ? 35 : 60,
    },
    text: {
      color: greenBrand,
      flexWrap: 'wrap',
    },
    header: {
      fontFamily: PanamaBold,
      letterSpacing: 1.3,
      fontSize: width < smBreakpoint ? 28 : width < mdBreakpoint ? 36 : 48,
      marginBottom: width < smBreakpoint ? 5 : width < mdBreakpoint ? 15 : 20,
    },
    body: {
      fontFamily: GTAmerica,
      fontWeight: '300',
      letterSpacing: 0.45,
      fontSize: width < smBreakpoint ? 14 : width < mdBreakpoint ? 15 : 16,
      marginVertical: 10,
    },
    imageDescription: {
      fontFamily: GTAmericaCompressed,
      fontWeight: '300',
      letterSpacing: 0.45,
      fontSize: width < smBreakpoint ? 12 : width < mdBreakpoint ? 14 : 16,
      marginVertical: width < smBreakpoint ? 5 : 8,
      textAlign: 'center',
      maxWidth: width < smBreakpoint ? '90vw' : '100%',
    },
    shareButton: {},
    arrow: {},
    squiggle: {
      height: 31,
      width: 123,
      alignSelf: 'center',
      resizeMode: 'contain',
      marginBottom: 0,
      marginTop: 25,
    },
    image: {
      marginHorizontal: 30,
      maxWidth: width < mdBreakpoint ? '80%' : '50%',
    },
    imageVertical: {
      height: width < smBreakpoint ? 400 : width < mdBreakpoint ? 500 : 550,
      width: '100%',
      alignSelf: 'center',
      resizeMode: 'contain',
    },
    imageHorizontal: {
      height:
        width < smBreakpoint
          ? 280
          : width < mdBreakpoint
          ? 350
          : width < lgBreakpoint
          ? 420
          : 550,
      width: '100%',
      alignSelf: 'center',
      resizeMode: 'contain',
    },
  });

  const renderHeader = (copy: string) => (
    <Text style={[styles.text, styles.header]}>{copy}</Text>
  );

  const renderParagraph = (copy: string) => (
    <Text style={[styles.text, styles.body]}>{copy}</Text>
  );

  const renderImageDescription = (copy: string) => (
    <Text style={[styles.text, styles.imageDescription]}>{copy}</Text>
  );

  return (
    <View style={styles.container}>
      <View
        style={{
          height: width < smBreakpoint ? 10 : width < mdBreakpoint ? 25 : 30,
        }}
      />
      <View style={{ flexDirection: width < lgBreakpoint ? 'column' : 'row' }}>
        <View style={[styles.imageContainer, { flexGrow: 1 }]}>
          <Image
            style={[
              styles.image,
              styles.imageVertical,
              {
                marginHorizontal: 0,
                maxWidth: '100%',
                height:
                  width < smBreakpoint
                    ? 320
                    : width < mdBreakpoint
                    ? 380
                    : width < lgBreakpoint
                    ? 400
                    : 480,
              },
            ]}
            source={
              'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/005/1+-+resize.jpg' as ImageURISource
            }
          />
          {renderImageDescription('Jean Metzinger, 1912ish')}
        </View>
        <View
          style={{
            flexDirection: 'column',
            flexShrink: 1,
            maxWidth: width < 900 ? '85%' : 700,
            flexWrap: 'wrap',
            marginHorizontal:
              width < smBreakpoint ? undefined : width < mdBreakpoint ? 25 : 50,
            alignSelf: 'center',
          }}
        >
          {width < smBreakpoint && <View style={{ height: 30 }} />}
          {renderHeader('Jean Metzinger, Cubism, & Quantum Theory')}

          {renderParagraph(
            'Pablo Picasso is known as the father of Cubism, but Jean Metzinger was its biggest champion. Read more about Jean, his work, and how his paintings helped shape our knowledge of quantum theory.'
          )}
        </View>
      </View>
      <View
        style={[
          styles.imageContainer,
          {
            marginBottom:
              width < smBreakpoint ? 30 : width < mdBreakpoint ? 20 : 0,
            marginTop: 20,
          },
        ]}
      >
        <Image style={[styles.image, styles.squiggle]} source={redSquiggle} />
      </View>
      <View style={[styles.innerContainer]}>
        <View style={{ height: 40 }} />
        {renderHeader('Who was Jean Metzinger?')}
        {renderParagraph(
          'Jean was born in 1883 in Nannes, France to a military family. His father died at a young age and his mother, a music professor, wanted him to become a doctor but he was more interested in math and painting, a foreshadowing of his involvement with the cubist movement. At 20 he moved to Paris and was able to immediately begin supporting himself as a painter, which he did until the end of his life in 1953.'
        )}
      </View>

      <View style={[styles.imageContainer, { marginVertical: 30 }]}>
        <Image
          style={[styles.image, styles.imageVertical]}
          source={
            'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/005/2-+resize.jpg' as ImageURISource
          }
        />
        {renderImageDescription(
          'La Danse by Metzinger, 1906 - a Divisionist style painting'
        )}
      </View>
      <View style={styles.innerContainer}>
        {renderHeader('From Color to Form')}
        {renderParagraph(
          'Before discovering Cubism, Jean dabbled in many different styles of painting throughout his lifetime. When you look at the arc of his career, it’s easy to see how interest in one style led him to the next and how external forces, like war, introduced objects to his work.'
        )}
        {renderParagraph(
          'His first style of painting after moving to Paris was Divisionist in which each brush stroke is cube-like and consists of one single color. This broken-down style of painting would be reflected again in a few years when he got into Cubism.'
        )}
      </View>

      <View style={styles.imageContainer}>
        <Image
          style={[
            styles.image,
            styles.imageHorizontal,
            {
              height:
                width < smBreakpoint
                  ? 225
                  : width < mdBreakpoint
                  ? 270
                  : width < lgBreakpoint
                  ? 340
                  : 430,
            },
          ]}
          source={
            'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/005/3+-+resize.jpg' as ImageURISource
          }
        />
        {renderImageDescription(
          'Paysage coloré aux oiseaux aquatiques by Meetzinger, 1907 – a Post-Divisionist style painting with a Fauvist palette'
        )}
      </View>

      <View style={styles.innerContainer}>
        {renderParagraph(
          'In 1905, he started incorporating the Fauvist style into his work, which used loud colors to express strong emotion.'
        )}
        {renderParagraph(
          'Both Divisionist and Fauvist styles are concerned almost exclusively with color. But in 1907, Metzinger became interested in Cubism, which put more emphasis on shape.'
        )}
        {renderParagraph(
          'The original Cubist movement started to break down with the outbreak of WWI. Some artists served in the front lines, and those who didn’t were still shocked by the atrocities of war. As a result, many abandoned the avant-garde movements that had been popular and favored returning to traditional styles, a form of normalcy.'
        )}
      </View>

      <View style={styles.imageContainer}>
        <Image
          style={[
            styles.image,
            styles.imageHorizontal,
            {
              height:
                width < smBreakpoint
                  ? 225
                  : width < mdBreakpoint
                  ? 270
                  : width < lgBreakpoint
                  ? 340
                  : 430,
            },
          ]}
          source={
            'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/005/4.-+resize.jpg' as ImageURISource
          }
        />
        {renderImageDescription(
          'Nature Morte by Metzinger, 1929 – a “mechanical world” style painting'
        )}
      </View>

      <View style={styles.innerContainer}>
        {renderParagraph(
          'But Metzinger was not one of them. Instead, he became even more interested in modernity and specifically the “mechanical world.” He started incorporating machinery into his cubist works, like the painting below.'
        )}
        {renderParagraph(
          'Though he dabbled in softer and more natural styles of painting later in life, he never really gave up on Cubism. Which brings me to…'
        )}
      </View>

      <View style={styles.imageContainer}>
        <Image
          style={[styles.image, styles.imageVertical]}
          source={
            'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/005/5+-+resize.jpg' as ImageURISource
          }
        />
        {renderImageDescription(
          'Nu aux Hortensias by Metzinger, 1935 – a more classical approach'
        )}
      </View>

      <View style={styles.innerContainer}>
        {renderHeader('What is Cubism?')}
        {renderParagraph(
          'Most of us know Cubism by the distinct visual style – elementary shapes that seem to form an abstracted version of reality. But Cubism is arguably the most realistic, most alive, style of painting because of the idea it represents.'
        )}
        {renderParagraph(
          'Since the Renaissance, most paintings were depicted in 3D. Length, width, and depth were used to invoke an accurate representation of reality. But for Cubists, this wasn’t enough.'
        )}
        {renderParagraph(
          'One of the central arguments of Cubist theory is that the world can only be experienced through “sensations” and 3D alone could not invoke the sensations needed to experience the world. Instead, Cubists sought to depict reality in 4D – length, width, depth, and time.'
        )}
        {renderParagraph(
          'So ya, all the crazy shapes you see – that’s an attempt to depict the sensation of the moment by depicting time.'
        )}
      </View>

      <View style={styles.imageContainer}>
        <Image
          style={[
            styles.image,
            styles.imageHorizontal,
            {
              height:
                width < smBreakpoint
                  ? 240
                  : width < mdBreakpoint
                  ? 280
                  : width < lgBreakpoint
                  ? 340
                  : 430,
            },
          ]}
          source={
            'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/005/6+-+resize.jpg' as ImageURISource
          }
        />
        {renderImageDescription(
          'Still Life with Compote and Glass, 1914 by Picasso – an Analytical Cubist painting'
        )}
      </View>

      <View style={styles.innerContainer}>
        {renderParagraph(
          'There were two types of Cubists – the analytical and the synthetic. Analytical Cubists tried to analyze things by breaking them down into their essential forms. Picasso was an Analytical Cubist.'
        )}
        {renderParagraph(
          'Metzinger was a Synthetic Cubist. Instead of breaking things down, Synthetic Cubists tried to build them up by representing many different viewpoints of the same object within the constraints of a single canvas. Metzinger often did this by what he called “mobile perspective” or literally turning the objects he was painting around so he could paint them from various viewpoints.'
        )}
      </View>

      <View style={styles.imageContainer}>
        <Image
          style={[styles.image, styles.imageVertical]}
          source={
            'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/005/7+-+resize.jpg' as ImageURISource
          }
        />
        {renderImageDescription(
          "La Femme à l'Éventail by Metzinger, 1913 – a Synthetic Cubist painting"
        )}
      </View>

      <View style={styles.innerContainer}>
        {renderHeader('Space-Time Continuum')}
        {renderParagraph(
          'In a nutshell, Metzinger’s paintings are playing with the space-time continuum by trying to depict both space in time in the same visual format. Metzinger wrote extensively on Cubist theory and his writings attracted a fanboy - Danish physicist named Neils Bohr.'
        )}
        {renderParagraph(
          'Metzinger’s writings on Cubist theory helped influence Bohrs own theories about electrons and Cubism made the jump from visual art to science. Bohr later won the Nobel Prize for his contributions to quantum theory and he always kept a one of Metzingers paintings hanging in his office.'
        )}
        <Image
          style={{ height: 60, width: 120, resizeMode: 'contain' }}
          source={require('@happymedium/components/assets/images/logo-hm-abbreviated-green.png')}
        />
      </View>

      <View style={styles.imageContainer}>
        <Image
          style={[
            styles.image,
            styles.imageVertical,
            {
              height:
                width < smBreakpoint
                  ? 320
                  : width < mdBreakpoint
                  ? 380
                  : width < lgBreakpoint
                  ? 400
                  : 480,
            },
          ]}
          source={
            'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/005/8+-+resize.jpg' as ImageURISource
          }
        />
        {renderImageDescription(
          'La Femme au Cheval by Metzinger, 1911 -  this hung in Bohrs office'
        )}
      </View>

      <View style={[styles.imageContainer, { marginBottom: 0, marginTop: 20 }]}>
        <Image style={[styles.image, styles.squiggle]} source={blueSquiggle} />
      </View>

      <View style={{ height: 80 }} />
    </View>
  );
};
