import React from 'react';
import { View, Image, ImageURISource, StyleSheet, Text } from 'react-native';

import {
  greenBrand,
  PanamaBold,
  GTAmerica,
  GTAmericaCompressed,
} from '../../../libs/constants';
import { useDimensions } from '../../../context/DimensionsContext';
import { mdBreakpoint, smBreakpoint, lgBreakpoint } from '../../../constants';
import { Hoverable } from 'react-native-web-hooks';
import { TouchableOpacity } from '../../common/TouchableOpacity';

interface SalonIssueOneProps {}

export const SalonIssueThree: React.FC<SalonIssueOneProps> = () => {
  const { width } = useDimensions();
  const blueSquiggle = require('@happymedium/components/assets/images/squiggles-blue.png');
  const redSquiggle = require('@happymedium/components/assets/images/squiggles-red.png');

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      flexDirection: 'column',
    },
    innerContainer: {
      maxWidth: width < 900 ? '85%' : 700,
      alignSelf: 'center',
    },
    imageContainer: {
      alignItems: 'center',
      marginVertical:
        width < smBreakpoint ? 20 : width < mdBreakpoint ? 35 : 60,
    },
    text: {
      color: greenBrand,
      flexWrap: 'wrap',
    },
    header: {
      fontFamily: PanamaBold,
      letterSpacing: 1.3,
      fontSize: width < smBreakpoint ? 28 : width < mdBreakpoint ? 36 : 48,
      marginBottom: width < smBreakpoint ? 5 : width < mdBreakpoint ? 15 : 20,
    },
    body: {
      fontFamily: GTAmerica,
      fontWeight: '300',
      letterSpacing: 0.45,
      fontSize: width < smBreakpoint ? 14 : width < mdBreakpoint ? 15 : 16,
      marginVertical: 10,
    },
    imageDescription: {
      fontFamily: GTAmericaCompressed,
      fontWeight: '300',
      letterSpacing: 0.45,
      fontSize: width < smBreakpoint ? 12 : width < mdBreakpoint ? 14 : 16,
      marginVertical: width < smBreakpoint ? 5 : 8,
      textAlign: 'center',
      maxWidth: width < smBreakpoint ? '90vw' : '100%',
    },
    shareButton: {},
    arrow: {},
    squiggle: {
      height: 31,
      width: 123,
      alignSelf: 'center',
      resizeMode: 'contain',
      marginBottom: 0,
      marginTop: 25,
    },
    image: {
      marginHorizontal: 30,
      maxWidth: width < mdBreakpoint ? '80%' : '50%',
    },
    imageVertical: {
      height: width < smBreakpoint ? 400 : width < mdBreakpoint ? 500 : 550,
      width: '100%',
      alignSelf: 'center',
      resizeMode: 'contain',
    },
    imageHorizontal: {
      height:
        width < smBreakpoint
          ? 280
          : width < mdBreakpoint
          ? 350
          : width < lgBreakpoint
          ? 420
          : 550,
      width: '100%',
      alignSelf: 'center',
      resizeMode: 'contain',
    },
  });

  const renderHeader = (copy: string) => (
    <Text style={[styles.text, styles.header]}>{copy}</Text>
  );

  const renderParagraph = (copy: string) => (
    <Text style={[styles.text, styles.body]}>{copy}</Text>
  );

  const renderImageDescription = (copy: string) => (
    <Text style={[styles.text, styles.imageDescription]}>{copy}</Text>
  );

  return (
    <View style={styles.container}>
      <View
        style={{
          height: width < smBreakpoint ? 10 : width < mdBreakpoint ? 25 : 30,
        }}
      />
      <View style={{ flexDirection: width < lgBreakpoint ? 'column' : 'row' }}>
        <View style={[styles.imageContainer, { flexGrow: 1 }]}>
          <Image
            style={[
              styles.image,
              styles.imageHorizontal,
              {
                marginHorizontal: 0,
                maxWidth: '100%',
                height:
                  width < smBreakpoint
                    ? 280
                    : width < mdBreakpoint
                    ? 380
                    : width < lgBreakpoint
                    ? 400
                    : 480,
                // marginBottom:
                //   width <= mdBreakpoint && width > smBreakpoint
                //     ? 20
                //     : width < lgBreakpoint && width > mdBreakpoint
                //     ? 40
                //     : undefined,
              },
            ]}
            source={
              'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/003/Img+1+resize.jpg' as ImageURISource
            }
          />
          {renderImageDescription(
            'Queen Elizabeth II by Lucian Freud, 2001 \nwhich obv caused some backlash'
          )}
        </View>
        <View
          style={{
            flexDirection: 'column',
            flexShrink: 1,
            maxWidth: width < 900 ? '85%' : 700,
            flexWrap: 'wrap',
            marginHorizontal:
              width < smBreakpoint ? undefined : width < mdBreakpoint ? 25 : 50,
            alignSelf: 'center',
          }}
        >
          {width < smBreakpoint && <View style={{ height: 30 }} />}
          {renderHeader('The Queen’s Art Stash')}
          <Text style={[styles.text, styles.body]}>
            This past Sunday, season four of The Crown dropped on Netflix. The
            Brooklyn Museum commemorated the new season by teaming up with
            Netflix to host a digital costume exhibit called{' '}
            <Hoverable>
              {(isHovered) => (
                <TouchableOpacity
                  style={{ margin: 0 }}
                  onPress={() =>
                    window.open(
                      'https://www.thequeenandthecrown.com/',
                      '__blank'
                    )
                  }
                >
                  <Text
                    style={[
                      styles.text,
                      styles.body,
                      {
                        textDecorationLine: 'underline',
                        fontWeight: isHovered ? '600' : '400',
                        margin: 0,
                      },
                    ]}
                  >
                    The Queen and the Crown
                  </Text>
                </TouchableOpacity>
              )}
            </Hoverable>
            <Text style={[styles.text, styles.body]}>.</Text>
          </Text>

          {renderParagraph(
            'I commemorated the event by digging deeper into the Queen’s art collection – the largest, and most mysterious, private art collection in the world.'
          )}
        </View>
      </View>
      <View
        style={[
          styles.imageContainer,
          {
            marginBottom:
              width < smBreakpoint ? 30 : width < mdBreakpoint ? 20 : 0,
            marginTop: 20,
          },
        ]}
      >
        <Image style={[styles.image, styles.squiggle]} source={redSquiggle} />
      </View>
      <View style={styles.imageContainer}>
        <Image
          style={[styles.image, styles.imageVertical]}
          source={
            'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/003/Img+2+resize.jpg' as ImageURISource
          }
        />
        {renderImageDescription(
          'A sketch by Leonardo da Vinci for his lost painting, \nLeda and the Swan, from the Royal Collection'
        )}
      </View>
      <View style={[styles.innerContainer]}>
        {renderHeader('A Brief History')}
        {renderParagraph(
          'Since the 1660s, England’s monarchs have been amassing a very large, very expensive collection of art. It spans nearly one million pieces and is valued at $13B. By comparison, the Louvre, an iconic French museum where Jay-Z and Beyoncé filmed "Apesh*t" in 2018, has 380,000 items with an estimated value of ~$35B.'
        )}
        {renderParagraph(
          'Ownership of the collection is murky with the Queen technically owning all of it, but some as a private individual and some on behalf of the monarchy, meaning it will be passed down to her heir. The collection is managed by the Royal Collection Trust, which was started in 1993 after a fire ripped through Windsor Castle and destroyed some artwork.'
        )}
        {renderParagraph(
          'The collection is housed across 13 royal residences and only a fraction of it has ever been revealed to the public, meaning no one really knows what’s in it. Because of the public nature of her position, this has raised some eyebrows in the UK – should more of her art be accessible to the public?'
        )}
      </View>
      <View style={styles.imageContainer}>
        <Image
          style={[
            styles.image,
            styles.imageVertical,
            {
              // height:
              //   width < smBreakpoint
              //     ? 230
              //     : width < mdBreakpoint
              //     ? 250
              //     : width < lgBreakpoint
              //     ? 310
              //     : 400,
            },
          ]}
          source={
            'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/003/Img+3+resize.jpg' as ImageURISource
          }
        />
        {renderImageDescription('Portrait of Dr. Gachet by Van Gogh, 1890')}
      </View>
      <View style={styles.innerContainer}>
        {renderHeader('Public vs. Private Art')}
        <Text style={[styles.text, styles.body]}>
          Allowing public access to private art collections is a relatively
          recent phenomenon. Starting in the 19th century, private art
          collectors began loaning pieces to museums for the first time. In
          today’s internet-powered world, more private collectors are warming up
          to the idea of sharing their collections digitally. Last year, the
          first museum for private art opened{' '}
          <Hoverable>
            {(isHovered) => (
              <TouchableOpacity
                style={{ margin: 0 }}
                onPress={() =>
                  window.open('https://www.collecteurs.com/', '__blank')
                }
              >
                <Text
                  style={[
                    styles.text,
                    styles.body,
                    {
                      textDecorationLine: 'underline',
                      fontWeight: isHovered ? '600' : '400',
                      margin: 0,
                    },
                  ]}
                >
                  online
                </Text>
              </TouchableOpacity>
            )}
          </Hoverable>
          <Text style={[styles.text, styles.body]}>.</Text>
        </Text>
        {renderParagraph(
          'For many private collectors, the choice to make their art accessible is driven by a sense of duty to the artists and the public and the sentiment that great art should be shared. But other collectors feel no such responsibility and keep their collections private. In some cases, famous works of art have even “disappeared” into the hands of private collectors, never to be seen again.'
        )}
        {renderParagraph(
          'The most notorious example of this is the case of Van Gogh’s Portrait of Dr. Gachet. Dr. Gachet was a homeopathic doctor who treated Van Gogh after he famously sliced off part of his ear. The two became friends and Van Gogh painted his portrait, which was confiscated in 1937 from a Frankfurt art museum by the Nazis.'
        )}
        {renderParagraph(
          'In 1990, it was auctioned at Christie’s and purchased by Ryoei Saito, a wealthy Japanese businessman, who said he hoped to have the work cremated with him when he died. Saito died in 1996 and the painting’s whereabouts remain unknown.'
        )}
      </View>
      <View style={styles.imageContainer}>
        <Image
          style={[
            styles.image,
            styles.imageHorizontal,
            {
              height:
                width < smBreakpoint
                  ? 240
                  : width < mdBreakpoint
                  ? 280
                  : width < lgBreakpoint
                  ? 340
                  : 430,
            },
          ]}
          source={
            'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/003/Img+4.jpg' as ImageURISource
          }
        />
        {renderImageDescription(
          'From the Royal Collection:\nTribuna of the Uffizi by Johan Zoffany, 1772-75'
        )}
      </View>
      <View style={styles.innerContainer}>
        {renderHeader('So, what do we know about the collection?')}
        {renderParagraph(
          'Traditionally, art collections are assembled around a narrative, usually focusing on a certain time period, style of work, or specific artists. That’s not the case with the Royal Collection – it’s pretty much a random smattering of items, both gifted and purchased at the whim of each monarch.'
        )}
        {renderParagraph(
          'There are some notable names in the collection like Leonardo da Vinci, Michelangelo, Rembrandt, Titian, Van Dyck, and Vermeer, all acquired by previous monarchs. Queen Elizabeth hasn’t added much to the royal cache, but there is a small selection of modern artists like Lucian Freud, David Hockney, and Andy Warhol.'
        )}
      </View>

      <View style={styles.imageContainer}>
        <Image
          style={[
            styles.image,
            styles.imageVertical,
            {
              // width: '100%',
              // marginBottom: width < smBreakpoint ? -20 : 0,
              // maxWidth: '100%',
              // height:
              //   width < smBreakpoint ? 300 : width < mdBreakpoint ? 300 : 370,
            },
          ]}
          source={
            'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/003/Img+5.png' as ImageURISource
          }
        />
        {renderImageDescription(
          'The Sovereign’s Sceptre with Cross, part of the crown jewels.\nIt features the world’s largest cut diamonds,\nweighing in at 530.2 carats.'
        )}
      </View>

      <View style={styles.innerContainer}>
        {renderParagraph(
          'Interestingly, Prince Charles and his father, the Duke of Edinburgh, are both amateur artists and have contributed some works to the collection. Beyond the expected painting and drawings, the collection spans photography, costumes, objects, and of course, the crown jewels.'
        )}
      </View>
      <View style={styles.imageContainer}>
        <Image
          style={[
            styles.image,
            styles.imageHorizontal,
            {
              height:
                width < smBreakpoint
                  ? 180
                  : width < mdBreakpoint
                  ? 250
                  : width < lgBreakpoint
                  ? 310
                  : 400,
            },
          ]}
          source={
            'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/003/Img+6+resize.jpg' as ImageURISource
          }
        />
        {renderImageDescription('A casual Après Ski watercolor sesh')}
      </View>
      <View style={styles.innerContainer}>
        {renderHeader('Royal Scandals')}
        {renderParagraph(
          'Of course, the collection isn’t without its scandals. In 2019, Prince Charles found himself in the middle of a fake-art scandal when it came to light that 17 paintings on display at one of his estates were forged by notorious art forger Tony Tetro. The paintings were on loan to the prince and immediately returned.'
        )}
        {renderParagraph(
          'But the darkest scandal involves the super sketchy Anthony Blunt, who was chief curator of the Royal Collection all while serving as a Soviet Spy. He was recruited by the Soviets in the 1930s while a student at Cambridge and continued to spy throughout WWII and into the 50s.'
        )}
      </View>
      <View style={styles.imageContainer}>
        <Image
          style={[
            styles.image,
            styles.imageVertical,
            {
              height:
                width < smBreakpoint
                  ? 280
                  : width < mdBreakpoint
                  ? 350
                  : width < lgBreakpoint
                  ? 400
                  : 480,
            },
          ]}
          source={
            'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/003/Img+7+resize.jpg' as ImageURISource
          }
        />
        {renderImageDescription('Certified sketchy MFer, Anthony Blunt')}
      </View>
      <View style={styles.innerContainer}>
        {renderParagraph(
          'He was appointed to his royal post in 1945 and almost immediately embarked on a secretive trip to Germany, allegedly to recover embarrassing letters written by Queen Victoria to her daughter, Empress Victoria, mother of Kaiser Wilhelm.'
        )}
        {renderParagraph(
          'It’s been rumored that his trip was actually to recover evidence linking the disgraced Duke of Windsor, who abdicated as King of the United Kingdom in 1936, to Hitler. The Duke was a known Nazi sympathizer and it’s been rumored that he shared credible Ally secrets with the dictator.'
        )}
        {renderParagraph(
          'Blunt confessed to spying in 1964 and his career as chief curator came to an end shortly after. He was publicly revealed as a spy by Margaret Thatcher in 1979 which led Queen Elizabeth to strip him of his knighthood.'
        )}
      </View>
      <View style={styles.imageContainer}>
        <Image
          style={[
            styles.image,
            styles.imageVertical,
            {
              // height:
              //   width < smBreakpoint
              //     ? 180
              //     : width < mdBreakpoint
              //     ? 250
              //     : width < lgBreakpoint
              //     ? 310
              //     : 400,
            },
          ]}
          source={
            'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/003/Img+8+resize.jpg' as ImageURISource
          }
        />
        {renderImageDescription(
          'A self-portrait of David Hockney,\npresented to the Queen in 2013'
        )}
      </View>
      <View style={styles.innerContainer}>
        {renderHeader('The Future of the Collection')}
        {renderParagraph(
          'It’s unclear what would happen to the collection should the UK become a Republic, but the question has already been posed theoretically in Parliament. In other European countries, art collections of fallen monarchies have traditionally been taken into ownership by the state or absorbed into national collections for the public to enjoy.'
        )}
        {renderParagraph(
          'In the meantime, the Royal Collection Trust will continue its mission of maintaining and conserving the large, and lovably random, collection.'
        )}
        {renderParagraph(
          'What do you think should happen to the collection? Email me and let me know.'
        )}
        <Image
          style={{ height: 60, width: 120, resizeMode: 'contain' }}
          source={require('@happymedium/components/assets/images/logo-hm-abbreviated-green.png')}
        />
      </View>

      <View style={[styles.imageContainer, { marginBottom: 0, marginTop: 20 }]}>
        <Image style={[styles.image, styles.squiggle]} source={blueSquiggle} />
      </View>

      <View style={{ height: 80 }} />
    </View>
  );
};
