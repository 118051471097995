import { blueBrand, whiteBrand } from '../../libs/constants';
import { Theme } from './types';
import { getStaticColors } from '../colors';

export const theme: Theme = {
  primaryForegroundColor: blueBrand,
  foregroundColor: blueBrand,
  primaryBackgroundColor: whiteBrand,
  backgroundColor: whiteBrand,
  logoColor: 'blue',
  id: 'blue',
  displayName: 'Blue',
  ...getStaticColors(),
};
