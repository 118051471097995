import React, { useState, useRef, useEffect } from 'react';
import { Text, View, StyleSheet, Image, Animated } from 'react-native';
import { useQuery } from '@apollo/react-hooks';

import { GetActivitiesDocument } from '../components/ApolloComponents';
import { CuratorHeader } from '../components/curator/CuratorHeader';
import { useThemeState } from '../context/ThemeContext';
import { Activities } from '../components/curator/Activities';
import { ThemedSubscribeForm } from '../components/forms/ThemedSubscribeForm';
import { ActivitiesFilter } from '../components/curator/ActivitiesFilter';
import { PanamaBold, whiteBrand, GTAmerica } from '../libs/constants';
import { Draw } from '../components/curator/Draw';
import { useDimensions } from '../context/DimensionsContext';
// import { TouchableOpacity } from '../components/common/TouchableOpacity';
// import { ResponsiveFilterContainer } from '../components/curator/ResponsiveFilterContainer';
// import { SplashScreen } from '../components/common/SplashScreen';
import { RouteComponentProps, useHistory } from '../navigation';

interface MatchParams {
  vip?: string;
}

interface Props extends RouteComponentProps<MatchParams> {}

export const CuratorScreen: React.FC<Props> = (props) => {
  const history = useHistory();
  const {
    match: {
      params: { vip },
    },
  } = props;
  {
    /* skip splash screen if query param === 'vip' */
  }
  if (vip === 'vip') {
    localStorage.setItem('clubMember', 'true');
    history.push({
      pathname: '/',
    });
  }
  // const plus = require(`@happymedium/components/assets/images/plus-white@2x.png`);
  // const minus = require(`@happymedium/components/assets/images/minus-white@2x.png`);
  const greenLogo = require(`@happymedium/components/assets/images/logo-green@200x.png`);
  const lgBreakpoint = 1180;
  const mdBreakpoint = 860;
  const smBreakpoint = 420;
  const { theme } = useThemeState();
  const { width, height } = useDimensions();
  const [displayFilters, setDisplayFilters] = useState(
    width < mdBreakpoint ? false : true
  );
  const activityTypes = ['watch', 'read', 'learn', 'make'];
  const [selectedFilters, setSelectedFilters] = useState(new Array<string>());
  // const slideUpValue = useRef(new Animated.Value(0)).current;
  const styles = StyleSheet.create({
    primaryContainer: {
      display: 'flex',
      flexDirection: 'row',
    },
    scrollView: {
      flex: 1,
      // transform: [
      //   {
      //     translateY: (slideUpValue.interpolate({
      //       inputRange: [0, 1],
      //       outputRange: [height, 0],
      //     }) as unknown) as number,
      //   },
      // ],
    },
    primaryColumn: {
      display: 'flex',
      flexDirection: 'column',
      width: width < lgBreakpoint ? '100vw' : '67vw',
      borderRightColor: theme.foregroundColor,
    },
    secondaryColumn: {
      display: width < lgBreakpoint ? 'none' : 'flex',
      flexDirection: 'column',
      width: '33vw',
    },
  });

  // const transitionDown = () => {
  //   return Animated.parallel([
  //     Animated.timing(slideUpValue, {
  //       toValue: 1,
  //       duration: 500,
  //       // useNativeDriver: true,
  //     }),
  //   ]).start();
  // };

  const { loading, error, data } = useQuery(GetActivitiesDocument);

  if (error) {
    return (
      <View
        style={{
          flex: 1,
          alignItems: 'center',
          alignContent: 'center',
          justifyContent: 'center',
          backgroundColor: theme.backgroundColor,
        }}
      >
        <Image style={{ height: 60, width: 250 }} source={greenLogo} />
        <Text
          style={{
            fontFamily: GTAmerica,
            fontSize: 16,
            color: theme.foregroundColor,
          }}
        >
          An error has occurred. Please try again.
        </Text>
      </View>
    );
  }
  const toggleFilter = () => {
    setDisplayFilters(!displayFilters);
  };

  const renderLoadingView = () => {
    return (
      <View
        style={{
          flex: 1,
          alignItems: 'center',
          alignContent: 'center',
          justifyContent: 'center',
          backgroundColor: theme.backgroundColor,
        }}
      >
        <Image style={{ height: 60, width: 250 }} source={greenLogo} />
        <Text
          style={{
            fontFamily: GTAmerica,
            fontSize: 16,
            color: theme.foregroundColor,
            marginTop: 20,
          }}
        >
          Loading...
        </Text>
      </View>
    );
  };

  // const clubMember = localStorage.getItem('clubMember');
  // const [isClubMember, setIsClubMember] = useState(false);

  // useEffect(() => {
  //   if (clubMember && clubMember === 'true') {
  //     setIsClubMember(true);
  //   }
  // }, [clubMember]);

  return (
    <>
      {/* <SplashScreen transitionDown={transitionDown} /> */}
      <Animated.ScrollView style={styles.scrollView}>
        <View style={styles.primaryContainer}>
          <View style={styles.primaryColumn}>
            <CuratorHeader />
            <View
              style={{
                backgroundColor: theme.backgroundColor,
                flex: 1,
              }}
            >
              {width < lgBreakpoint ? null : (
                // <ResponsiveFilterContainer
                //   displayFilters={displayFilters}
                //   toggleFilter={toggleFilter}
                //   setSelectedFilters={setSelectedFilters}
                //   selectedFilters={selectedFilters}
                // />
                <ActivitiesFilter
                  setSelectedFilters={setSelectedFilters}
                  selectedFilters={selectedFilters}
                  filters={activityTypes}
                />
              )}
              {loading ? (
                renderLoadingView()
              ) : width < lgBreakpoint ? (
                <Draw />
              ) : (
                <Activities
                  activityTypes={activityTypes}
                  selectedFilters={selectedFilters}
                  activities={data.getActivities}
                />
              )}
            </View>
          </View>
          <View style={styles.secondaryColumn}>
            <View
              style={{
                borderLeftWidth: 2,
                borderLeftColor: theme.foregroundColor,
              }}
            >
              <ThemedSubscribeForm />
            </View>
            <View
              style={{
                borderLeftWidth: 2,
                backgroundColor: theme.backgroundColor,
                borderLeftColor: theme.foregroundColor,
                flex: 1,
              }}
            >
              <Draw />
            </View>
          </View>
        </View>
      </Animated.ScrollView>
    </>
  );
};
