import React from 'react';
import { View, StyleSheet, Text, Image } from 'react-native';
import { CuratorHeader } from '../components/curator/CuratorHeader';
import { useThemeState } from '../context/ThemeContext';
import { GTAmerica, PanamaBold } from '../libs/constants';
import { useDimensions } from '../context/DimensionsContext';

interface Props {}

export const RefundPolicyScreen: React.FC<Props> = () => {
  document.title = 'Happy Medium | Refund Policy';
  const { theme } = useThemeState();
  const { width } = useDimensions();
  const mdBreakpoint = 860;
  const flowers = require('@happymedium/components/assets/images/arrangement.png');
  const styles = StyleSheet.create({
    container: {
      backgroundColor: theme.backgroundColor,
      flex: 1,
    },
  });
  return (
    <View style={[styles.container]}>
      <CuratorHeader />
      <View
        style={{
          maxWidth: 600,
          marginTop: width < mdBreakpoint ? 20 : 80,
          alignSelf: 'center',
          flex: 1,
        }}
      >
        <Text
          style={{
            color: theme.foregroundColor,
            fontFamily: PanamaBold,
            fontSize: width < mdBreakpoint ? 24 : 28,
            letterSpacing: 1.2,
            paddingHorizontal: 20,
          }}
        >
          Refunds, Rescheduling, & Returns
        </Text>
        <View style={{ height: 20 }} />
        <Text
          style={{
            color: theme.foregroundColor,
            fontFamily: PanamaBold,
            fontSize: width < mdBreakpoint ? 20 : 22,
            letterSpacing: 1.2,
            paddingHorizontal: 20,
            textTransform: 'uppercase',
          }}
        >
          Events
        </Text>
        <Text
          style={{
            color: theme.foregroundColor,
            fontFamily: GTAmerica,
            fontSize: width < mdBreakpoint ? 12 : 14,
            paddingHorizontal: 20,
          }}
        >
          There are no refunds on event tickets unless Happy Medium cancels the
          event. If you're unable to attend your event, email
          reservations@happy-medium.co to reschedule or receive an account
          credit. No changes or credits will be issued within 24 hours of the
          event start time.
        </Text>
        <View style={{ height: 20 }} />
        <Text
          style={{
            color: theme.foregroundColor,
            fontFamily: PanamaBold,
            fontSize: width < mdBreakpoint ? 20 : 22,
            letterSpacing: 1.2,
            paddingHorizontal: 20,
            textTransform: 'uppercase',
          }}
        >
          Physical Products
        </Text>
        <Text
          style={{
            color: theme.foregroundColor,
            fontFamily: GTAmerica,
            fontSize: width < mdBreakpoint ? 12 : 14,
            paddingHorizontal: 20,
          }}
        >
          Physical products are eligible for full refund, exchange, or credit
          upon return of the item(s) within fifteen (15) days of delivery if the
          product does not need your expectations. Email
          founders@happy-medium.co to begin a return.
        </Text>
        <Image
          source={flowers}
          style={{ width: 'auto', height: 100, marginTop: 40 }}
          resizeMethod="scale"
          resizeMode="contain"
        />
      </View>
    </View>
  );
};
