import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
import {
  Text,
  View,
  Image,
  TextInput,
  ActivityIndicator,
  StyleSheet,
} from 'react-native';
import { Formik } from 'formik';

import { SubscribeNewsletterDocument } from '../ApolloComponents';
import { TouchableOpacity } from '../common/TouchableOpacity';
import {
  GTAmerica,
  PanamaBold,
  whiteBrand,
  greenBrand,
} from '../../libs/constants';
import { theme } from '../../styles/themes/green';
import { useHistory } from '../../navigation';
import { useDimensions } from '../../context/DimensionsContext';
import {
  invalidEmailErrorMessage,
  existingEmailAddressErrorMessage,
  emailRegex,
} from '../../constants';
import { SafeAreaView } from 'react-native';

interface Props {
  email?: string;
}

interface FormValues {
  email: string;
}

export const NewsletterSubscribeForm: React.FC<Props> = ({ email }) => {
  const lgBreakpoint = 1180;
  const mdBreakpoint = 860;
  const smBreakpoint = 420;
  const [subscribe, { data }] = useMutation(SubscribeNewsletterDocument);
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [displayError, setDisplayError] = useState(false);
  const [displayDup, setDisplayDup] = useState(false);
  const [hideForm, setHideForm] = useState(false);
  const [showText, setShowText] = useState(true);
  const history = useHistory();
  const handleOnSubmit = (values: FormValues, handleSubmit: () => void) => {
    setDisplayDup(false);
    setDisplayError(false);
    if (
      values.email === null ||
      values.email === '' ||
      !emailRegex.test(values.email)
    ) {
      setDisplayError(true);
      return;
    }
    handleSubmit();
  };
  useEffect(() => {
    // Change the state every second or the time given by User.
    const interval = setInterval(() => {
      setShowText((showText) => !showText);
    }, 120);
    return () => clearInterval(interval);
  }, []);
  const { width, height } = useDimensions();
  // const submitArrow = require(`@happymedium/components/assets/images/arrow-right-green@300x.png`);
  const styles = StyleSheet.create({
    emailInput: {
      width: width < mdBreakpoint ? '90%' : 750,
      backgroundColor: theme.backgroundColor,
      fontFamily: PanamaBold,
      height: width < smBreakpoint ? 40 : 64,
      fontSize: 18,
      letterSpacing: 0.9,
      paddingLeft: width < smBreakpoint ? 10 : 20,
      color: theme.foregroundColor,
      // placeholderTextColor: theme.foregroundColor, // TODOD: throwing an error?
      // flexGrow: 1,
      // borderBottomWidth: 1,
      borderBottomColor: theme.foregroundColor,
    },
    submitArrow: {
      width: width < smBreakpoint ? 28 : 54,
      height: width < smBreakpoint ? 20 : 40,
      alignSelf: 'flex-end',
      top: width < smBreakpoint ? -30 : -50,
    },
  });
  return (
    <View style={{ width: '100%' }}>
      <Formik<FormValues>
        initialValues={{ email: email ? email : '' }}
        onSubmit={async (values, { resetForm }) => {
          setDisabled(true);
          setDisplayDup(false);
          setDisplayError(false);
          setLoading(true);
          const result = await subscribe({
            variables: {
              email: values.email,
            },
          });
          if (result.data.subscribe.success === true) {
            setHideForm(true);
            // setTimeout(() => history.push('/'), 1200);

            setDisabled(false);
            setLoading(false);
            resetForm({ values: { email: '' } });
          }
          if (
            result.data.subscribe.success === false &&
            result.data.subscribe.errors[0].message === 'Already in the squad'
          ) {
            setDisplayDup(true);
            setLoading(false);
            setDisabled(false);
            // console.log('squad');
          }
          // console.log('result', result);
          // console.log('data', data);
        }}
      >
        {({ handleBlur, handleChange, handleSubmit, values }) => (
          <View style={{ width: '100%' }}>
            {hideForm ? (
              <View
                style={{
                  width: '100%',
                  marginTop: 0,
                  alignItems: 'center',
                }}
              >
                <Text
                  style={{
                    textAlign: 'center',
                    fontFamily: PanamaBold,
                    textTransform: 'uppercase',
                    color: whiteBrand,
                    fontSize: 32,
                  }}
                >
                  Thanks for signing up!
                </Text>
                {/* <Text
                  style={{
                    textAlign: 'center',
                    fontFamily: GTAmerica,
                    textTransform: 'capitalize',
                    color: whiteBrand,
                    fontSize: 26,
                    display: showText ? 'none' : 'flex',
                  }}
                >
                  Redirecting...
                </Text> */}
              </View>
            ) : (
              <View
                style={{
                  alignItems: 'center',
                  // width: '100%',
                  flexDirection: 'row',
                  alignSelf: 'center',
                  justifyContent: 'center',
                  alignContent: 'center',
                }}
              >
                <TextInput
                  // placeholderTextColor={theme.foregroundColor}
                  onChangeText={handleChange('email')}
                  onBlur={handleBlur('email')}
                  value={values.email}
                  onSubmitEditing={() => {
                    handleOnSubmit(values, handleSubmit);
                  }}
                  style={[styles.emailInput, {}]}
                  placeholder="Your email"
                  keyboardType="email-address"
                />
                {loading ? <ActivityIndicator /> : null}
                {loading ? null : (
                  <TouchableOpacity
                    // loading={loading}
                    disabled={disabled}
                    style={{
                      borderColor: whiteBrand,
                      borderWidth: 1,
                      justifyContent: 'center',
                      alignContent: 'center',
                      alignItems: 'center',
                      flexGrow: 1,
                      height: width < smBreakpoint ? 40 : 64,
                      padding: 5,
                    }}
                    onPress={() => {
                      handleOnSubmit(values, handleSubmit);
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: PanamaBold,
                        color: whiteBrand,
                        fontSize: 18,
                        // height: '100%',
                        textAlignVertical: 'center',
                      }}
                    >
                      Subscribe
                    </Text>
                    {/* <Image source={submitArrow} style={styles.submitArrow} /> */}
                  </TouchableOpacity>
                )}
              </View>
            )}
            {displayError ? (
              <View>
                <Text
                  style={{
                    textAlign: 'center',
                    color: whiteBrand,
                    fontFamily: GTAmerica,
                  }}
                >
                  {invalidEmailErrorMessage}
                </Text>
              </View>
            ) : null}
            {displayDup ? (
              <View>
                <Text
                  style={{
                    textAlign: 'center',
                    color: whiteBrand,
                    fontFamily: GTAmerica,
                  }}
                >
                  {existingEmailAddressErrorMessage}
                </Text>
              </View>
            ) : null}
          </View>
        )}
      </Formik>
    </View>
  );
};
