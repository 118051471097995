import React from 'react';
import { View, Image, ImageURISource, StyleSheet, Text } from 'react-native';

import {
  greenBrand,
  PanamaBold,
  GTAmerica,
  GTAmericaCompressed,
} from '../../../libs/constants';
import { useDimensions } from '../../../context/DimensionsContext';
import { mdBreakpoint, smBreakpoint, lgBreakpoint } from '../../../constants';
import { HoverableLink } from '../../common/HoverableLink';

interface SalonIssueOneProps {}

export const SalonIssueNine: React.FC<SalonIssueOneProps> = () => {
  const { width } = useDimensions();
  const blueSquiggle = require('@happymedium/components/assets/images/squiggles-blue.png');
  const redSquiggle = require('@happymedium/components/assets/images/squiggles-red.png');

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      flexDirection: 'column',
    },
    innerContainer: {
      maxWidth: width < 900 ? '85%' : 700,
      alignSelf: 'center',
    },
    imageContainer: {
      alignItems: 'center',
      marginVertical:
        width < smBreakpoint ? 20 : width < mdBreakpoint ? 35 : 60,
    },
    text: {
      color: greenBrand,
      flexWrap: 'wrap',
    },
    header: {
      fontFamily: PanamaBold,
      letterSpacing: 1.3,
      fontSize: width < smBreakpoint ? 28 : width < mdBreakpoint ? 36 : 48,
      marginBottom: width < smBreakpoint ? 5 : width < mdBreakpoint ? 15 : 20,
    },
    subHeader: {
      fontSize: width < smBreakpoint ? 18 : width < mdBreakpoint ? 28 : 36,
      marginBottom: width < smBreakpoint ? 5 : width < mdBreakpoint ? 8 : 10,
    },
    body: {
      fontFamily: GTAmerica,
      fontWeight: '300',
      letterSpacing: 0.45,
      fontSize: width < smBreakpoint ? 14 : width < mdBreakpoint ? 15 : 16,
      marginVertical: 10,
    },
    imageDescription: {
      fontFamily: GTAmericaCompressed,
      fontWeight: '300',
      letterSpacing: 0.45,
      fontSize: width < smBreakpoint ? 12 : width < mdBreakpoint ? 14 : 16,
      marginVertical: width < smBreakpoint ? 5 : 10,
      textAlign: 'center',
      maxWidth: width < smBreakpoint ? '90vw' : '100%',
    },
    shareButton: {},
    arrow: {},
    squiggle: {
      height: 31,
      width: 123,
      alignSelf: 'center',
      resizeMode: 'contain',
      marginBottom: 0,
      marginTop: 25,
    },
    image: {
      marginHorizontal: 30,
      maxWidth: width < mdBreakpoint ? '80%' : '50%',
    },
    imageVertical: {
      height: width < smBreakpoint ? 400 : width < mdBreakpoint ? 500 : 550,
      width: '100%',
      alignSelf: 'center',
      resizeMode: 'contain',
    },
    imageHorizontal: {
      height:
        width < smBreakpoint
          ? 280
          : width < mdBreakpoint
          ? 350
          : width < lgBreakpoint
          ? 420
          : 550,
      width: '100%',
      alignSelf: 'center',
      resizeMode: 'contain',
    },
  });

  const renderHeader = (copy: string) => (
    <Text style={[styles.text, styles.header]}>{copy}</Text>
  );

  const renderParagraph = (copy: string) => (
    <Text style={[styles.text, styles.body]}>{copy}</Text>
  );

  const renderImageDescription = (copy: string) => (
    <Text style={[styles.text, styles.imageDescription]}>{copy}</Text>
  );

  return (
    <View style={styles.container}>
      <View
        style={{
          height: width < smBreakpoint ? 10 : width < mdBreakpoint ? 25 : 80,
        }}
      />
      <View style={{ flexDirection: width < lgBreakpoint ? 'column' : 'row' }}>
        <Image
          style={[
            styles.image,
            styles.imageHorizontal,
            {
              marginBottom:
                width <= mdBreakpoint && width > smBreakpoint
                  ? 20
                  : width < lgBreakpoint && width > mdBreakpoint
                  ? 40
                  : undefined,
            },
          ]}
          source={
            'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/009/Salon-009-1.png' as ImageURISource
          }
        />
        <View
          style={{
            flexDirection: 'column',
            flexShrink: 1,
            maxWidth: width < 900 ? '85%' : 700,
            flexWrap: 'wrap',
            marginHorizontal:
              width < smBreakpoint ? undefined : width < mdBreakpoint ? 25 : 50,
            alignSelf: 'center',
          }}
        >
          {width < smBreakpoint && <View style={{ height: 30 }} />}
          {renderHeader('Shel Silverstein')}

          <Text style={[styles.text, styles.body]}>
            If you grew up in the US, you probably know Shel Silverstein as the
            author of classic children’s books like{' '}
            <HoverableLink
              linkUrl={'https://en.wikipedia.org/wiki/The_Giving_Tree'}
              linkType={'web'}
            >
              The Giving Tree
            </HoverableLink>
            ,{' '}
            <HoverableLink
              linkUrl={'https://en.wikipedia.org/wiki/Where_the_Sidewalk_Ends'}
              linkType={'web'}
            >
              Where the Sidewalk Ends
            </HoverableLink>
            , and{' '}
            <HoverableLink
              linkUrl={'https://en.wikipedia.org/wiki/A_Light_in_the_Attic'}
              linkType={'web'}
            >
              A Light in the Attic
            </HoverableLink>
            .
          </Text>
          {renderParagraph(
            'But Shel was much more than just a children’s book author. In fact, most of his work was decidedly not for kids. He had his own column and cartoon in Playboy for almost 2 decades. He wrote songs for Johnny Cash. Over his lifetime, he won 2 Grammys, a Golden Globe, and an Academy Award. He was an artist, an author, songwriter, and a playwright.'
          )}
        </View>
      </View>
      <View
        style={[
          styles.imageContainer,
          {
            marginBottom:
              width < smBreakpoint ? 30 : width < mdBreakpoint ? 20 : 0,
            marginTop: 20,
          },
        ]}
      >
        <Image style={[styles.image, styles.squiggle]} source={redSquiggle} />
      </View>
      <View style={[styles.innerContainer, { marginTop: 30 }]}>
        {renderParagraph(
          'Awards aside, his real lasting achievement is his body of work which, nearly 50 years later, is just as meaningful today as ever before. Deceptively simple and wildly approachable, Shel used his work to confront head-on some of the most difficult parts of the human condition – love, loss, meaning, and change.'
        )}
      </View>
      <View style={styles.imageContainer}>
        <Image
          style={[
            styles.image,
            styles.imageHorizontal,
            {
              height:
                width < smBreakpoint
                  ? 230
                  : width < mdBreakpoint
                  ? 250
                  : width < lgBreakpoint
                  ? 310
                  : 400,
            },
          ]}
          source={
            'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/009/Salon-009-2.png' as ImageURISource
          }
        />
        {renderImageDescription('Baby Shel')}
      </View>
      <View style={styles.innerContainer}>
        {renderHeader('Who was Shel Silverstein?')}
        {renderParagraph(
          'Sheldon Allan Silverstein was born in Chicago in 1930. His father owned a bakery. He started writing and drawing as a kid because he was neither good at sports nor popular with the ladies:'
        )}
        <Text style={[styles.text, styles.body, { fontStyle: 'italic' }]}>
          “When I was a kid—12 to 14, I'd much rather have been a good baseball
          player or a hit with the girls, but I couldn't play ball. I couldn't
          dance. Luckily, the girls didn't want me. Not much I could do about
          that. So I started to draw and to write. I was also lucky that I
          didn't have anybody to copy, be impressed by. I had developed my own
          style; I was creating before I knew there was a Thurber, a Benchley, a
          Price and a Steinberg. I never saw their work 'til I was around 30. By
          the time I got to where I was attracting girls, I was already into
          work, and it was more important to me. Not that I wouldn't rather make
          love, but the work has become a habit”
        </Text>
        {renderParagraph(
          'After high school, he went to the University of Illinois, but was expelled for bad grades. He briefly attended the Chicago Fine Arts school but left after a year. Finally, he settled at Roosevelt University, where he studied English.'
        )}
      </View>
      <View style={styles.imageContainer}>
        <Image
          style={[
            styles.image,
            styles.imageHorizontal,
            {
              height:
                width < smBreakpoint
                  ? 180
                  : width < mdBreakpoint
                  ? 250
                  : width < lgBreakpoint
                  ? 310
                  : 400,
            },
          ]}
          source={
            'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/009/Salon-009-3.jpg' as ImageURISource
          }
        />
        {renderImageDescription('Shel in the Army')}
      </View>
      <View style={styles.innerContainer}>
        {renderParagraph(
          'After college, he was drafted into the army. His job was to handle the layout for the military newspaper – Stars and Stripes. He would also occasionally submit his own cartoons, one of which was so cheeky that he was almost court-martialed over its content. '
        )}
        {renderParagraph(
          'After his military service, he returned to Chicago and focused on his cartoonist career. He submitted his work to publications across the country and worked as a hotdog vendor to make ends meet.'
        )}
      </View>
      <View style={[styles.innerContainer, { marginTop: 30 }]}>
        {renderHeader('Playboy')}
        {renderParagraph(
          'His first big break came when he joined playboy in 1953, where he quickly became one of the leading cartoonists. He had a column called “Shel Silverstein Visits…” which was a hybrid comic and travel column. For almost 2 decades, Playboy sent him around the world to places like Fire Island, Mexico, London, Paris, Africa, and Spain and to experiences like the White Sox Training camp and a New Jersey nudist colony.'
        )}
      </View>
      <View style={styles.imageContainer}>
        <Image
          style={[
            styles.image,
            styles.imageHorizontal,
            {
              height:
                width < smBreakpoint
                  ? 210
                  : width < mdBreakpoint
                  ? 250
                  : width < lgBreakpoint
                  ? 310
                  : 400,
            },
          ]}
          source={
            'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/009/Salon-009-7.jpg' as ImageURISource
          }
        />
      </View>
      <View style={styles.innerContainer}>
        {renderHeader('Renaissance Man')}
        {renderParagraph(
          'While at Playboy, he also explored other areas of work. A collection of his cartoons was published as a book in 1960. His first book of new material, Uncle Shelby’s ABZ Book, was published in 1961. Because of the silly nature, but heavy subject matter, it was unclear at the time if the book was intended for children or adults. He also wrote and recorded the song “A Boy Named Sue” which would later be popularized by Jonny Cash.'
        )}
        {renderParagraph(
          'Shel said he never intended to write for children, but after being convinced by a publisher, he finally wrote a dedicated children’s book in 1964. The age of his reader seemed inconsequential to him:'
        )}
        <Text style={[styles.text, styles.body, { fontStyle: 'italic' }]}>
          “I would hope that people, no matter what age, would find something to
          identify with in my books, pick up one and experience a personal sense
          of discovery. That's great. I think that if you're a creative person,
          you should just go about your business, do your work and not care
          about how it's received.”
        </Text>
      </View>

      <View style={styles.imageContainer}>
        <Image
          style={[
            styles.image,
            styles.imageHorizontal,
            {
              height:
                width < smBreakpoint
                  ? 280
                  : width < mdBreakpoint
                  ? 300
                  : width < lgBreakpoint
                  ? 340
                  : 400,
            },
          ]}
          source={
            'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/009/Salon-009-4.png' as ImageURISource
          }
        />
      </View>

      <View style={styles.innerContainer}>
        {renderHeader('The Deeper Meaning')}
        {renderParagraph(
          'Back to the original point – Shel was deep af. In the same way parents “hide the vegetables” to give their kids a healthy meal, Shel “hid the vegetables” of life lessons in his art. The results were divisive, but also exactly what gives his work staying power. Let’s look at two notable examples.'
        )}
      </View>

      <View style={[styles.innerContainer, { marginTop: 30 }]}>
        <Text style={[styles.text, styles.header, styles.subHeader]}>
          The Giving Tree
        </Text>
        {renderParagraph(
          'In a nutshell, the story goes like this – a boy and a Tree are friends. When the boy is young, he enjoys swinging from the Tree’s branches. As the boy ages, the Tree gives him parts of herself – apples to sell, branches to make a house with, her trunk to build a boat – and each time she gives, it makes her happy. At the end, the boy is an old man and the Tree is an unhappy stump with nothing else to give. The boy tells the Tree that all he needs is a quiet place to sit, which she gives him, and she is happy again.'
        )}
        {renderParagraph(
          'The book was (is?) divisive because it’s unclear if the relationship between the boy and the Tree is positive or negative. Is this a celebration about the joy of selfless giving? Or is the boy a toxic friend who takes, takes, takes but never gives? Idk – you tell me!'
        )}
      </View>

      <View
        style={[
          styles.innerContainer,
          {
            flexDirection: width < mdBreakpoint ? 'column' : 'row',
            width: '100%',
            justifyContent: width > 620 ? 'space-evenly' : 'center',
            alignItems: 'center',
          },
        ]}
      >
        <View
          style={[
            styles.imageContainer,
            // { flexDirection: width < mdBreakpoint ? 'column' : 'row' },
          ]}
        >
          <Image
            style={[
              styles.image,
              styles.imageVertical,
              {
                // width: '100%',
                width: 350,
                // marginBottom: width < smBreakpoint ? -20 : 0,
                maxWidth: '100%',
                height: 500,
                // width < smBreakpoint ? 300 : width < mdBreakpoint ? 300 : 370,
              },
            ]}
            source={
              'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/009/Salon-009-8.jpeg' as ImageURISource
            }
          />
        </View>

        <View style={styles.imageContainer}>
          <Image
            style={[
              styles.image,
              styles.imageVertical,
              {
                // marginBottom: width < smBreakpoint ? -20 : 0,
                maxWidth: '100%',
                width: 350,
                height: 500,
              },
            ]}
            source={
              'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/009/Salon-009-9.jpg' as ImageURISource
            }
          />
        </View>
      </View>
      <View style={styles.innerContainer}>
        <Text style={[styles.text, styles.header, styles.subHeader]}>
          The Missing Piece and the Big O
        </Text>
        {renderParagraph(
          'I wish I had read this 25 years ago. The story goes like this – the missing piece sat alone waiting for someone to come along and take it somewhere. Various shapes come by, but none are quite right –  the missing piece either doesn’t fit or when he does, they can’t roll away together.'
        )}
        {renderParagraph(
          'He tries to attract his perfect match by making a flashy sign, and finally a circle with a missing slice comes by and they roll away together. But then the missing piece grows and no longer fits in the circle.'
        )}
        <Text style={[styles.text, styles.body]}>
          Sad, he sits alone again until a totally new shape - a complete circle
          - comes by. They have a great chat and, in the end, the circle
          inspires him to try rolling by himself. It’s hard at first, but in the
          end they roll off together, but separately. See the whole story{' '}
          <HoverableLink
            linkUrl={
              'https://www.brainpickings.org/2015/02/12/the-missing-piece-meets-the-big-o-shel-silverstein/'
            }
            linkType={'web'}
          >
            here
          </HoverableLink>
          .
        </Text>
        {renderParagraph(
          'The Missing Piece and the Big O is really an allegory about how no one else in the world can complete us, no matter how hard we look or how much we want it. There are so many life lessons packed into this simple children’s book.'
        )}
        {renderParagraph('And that is the beauty of Shel.')}
        <Image
          style={{ height: 60, width: 120, resizeMode: 'contain' }}
          source={require('@happymedium/components/assets/images/logo-hm-abbreviated-green.png')}
        />
      </View>

      <View style={[styles.imageContainer, { marginBottom: 0, marginTop: 20 }]}>
        <Image style={[styles.image, styles.squiggle]} source={blueSquiggle} />
      </View>

      <View style={{ height: 80 }} />
    </View>
  );
};
