import React from "react";
import { View, StyleSheet, Text, Image } from "react-native";
import { CuratorHeader } from "../components/curator/CuratorHeader";
import { useThemeState } from "../context/ThemeContext";
import { PanamaBold } from "../libs/constants";
import { useDimensions } from "../context/DimensionsContext";
import { H1, H2 } from "@expo/html-elements";
import { HoverableLink } from "../components/common/HoverableLink";

interface Props { }

export const FriendshipBraceletsScreen: React.FC<Props> = () => {
    document.title = "Friendship Bracelets | Happy Medium";
    const { theme } = useThemeState();
    const { width } = useDimensions();
    const mdBreakpoint = 860;
    const flowers = require("@happymedium/components/assets/images/arrangement.png");
    const styles = StyleSheet.create({
        container: {
            backgroundColor: theme.backgroundColor,
            flex: 1,
        },
    });
    return (
        <View style={[styles.container]}>
            <CuratorHeader />
            <View
                style={{
                    maxWidth: 600,
                    marginTop: width < mdBreakpoint ? 20 : 80,
                    alignSelf: "center",
                    // alignContent: 'center',
                    alignItems: "center",
                    // justifyContent: 'center',
                    flex: 1,
                }}
            >
                <H1>Friendship Bracelets</H1>

                <View style={{ height: 40 }} />

                <H2>The Basics</H2>
                <Image width={400} height={800} style={{ maxWidth: '98%', resizeMode: 'contain' }} source={{ uri: 'https://ecomm-site.s3.us-east-2.amazonaws.com/HM_FB_Guide_Basic.gif' }} />
                <View style={{ height: 40 }} />
                <H2>Adding Charms</H2>
                <Image width={400} height={800} style={{ maxWidth: '98%', resizeMode: 'contain' }} source={{ uri: 'https://ecomm-site.s3.us-east-2.amazonaws.com/HM_FB_Guide_Charm.gif' }} />

            </View>
        </View>
    );
};
