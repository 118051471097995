export const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
export const usPhoneRegex = /^\([2-9][\d]{2}\) [\d]{3}-[\d]{4}$/;
export const invalidEmailErrorMessage =
  '***please provide a valid email address***';
export const invalidPhoneErrorMessage =
  '***please provide a valid phone number***';
export const existingEmailAddressErrorMessage =
  "***you're already in the squad***";
export const existingPhoneNumberErrorMessage =
  "***you're already on the list***";

export const xlBreakpoint = 1440;
export const lgBreakpoint = 1180;
export const mdBreakpoint = 860;
export const smMdBreakpoint = 640;
export const smBreakpoint = 420;
