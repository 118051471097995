import { redBrand, whiteBrand } from '../../libs/constants';
import { getStaticColors } from '../colors';
import { Theme } from './types';

export const theme: Theme = {
  primaryForegroundColor: redBrand,
  foregroundColor: redBrand,
  primaryBackgroundColor: whiteBrand,
  backgroundColor: whiteBrand,
  logoColor: 'red',
  id: 'red',
  displayName: 'Red',
  ...getStaticColors(),
};
