import React, { useState } from 'react';
import { Formik } from 'formik';
import { View, ActivityIndicator, Image, Text, StyleSheet } from 'react-native';
import { useMutation } from '@apollo/react-hooks';
import { MaskedTextInput } from 'react-native-mask-text';

import { CreateCustomerDocument } from '../ApolloComponents';
import {
  whiteBrand,
  GTAmericaCompressed,
  wineBrand,
  GTAmerica,
  blackBrand,
  greenBrand,
} from '../../libs/constants';
import { usPhoneRegex, invalidPhoneErrorMessage } from '../../constants';
import { TouchableOpacity } from '../common/TouchableOpacity';

interface Props {}

interface FormValues {
  phoneNumber: string;
}

export const PhoneNumberSignupForm: React.FC<Props> = ({}) => {
  // const clubMember = localStorage.getItem('clubMember');
  // console.log('clubMember', clubMember);
  const submitArrow = require(`@happymedium/components/assets/images/arrow-right-green@300x.png`);
  const [createCustomer, { data }] = useMutation(CreateCustomerDocument);
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [displayError, setDisplayError] = useState(false);
  const [hideForm, setHideForm] = useState(false);
  const inputHeight = 50;
  const handleOnSubmit = (values: FormValues, handleSubmit: () => void) => {
    // setDisplayDup(false);
    setDisplayError(false);
    if (
      values.phoneNumber === null ||
      values.phoneNumber === '' ||
      !usPhoneRegex.test(values.phoneNumber)
    ) {
      setDisplayError(true);
      return;
    }
    handleSubmit();
  };
  const styles = StyleSheet.create({
    subscribeForm: {
      width: 320,
      maxWidth: '85%',
      flexDirection: 'row',
      // flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
    },
    phoneInput: {
      width: '100%',
      backgroundColor: whiteBrand,
      fontFamily: GTAmericaCompressed,
      height: inputHeight,
      fontSize: 24,
      letterSpacing: 0.9,
      paddingLeft: 8,
      color: wineBrand,
      // placeholderTextColor: wineBrand, // TODOD: throwing an error?
    },
    submitArrow: {
      width: 40,
      height: 30,
      alignSelf: 'center',
      marginRight: 8,
      // left: -60,
    },
  });
  return (
    <Formik<FormValues>
      initialValues={{ phoneNumber: '' }}
      onSubmit={async (values, { resetForm }) => {
        setDisabled(true);
        setLoading(true);
        const result = await createCustomer({
          variables: {
            phoneNumber: values.phoneNumber,
          },
        });

        if (result.data.createCustomer.success === true) {
          setDisabled(false);
          setLoading(false);
          resetForm({ values: { phoneNumber: '' } });
          setHideForm(true);
          // transitionDown();
          // transitionUp();
          // localStorage.setItem('clubMember', 'true');
        }
        // if (
        //   result.data.createCustomer.success === false &&
        //   result.data.createCustomer.errors[0].message ===
        //     'Already in the squad'
        // ) {
        //   setLoading(false);
        //   setDisabled(false);
        // }
      }}
    >
      {({ handleBlur, handleChange, handleSubmit, values }) => (
        <>
          <View style={styles.subscribeForm}>
            {hideForm ? (
              <Text
                style={{
                  fontFamily: GTAmerica,
                  fontSize: 24,
                  color: whiteBrand,
                }}
              >
                Thanks for signing up!
              </Text>
            ) : (
              <>
                <MaskedTextInput
                  placeholderTextColor={wineBrand}
                  style={styles.phoneInput}
                  onChangeText={handleChange('phoneNumber')}
                  keyboardType="phone-pad"
                  mask="(999) 999-9999"
                  placeholder="(555) 555-5555"
                  onBlur={handleBlur('phoneNumber')}
                  value={values.phoneNumber}
                  onSubmitEditing={() => {
                    handleOnSubmit(values, handleSubmit);
                  }}
                />
                {loading ? (
                  <ActivityIndicator />
                ) : (
                  <TouchableOpacity
                    // loading={loading}
                    disabled={disabled}
                    style={{
                      backgroundColor: whiteBrand,
                      height: inputHeight,
                      justifyContent: 'center',
                      flexGrow: 1,
                    }}
                    onPress={() => {
                      handleOnSubmit(values, handleSubmit);
                    }}
                  >
                    <Image source={submitArrow} style={styles.submitArrow} />
                  </TouchableOpacity>
                )}
              </>
            )}
          </View>
          {displayError && (
            <View>
              <Text
                style={{
                  textAlign: 'center',
                  color: whiteBrand,
                  fontFamily: GTAmericaCompressed,
                  textTransform: 'uppercase',
                }}
              >
                {invalidPhoneErrorMessage}
              </Text>
            </View>
          )}
        </>
      )}
    </Formik>
  );
};
