import React from 'react';
import { ApolloProvider } from '@apollo/react-hooks';

import { Routes } from './navigation/Routes';
import { client } from './libs/apollo';
import { DimensionsProvider } from './context/DimensionsContext';
import { ThemeProvider } from './context/ThemeContext';
import { AppLayoutProvider } from './context/LayoutContext';

export function App() {
  return (
    <DimensionsProvider>
      <ApolloProvider client={client}>
        <AppLayoutProvider>
          <ThemeProvider>
            <>
              <Routes />
            </>
          </ThemeProvider>
        </AppLayoutProvider>
      </ApolloProvider>
    </DimensionsProvider>
  );
}
