import React from 'react';
import { salonIssues } from '../../data/SalonIssues';

interface Props {
  issueNumber: number;
}

export const SalonIssueRenderer = ({ issueNumber }: Props) => {
  const issue = salonIssues[issueNumber];

  return <>{issue}</>;
};
