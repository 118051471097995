import React from 'react';
import { View, ScrollView, StyleSheet, Image, Text } from 'react-native';
import { useQuery } from '@apollo/react-hooks';

import { GetSubmissionsDocument } from '../components/ApolloComponents';
import { Drawings } from '../components/gallery/Drawings';
import { useThemeState } from '../context/ThemeContext';
import { GTAmerica } from '../libs/constants';
import { CuratorHeader } from '../components/curator/CuratorHeader';

interface Props {}

export const GalleryScreen: React.FC<Props> = () => {
  document.title = `Happy Medium | Gallery`;
  const { loading, error, data } = useQuery(GetSubmissionsDocument);
  const { theme } = useThemeState();
  const greenLogo = require(`@happymedium/components/assets/images/logo-green@200x.png`);
  const styles = StyleSheet.create({
    container: {
      flex: 1,
      width: '100%',
      backgroundColor: theme.backgroundColor,
    },
  });
  if (error) {
    return (
      <View
        style={{
          flex: 1,
          alignItems: 'center',
          alignContent: 'center',
          justifyContent: 'center',
          backgroundColor: theme.backgroundColor,
        }}
      >
        <Image style={{ height: 60, width: 250 }} source={greenLogo} />
        <Text
          style={{
            fontFamily: GTAmerica,
            fontSize: 16,
            color: theme.foregroundColor,
          }}
        >
          An error has occurred. Please try again.
        </Text>
      </View>
    );
  }
  const renderLoadingView = () => {
    return (
      <View
        style={{
          flex: 1,
          height: '100vh',
          minHeight: '100vh',
          alignItems: 'center',
          alignContent: 'center',
          justifyContent: 'center',
          backgroundColor: theme.backgroundColor,
        }}
      >
        <Image style={{ height: 60, width: 250 }} source={greenLogo} />
        <Text
          style={{
            fontFamily: GTAmerica,
            fontSize: 16,
            color: theme.foregroundColor,
            marginTop: 20,
          }}
        >
          Loading...
        </Text>
      </View>
    );
  };
  return (
    <ScrollView style={styles.container}>
      <CuratorHeader subheader={'gallery'} />
      {loading ? (
        renderLoadingView()
      ) : (
        <Drawings drawings={data.getSubmissions} />
      )}
    </ScrollView>
  );
};
