import React from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { View, Text, StyleSheet } from 'react-native';

import { themes } from '../../styles/themes';
import { GTAmericaCompressed } from '../../libs/constants';
import { useThemeState, useThemeDispatch } from '../../context/ThemeContext';
import { useDimensions } from '../../context/DimensionsContext';
import { TouchableOpacity } from '../common/TouchableOpacity';

interface Props { }

export const ThemePicker: React.FC<Props> = () => {
  const lgBreakpoint = 1180;
  const mdBreakpoint = 860;
  const smBreakpoint = 420;
  const { width } = useDimensions();
  const { theme } = useThemeState();
  const dispatch = useThemeDispatch();
  const client = useApolloClient();

  const styles = StyleSheet.create({
    themePicker: {
      fontFamily: GTAmericaCompressed,
      fontWeight: '400',
      fontSize: width < mdBreakpoint ? 18 : 21,
      letterSpacing: 2.1,
      textTransform: 'uppercase',
      marginHorizontal: width < mdBreakpoint ? 10 : 0,
    },
    container: {
      flexDirection: width < mdBreakpoint ? 'row' : 'column',
      justifyContent: width < mdBreakpoint ? 'space-between' : undefined,
      width: width < mdBreakpoint ? undefined : 150,
      zIndex: 10
    },
  });
  var themeOptions = Object.entries(themes).map(([key, themeValue]) => {
    return (
      <TouchableOpacity
        key={key}
        onPress={() => {
          dispatch(themeValue);
          client.writeData({ data: { theme: themeValue } });
        }}

      >
        <Text style={[
          styles.themePicker,
          {
            color: themeValue.foregroundColor,
            textDecorationLine:
              theme.id === themeValue.id ? 'underline' : 'none',
          },
        ]}>
          {themeValue.displayName}
        </Text>
      </TouchableOpacity>
    );
  });
  return <View style={styles.container}>{themeOptions}</View>;
};
