import React from "react";
import { View, StyleSheet, Text, Image } from "react-native";
import { CuratorHeader } from "../components/curator/CuratorHeader";
import { useThemeState } from "../context/ThemeContext";
import { PanamaBold } from "../libs/constants";
import { useDimensions } from "../context/DimensionsContext";

interface Props {}

export const AboutScreen: React.FC<Props> = () => {
  document.title = "Happy Medium | About";
  const { theme } = useThemeState();
  const { width } = useDimensions();
  const mdBreakpoint = 860;
  const flowers = require("@happymedium/components/assets/images/arrangement.png");
  const styles = StyleSheet.create({
    container: {
      backgroundColor: theme.backgroundColor,
      flex: 1,
    },
  });
  return (
    <View style={[styles.container]}>
      <CuratorHeader />
      <View
        style={{
          maxWidth: 600,
          marginTop: width < mdBreakpoint ? 20 : 80,
          alignSelf: "center",
          flex: 1,
        }}
      >
        <Text
          style={{
            color: theme.foregroundColor,
            fontFamily: PanamaBold,
            fontSize: width < mdBreakpoint ? 16 : 18,
            letterSpacing: 1.2,
            paddingHorizontal: 20,
          }}
        >
          Happy Medium exists to create and curate the best supplies, content,
          and events for{" "}
          <Text
            style={{
              fontStyle: "italic",
              fontWeight: "600",
              letterSpacing: 0.9,
            }}
          >
            casual artists
          </Text>
          . There's a lot of stuff out there for kids and capital "A" Artists,
          but there isn't much for those that fall somewhere in-between. We are
          on a mission to fill the gap.
          {"\n\n"}We want to be part of a world where more people feel
          emboldened to create things with their hands, just for the hell of it
          - because the real thrill simply lies in the making.
        </Text>
        <Image
          source={flowers}
          style={{ width: "auto", height: 100, marginTop: 40 }}
          resizeMethod="scale"
          resizeMode="contain"
        />
      </View>
    </View>
  );
};
