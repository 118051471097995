import React from "react";
import { ScrollView, View, Text, Image, ImageBackground } from "react-native";
import { H1 } from "../components/common/H1";

import { CuratorHeader } from "../components/curator/CuratorHeader";
import { SalonSignup } from "../components/salon/SalonSignup";
import { mdBreakpoint } from "../constants";
import { useDimensions } from "../context/DimensionsContext";
import { useThemeState } from "../context/ThemeContext";
import { PanamaBold } from "../libs/constants";

interface Props {}

export const ArtSchoolFigureDrawingScreen: React.FC<Props> = () => {
  const { theme } = useThemeState();
  const { width, height } = useDimensions();

  const Hero = () => (
    <View
      style={{
        display: "flex",
        alignContent: "center",
        // flex: 1
        // height: 700
        flexDirection: "column",
        justifyContent: "center",
        maxWidth: "100%",
        zIndex: 0,
      }}
    >
      <ImageBackground
        resizeMode="cover"
        style={{
          // flex: 1,
          // justifyContent: 'center',
          height: width < mdBreakpoint ? 484 : 700,
          maxWidth: "100%",
          alignItems: "center",
          // alignSelf: 'center'
        }}
        source={{
          uri:
            "https://49market.s3.amazonaws.com/HM+figure+drawing+visual+essay+June+2022-000072900019.jpg",
        }}
      >
        <View
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            alignSelf: "center",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Text
            style={{
              fontFamily: PanamaBold,
              textAlignVertical: "center",
              color: "#fff",
              fontSize: width < mdBreakpoint ? 40 : 70,
              lineHeight: width < mdBreakpoint ? 55 : 80,
              letterSpacing: 2.7,
              textAlign: "center",
              textTransform: "uppercase",
            }}
          >
            Your Guide to{"\n"}An Evening of Figure Drawing{"\n"}at Happy Medium
          </Text>
        </View>
      </ImageBackground>
      {/* </View> */}
    </View>
  );

  return (
    <ScrollView style={{ flex: 1, backgroundColor: theme.backgroundColor }}>
      <CuratorHeader />
      <Hero />
      <View
        style={{ flex: 1, alignSelf: "center", width: 700, maxWidth: "90vw" }}
      >
        {/* <View style={{ flex: 1 }}>
          <Text
            style={{
              position: 'absolute',
              // top: 0,
              // left: 0,
              right: 0,
              bottom: 0,
              marginLeft: 'auto',
              // marginRight: 'auto',
              marginTop: 'auto',
              // marginBottom: 'auto',
            }}
          >
            Your Guide to An Evening of Figure Drawing at Happy Medium
          </Text>
          <Image
            style={{ width: 600, height: 800, alignSelf: 'center' }}
            source={{
              uri: 'https://49market.s3.amazonaws.com/IMG_3456.jpg',
            }}
          />
        </View> */}
        <View style={{ height: width < mdBreakpoint ? 30 : 60 }} />
        <Text style={{ color: theme.foregroundColor }}>
          So you've decided to try figure drawing, but you never went to art
          school - you're in the right place. Almost half of the people that
          attend Happy Medium figure drawing events are completely new to the
          practice, so you'll be in very good company! We've included everything
          you need to know below 😊
          {"\n\n"}
          The Happy Medium figure drawing experience is designed to be a playful
          indulgence. It's about you gifting yourself ~2 hours to relax and
          explore your creativity so take the pressure off. Two hours of no
          screens, no work, no expectations, no notifications. Just a beautiful
          space, great music, good smells, and a room full of creative energy.
          {"\n\n"}
          You don't need to bring anything with you or even know how to draw a
          stick figure to reap the rewards of spending time in a flow state.
          Many people describe the figure drawing experience at Happy Medium as
          a "brain massage" and, in the medical sense, it kind of is. When you
          make things with your hands, as you will do at this event, new
          connections, new pathways, are formed in your brain. Your brain
          literally rewires itself when you make things with your hands. You can
          take this new and improved brain structure into every other area of
          your life.{"\n\n"}
          Logistically, when you arrive, you'll find our blue door with a sign
          that says "Figure Drawing" - open it and come upstairs (doors open at
          6:45pm). When you walk into the studio, come say hi at the bar and
          we'll get you checked in. You'll also receive your figure drawing kit:
          willow charcoal, our house-made figure drawing sketchbook, and a
          studio cloth.{"\n\n"}
          There's no assigned seating - you're free to sit wherever you'd like.
          If you sit in a chair without a table in front of it, we have a large
          selection of drawing boards of various sizes so you will always have a
          solid surface to draw on. Every seat was created to provide an
          excellent view. If you're meeting someone, you are welcome to save a
          seat for them. After you've found your seat, join us at the bar for a
          drink and snack before we get started.{"\n\n"}
          Drawing will begin promptly at 7:30pm. We do allow people to enter
          after the event has begun, but the door will be locked so you'll have
          to text the number posted on the door for access. There is no talking
          permitted while we're drawing.{"\n\n"}
          There's only one rule at Happy Medium - no photos of the model while
          they are on the stage as the model will be nude. If there is an
          exception to this rule (some models do allow photos) it will be
          announced at the start of the event.
          {"\n\n"}
          Charcoal is simple to use - the harder you press, the darker the line.
          You'll notice that the sticks are different sizes - the only
          difference is the size of the mark each piece will create and how easy
          it is to break. You can blend and smudge charcoal with your fingers to
          create shadows and soft shapes. There's no eraser but you can rub out
          any mistakes with your studio cloth or draw over them to create a
          layered effect. Your hands will get dirty, but that's part of the
          experience. Feel free to bring any other art supplies you'd like to
          use as well!
          {"\n\n"}
          We'll start with some 1 minute poses to get your hand, eyes, and mind
          warmed up. One minute will feel extremely fast - it's supposed to. To
          successfully induce a flow state, you have to be shocked into a new
          frame of mind. There's no better way than a few creative wind sprints.
          Don't worry about capturing specifics of the body, but instead try to
          capture the motion, or gesture, like this: {"\n\n"}
        </Text>
        <View style={{ height: width < mdBreakpoint ? 20 : 60 }} />
        <Image
          source={{ uri: "https://49market.s3.amazonaws.com/kandinsky.jpg" }}
          style={{
            height: width < mdBreakpoint ? 500 : 600,
            width: 450,
            maxWidth: "90vw",
            alignSelf: "center",
            resizeMode: "contain",
          }}
        />
        <View style={{ height: width < mdBreakpoint ? 20 : 60 }} />
        <Text style={{ color: theme.foregroundColor }}>
          After the warm up, we will go into a series of longer poses (3,5,8,
          and ending with 12 minutes) for the rest of our time with a short
          break in the middle. During the longer poses, we like to challenge
          ourselves to try to draw exactly what we see. The secret to drawing is
          to be an honest observer - we often draw things the way we think they
          *should* look, instead of how they actually look. Longer poses are
          ideal for slowing down, studying the shadows and forms of the body,
          and then translating what you see (and feel) onto the page. Sometimes
          we'll pick one part of the body, other times we'll try to capture the
          whole pose and the rest of the room for that matter. Just depends on
          what we're feeling in the moment.{"\n\n"}
          At the end, you might be surprised at what you've made. Or maybe
          you'll really love something. Or you might want to just toss it all
          into the recycling and that's cool too because the output isn't
          important! The benefit comes from the act of creating, not from the
          output.{"\n\n"}
          We'll end with our manifesto - it's our take on the creative process
          and we hope it inspires you to come boldly into your first figure
          drawing session! {"\n\n"}
        </Text>
        <Text style={{ color: theme.foregroundColor, fontStyle: "italic" }}>
          Before we begin, there are some things to know.{"\n"}
          Not everyone is a Frida, Pablo or Yayoi. But everyone is creative.
          {"\n"}
          And to go into oneself, one must be prepared to get over oneself.
          There is a good chance your art may be—at first—not great.{"\n"}
          But not great is perfect. It's a sign of doing.{"\n"}
          And this is exactly the point.{"\n"}
          Making art is about relinquishing all notions of good, bad, or ugly,
          Marching hands-first towards the liberation that self-expression
          holds. On the other side, you may find emotions such as:
          Joy/satisfaction/calm/comfort/elation et al.{"\n"}
          And one nice byproduct of being a beginner at anything. Humility.
          {"\n"}
          So bravo, you. For getting on that hobby horse. For starting a new
          habit. For simply doing. {"\n\n"}
        </Text>
        <Image
          style={{ height: 60, width: 120, resizeMode: "contain" }}
          source={require("@happymedium/components/assets/images/logo-hm-abbreviated-green.png")}
        />
        <View style={{ height: 60 }} />
      </View>
      <SalonSignup />
    </ScrollView>
  );
};
