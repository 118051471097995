import React from 'react';
import {
  View,
  StyleSheet,
  Image,
  Text,
  ScrollView,
  Linking,
} from 'react-native';
import {
  greenBrand,
  whiteBrand,
  PanamaBold,
  GTAmerica,
} from '../libs/constants';
import { useDimensions } from '../context/DimensionsContext';
import { NewsletterSubscribeForm } from '../components/forms/NewsletterSubscribeForm';
import { smBreakpoint, mdBreakpoint } from '../constants';
import { SalonIssuePreviews } from '../components/salon/IssuePreviews';
import { salonIssuePreviewData } from '../data/SalonIssues';
import { TouchableOpacity } from '../components/common/TouchableOpacity';
import { RouteComponentProps } from '../navigation';

interface MatchParams {
  email?: string;
}

interface Props extends RouteComponentProps<MatchParams> {}

export const JoinTheSalonScreen: React.FC<Props> = (props) => {
  document.title = `Happy Medium | Join The Salon`;
  const {
    match: {
      params: { email },
    },
  } = props;
  const { width, height } = useDimensions();
  const logoColor = 'white';
  const logo = require(`@happymedium/components/assets/images/logo-${logoColor}@200x.png`);
  const styles = StyleSheet.create({
    container: {
      backgroundColor: greenBrand,
      flexDirection: 'column',
      alignContent: 'center',
      alignItems: 'center',
      // height: '80vh',
    },
    logo: {
      height: width < smBreakpoint ? 24 : width < mdBreakpoint ? 35 : 50,
      width: width < smBreakpoint ? 100 : width < mdBreakpoint ? 150 : 211,
      marginTop: 50,
      marginBottom: width < smBreakpoint ? 40 : 80,
    },
    copy: { color: whiteBrand, width: '85%', textAlign: 'center' },
    header: {
      fontFamily: PanamaBold,
      fontSize: width < smBreakpoint ? 24 : 48,
      letterSpacing: 1.01,
      textTransform: 'uppercase',
    },
    secondaryCopy: {
      fontFamily: GTAmerica,
      fontSize: width < smBreakpoint ? 16 : 24,
      letterSpacing: 0.84,
      marginTop: 20,
    },
  });
  return (
    <ScrollView style={{ flex: 1 }}>
      <View style={styles.container}>
        <TouchableOpacity onPress={() => Linking.openURL('/')}>
          <Image
            accessibilityLabel="Happy Medium Logo"
            style={styles.logo}
            source={logo}
          />
        </TouchableOpacity>
        <Text style={[styles.copy, styles.header]}>
          Be the most cultured person in the room
        </Text>
        <Text style={[styles.copy, styles.secondaryCopy]}>
          Join The Salon and get weekly stories about art, culture, and
          creativity.{' '}
        </Text>
        <View style={{ height: width < smBreakpoint ? 30 : 80 }} />
        <NewsletterSubscribeForm email={email} />
        <View style={{ height: width < smBreakpoint ? 30 : 80 }} />
      </View>
      <View style={{ height: 80 }} />
      <SalonIssuePreviews issues={salonIssuePreviewData} />
      <View
        style={{
          height: 80,
        }}
      />
    </ScrollView>
  );
};
