import React from 'react';
import { View, Text, Image, StyleSheet } from 'react-native';

import {
  PanamaBold,
  GTAmericaCompressed,
  GTAmerica,
} from '../../libs/constants';
import { useThemeState } from '../../context/ThemeContext';
import { Activity } from '../ApolloComponents';
import { useDimensions } from '../../context/DimensionsContext';

interface AcitvityProps {
  activity: Partial<Activity>;
}
interface Props {}

export const CuratedHeader: React.FC<AcitvityProps> = React.memo(
  ({ activity }) => {
    const lgBreakpoint = 1180;
    const mdBreakpoint = 860;
    const smBreakpoint = 420;
    const { theme } = useThemeState();
    const { width } = useDimensions();
    const styles = StyleSheet.create({
      container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        alignContent: 'center',
        justifyContent: 'space-between',
        height: width < mdBreakpoint ? 110 : 120,
      },
      middleStack: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      },
      middleStackCopy: {
        textTransform: 'uppercase',
        fontSize: width < smBreakpoint ? 18 : width < mdBreakpoint ? 22 : 28,
        fontFamily: PanamaBold,
        letterSpacing:
          width < smBreakpoint ? 0.9 : width < mdBreakpoint ? 1.1 : 1.3,
        lineHeight: width < smBreakpoint ? 22 : width < mdBreakpoint ? 30 : 36,
        color: theme.foregroundColor,
      },
      verticalText: {
        fontSize: 14,
        fontFamily: GTAmericaCompressed,
        fontWeight: '500',
        letterSpacing:
          width < smBreakpoint ? 2.95 : width < mdBreakpoint ? 2.69 : 2.14,
        color: theme.foregroundColor,
        textTransform: 'uppercase',
      },
      verticalUpText: {
        transform: [{ rotate: '-90deg' }],
      },
      verticalDownText: {
        transform: [{ rotate: '90deg' }],
      },
    });
    return (
      <View style={styles.container}>
        <Text style={[styles.verticalText, styles.verticalUpText]}>
          {activity.activityType![0]}
        </Text>
        <View style={styles.middleStack}>
          <Text style={styles.middleStackCopy}>{activity.title}</Text>
          <Text style={styles.middleStackCopy}>{activity.organizer}</Text>
        </View>
        <Text style={[styles.verticalText, styles.verticalDownText]}>
          {activity.activityType![0]}
        </Text>
      </View>
    );
  }
);
