import React from 'react';
import { View, Image, ImageURISource, StyleSheet, Text } from 'react-native';
import { UL, LI, A } from '@expo/html-elements';

import {
  greenBrand,
  PanamaBold,
  GTAmerica,
  GTAmericaCompressed,
  Vorsik,
  Cochin,
  Pistilli,
  Minion,
} from '../../../libs/constants';
import { useDimensions } from '../../../context/DimensionsContext';
import {
  mdBreakpoint,
  smBreakpoint,
  lgBreakpoint,
  smMdBreakpoint,
} from '../../../constants';
import { HoverableLink } from '../../common/HoverableLink';

interface SalonIssueProps {}

export const FrickByEdith: React.FC<SalonIssueProps> = () => {
  const { width } = useDimensions();
  const blueSquiggle = require('@happymedium/components/assets/images/squiggles-blue.png');
  const redSquiggle = require('@happymedium/components/assets/images/squiggles-red.png');
  const fragonard = require('@happymedium/components/assets/images/fragonard.png');
  const frick = require('@happymedium/components/assets/images/frick.png');
  const foyer = require('@happymedium/components/assets/images/frickfoyer.png');
  const yellowstar = require('@happymedium/components/assets/images/yellowstar.png');

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      flexDirection: 'column',
    },
    innerContainer: {
      maxWidth: width < 900 ? '85%' : 700,
      alignSelf: 'center',
    },
    imageContainer: {
      alignItems: 'center',
      marginVertical:
        width < smBreakpoint ? 20 : width < mdBreakpoint ? 35 : 60,
    },
    text: {
      color: greenBrand,
      flexWrap: 'wrap',
    },
    header: {
      fontFamily: Vorsik,
      // letterSpacing: 1.3,
      fontSize:
        width < smBreakpoint
          ? 28
          : width < smMdBreakpoint
          ? 32
          : width < mdBreakpoint
          ? 48
          : width < lgBreakpoint
          ? 56
          : 78,
      marginBottom: width < smBreakpoint ? 5 : width < mdBreakpoint ? 15 : 20,
      textTransform: 'uppercase',
      textAlign: 'center',
    },
    superheader: {
      fontSize:
        width < smBreakpoint
          ? 36
          : width < smMdBreakpoint
          ? 48
          : width < mdBreakpoint
          ? 56
          : 78,
    },
    subheader: {
      fontFamily: Cochin,
      fontSize: width < smBreakpoint ? 22 : width < mdBreakpoint ? 28 : 36,
    },
    body: {
      fontFamily: Cochin,
      fontWeight: '400',
      // letterSpacing: 0.45,
      fontSize: width < smBreakpoint ? 16 : width < mdBreakpoint ? 18 : 20,
      // marginVertical: 10,
      textAlign: 'justify',
    },
    imageDescription: {
      fontFamily: Cochin,
      fontWeight: '400',
      // letterSpacing: 0.45,
      fontSize: width < smBreakpoint ? 12 : width < mdBreakpoint ? 14 : 16,
      marginVertical: width < smBreakpoint ? 5 : 8,
      textAlign: 'center',
    },
    shareButton: {},
    arrow: {},
    squiggle: {
      height: 31,
      width: 123,
      alignSelf: 'center',
      resizeMode: 'contain',
      marginBottom: 0,
      marginTop: 25,
    },
    image: {
      marginHorizontal: 30,
      maxWidth: width < mdBreakpoint ? '80%' : '50%',
    },
    imageVertical: {
      height: width < smBreakpoint ? 400 : width < mdBreakpoint ? 500 : 550,
      width: '100%',
      alignSelf: 'center',
      resizeMode: 'contain',
    },
    imageHorizontal: {
      height:
        width < smBreakpoint
          ? 280
          : width < mdBreakpoint
          ? 350
          : width < lgBreakpoint
          ? 420
          : 550,
      width: '100%',
      alignSelf: 'center',
      resizeMode: 'contain',
    },
  });

  const renderHeader = (copy: string) => (
    <Text style={[styles.text, styles.header]}>{copy}</Text>
  );

  const renderParagraph = (copy: string) => (
    <Text style={[styles.text, styles.body]}>{copy}</Text>
  );

  const renderImageDescription = (copy: string) => (
    <Text style={[styles.text, styles.imageDescription]}>{copy}</Text>
  );

  const renderSwatch = (color: string, description: string) => (
    <View style={{ alignContent: 'center', padding: 10 }}>
      <View style={{ height: 135, width: 135, backgroundColor: color }} />
      <Text
        style={[styles.imageDescription, { width: 135, textAlign: 'left' }]}
      >
        {description}
      </Text>
    </View>
  );

  return (
    <View style={styles.container}>
      <Text style={[styles.header, { marginTop: 30 }]}>
        A Chromatic,{'\n'}Meteorological,{'\n'}and Sonic Study of{'\n'}a Friday
        Afternoon at{'\n'}the Frick Madison
      </Text>
      <View
        style={{
          width: 640,
          maxWidth: '85%',
          paddingHorizontal: 0,
          alignSelf: 'center',
        }}
      >
        <View style={{ height: 30 }} />
        <Text
          style={[
            styles.body,
            {
              textAlign: 'center',
              width: 490,
              maxWidth: '95%',
              alignSelf: 'center',
            },
          ]}
        >
          In the rooms of the Brutalist Marcel Breuer building on Madison
          Avenue, I’ve seen:
        </Text>

        <View style={{ height: 20 }} />
        <UL>
          <LI style={styles.body}>
            — a mammoth camera obscura constructed by Zoe Leonard,
          </LI>
          <LI style={styles.body}>
            — a towering mound of rock-hard play-doh by Jeff Koons,
          </LI>
          <LI style={styles.body}>
            — and a spiralbound notebook where David Foster Wallace organized
            scenes for his posthumous novel The Pale King (its cover a blue-eyed
            kitten on a bed of roses, the brand name “Cuddly Cuties”) on
            display.
          </LI>
        </UL>
        <View style={{ height: 20 }} />
        <Text style={[styles.body]}>
          Despite this, or because of it, it comes as an even greater shock to
          see these vast roseate panels of courtship by Jean-Honoré Fragonard
          here now. Allow me to take you on a tour of the Frick Madison on a
          Friday afternoon (there are no photos allowed). I like to take the
          elevator to the top floor and work my way down. It’s dead quiet in
          here because everyone else is listening to a mobile audio guide. Any
          sounds are the exception, rather than the rule (visitors may be too
          shy to gossip in front of such stately subjects).
        </Text>
      </View>
      <View
        style={{
          height: 3,
          width: 100,
          backgroundColor: 'rgb(66, 81, 48)',
          alignSelf: 'center',
          marginVertical: 50,
        }}
      />
      <View
        style={{
          flexDirection: width < lgBreakpoint ? 'column-reverse' : 'row',
          alignSelf: 'center',
          paddingVertical: 20,
        }}
      >
        <View
          style={{
            flexDirection: 'column',
            marginTop: width < mdBreakpoint ? 40 : 0,
          }}
        >
          <Image
            style={{
              height:
                width < smBreakpoint ? 387 : width < smMdBreakpoint ? 452 : 573,
              width:
                width < smBreakpoint ? 300 : width < smMdBreakpoint ? 350 : 444,
              resizeMode: 'contain',
              alignSelf: width < lgBreakpoint ? 'center' : 'auto',
              // maxWidth: '95vw',
              // maxHeight: '100vh',
            }}
            source={fragonard}
          />
          <View
            style={{
              height: 2,
              width: 200,
              backgroundColor: 'rgb(66, 81, 48)',
              alignSelf: 'center',
              marginTop: 28,
            }}
          />
          <Text style={[styles.imageDescription, { maxWidth: 444 }]}>
            An illicit image of the storm brewing{'\n'}beyond the Fragonard
            room.
          </Text>
        </View>
        <View>
          <Text
            style={[
              styles.header,
              {
                left: width < lgBreakpoint ? 0 : -140,
                position: 'relative',
                top: width < lgBreakpoint ? -25 : -15,
              },
              styles.superheader,
              // { alignSelf: 'flex-start' },
            ]}
          >
            Fourth Floor
          </Text>
          <View style={{ marginLeft: width < lgBreakpoint ? 0 : 120 }}>
            <Text
              style={[
                styles.subheader,
                { marginLeft: width < lgBreakpoint ? 20 : 0 },
              ]}
            >
              “The party’s about to start.”
            </Text>
            <Text
              style={[
                styles.imageDescription,
                {
                  textAlign: 'justify',
                  paddingLeft: 35,
                  marginLeft: width < lgBreakpoint ? 20 : 0,
                },
              ]}
            >
              - a security guard watching the severe thunderstorm develop{'\n'}
              outside of the Breuer’s polygonal window
            </Text>
            <View
              style={{
                flexDirection: 'row',
                maxWidth: 600,
                flexWrap: 'wrap',
                alignContent: 'space-between',
                marginTop: 40,
                justifyContent: width < lgBreakpoint ? 'center' : 'flex-start',
                // alignItems: 'center',
              }}
            >
              {renderSwatch(
                'rgb(136, 47, 43)',
                'The Progress of Love: The Lover Crowned'
              )}
              {renderSwatch('rgb(251, 239, 215)', 'Camillo Borghese')}
              {renderSwatch(
                'rgb(45,61,50)',
                'The Progress of Love: Love Letters'
              )}
              {renderSwatch(
                'rgb(107,130,120)',
                'The Progress of Love: The Meeting'
              )}
              {renderSwatch('rgb(132,142,153)', 'The Four Seasons: Spring')}
              {renderSwatch('rgb(141,56,38)', 'The Progress of Love: Reverie')}
            </View>
          </View>
        </View>
      </View>
      {/* QUOTE SECTION */}
      <View>
        <View
          style={{
            height: 4,
            maxWidth: width < mdBreakpoint ? '98%' : 700,
            width: '100%',
            borderWidth: 1,
            borderColor: '#23a349',
            alignSelf: 'center',
            marginTop: 40,
          }}
        />
        <View
          style={{
            flexDirection: 'row',
            alignContent: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            marginVertical: 20,
          }}
        >
          <View
            style={{
              height: 400,
              width: 4,
              borderWidth: 1,
              borderColor: '#23a349',
              alignSelf: 'center',
              // marginRight: 30,
            }}
          />
          <View style={{ flexDirection: 'column' }}>
            <Text
              style={{
                fontFamily: Vorsik,
                fontSize: width < mdBreakpoint ? 18 : 28,
                textTransform: 'uppercase',
                textAlign: 'center',
                marginTop: 20,
                color: 'rgb(170,84,55)',
              }}
            >
              OVERHEARD, BY THE FRAGONARDS:
            </Text>
            <View
              style={{
                height: 4,
                maxWidth: width < mdBreakpoint ? 290 : 400,
                width: '100%',
                borderWidth: 1,
                borderColor: '#23a349',
                alignSelf: 'center',
              }}
            />
            <View
              style={{
                width: width < mdBreakpoint ? '96vw' : 695,
                paddingHorizontal: width < mdBreakpoint ? 7 : 70,
              }}
            >
              <Text
                style={[
                  styles.body,
                  { padding: 20, alignSelf: 'flex-start', marginTop: 10 },
                ]}
              >
                “Fantastic light!”
              </Text>
              <Text
                style={[styles.body, { padding: 20, alignSelf: 'flex-end' }]}
              >
                “Those trees look like huge broccoli stalks.”
              </Text>
              <Text
                style={[styles.body, { padding: 20, alignSelf: 'flex-start' }]}
              >
                “Where does your friend, [Redacted], work?”
              </Text>
              <Text
                style={[styles.body, { padding: 20, alignSelf: 'flex-end' }]}
              >
                “At the Met.”
              </Text>
              <Text
                style={[styles.body, { padding: 20, alignSelf: 'flex-start' }]}
              >
                “Is he allowed to visit other museums?”
              </Text>
            </View>
          </View>
          <View
            style={{
              height: 400,
              width: 4,
              borderWidth: 1,
              borderColor: '#23a349',
              alignSelf: 'center',
              // marginLeft: 30,
            }}
          />
        </View>
        <View
          style={{
            height: 4,
            maxWidth: width < mdBreakpoint ? '98%' : 700,
            width: '100%',
            borderWidth: 1,
            borderColor: '#23a349',
            alignSelf: 'center',
          }}
        />
      </View>
      {/* END QUOTE SECTION */}
      <View
        style={{
          height: 3,
          width: 100,
          backgroundColor: 'rgb(66, 81, 48)',
          alignSelf: 'center',
          marginTop: 80,
          marginBottom: 20,
        }}
      />
      {/* THIRD FLOOR */}
      <View style={{ maxWidth: 800, width: '100%', alignSelf: 'center' }}>
        <Text
          style={[
            styles.header,
            {
              alignSelf: 'flex-start',
              position: 'relative',
              left: width < lgBreakpoint ? 10 : -100,
            },
            styles.superheader,
          ]}
        >
          Third Floor
        </Text>
        <View
          style={{
            height: width < lgBreakpoint ? 175 : 275,
            width: width < lgBreakpoint ? 175 : 275,
            borderRadius: 1000,
            backgroundColor: 'rgb(77,20,9)',
            alignSelf: 'flex-end',
            marginRight: width < lgBreakpoint ? 5 : 0,
          }}
        />
        <View
          style={{
            flexDirection: 'row',
            width: '100%',
            maxWidth: '100vw',
            marginRight: width < mdBreakpoint ? 5 : 0,
            marginLeft: width < mdBreakpoint ? 5 : 0,
            alignItems: 'flex-start',
          }}
        >
          <Text
            style={[
              styles.imageDescription,
              {
                alignSelf: 'flex-end',
                textAlign: 'justify',
                width: '60%',
                maxWidth: 400,
              },
            ]}
          >
            There’s a Velázquez in the room of the Spanish paintings, and it
            looks like King Philip IV of Spain could walk right out of the frame
            at any moment.
          </Text>
          <Text
            style={[
              styles.imageDescription,
              {
                textAlign: 'justify',
                marginLeft: width < mdBreakpoint ? 20 : 'auto',
                width: '31%',
                maxWidth: 160,
              },
            ]}
          >
            The red of the red cap in Titian's Portrait of a Man in a Red Cap
          </Text>
        </View>
        <Text
          style={[
            styles.imageDescription,
            {
              alignSelf: 'flex-start',
              textAlign: 'justify',
              marginLeft:
                width < smBreakpoint
                  ? 40
                  : width < mdBreakpoint
                  ? 70
                  : width < lgBreakpoint
                  ? 100
                  : 140,
              marginTop: 10,
              width: '75%',
              maxWidth: 360,
            },
          ]}
        >
          In case of such emergencies, patient museum guards stand by, wearing
          thick maroon blazers emblazoned with a monogram.
        </Text>
        <View
          style={{
            flexDirection: 'row',
            alignSelf: 'center',
            height: 230,
            alignItems: 'flex-end',
          }}
        >
          <Image
            style={{
              height: 12,
              width: 12,
              resizeMode: 'contain',
              marginLeft: 140,
              marginTop: 72,
              marginRight: -22,
              alignSelf: 'flex-start',
            }}
            source={yellowstar}
          />
          <Text
            style={{
              fontFamily: Pistilli,
              fontSize: 170,
              paddingTop: 25,
              // marginLeft: 140,
            }}
          >
            1
          </Text>
          <Text
            style={{
              fontFamily: Vorsik,
              fontSize: 18,
              // marginTop: 10,
              height: '100%',
              width: 180,
              textTransform: 'uppercase',
              transform: [{ rotate: '-90deg' }],
              // marginLeft: 25,
              marginBottom: 16,
            }}
          >
            Starry Night{'\n'}Mask
          </Text>
        </View>
        <View>
          <Image
            style={{
              resizeMode: 'cover',
              height: width < smBreakpoint ? 251 : 510,
              width: width < smBreakpoint ? 350 : 710,
              maxWidth: '95%',
              alignSelf: 'center',
              borderWidth: 6,
              borderColor: 'black',
            }}
            source={frick}
          />
          <View
            style={{
              height: 2,
              width: 400,
              maxWidth: '80%',
              backgroundColor: 'rgb(66, 81, 48)',
              alignSelf: 'center',
              marginTop: 12,
            }}
          />
          <Text
            style={[
              styles.imageDescription,
              { maxWidth: 444, alignSelf: 'center', marginBottom: 6 },
            ]}
          >
            The typical venue of the Frick collection.
          </Text>
          <Text
            style={[
              styles.imageDescription,
              {
                maxWidth: 244,
                alignSelf: 'center',
                fontSize: 9,
                marginTop: 0,
              },
            ]}
          >
            * Image courtesy of the Irma and Paul Milstein{'\n'}Division of
            United States History, Local History and{'\n'} Genealogy, The New
            York Public Library.
          </Text>
        </View>
      </View>
      {/* END THIRD FLOOR */}
      <View
        style={{
          height: 3,
          width: 100,
          backgroundColor: 'rgb(66, 81, 48)',
          alignSelf: 'center',
          marginVertical: 40,
        }}
      />
      {/* SECOND FLOOR */}
      <View style={{ maxWidth: 900, width: '100%', alignSelf: 'center' }}>
        <View
          style={{
            flexDirection: width < lgBreakpoint ? 'column-reverse' : 'row',
            position: 'relative',
            top: width < lgBreakpoint ? 0 : -130,
          }}
        >
          <View
            style={{
              flexDirection: 'column',
              marginTop: width < mdBreakpoint ? 20 : 0,
            }}
          >
            <View
              style={{
                height: width < lgBreakpoint ? 175 : 275,
                width: width < lgBreakpoint ? 175 : 275,
                borderRadius: 1000,
                backgroundColor: 'rgb(120,130,155)',
                position: 'relative',
                left: width < lgBreakpoint ? 50 : -100,
                alignSelf: width < lgBreakpoint ? 'flex-start' : 'auto',
              }}
            />
            <Text
              style={[
                styles.imageDescription,
                {
                  // alignSelf: width < lgBreakpoint ? 'flex-start' : 'flex-end',
                  textAlign: 'justify',
                  position: 'relative',
                  left: width < lgBreakpoint ? 140 : 40,
                  // marginRight: width < lgBreakpoint ? 'auto' : 0,
                  // marginLeft: width < lgBreakpoint ? 0 : 'auto',
                },
              ]}
            >
              The ruff collar on Van Dyck’s{'\n'}Genoese Noblewoman
            </Text>
          </View>
          <View
            style={{
              flexDirection: 'column',
              position: 'relative',
              right: width < lgBreakpoint ? 0 : -60,
              top: width < lgBreakpoint ? 0 : 100,
            }}
          >
            <Text
              style={[
                styles.header,
                {
                  alignSelf: width < lgBreakpoint ? 'center' : 'flex-end',
                },
                styles.superheader,
              ]}
            >
              Second Floor
            </Text>
            <Text
              style={[
                styles.imageDescription,
                {
                  maxWidth:
                    width < smBreakpoint
                      ? 300
                      : width < smMdBreakpoint
                      ? 325
                      : width < mdBreakpoint
                      ? 350
                      : width < lgBreakpoint
                      ? 375
                      : 400,
                  alignSelf: width < lgBreakpoint ? 'center' : 'flex-end',
                  textAlign: 'left',
                },
              ]}
            >
              Wandering between the portraits by Frans Hals, with their more or
              less neutral backgrounds and strong black and white color schemes,
              I wonder if the photographer Richard Avedon got some of his ideas
              from Hals.
            </Text>
          </View>
        </View>
        {/* QUOTE SECTION */}
        <View>
          <View
            style={{
              height: 4,
              maxWidth: width < mdBreakpoint ? '98%' : 700,
              width: '100%',
              borderWidth: 1,
              borderColor: 'rgb(170,84,55)',
              alignSelf: 'center',
              marginTop: width < mdBreakpoint ? 40 : 0,
              marginBottom: width < mdBreakpoint ? 5 : 0,
            }}
          />
          <View
            style={{
              flexDirection: 'row',
              alignContent: 'center',
              alignItems: 'center',
              justifyContent: 'center',
              // marginVertical: 40,
            }}
          >
            <View
              style={{
                height: 400,
                width: 4,
                borderWidth: 1,
                borderColor: 'rgb(170,84,55)',
                alignSelf: 'center',
                // marginRight: 30,
              }}
            />
            <View style={{ flexDirection: 'column' }}>
              <Text
                style={{
                  fontFamily: Vorsik,
                  fontSize: width < mdBreakpoint ? 16 : 28,
                  textTransform: 'uppercase',
                  textAlign: 'center',
                  marginTop: 20,
                  color: '#23a349',
                }}
              >
                OVERHEARD, BY THE DUTCH PAINTINGS:
              </Text>
              <View
                style={{
                  height: 4,
                  maxWidth: width < mdBreakpoint ? 290 : 400,
                  width: '100%',
                  borderWidth: 1,
                  borderColor: 'rgb(170,84,55)',
                  alignSelf: 'center',
                }}
              />
              <View
                style={{
                  width: width < mdBreakpoint ? '96vw' : 695,
                  paddingHorizontal: width < mdBreakpoint ? 0 : 70,
                }}
              >
                <Text
                  style={[
                    styles.body,
                    { padding: 20, alignSelf: 'flex-end', marginTop: 10 },
                  ]}
                >
                  “I thought this was Hobbema because he always paints these
                  trees.”
                </Text>
                <Text
                  style={[
                    styles.body,
                    { padding: 20, alignSelf: 'flex-start' },
                  ]}
                >
                  “Lace is pretty exotic.”
                </Text>
                <Text
                  style={[styles.body, { padding: 20, alignSelf: 'flex-end' }]}
                >
                  “...Western tendencies to classify, taxonomize{'\n'}and render
                  into hierarchy...”
                </Text>
                <Text
                  style={[
                    styles.body,
                    { padding: 20, alignSelf: 'flex-start' },
                  ]}
                >
                  “...social isolation is as unhealthy as smoking{'\n'}fifteen
                  packs of cigarettes as day...”
                </Text>
                <Text
                  style={[styles.body, { padding: 20, alignSelf: 'flex-end' }]}
                >
                  “Do you find him sympathetic or harsh?”*
                </Text>
              </View>
            </View>
            <View
              style={{
                height: 400,
                width: 4,
                borderWidth: 1,
                borderColor: 'rgb(170,84,55)',
                alignSelf: 'center',
                // marginLeft: 30,
              }}
            />
            {width >= lgBreakpoint && (
              <Text
                style={{
                  fontFamily: Vorsik,
                  fontSize: 11,
                  transform: [{ rotate: '-90deg' }],
                  position: 'absolute',
                  right: -60,
                  bottom: 140,
                }}
              >
                *Not sure if this is with regard to the portrait’s subject{'\n'}
                or a living person the visitors were discussing.
              </Text>
            )}
          </View>
          <View
            style={{
              height: 4,
              maxWidth: width < mdBreakpoint ? '98%' : 700,
              width: '100%',
              borderWidth: 1,
              borderColor: 'rgb(170,84,55)',
              alignSelf: 'center',
              marginTop: width < mdBreakpoint ? 5 : 0,
            }}
          />
          {width < lgBreakpoint && (
            <Text
              style={{
                fontFamily: Vorsik,
                fontSize: 11,
                marginLeft: 15,
                lineHeight: 10,
                marginTop: 5,
              }}
            >
              *Not sure if this is with regard to the portrait’s subject{'\n'}
              or a living person the visitors were discussing.
            </Text>
          )}
        </View>
        {/* END QUOTE SECTION */}
      </View>
      {/* END SECOND FLOOR */}
      {/* STATS SECTION */}
      <View
        style={{
          flexDirection: width < lgBreakpoint ? 'column' : 'row',
          justifyContent: 'flex-end',
          marginTop: width < lgBreakpoint ? 40 : 20,
          maxWidth: '95%',
          // alignItems: 'center',
        }}
      >
        <View
          style={{
            flexDirection: 'row',
            alignSelf: 'center',
          }}
        >
          <Text
            style={{
              fontFamily: Pistilli,
              color: 'rgb(107,130,120)',
              fontSize: 170,
              paddingTop: 25,
              marginLeft: 40,
            }}
          >
            6
          </Text>
          <Text
            style={{
              fontFamily: Vorsik,
              fontSize: 18,
              lineHeight: 20,
              textTransform: 'uppercase',
              transform: [{ rotate: '-90deg' }],
              marginLeft: 5,
              marginBottom: 25,
            }}
          >
            Cell Phones{'\n'}Ringing Over{'\n'}the Course of{'\n'}Fifteen
            Minutes
          </Text>
        </View>
        <View
          style={{
            flexDirection: 'row',
            alignSelf: 'center',
          }}
        >
          <Text
            style={{
              fontFamily: Pistilli,
              color: 'rgb(132,142,153)',
              fontSize: 170,
              paddingTop: 25,
              marginLeft: width < lgBreakpoint ? 40 : 'auto',
            }}
          >
            3
          </Text>
          <Text
            style={{
              fontFamily: Vorsik,
              fontSize: 18,
              lineHeight: 20,
              textTransform: 'uppercase',
              transform: [{ rotate: '-90deg' }],
              marginLeft: 55,
              marginBottom: -20,
            }}
          >
            Different{'\n'}Ringtones
          </Text>
        </View>
        <View
          style={{
            flexDirection: 'row',
            alignSelf: 'center',
          }}
        >
          <Text
            style={{
              fontFamily: Pistilli,
              color: 'rgb(251,239,215)',
              fontSize: 170,
              // paddingTop: 25,
              // marginLeft: -60,
            }}
          >
            1
          </Text>
          <Text
            style={{
              fontFamily: Vorsik,
              fontSize: 18,
              textTransform: 'uppercase',
              lineHeight: 20,
              transform: [{ rotate: '-90deg' }],
              marginLeft: 5,
              marginBottom: 15,
            }}
          >
            Guy Imitating{'\n'}The Barbet{'\n'}Angel's{'\n'}Pointing{'\n'}
            Gesture
          </Text>
        </View>
      </View>
      {/* END STATS SECTION */}
      <View
        style={{
          height: 3,
          width: 100,
          backgroundColor: 'rgb(66, 81, 48)',
          alignSelf: 'center',
          marginVertical: 40,
        }}
      />
      <View style={{ alignSelf: 'center' }}>
        {width < lgBreakpoint && (
          <Text style={[styles.header, styles.superheader]}>Lobby</Text>
        )}
        <Image
          style={{
            resizeMode: 'contain',
            height:
              width < smBreakpoint ? 387 : width < smMdBreakpoint ? 451 : 1153,
            width:
              width < smBreakpoint ? 300 : width < smMdBreakpoint ? 350 : 894,
            maxWidth: '95%',
          }}
          source={foyer}
        />
        {width >= lgBreakpoint && (
          <Text
            style={[
              styles.header,
              { position: 'absolute', right: width < smBreakpoint ? -5 : 15 },
            ]}
          >
            Lobby
          </Text>
        )}
      </View>
      <View
        style={{
          height: 2,
          width:
            width < smBreakpoint
              ? 200
              : width < mdBreakpoint
              ? 250
              : width < lgBreakpoint
              ? 325
              : 400,
          maxWidth: '90%',
          backgroundColor: 'rgb(66, 81, 48)',
          alignSelf: 'center',
          marginBottom: 18,
        }}
      />
      <Text style={[styles.body, { textAlign: 'center' }]}>
        The lobby’s distinctive, button-like,{'\n'}flying-saucer-ish ceiling
        lights welcome{'\n'}you in and then see you out.{' '}
      </Text>
      <View
        style={{
          maxWidth: 800,
          width: '80%',
          alignSelf: 'center',
          marginVertical: 40,
        }}
      >
        <Text style={[styles.body, { alignSelf: 'flex-end' }]}>
          - Edith Young
        </Text>
        <Text
          style={[
            styles.body,
            { marginTop: 40, textAlign: 'left', flexWrap: 'wrap' },
          ]}
        >
          {/* @ts-ignore */}
          <A
            style={{ color: greenBrand, textDecorationLine: 'underline' }}
            href="https://www.edith.nyc/"
          >
            Edith Young
          </A>{' '}
          is an artist, designer and writer who lives in New York City. Her
          first book, {/* @ts-ignore */}
          <A
            accessible={true}
            style={{ color: greenBrand, textDecorationLine: 'underline' }}
            href="https://bookshop.org/books/color-scheme-an-irreverent-history-of-art-and-pop-culture-in-color-palettes/9781616899929"
            target="_blank"
          >
            Color Scheme: An Irreverent History of Art and Pop Culture in Color
            Palettes
          </A>
          {/* <HoverableLink
            linkType="web"
            textStyle={{
              fontFamily: Cochin,
              fontSize:
                width < smBreakpoint ? 16 : width < mdBreakpoint ? 18 : 20,
              textAlign: 'left',
              flexWrap: 'wrap',
            }}
            linkUrl="https://bookshop.org/books/color-scheme-an-irreverent-history-of-art-and-pop-culture-in-color-palettes/9781616899929"
            children="Color Scheme: An Irreverent History of Art and Pop Culture in Color Palettes"
          /> */}
          , will be published by Princeton Architectural Press in October 2021.
        </Text>
      </View>
    </View>
  );
};
