import React from 'react';
import { ScrollView } from 'react-native';

import { IssuePreview } from '../../data/SalonIssues';
import { SalonIssuePreview } from './IssuePreview';
import { whiteBrand } from '../../libs/constants';

interface Props {
  issues: IssuePreview[];
}

export const SalonIssuePreviews: React.FC<Props> = ({ issues }) => {
  return (
    <ScrollView style={{ backgroundColor: whiteBrand }}>
      {issues.map((i) => (
        <SalonIssuePreview key={`${i.publishedDate}-issue`} issuePreview={i} />
      ))}
    </ScrollView>
  );
};
