import React, { useRef } from 'react';
import {
  StyleProp,
  ViewStyle,
  StyleSheet,
  View,
  Text,
  TextStyle,
} from 'react-native';

import { TouchableOpacity, TouchableOpacityProps } from './TouchableOpacity';
import { ThemeColors } from '../../styles/themes/types';
import { useThemeState } from '../../context/ThemeContext';
import { useDimensions } from '../../context/DimensionsContext';

export const defaultRadioButtonSize = 29;
export const radioLabelSpacing = 12;

export interface RadioButtonProps {
  analyticsLabel: TouchableOpacityProps['analyticsLabel'];
  checked?: boolean | null;
  containerStyle?: StyleProp<ViewStyle>[];
  labelStyle?: StyleProp<TextStyle>;
  defaultValue?: boolean | null;
  disabled?: boolean;
  enableIndeterminateState?: boolean;
  label?: string | React.ReactNode;
  labelTooltip?: string;
  right?: React.ReactNode;
  left?: React.ReactNode;
  size?: number;
  onChange?: (value: boolean | null) => void;
  checkedBackgroundThemeColor?:
    | keyof ThemeColors
    | ((theme: ThemeColors) => string);
  checkedForegroundThemeColor?:
    | keyof ThemeColors
    | ((theme: ThemeColors) => string);
  uncheckedBackgroundThemeColor?:
    | keyof ThemeColors
    | ((theme: ThemeColors) => string);
}

const borderWidth = 2;

export const RadioButton: React.FC<RadioButtonProps> = React.memo(
  (props: RadioButtonProps) => {
    const lgBreakpoint = 1180;
    const mdBreakpoint = 860;
    const smBreakpoint = 420;
    const { width } = useDimensions();
    const styles = StyleSheet.create({
      container: {
        flexDirection: 'row',
        alignContent: 'center',
        alignItems: 'center',
        alignSelf: 'flex-start',
        justifyContent: 'center',
        paddingVertical: 10,
        // flex: 1,
        flexBasis: width < lgBreakpoint ? '50%' : undefined,
      },
      radioButtonContainer: {
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        // textAlign: 'center',
        display: 'flex',
      },
      radioButton: {
        position: 'relative',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        // textAlign: 'center',
      },
      center: {
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        // textAlign: 'center',
        // display: 'flex',
      },
    });
    const { theme } = useThemeState();
    const {
      defaultValue,
      checked = defaultValue,
      analyticsLabel = undefined,
      enableIndeterminateState = false,
      containerStyle,
      disabled,
      label,
      labelStyle,
      labelTooltip,
      left,
      onChange,
      right,
      size = defaultRadioButtonSize,
      checkedBackgroundThemeColor = theme.backgroundColor,
      checkedForegroundThemeColor = theme.foregroundColor,
    } = props;

    const lastBooleanRef = useRef(
      typeof props.checked === 'boolean'
        ? props.checked
        : enableIndeterminateState
        ? !defaultValue
        : !!defaultValue
    );

    const isIndeterminateState = enableIndeterminateState && checked === null;

    const getNextValue = () =>
      enableIndeterminateState
        ? checked === null
          ? !lastBooleanRef.current
          : null
        : !checked;

    const handleOnChange = () => {
      if (!onChange) return;

      const nextValue = getNextValue();

      if (typeof nextValue === 'boolean') {
        lastBooleanRef.current = nextValue;
      }

      setTimeout(() => {
        onChange(nextValue);
      }, 1);
    };

    return (
      <TouchableOpacity
        disabled={disabled}
        onPress={disabled ? undefined : handleOnChange}
        analyticsCategory="radio"
        style={[styles.container, containerStyle]}
      >
        <View
          testID="v"
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexGrow: 1,
            flexShrink: 1,
            flexBasis: 'auto',
            alignItems: 'stretch',
            paddingVertical: 10,
          }}
        >
          <View
            style={[
              styles.radioButtonContainer,
              {
                width: size,
                height: size,
                marginLeft: 40,
                borderRadius: Math.round(size / 2),
              },
            ]}
          >
            <View
              collapsable={false}
              style={[StyleSheet.absoluteFill, styles.center, { zIndex: 1 }]}
            >
              <View
                style={{
                  width: Math.floor(
                    (isIndeterminateState ? size * 0.8 : size) - 2 * borderWidth
                  ),
                  height: Math.floor(
                    (isIndeterminateState ? size * 0.8 : size) - 2 * borderWidth
                  ),
                  borderRadius: Math.round(size / 2),
                }}
              />
            </View>
            <View
              collapsable={false}
              style={[
                StyleSheet.absoluteFill,
                styles.center,
                styles.radioButton,
                {
                  width: size,
                  height: size,
                  borderWidth,
                  borderColor: theme.foregroundColor,
                  backgroundColor: theme.backgroundColor,
                  // borderRadius: Math.round(size / 2),
                  borderRadius: size / 2,
                  zIndex: 2,
                },
              ]}
            >
              <View
                collapsable={false}
                style={[
                  styles.center,
                  {
                    backgroundColor: theme.foregroundColor,
                    // borderRadius: Math.round((size - 9) / 2),
                    borderRadius: (size - 8) / 2,
                    width: size - 8,
                    height: size - 8,
                    left: 'auto',
                    right: 'auto',
                    top: 'auto',
                    bottom: 'auto',
                    opacity: checked ? 1 : 0,
                    alignSelf: 'center',
                  },
                ]}
              />
            </View>
          </View>
          <Text
            style={[
              {
                color: theme.foregroundColor,
                // left: 0,
                // position: 'relative',
                textTransform: 'capitalize',
                paddingLeft: radioLabelSpacing,
              },
              labelStyle,
            ]}
          >
            {label}
          </Text>
        </View>
      </TouchableOpacity>
    );
  }
);
