import React from 'react';
import { View, Image, ImageURISource, StyleSheet, Text } from 'react-native';
import { Hoverable } from 'react-native-web-hooks';

import {
  greenBrand,
  PanamaBold,
  GTAmerica,
  GTAmericaCompressed,
} from '../../../libs/constants';
import { useDimensions } from '../../../context/DimensionsContext';
import { mdBreakpoint, smBreakpoint, lgBreakpoint } from '../../../constants';
import { TouchableOpacity } from '../../common/TouchableOpacity';

interface SalonIssueOneProps {}

export const SalonIssueEight: React.FC<SalonIssueOneProps> = () => {
  const { width } = useDimensions();
  const blueSquiggle = require('@happymedium/components/assets/images/squiggles-blue.png');
  const redSquiggle = require('@happymedium/components/assets/images/squiggles-red.png');

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      flexDirection: 'column',
    },
    innerContainer: {
      maxWidth: width < 900 ? '85%' : 700,
      alignSelf: 'center',
    },
    imageContainer: {
      alignItems: 'center',
      marginVertical:
        width < smBreakpoint ? 20 : width < mdBreakpoint ? 35 : 60,
    },
    text: {
      color: greenBrand,
      flexWrap: 'wrap',
    },
    header: {
      fontFamily: PanamaBold,
      letterSpacing: 1.3,
      fontSize: width < smBreakpoint ? 28 : width < mdBreakpoint ? 36 : 48,
      marginBottom: width < smBreakpoint ? 5 : width < mdBreakpoint ? 15 : 20,
    },
    body: {
      fontFamily: GTAmerica,
      fontWeight: '300',
      letterSpacing: 0.45,
      fontSize: width < smBreakpoint ? 14 : width < mdBreakpoint ? 15 : 16,
      marginVertical: 10,
    },
    imageDescription: {
      fontFamily: GTAmericaCompressed,
      fontWeight: '300',
      letterSpacing: 0.45,
      fontSize: width < smBreakpoint ? 12 : width < mdBreakpoint ? 14 : 16,
      marginVertical: width < smBreakpoint ? 5 : 8,
      textAlign: 'center',
      maxWidth: width < smBreakpoint ? '90vw' : '100%',
    },
    shareButton: {},
    arrow: {},
    squiggle: {
      height: 31,
      width: 123,
      alignSelf: 'center',
      resizeMode: 'contain',
      marginBottom: 0,
      marginTop: 25,
    },
    image: {
      marginHorizontal: 30,
      maxWidth: width < mdBreakpoint ? '80%' : '50%',
    },
    imageVertical: {
      height: width < smBreakpoint ? 400 : width < mdBreakpoint ? 500 : 550,
      width: '100%',
      alignSelf: 'center',
      resizeMode: 'contain',
    },
    imageHorizontal: {
      height:
        width < smBreakpoint
          ? 280
          : width < mdBreakpoint
          ? 350
          : width < lgBreakpoint
          ? 420
          : 550,
      width: '100%',
      alignSelf: 'center',
      resizeMode: 'contain',
    },
  });

  const renderHeader = (copy: string) => (
    <Text style={[styles.text, styles.header]}>{copy}</Text>
  );

  const renderParagraph = (copy: string) => (
    <Text style={[styles.text, styles.body]}>{copy}</Text>
  );

  const renderImageDescription = (copy: string) => (
    <Text style={[styles.text, styles.imageDescription]}>{copy}</Text>
  );

  return (
    <View style={styles.container}>
      <View
        style={{
          height: width < smBreakpoint ? 10 : width < mdBreakpoint ? 25 : 30,
        }}
      />
      <View style={{ flexDirection: width < lgBreakpoint ? 'column' : 'row' }}>
        <View style={[styles.imageContainer, { flexGrow: 1 }]}>
          <Image
            style={[
              styles.image,
              styles.imageVertical,
              {
                marginHorizontal: 0,
                maxWidth: '100%',
                height:
                  width < smBreakpoint
                    ? 320
                    : width < mdBreakpoint
                    ? 380
                    : width < lgBreakpoint
                    ? 400
                    : 480,
              },
            ]}
            source={
              "https://thesalonnewsletter.s3.us-east-2.amazonaws.com/008/Sotheby's.jpg" as ImageURISource
            }
          />
          {renderImageDescription('')}
        </View>
        <View
          style={{
            flexDirection: 'column',
            flexShrink: 1,
            maxWidth: width < 900 ? '85%' : 700,
            flexWrap: 'wrap',
            marginHorizontal:
              width < smBreakpoint ? undefined : width < mdBreakpoint ? 25 : 50,
            alignSelf: 'center',
          }}
        >
          {width < smBreakpoint && <View style={{ height: 30 }} />}
          {renderHeader('What makes art good?')}

          {renderParagraph('According to us. Plus, why art matters.')}
        </View>
      </View>
      <View
        style={[
          styles.imageContainer,
          {
            marginBottom:
              width < smBreakpoint ? 30 : width < mdBreakpoint ? 20 : 0,
            marginTop: 20,
          },
        ]}
      >
        <Image style={[styles.image, styles.squiggle]} source={redSquiggle} />
      </View>
      <View style={[styles.innerContainer]}>
        <View style={{ height: 40 }} />
        {renderParagraph(
          'Depending on who you ask, you may be told that art is only good when the artist has demonstrated their mastery and superior skill. Or you may be told that good art is art that sells for millions at auction.'
        )}
        {renderParagraph(
          "The truth is the value of art has nothing to do with either of these things. Amanda Palmer once said “You're an artist when you say you are. And you're a good artist when you make somebody else experience or feel something deep or unexpected.” "
        )}
        {renderParagraph(
          'At Happy Medium, we believe good art happens when the artist leaves a piece of themselves behind for the observer. We’re uninterested in the accuracy of perspective and we’re not impressed by a high price. Instead, we value art that connects us to the artist.'
        )}
      </View>

      <View style={[styles.imageContainer, { marginVertical: 30 }]}>
        <Image
          style={[
            styles.image,
            styles.imageHorizontal,
            {
              height:
                width < smBreakpoint
                  ? 200
                  : width < mdBreakpoint
                  ? 270
                  : width < lgBreakpoint
                  ? 300
                  : 430,
            },
          ]}
          source={
            'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/008/Van+Gogh.jpg' as ImageURISource
          }
        />
        {renderImageDescription('Farmhouse by Vincent Van Gogh, 1890')}
      </View>
      <View style={styles.innerContainer}>
        {renderParagraph(
          'This connection to the artist is what differentiates art from artifacts. When you stand in front of a Van Gogh painting, the deep, gloopy brushstrokes and distorted depiction of reality give you clues about how his mind worked and what his heartfelt. In this way, art becomes a time machine that transports through time and space to another human being. Therein lies the power of art.'
        )}
      </View>

      <View style={styles.imageContainer}>
        <Image
          style={[
            styles.image,
            styles.imageHorizontal,
            {
              height:
                width < smBreakpoint
                  ? 225
                  : width < mdBreakpoint
                  ? 270
                  : width < lgBreakpoint
                  ? 340
                  : 430,
            },
          ]}
          source={
            'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/008/Munch.jpg' as ImageURISource
          }
        />
        {renderImageDescription(
          'The Scream by Edvard Munch, 1893 – painted after a “gust of melancholy”\nwhich is almost palpable when viewing the work'
        )}
      </View>

      <View style={styles.innerContainer}>
        {renderHeader('Why art matters')}
        {renderParagraph(
          'What makes art good is also what makes art matter. To inject the canvas with your soul, you have to have a connection to your soul, no matter how weak the connection might be at first. In a world full of distraction, this type of deep introspection has become more difficult but more important than ever.'
        )}
        {renderParagraph(
          'From a scientific perspective, the benefits of making art are very similar to the benefits of meditating, which is why we say “art is mediation for those who think they can’t meditate.”'
        )}
        {renderParagraph(
          'When you make, your blood pressure goes down, your cortisol (stress hormone) drops, and you get into a flow state. Unlike meditating, making things with your hands also exercises your creative muscle, which helps prepare you for creative problem-solving in the future, and strengthens confidence. The best part is, you don’t have to like what you make, nor does it have to “look good,” for you to reap the benefits of creating.'
        )}
        {renderParagraph(
          'Art matters because it has the power to connect you with yourself, with others, and with the world around you. Good art is good-for-you art.'
        )}
      </View>

      <View style={styles.imageContainer}>
        <Image
          style={[
            styles.image,
            styles.imageHorizontal,
            {
              height:
                width < smBreakpoint
                  ? 225
                  : width < mdBreakpoint
                  ? 270
                  : width < lgBreakpoint
                  ? 340
                  : 430,
            },
          ]}
          source={
            'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/008/Picasso.jpg' as ImageURISource
          }
        />
        {renderImageDescription('Bouquet of Peace by Pablo Picasso, 1958')}
      </View>

      <View style={styles.innerContainer}>
        {renderHeader('How to make good art')}
        {renderParagraph(
          'Picasso famously said, “every child is an artist. The problem is how to remain an artist once we grow up.” Most adults get hung up on what their art looks like and this becomes a significant barrier to creating. Once you let go of the notion that good art has to look a certain way, making art becomes much more accessible.'
        )}
        {renderParagraph(
          'The secret to making good art is to treat your art like a therapist, a journal, a best friend, a punching bag, or a mindless meditation. Use your art to process whatever you’re going through, thinking about, reflect on what makes you happy or sad, to memorialize, to celebrate, to disconnect. Good art is a result of the process, not the end product.'
        )}
      </View>

      <View style={styles.innerContainer}>
        {renderHeader('Why Happy Medium exists')}
        {renderParagraph(
          'Happy Medium is an art supply company that exists to empower the pursuit of good art, as we define it. Our mission is to unlock the world’s creativity, one casual artist at a time.'
        )}
        {renderParagraph(
          'We’re launching our products later this year and can’t wait to share more. In the meantime, we’re focused on building and supporting a community of casual artists who, like us, make art because of what it gives to them. It started with a humble newsletter, a drawing tool, and an Instagram account. We’ve since kicked off our first Artist’s Way group and have many exciting things in the pipeline.'
        )}
        <Text style={[styles.text, styles.body]}>
          How can we best support you? What are you most interested in within
          the world of art? How has art changed your life? What’s holding you
          back from creating? What’s your favorite piece you’ve ever made and
          why? Or just a quick “hey.” Please reach out – you can email us
          directly at{' '}
          <Hoverable>
            {(isHovered) => (
              <TouchableOpacity
                style={{ margin: 0 }}
                onPress={() => window.open('mailto:founders@happy-medium.co')}
              >
                <Text
                  style={[
                    styles.text,
                    styles.body,
                    {
                      textDecorationLine: 'underline',
                      fontWeight: isHovered ? '600' : '400',
                      margin: 0,
                    },
                  ]}
                >
                  founders@happy-medium.co
                </Text>
              </TouchableOpacity>
            )}
          </Hoverable>
          . We’d love to hear from you.
        </Text>
        <Image
          style={{ height: 60, width: 120, resizeMode: 'contain' }}
          source={require('@happymedium/components/assets/images/logo-hm-abbreviated-green.png')}
        />
      </View>

      <View style={[styles.imageContainer, { marginBottom: 0, marginTop: 20 }]}>
        <Image style={[styles.image, styles.squiggle]} source={blueSquiggle} />
      </View>

      <View style={{ height: 80 }} />
    </View>
  );
};
