import React from 'react';
import { View, FlatList, FlatListProps, Text, Image } from 'react-native';
import { ArtSubmission } from '../ApolloComponents';
import { SubmittedDrawing } from './SubmittedDrawing';
import { GTAmerica } from '../../libs/constants';
import { useThemeState } from '../../context/ThemeContext';
import { useDimensions } from '../../context/DimensionsContext';

interface Props {
  drawings: ArtSubmission[];
}

export const Drawings: React.FC<Props> = ({ drawings }) => {
  const lgBreakpoint = 1180;
  const mdBreakpoint = 1195;
  const smBreakpoint = 940;
  const { theme } = useThemeState();
  const { width } = useDimensions();
  const greenLogo = require(`@happymedium/components/assets/images/logo-green@200x.png`);
  const renderItem: FlatListProps<Partial<ArtSubmission>>['renderItem'] = ({
    item: drawing,
    index,
  }) => {
    return <SubmittedDrawing index={index} drawing={drawing} />;
  };

  const keyExtractor: FlatListProps<Partial<ArtSubmission>>['keyExtractor'] = (
    drawing
  ) => {
    return `drawing-card-${drawing.id}`;
  };

  const listEmptyComponent = (
    <View
      style={{
        flex: 1,
        alignItems: 'center',
        alignContent: 'center',
        justifyContent: 'center',
        backgroundColor: theme.backgroundColor,
      }}
    >
      <Image style={{ height: 60, width: 250 }} source={greenLogo} />
      <Text
        style={{
          fontFamily: GTAmerica,
          fontSize: 16,
          color: theme.foregroundColor,
        }}
      >
        The gallery is currently under construction. Check back shortly.
      </Text>
    </View>
  );

  return (
    <View
      collapsable={false}
      style={{
        flexDirection: 'row',
        flexWrap: 'wrap',
        flex: 1,
        alignContent: 'center',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <FlatList
        contentContainerStyle={{
          flex: 1,
          flexDirection: 'column',
          alignContent: 'flex-start',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          width: '100%',
        }}
        keyExtractor={keyExtractor}
        key={width < smBreakpoint ? 1 : width < mdBreakpoint ? 2 : 3}
        numColumns={width < smBreakpoint ? 1 : width < mdBreakpoint ? 2 : 3}
        renderItem={renderItem}
        data={drawings}
        ListEmptyComponent={listEmptyComponent}
      />
    </View>
  );
};
