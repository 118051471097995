import React from "react";
import { View, StyleSheet, Text, Image } from "react-native";
import { CuratorHeader } from "../components/curator/CuratorHeader";
import { useThemeState } from "../context/ThemeContext";
import { PanamaBold } from "../libs/constants";
import { useDimensions } from "../context/DimensionsContext";
import { H1 } from "@expo/html-elements";
import { HoverableLink } from "../components/common/HoverableLink";

interface Props { }

export const ContactScreen: React.FC<Props> = () => {
  document.title = "Contact | Happy Medium";
  const { theme } = useThemeState();
  const { width } = useDimensions();
  const mdBreakpoint = 860;
  const flowers = require("@happymedium/components/assets/images/arrangement.png");
  const styles = StyleSheet.create({
    container: {
      backgroundColor: theme.backgroundColor,
      flex: 1,
    },
  });
  return (
    <View style={[styles.container]}>
      <CuratorHeader />
      <View
        style={{
          maxWidth: 600,
          marginTop: width < mdBreakpoint ? 20 : 80,
          alignSelf: "center",
          // alignContent: 'center',
          alignItems: "center",
          // justifyContent: 'center',
          flex: 1,
        }}
      >
        <Text
          style={{
            color: theme.foregroundColor,
            fontFamily: PanamaBold,
            fontSize: width < mdBreakpoint ? 16 : 18,
            letterSpacing: 1.2,
            paddingHorizontal: 10,
            textAlign: "center",
          }}
        >
          Ticketing or reservation questions?{"\n"}
        </Text>
        <HoverableLink linkType="mail" linkUrl="reservations@happy-medium.co">
          <Text style={{ color: theme.foregroundColor }}>
            reservations@happy-medium.co
          </Text>
        </HoverableLink>

        <Text
          style={{
            color: theme.foregroundColor,
            fontFamily: PanamaBold,
            fontSize: width < mdBreakpoint ? 16 : 18,
            letterSpacing: 1.2,
            paddingHorizontal: 10,
            textAlign: "center",
          }}
        >
          Private events?{"\n"}(not art cafe reservations!){"\n"}
        </Text>
        <Text style={{ fontWeight: "bold", color: theme.foregroundColor }}>
          Want to host a private event at Happy Medium? We would love to have
          you! Evening & weekend events start at $4,000. Daytime events
          Monday-Thursday start at $960.
        </Text>
        <HoverableLink linkType="mail" linkUrl="events@happy-medium.co">
          <Text style={{ color: theme.foregroundColor }}>
            events@happy-medium.co
          </Text>
        </HoverableLink>
        <Text
          style={{
            color: theme.foregroundColor,
            fontFamily: PanamaBold,
            fontSize: width < mdBreakpoint ? 16 : 18,
            letterSpacing: 1.2,
            paddingHorizontal: 10,
          }}
        >
          Furniture & Interiors?
        </Text>
        <HoverableLink linkType="mail" linkUrl="design@happy-medium.co">
          <Text style={{ color: theme.foregroundColor }}>
            design@happy-medium.co
          </Text>
        </HoverableLink>
        {/* <Text
          style={{
            color: theme.foregroundColor,
            fontFamily: PanamaBold,
            fontSize: width < mdBreakpoint ? 16 : 18,
            letterSpacing: 1.2,
            paddingHorizontal: 10,
          }}
        >
          Feedback?
        </Text>
        <HoverableLink linkType="mail" linkUrl="founders@happy-medium.co">
          <Text style={{ color: theme.foregroundColor }}>
            founders@happy-medium.co
          </Text>
        </HoverableLink> */}
      </View>
    </View>
  );
};
