import React from 'react';
import { View, ScrollView } from 'react-native';

import { SalonHeader } from '../components/salon/SalonHeader';
import { SalonIssuePreviews } from '../components/salon/IssuePreviews';
import { salonIssuePreviewData } from '../data/SalonIssues';
import { useDimensions } from '../context/DimensionsContext';
import { smBreakpoint, mdBreakpoint } from '../constants';
import { SalonSignup } from '../components/salon/SalonSignup';
import { whiteBrand } from '../libs/constants';

interface Props {}

export const TheSalonScreen: React.FC<Props> = () => {
  document.title = `Happy Medium | The Salon`;
  const { width } = useDimensions();
  console.log('I', salonIssuePreviewData);
  return (
    <ScrollView style={{ backgroundColor: whiteBrand }}>
      <SalonHeader />
      <View
        style={{
          height: width < smBreakpoint ? 10 : width < mdBreakpoint ? 20 : 30,
        }}
      />
      <SalonIssuePreviews issues={salonIssuePreviewData} />
      <View
        style={{
          height: width < smBreakpoint ? 40 : width < mdBreakpoint ? 60 : 80,
        }}
      />
      <SalonSignup />
    </ScrollView>
  );
};
