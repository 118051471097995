import React from 'react';
import { View, Image, ImageURISource, StyleSheet, Text } from 'react-native';
import { Hoverable } from 'react-native-web-hooks';

import {
  greenBrand,
  PanamaBold,
  GTAmerica,
  GTAmericaCompressed,
} from '../../../libs/constants';
import { useDimensions } from '../../../context/DimensionsContext';
import { mdBreakpoint, smBreakpoint, lgBreakpoint } from '../../../constants';
import { TouchableOpacity } from '../../common/TouchableOpacity';
import { HoverableLink } from '../../common/HoverableLink';

interface SalonIssueOneProps {}

export const SalonIssueTen: React.FC<SalonIssueOneProps> = () => {
  const { width } = useDimensions();
  const blueSquiggle = require('@happymedium/components/assets/images/squiggles-blue.png');
  const redSquiggle = require('@happymedium/components/assets/images/squiggles-red.png');

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      flexDirection: 'column',
    },
    innerContainer: {
      maxWidth: width < 900 ? '85%' : 700,
      alignSelf: 'center',
    },
    imageContainer: {
      alignItems: 'center',
      marginVertical:
        width < smBreakpoint ? 20 : width < mdBreakpoint ? 35 : 60,
    },
    text: {
      color: greenBrand,
      flexWrap: 'wrap',
    },
    header: {
      fontFamily: PanamaBold,
      letterSpacing: 1.3,
      fontSize: width < smBreakpoint ? 28 : width < mdBreakpoint ? 36 : 48,
      marginBottom: width < smBreakpoint ? 5 : width < mdBreakpoint ? 15 : 20,
    },
    body: {
      fontFamily: GTAmerica,
      fontWeight: '300',
      letterSpacing: 0.45,
      fontSize: width < smBreakpoint ? 14 : width < mdBreakpoint ? 15 : 16,
      marginVertical: 10,
      textAlign: 'justify',
    },
    imageDescription: {
      fontFamily: GTAmericaCompressed,
      fontWeight: '300',
      letterSpacing: 0.45,
      fontSize: width < smBreakpoint ? 12 : width < mdBreakpoint ? 14 : 16,
      marginVertical: width < smBreakpoint ? 5 : 8,
      textAlign: 'center',
      maxWidth: width < smBreakpoint ? '90vw' : '100%',
    },
    shareButton: {},
    arrow: {},
    squiggle: {
      height: 31,
      width: 123,
      alignSelf: 'center',
      resizeMode: 'contain',
      marginBottom: 0,
      marginTop: 25,
    },
    image: {
      marginHorizontal: 30,
      maxWidth: width < mdBreakpoint ? '80%' : '50%',
    },
    imageVertical: {
      height: width < smBreakpoint ? 400 : width < mdBreakpoint ? 500 : 550,
      width: '100%',
      alignSelf: 'center',
      resizeMode: 'contain',
    },
    imageHorizontal: {
      height:
        width < smBreakpoint
          ? 280
          : width < mdBreakpoint
          ? 350
          : width < lgBreakpoint
          ? 420
          : 550,
      width: '100%',
      alignSelf: 'center',
      resizeMode: 'contain',
    },
  });

  const renderHeader = (copy: string) => (
    <Text style={[styles.text, styles.header]}>{copy}</Text>
  );

  const renderParagraph = (copy: string) => (
    <Text style={[styles.text, styles.body]}>{copy}</Text>
  );

  const renderImageDescription = (copy: string) => (
    <Text style={[styles.text, styles.imageDescription]}>{copy}</Text>
  );

  return (
    <View style={styles.container}>
      <View
        style={{
          height: width < smBreakpoint ? 10 : width < mdBreakpoint ? 25 : 30,
        }}
      />
      <View style={{ flexDirection: width < lgBreakpoint ? 'column' : 'row' }}>
        <View style={[styles.imageContainer, { flexGrow: 1 }]}>
          <Image
            style={[
              styles.image,
              styles.imageVertical,
              {
                marginHorizontal: 0,
                maxWidth: '100%',
                height:
                  width < smBreakpoint
                    ? 320
                    : width < mdBreakpoint
                    ? 380
                    : width < lgBreakpoint
                    ? 400
                    : 480,
              },
            ]}
            source={
              'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/010/1+resize.jpg' as ImageURISource
            }
          />
          {renderImageDescription(
            'Twelfth Dynasty jewelry from Buhen, Nubia, excavated 1907–1911\nWatercolor by M. Louise Baker, 1911'
          )}
        </View>
        <View
          style={{
            flexDirection: 'column',
            flexShrink: 1,
            maxWidth: width < 900 ? '85%' : 700,
            flexWrap: 'wrap',
            marginHorizontal:
              width < smBreakpoint ? undefined : width < mdBreakpoint ? 25 : 50,
            alignSelf: 'center',
          }}
        >
          {width < smBreakpoint && <View style={{ height: 30 }} />}
          {renderHeader('M. Louise Baker')}

          {renderParagraph(
            'Before cameras and computers, art served the critical function of capturing the visual side of life. In today’s content-heavy world, it’s easy to forget that art was once not a mere ornamental luxury, it was a requirement for preserving history visually.'
          )}
        </View>
      </View>
      <View
        style={[
          styles.imageContainer,
          {
            marginBottom:
              width < smBreakpoint ? 30 : width < mdBreakpoint ? 20 : 0,
            marginTop: 20,
          },
        ]}
      >
        <Image style={[styles.image, styles.squiggle]} source={redSquiggle} />
      </View>

      <View style={[styles.imageContainer, { marginTop: 50 }]}>
        <Image
          style={[
            styles.image,
            styles.imageHorizontal,
            {
              height:
                width < smBreakpoint
                  ? 200
                  : width < mdBreakpoint
                  ? 270
                  : width < lgBreakpoint
                  ? 300
                  : 380,
            },
          ]}
          source={
            'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/010/2+resize.jpg' as ImageURISource
          }
        />
        {renderImageDescription('')}
      </View>

      <View style={[styles.innerContainer]}>
        <Text style={[styles.text, styles.body]}>
          If you live in NYC, you’ve likely seen a billboard by{' '}
          <HoverableLink
            linkUrl={'https://colossalmedia.com/'}
            linkType={'web'}
          >
            Colossal Media
          </HoverableLink>
          . Founded in 2004 and based in Brooklyn, Colossal designs memorable
          outdoor advertising campaigns for brands and hand paints each one.
          Considered a novelty today, hand painted billboards used to be the
          norm.
        </Text>

        {renderParagraph(
          'While the market for commercial artists hasn’t dried up, it’s certainly gotten smaller thanks to advances in technology. In the past, cartoonists and artists were on staff at every business who had something to say – newspaper ads, posters, brand identities, it was all done by hand.'
        )}
        {renderParagraph(
          'Archeologists, scientists, and medical doctors also employed artists. Darwin brought an artist with him on the HMS Beagle. If Indiana Jones was a bit older, he likely would have had an archeological illustrator for all of his adventures.'
        )}
        {renderParagraph(
          'Archeological illustrator sound like a cool job? As it turns out, an archeological illustrator, as well as a Museum Artist and art teacher, is exactly what M. Louise Baker was. In the early part of the 20th century, she traveled around the globe as the resident artist at the University of Pennsylvania’s Museum of Archeology and Anthropology. She was fierce, curious, and had that rare joie de vivre of someone who has genuinely found their niche in life.'
        )}
      </View>

      <View style={[styles.imageContainer, { marginVertical: 30 }]}>
        <Image
          style={[
            styles.image,
            styles.imageHorizontal,
            {
              height:
                width < smBreakpoint
                  ? 380
                  : width < mdBreakpoint
                  ? 400
                  : width < lgBreakpoint
                  ? 420
                  : 500,
            },
          ]}
          source={
            'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/010/3resize.jpg' as ImageURISource
          }
        />
        {renderImageDescription(
          'M. Louise Baker applying finishing touches to the\nreconstruction of the bull-headed gold lyre from Ur, ca. 1930'
        )}
      </View>
      <View style={styles.innerContainer}>
        {renderHeader('Who was M. Louise Baker?')}
        {renderParagraph(
          'Mary Louise Baker was born in Ohio in 1872, the same year the Met Museum opened in New York. At age 19, she left Ohio for neighboring Pennsylvania where she found work as a teacher in one room schoolhouses for a number of years.'
        )}
        {renderParagraph(
          'M. Louise kept diligent diaries throughout her whole life. 54 volumes covering the years 1889 – 1960 were donated to the Penn Museum Archives in 2011. We know from her writings that it was around this period of time, while teaching in Pennsylvania, that she developed serious eye problems –'
        )}
        <Text style={[styles.text, styles.body, { fontStyle: 'italic' }]}>
          “My eyes were growing worse and the severe headaches made study almost
          impossible. Finally I spent a fortnight in Wilmington under an
          oculist’s care. He ordered my temples to be leeched and cupped to
          relieve the congestion The huge leeches were ravenous when released
          from the box and quickly fastened themselves to my temples….Here they
          gorged themselves until full.…In my most successful nightmares I
          recall these awful leaches.”
        </Text>
        {renderParagraph(
          'In 1900, when she was 28, she felt the current of life pushing her in a new direction – “We were in a new century, I was beginning a new life…and now was the time to break away.”'
        )}
        {renderParagraph(
          'She returned to school and enrolled at Penn¬sylvania Museum School of Industrial Art. She changed her name from Mary Louise Baker to M. Louise Baker and took a room at the YWCA for ~$3 a week. She also began working as a freelance artist with Kelly’s Furniture Store where she made pen and ink sketches for newspaper ads.'
        )}
      </View>

      <View style={styles.imageContainer}>
        <Image
          style={[
            styles.image,
            styles.imageVertical,
            // {
            //   height:
            //     width < smBreakpoint
            //       ? 325
            //       : width < mdBreakpoint
            //       ? 370
            //       : width < lgBreakpoint
            //       ? 400
            //       : 500,
            // },
          ]}
          source={
            'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/010/4+resize.jpg' as ImageURISource
          }
        />
        {renderImageDescription(
          'Ostrich shell with mosaic incrustation by M. Louise Baker'
        )}
      </View>

      <View style={styles.innerContainer}>
        {renderParagraph(
          'In her second year of school, she won a scholarship which allowed her to focus on her art. She was also invited in her second year to start working with the Penn Museum as a pottery restorer. She continued to work with the museum after graduation while also taking on a part time teaching role. On the side, she continued to earn a reputation as a respected magazine, newspaper, and advertising illustrator. '
        )}
        {renderParagraph(
          'In 1908, when M. Louise was 36, she was hired by the museum to begin documenting a large collection of pottery by drawing and watercolor painting them. This began a 28-year partnership between her and the museum which would last until her retirement in 1936.'
        )}
      </View>

      <View style={styles.imageContainer}>
        <Image
          style={[
            styles.image,
            styles.imageVertical,
            // {
            //   height:
            //     width < smBreakpoint
            //       ? 225
            //       : width < mdBreakpoint
            //       ? 270
            //       : width < lgBreakpoint
            //       ? 340
            //       : 430,
            // },
          ]}
          source={
            'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/010/5+reseize.jpg' as ImageURISource
          }
        />
        {renderImageDescription(
          'Beads from the Ur excavations by M. Louise Baker, 1900'
        )}
      </View>

      <View style={styles.innerContainer}>
        {renderHeader('War & Travel')}
        {renderParagraph(
          'Shortly after starting at the museum, WWI broke out. M. Louise wanted to serve and, despite her health issues, was accepted into the Quaker relief organization. For over a year, oversaw the creation of an embroidery industry in France. She led a team of 800 women, mostly French refugees.'
        )}
        {renderParagraph(
          'In 1931, when she was almost 60 years old, M. Louise was granted a 6-month absence to travel through Central America and Mexico, documenting the finest examples of Mayan pottery. It was the opportunity of a lifetime:'
        )}
      </View>

      <View style={styles.imageContainer}>
        <Image
          style={[
            styles.image,
            styles.imageHorizontal,
            {
              height:
                width < smBreakpoint
                  ? 195
                  : width < mdBreakpoint
                  ? 230
                  : width < lgBreakpoint
                  ? 290
                  : 370,
            },
          ]}
          source={
            'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/010/6+resize.jpg' as ImageURISource
          }
        />
        {renderImageDescription(
          'Red clay bowl - Quiche, Guatemala by Mary Louise Baker'
        )}
      </View>

      <View style={styles.innerContainer}>
        <Text style={[styles.text, styles.body, { fontStyle: 'italic' }]}>
          “No woman before me was ever sent on such a trip and I adored every
          minute of it. You see, my three interests are art, archaeology and
          avia¬tion, and I was able to combine all three on my visit to the
          jungles. . . It was my job not only to make drawings of the pottery,
          but to find good and valuable examples. I did this by getting
          infor¬mation from the natives wherever I went. I would sketch one jar
          and the people who watched me work would tell me of someone miles away
          who had a beautiful vase that had been in the family for centuries.
          Sometimes the places were many miles distant over roads that would
          take me days and weeks by boat and mule. Here is where the airplane
          came in handy.”
        </Text>
        {renderParagraph(
          'During her career, she traveled throughout Central America, to Europe, Baghdad, and Iraq documenting potter, ancient tombs, and the finds of archaeological digs for posterity.'
        )}
      </View>

      <View style={styles.imageContainer}>
        <Image
          style={[
            styles.image,
            styles.imageHorizontal,
            {
              height:
                width < smBreakpoint
                  ? 225
                  : width < mdBreakpoint
                  ? 270
                  : width < lgBreakpoint
                  ? 340
                  : 430,
            },
          ]}
          source={
            'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/010/7resize.jpg' as ImageURISource
          }
        />
        {renderImageDescription(
          'M. Louise Baker working on the "Luna Vase", Guatemala City, 1931'
        )}
      </View>

      <View style={styles.innerContainer}>
        {renderHeader('Joie de Vivre')}
        {renderParagraph(
          'The eye problems that began in her 20s never fully went away. Her working life was punctured by a series of surgeries and recovery, sometimes requiring multiple months of rest whereby she could only use her eyes for a few minutes a day. By 1936, the eye problems that plagued her most of her adult life had made it nearly impossible to work. After her 7th surgery, she retired.'
        )}
        {renderParagraph(
          'Until the very end of her career, M. Louise didn’t let her physical ailments, nor her age, stand in her way. Despite coming to art later in life, she made a name for herself as a world-renown archaeological illustrator at a time most people, let alone women on their own, didn’t travel. This relentless drive was fueled by her passion for art and what art gave to her -'
        )}
        <Text style={[styles.text, styles.body, { fontStyle: 'italic' }]}>
          “My 28 years connection with the University of Pennsylvania Museum was
          one of happy memories.…My work was varied and far reaching,
          consequently exciting and fascinating. When I mounted my stool in the
          morning, I never knew what part of the globe I would visit before
          sundown.”
        </Text>
        {renderParagraph(
          'Art has the ability to transport us away to another part of the globe, right from our own home. In a time like this – isn’t that a pleasant thought?'
        )}

        <Image
          style={{ height: 60, width: 120, resizeMode: 'contain' }}
          source={require('@happymedium/components/assets/images/logo-hm-abbreviated-green.png')}
        />
      </View>

      <View style={[styles.imageContainer, { marginBottom: 0, marginTop: 20 }]}>
        <Image style={[styles.image, styles.squiggle]} source={blueSquiggle} />
      </View>

      <View style={{ height: 80 }} />
    </View>
  );
};
