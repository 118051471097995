import React from "react";

import { Router, Switch, Route, Redirect } from "../navigation/index";
import { UnsubscribeScreen } from "../screens/Unsubscribe";
import { NewsletterSignupScreen } from "../screens/NewsletterSignupScreen";
import { CuratorScreen } from "../screens/Curator";
import { GalleryScreen } from "../screens/GalleryScreen";
import { JoinTheSalonScreen } from "../screens/JoinTheSalon";
import { SalonIssueScreen } from "../screens/SalonIssue";
import { TheSalonScreen } from "../screens/TheSalonScreen";
import { AboutScreen } from "../screens/AboutScreen";
import { ArtCafeScreen } from "../screens/ArtCafeScreen";
import { EventsLandingScreen } from "../screens/EventsLandingScreen";
import { EventOne } from "../screens/EventOne";
import { OldRoseEventScreen } from "../screens/OldRoseEventScreen";
import { ArtSchoolFigureDrawingScreen } from "../screens/ArtSchoolFigureDrawingScreen";
import { RefundPolicyScreen } from "../screens/RefundPolicyScreen";
import { KatieRomeroEventScreen } from "../screens/KatieRomeroEventScreen";
import { EventsFaqScreen } from "../screens/EventsFaqScreen";
import { FigureDrawingScreen } from "../screens/FigureDrawingScreen";
import { BuildAChairScreen } from "../screens/BuildAChairScreen";
import { MenuScreen } from "../screens/MenuScreen";
import { ShopScreen } from "../screens/ShopScreen";
import { GiftCardScreen } from "../screens/GiftCardScreen";
import { ContactScreen } from "../screens/ContactScreen";
import { ArtCafeLargePartiesScreen } from "../screens/ArtCafeLargeParty";
import { LargeCanvasScreen } from "../screens/LargeCanvas";
import { PotteryScreen } from "../screens/PotteryScreen";
import { PotteryFaqScreen } from "../screens/PotteryFaqScreen";
import { WhereIsMyPotScreen } from "../screens/WhereIsMyPot";
import { WhereIsMyPaintAPotScreen } from "../screens/WhereIsMyPaintAPot";
import { TermsOfServiceScreen } from "../screens/TermsOfServiceScreen";
import { FriendshipBraceletsScreen } from "../screens/FriendshipBraceletsScreen";
import { NudgeScreen } from "../screens/Nudge";

export function Routes() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={CuratorScreen} />
        <Route exact path="/gallery" component={GalleryScreen} />
        <Route exact path="/about" component={AboutScreen} />
        <Route exact path="/contact" component={ContactScreen} />
        <Route exact path="/menu" component={MenuScreen} />
        <Route exact path="/art-cafe" component={ArtCafeScreen} />
        {/* <Route exact path="/art-cafe/:menu?" component={ArtCafeScreen} /> */}
        <Route exact path="/art-cafe/menu" component={MenuScreen} />
        <Route
          exact
          path="/art-cafe/large-parties"
          component={ArtCafeLargePartiesScreen}
        />
        <Route
          exact
          path="/art-cafe/friendship-bracelets"
          component={FriendshipBraceletsScreen}
        />
        <Route
          exact
          path="/art-school/figure-drawing"
          component={ArtSchoolFigureDrawingScreen}
        />
        <Route exact path="/events/faq" component={EventsFaqScreen} />
        <Route
          exact
          path="/events/build-a-chair"
          component={BuildAChairScreen}
        />
        <Route
          exact
          path="/events/figure-drawing"
          component={ArtSchoolFigureDrawingScreen}
        />
        <Route
          exact
          path="/events/large-canvas"
          component={LargeCanvasScreen}
        />
        <Route exact path="/events/nudge" component={NudgeScreen} />
        <Route
          exact
          path="/events/tiktok-meetup"
          component={KatieRomeroEventScreen}
        />
        {/* <Route
          exact
          path="/events/figure-drawing/old-rose"
          component={OldRoseEventScreen}
        /> */}
        <Route
          exact
          path="/events/:isSmsSubscriber?"
          component={EventsLandingScreen}
        />

        <Route exact path="/event/1" component={EventOne} />
        <Route
          exact
          path="/join-the-salon/:email?"
          component={JoinTheSalonScreen}
        />
        <Route exact path="/terms-of-service" component={TermsOfServiceScreen} />
        <Route exact path="/the-salon" component={TheSalonScreen} />
        <Route exact path="/refund-policy" component={RefundPolicyScreen} />
        <Route exact path="/unsubscribe/:email" component={UnsubscribeScreen} />
        <Route exact path="/voice" component={RefundPolicyScreen} />
        <Route
          exact
          path="/the-salon/:issueNumber/:filter?"
          component={SalonIssueScreen}
        />
        <Route exact path="/pottery" component={PotteryScreen} />
        <Route exact path="/pottery/faq" component={PotteryFaqScreen} />
        <Route
          exact
          path="/pottery/where-is-my-paint-a-pot"
          component={WhereIsMyPaintAPotScreen}
        />
        <Route
          exact
          path="/pottery/where-is-my-pot"
          component={WhereIsMyPotScreen}
        />
        <Route exact path="/shop" component={ShopScreen} />
        <Route exact path="/shop/gift-card" component={GiftCardScreen} />
        <Route
          exact
          path="/subscribe/:email?"
          component={NewsletterSignupScreen}
        />
        {/* skip splash screen if query param === 'vip' */}
        <Route exact path="/:vip?" component={CuratorScreen} />
        <Redirect from="*" to="/" />
      </Switch>
    </Router>
  );
}
