import React from 'react';
import { View, StyleSheet, Text, Image, ScrollView } from 'react-native';
import { CuratorHeader } from '../components/curator/CuratorHeader';
import { useThemeState } from '../context/ThemeContext';
import { GTAmerica, PanamaBold } from '../libs/constants';
import { useDimensions } from '../context/DimensionsContext';
import { SalonSignup } from '../components/salon/SalonSignup';
import { HoverableLink } from '../components/common/HoverableLink';
import { RouteComponentProps } from '../navigation';
import { useRef } from 'react';
import { smBreakpoint } from '../constants';

interface Props {}

export const ArtCafeLargePartiesScreen: React.FC<
  RouteComponentProps<{
    menu: string;
  }>
> = (props: RouteComponentProps<{ menu: string }>) => {
  document.title = 'Art Cafe Large Parties | Happy Medium';
  const { theme } = useThemeState();
  const { width } = useDimensions();

  const {
    match: {
      params: { menu },
    },
  } = props;

  const scrollToMenu = menu === 'menu';

  const scrollRef = useRef<ScrollView>(null);

  // if (scrollToMenu) {
  //   scrollRef.current && scrollRef.current.scrollToEnd({ animated: true });
  // }

  const mdBreakpoint = 860;
  const flowers = require('@happymedium/components/assets/images/arrangement.png');
  const styles = StyleSheet.create({
    container: {
      backgroundColor: theme.backgroundColor,
      flex: 1,
    },
    body: {
      color: theme.foregroundColor,
      fontFamily: GTAmerica,
      fontSize: width < mdBreakpoint ? 12 : 14,
    },
    header: {
      color: theme.foregroundColor,
      fontFamily: PanamaBold,
      fontSize: width < mdBreakpoint ? 20 : 22,
      letterSpacing: 1.2,
      textTransform: 'uppercase',
    },
  });
  return (
    <ScrollView
      ref={scrollRef}
      style={{ backgroundColor: theme.primaryBackgroundColor }}
      onContentSizeChange={() => {
        if (scrollToMenu) {

          scrollRef.current?.scrollTo(width < smBreakpoint ? 2000 : 3000)
        }
      }}
    >
      <CuratorHeader />
      <View style={[styles.container]}>
        <Image
          style={{ height: width / 1.73, width: width }}
          source={{ uri: 'https://49market.s3.amazonaws.com/art_cafe_1.png' }}
        />
        <View
          style={{
            maxWidth: width < mdBreakpoint ? '90%' : 540,
            marginTop: width < mdBreakpoint ? 20 : 60,
            alignSelf: 'center',
            backgroundColor: theme.backgroundColor,
          }}
        >
          <Text
            style={{
              color: theme.foregroundColor,
              fontFamily: PanamaBold,
              fontSize: width < mdBreakpoint ? 24 : 32,
              letterSpacing: 1.2,
              textTransform: 'uppercase',
            }}
          >
            Large Parties at the Art Cafe
          </Text>
          <View style={{ height: 20 }} />
          
          <Text style={styles.body}>
            Want to bring your whole crew? We're happy to accommodate large groups when we have space, but we do require a non-refundable deposit of $15 per person when making a reservation for 6 or more people. The deposit can be applied to your bill at the end of your time with us. Deposits must be paid in full by one person. If someone can't make it and you let us know at least 24 hours before the beginning of your reservation, we're happy to apply their deposit to your total bill. If we're not given at least 24 hours' notice the deposit for the no-show will be forfeit. We have to enforce this policy because spots reserved, but not used prevent us from serving other customers. A 20% service charge will be applied to the total bill for all groups of 10 or more.
          </Text>
          
          <View style={{ height: 80 }} />
        </View>
      </View>
      <SalonSignup />
    </ScrollView>
  );
};
