import React from 'react';
import { View, StyleSheet, Image, Text, Linking } from 'react-native';

import { useDimensions } from '../../context/DimensionsContext';
import { greenBrand, PanamaBold, GTAmerica } from '../../libs/constants';
import { TouchableOpacity } from '../common/TouchableOpacity';
import { smBreakpoint, mdBreakpoint } from '../../constants';
import { useThemeState } from '../../context/ThemeContext';

interface Props {
  issueNumber: number;
}

export const SalonIssueHeader: React.FC<Props> = ({ issueNumber }) => {
  const { width } = useDimensions();
  const { theme } = useThemeState();
  const logo = require(`@happymedium/components/assets/images/logo-green@200x.png`);
  const styles = StyleSheet.create({
    container: {
      // flex: 1,
      borderBottomColor: greenBrand,
      borderBottomWidth: 2,
      backgroundColor: theme.backgroundColor,
    },
    logo: {
      height: width < smBreakpoint ? 30 : width < mdBreakpoint ? 25 : 50,
      width: width < smBreakpoint ? 80 : width < mdBreakpoint ? 120 : 210,
      resizeMode: 'contain',
      marginTop: width < smBreakpoint ? 20 : width < mdBreakpoint ? 25 : 30,
      marginLeft: width < smBreakpoint ? 20 : width < mdBreakpoint ? 10 : 40,
    },
    text: {
      color: greenBrand,
      textAlign: 'center',
    },
    salon: {
      fontSize: width < smBreakpoint ? 14 : width < mdBreakpoint ? 18 : 24,
      letterSpacing: 1.3,
      fontFamily: PanamaBold,
    },
    issue: {
      fontSize: width < smBreakpoint ? 24 : width < mdBreakpoint ? 36 : 48,
      letterSpacing: 0.45,
      fontFamily: GTAmerica,
      fontWeight: '300',
    },
  });
  return (
    <View style={styles.container}>
      <TouchableOpacity
        style={{ position: 'absolute' }}
        onPress={() => Linking.openURL('/')}
      >
        <Image accessibilityLabel="logo" style={styles.logo} source={logo} />
      </TouchableOpacity>
      <View
        style={{
          flexDirection: 'column',
          alignSelf: 'center',
          marginTop: width < smBreakpoint ? 30 : width < mdBreakpoint ? 40 : 50,
          marginBottom:
            width < smBreakpoint ? 20 : width < mdBreakpoint ? 25 : 30,
        }}
      >
        <TouchableOpacity onPress={() => Linking.openURL('/the-salon')}>
          <Text style={[styles.text, styles.salon]}>The Salon</Text>
        </TouchableOpacity>
        <Text style={[styles.text, styles.issue]}>Issue No. {issueNumber}</Text>
      </View>
    </View>
  );
};
