import { A, H2 } from '@expo/html-elements';
import { TextProps } from '@expo/html-elements/build/primitives/Text';
import React from 'react';
import {
  Image,
  Linking,
  ScrollView,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import { Hoverable } from 'react-native-web-hooks';
import { HoverableLink } from '../components/common/HoverableLink';
import { PrimaryButton } from '../components/common/PrimaryButton';
import { TouchableOpacity } from '../components/common/TouchableOpacity';
import { CuratorHeader } from '../components/curator/CuratorHeader';
import { SalonSignup } from '../components/salon/SalonSignup';
import { smBreakpoint } from '../constants';
import { useDimensions } from '../context/DimensionsContext';
import { useThemeState } from '../context/ThemeContext';
import {
  blackBrand,
  GTAmerica,
  GTAmericaCompressed,
  mdbreakpoint,
  PanamaBold,
  redBrand,
  TrailersDemiBold,
  wineBrand,
} from '../libs/constants';

interface Props {}

export const ShopScreen: React.FC<Props> = () => {
  const { theme } = useThemeState();
  const { width, height } = useDimensions();
  const styles = StyleSheet.create({
    text: {
      color: theme.foregroundColor,
    },
    secondaryHeader: {
      fontFamily: GTAmericaCompressed,
      textTransform: 'uppercase',
      color: theme.foregroundColor,
      fontSize: width < smBreakpoint ? 20 : width < mdbreakpoint ? 24 : 28,
    },
    body: {
      fontFamily: GTAmerica,
      fontWeight: '300',
      fontSize: width < smBreakpoint ? 12 : width < mdbreakpoint ? 14 : 16,
    },
    infoText: {
      fontFamily: GTAmericaCompressed,
      fontSize: width < smBreakpoint ? 14 : width < mdbreakpoint ? 16 : 18,
      letterSpacing: 1.02,
    },
  });
  return (
    <ScrollView
      style={{
        flex: 1,
        backgroundColor: theme.backgroundColor,
      }}
    >
      <CuratorHeader />

      <View style={{ height: 40 }} />
      <View
        style={{
          justifyContent: 'center',
          width: 700,
          maxWidth: '90vw',
          alignSelf: 'center',
        }}
      >
        <Text
          style={{
            fontFamily: PanamaBold,
            color: theme.foregroundColor,
            textTransform: 'uppercase',
            fontSize:
              width < smBreakpoint ? 22 : width < mdbreakpoint ? 32 : 48,
            letterSpacing: 1.02,
            lineHeight:
              width < smBreakpoint ? 24 : width < mdbreakpoint ? 34 : 50,
          }}
        >
          Shop All
        </Text>
        <Text
          style={{
            fontFamily: GTAmerica,
            fontWeight: '300',
            color: theme.foregroundColor,
            fontSize:
              width < smBreakpoint ? 12 : width < mdbreakpoint ? 14 : 16,
            letterSpacing: 1.07,
          }}
        >
          everything we offer in one convenient place
        </Text>
        <View style={{ height: 20 }} />
        <View style={{ height: 20 }} />
        <HoverableLink linkUrl="/shop/gift-card" linkType="internal">
          <Text style={styles.secondaryHeader}>Gift Cards</Text>
        </HoverableLink>
        {/* <Text style={[styles.text, styles.body]}>
          No, but as long as you let us know you won't be able to make it at
          least 24 hours before the event start time we're happy to offer you a
          credit that is valid for 30 days and can be applied to any future
          events, products, or art cafe experience.{'\n\n'}
        </Text> */}
        <View style={{ height: 60 }} />
      </View>
      <View style={{ height: 60 }} />
      <SalonSignup />
    </ScrollView>
  );
};
