import React from 'react';
import { View, StyleSheet, Image, Text, Linking } from 'react-native';
import { useDimensions } from '../../context/DimensionsContext';
import { greenBrand, PanamaBold, GTAmerica } from '../../libs/constants';
import { TouchableOpacity } from '../common/TouchableOpacity';
import { mdBreakpoint, smBreakpoint } from '../../constants';

interface Props {}

export const SalonHeader: React.FC<Props> = () => {
  const { width } = useDimensions();
  const logo = require(`@happymedium/components/assets/images/logo-green@200x.png`);
  const abbreviatedLogo = require(`@happymedium/components/assets/images/logo-hm-abbreviated-green.png`);
  const styles = StyleSheet.create({
    container: {
      // flex: 1,
      borderBottomColor: greenBrand,
      borderBottomWidth: 2,
    },
    logo: {
      height: width < smBreakpoint ? 16 : width < mdBreakpoint ? 25 : 50,
      width: width < smBreakpoint ? 50 : width < mdBreakpoint ? 120 : 210,
      resizeMode: 'contain',
      marginTop: width < smBreakpoint ? 20 : width < mdBreakpoint ? 25 : 30,
      marginLeft: width < smBreakpoint ? 20 : width < mdBreakpoint ? 10 : 40,
    },
    text: {
      color: greenBrand,
      textAlign: 'center',
    },
    salon: {
      fontSize: width < smBreakpoint ? 24 : width < mdBreakpoint ? 36 : 48,
      letterSpacing: 1.3,
      fontFamily: PanamaBold,
    },
    subheader: {
      fontSize: width < smBreakpoint ? 14 : width < mdBreakpoint ? 18 : 24,
      letterSpacing: 0.84,
      fontFamily: GTAmerica,
      fontWeight: '500',
    },
  });
  return (
    <View style={styles.container}>
      <TouchableOpacity
        style={{ position: 'absolute' }}
        onPress={() => Linking.openURL('/')}
      >
        <Image
          accessibilityLabel="logo"
          style={styles.logo}
          source={width <= mdBreakpoint ? abbreviatedLogo : logo}
        />
      </TouchableOpacity>
      <View
        style={{
          flexDirection: 'column',
          alignSelf: 'center',
          marginTop: width < smBreakpoint ? 30 : width < mdBreakpoint ? 40 : 50,
          marginBottom:
            width < smBreakpoint ? 20 : width < mdBreakpoint ? 25 : 30,
        }}
      >
        <Text style={[styles.text, styles.salon]}>The Salon</Text>
        <Text style={[styles.text, styles.subheader]}>
          Stories about art, culture, and creativity.
        </Text>
      </View>
    </View>
  );
};
