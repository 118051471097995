import React from 'react';
import { View, Image, ImageURISource, StyleSheet, Text } from 'react-native';
import { Hoverable } from 'react-native-web-hooks';

import {
  greenBrand,
  PanamaBold,
  GTAmerica,
  GTAmericaCompressed,
} from '../../../libs/constants';
import { useDimensions } from '../../../context/DimensionsContext';
import { mdBreakpoint, smBreakpoint, lgBreakpoint } from '../../../constants';
import { TouchableOpacity } from '../../common/TouchableOpacity';
import { HoverableLink } from '../../common/HoverableLink';

interface SalonIssueProps {}

export const NellieBlue: React.FC<SalonIssueProps> = () => {
  const { width } = useDimensions();
  const blueSquiggle = require('@happymedium/components/assets/images/squiggles-blue.png');
  const redSquiggle = require('@happymedium/components/assets/images/squiggles-red.png');

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      flexDirection: 'column',
    },
    innerContainer: {
      maxWidth: width < 900 ? '85%' : 700,
      alignSelf: 'center',
    },
    imageContainer: {
      alignItems: 'center',
      marginVertical:
        width < smBreakpoint ? 20 : width < mdBreakpoint ? 35 : 60,
    },
    text: {
      color: greenBrand,
      flexWrap: 'wrap',
    },
    header: {
      fontFamily: PanamaBold,
      letterSpacing: 1.3,
      fontSize: width < smBreakpoint ? 28 : width < mdBreakpoint ? 36 : 48,
      marginBottom: width < smBreakpoint ? 5 : width < mdBreakpoint ? 15 : 20,
    },
    body: {
      fontFamily: GTAmerica,
      fontWeight: '300',
      letterSpacing: 0.45,
      fontSize: width < smBreakpoint ? 14 : width < mdBreakpoint ? 15 : 16,
      marginVertical: 10,
      textAlign: 'justify',
    },
    imageDescription: {
      fontFamily: GTAmericaCompressed,
      fontWeight: '300',
      letterSpacing: 0.45,
      fontSize: width < smBreakpoint ? 12 : width < mdBreakpoint ? 14 : 16,
      marginVertical: width < smBreakpoint ? 5 : 8,
      textAlign: 'center',
      maxWidth: width < smBreakpoint ? '90vw' : '100%',
    },
    shareButton: {},
    arrow: {},
    squiggle: {
      height: 31,
      width: 123,
      alignSelf: 'center',
      resizeMode: 'contain',
      marginBottom: 0,
      marginTop: 25,
    },
    image: {
      marginHorizontal: 30,
      maxWidth: width < mdBreakpoint ? '80%' : '50%',
    },
    imageVertical: {
      height: width < smBreakpoint ? 400 : width < mdBreakpoint ? 500 : 550,
      width: '100%',
      alignSelf: 'center',
      resizeMode: 'contain',
    },
    imageHorizontal: {
      height:
        width < smBreakpoint
          ? 280
          : width < mdBreakpoint
          ? 350
          : width < lgBreakpoint
          ? 420
          : 550,
      width: '100%',
      alignSelf: 'center',
      resizeMode: 'contain',
    },
  });

  const renderHeader = (copy: string) => (
    <Text style={[styles.text, styles.header]}>{copy}</Text>
  );

  const renderParagraph = (copy: string) => (
    <Text style={[styles.text, styles.body]}>{copy}</Text>
  );

  const renderImageDescription = (copy: string) => (
    <Text style={[styles.text, styles.imageDescription]}>{copy}</Text>
  );

  return (
    <View style={styles.container}>
      <View
        style={{
          height: width < smBreakpoint ? 10 : width < mdBreakpoint ? 25 : 30,
        }}
      />
      <View style={{ flexDirection: width < lgBreakpoint ? 'column' : 'row' }}>
        <View style={[styles.imageContainer, { flexGrow: 1 }]}>
          <Image
            style={[
              styles.image,
              styles.imageVertical,
              {
                marginHorizontal: 0,
                maxWidth: '100%',
                height:
                  width < smBreakpoint
                    ? 320
                    : width < mdBreakpoint
                    ? 380
                    : width < lgBreakpoint
                    ? 400
                    : 480,
              },
            ]}
            source={
              'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/013/Nellie+-+Image+1.jpg' as ImageURISource
            }
          />
          <Text style={[styles.text, styles.imageDescription]}>
            Nellie Blue shot by{' '}
            <HoverableLink
              textStyle={[styles.text, styles.imageDescription]}
              linkUrl={'https://sidewalkkilla.com/main/'}
              linkType={'web'}
            >
              Sidewalkkilla
            </HoverableLink>{' '}
            in the Happy Medium studio
          </Text>
        </View>
        <View
          style={{
            flexDirection: 'column',
            flexShrink: 1,
            maxWidth: width < 900 ? '85%' : 700,
            flexWrap: 'wrap',
            marginHorizontal:
              width < smBreakpoint ? undefined : width < mdBreakpoint ? 25 : 50,
            alignSelf: 'center',
          }}
        >
          {width < smBreakpoint && <View style={{ height: 30 }} />}
          {renderHeader('Whoa, Nellie')}

          {renderParagraph(
            "Nellie Blue is one of the muses of Happy Medium. The energy she brings to the stage is infectious and she's inspired hundreds of us over the last 6 months. Working with Nellie, we've had the pleasure of getting to know the woman behind the muse and we thought it was time you learned more about her too. We sat down with Nellie to ask more about her life, art, and what inspires her. Enjoy!"
          )}
        </View>
      </View>
      <View
        style={[
          styles.imageContainer,
          {
            marginBottom:
              width < smBreakpoint ? 30 : width < mdBreakpoint ? 20 : 0,
            marginTop: 20,
          },
        ]}
      >
        <Image style={[styles.image, styles.squiggle]} source={redSquiggle} />
      </View>

      <View style={[styles.innerContainer, { marginTop: 50 }]}>
        {renderHeader("Hi, what's your name?")}

        {renderParagraph(
          "Nellie Blue. I started going by it when I dropped out of college in 2013. It was my great grandmother's nickname. She had bright blue eyes, and coincidentally was a talented figure artist."
        )}
      </View>
      <View style={[styles.innerContainer, { marginTop: 50 }]}>
        {renderHeader('Where are you from?')}

        {renderParagraph(
          'I was born and raised on the isle of Manhattan in the state of New York. My family has a small farm in New Jersey and I’ve been fortunate to spend time there as well. While I was conditioned as a city kid, I’m usually happiest when I’m in the country.'
        )}
      </View>

      <View style={[styles.imageContainer, { marginVertical: 30 }]}>
        <Image
          style={[styles.image, styles.imageHorizontal]}
          source={
            'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/013/Nellie+-+image+2.jpg' as ImageURISource
          }
        />
        {renderImageDescription('Young Hare by Albrecht Dürer, 1502')}
      </View>

      <View style={[styles.innerContainer, { marginTop: 50 }]}>
        {renderHeader('What is your first memory of art in your life?')}

        {renderParagraph(
          "My mom had this small print of Albrecht Dürer's “Young Hare” in the bathroom that I used to stare at up close. The detail on that painting is wild. Also my aunt and uncle had this giant “Free South Africa” Keith Haring print in their house that I remember was quite striking to me as a child. And I can't forget all the dying Jesus art at Catholic school."
        )}
      </View>

      <View style={[styles.innerContainer, { marginTop: 50 }]}>
        {renderHeader('Did you study art in school at all?')}

        {renderParagraph(
          "I have dabbled in many fields creatively since I was a kid, but I never studied fine art seriously. Most of my formal arts training is in acting, although I'm taking a break from it now to pursue filmmaking and directing. As for more everyday practices, I adore creating palettes and textures with three dimensional objects through fashion styling (myself), interior design, and jewelry making. I've always enjoyed taking photographs and I'm drawn to capturing the Equus genus. I don't ever want to limit myself creatively. "
        )}
      </View>

      <View style={[styles.imageContainer, { marginVertical: 30 }]}>
        <Image
          style={[styles.image, styles.imageVertical]}
          source={{
            uri:
              'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/013/Nellie+-+Image+3.jpg',
          }}
        />
        <Text style={[styles.text, styles.imageDescription]}>
          Nellie Blue shot by{' '}
          <HoverableLink
            textStyle={[styles.text, styles.imageDescription]}
            linkUrl={'https://sidewalkkilla.com/main/'}
            linkType={'web'}
          >
            Sidewalkkilla
          </HoverableLink>{' '}
          in the Happy Medium studio
        </Text>
      </View>

      <View style={[styles.innerContainer, { marginTop: 50 }]}>
        {renderHeader('Why figure modeling?')}

        {renderParagraph(
          "Ultimately, I think modeling is a condensed version of acting which is a great love of mine. It makes me feel grounded in my divine feminine power. Art is healing no matter what side of the easel you're on, and at this point in my life, you won't find me doing anything that's not healing in one way or another. I also enjoy inspiring people - as a model and as a human. Knowing you've opened a new creative pathway for someone is superbly rewarding. It reminds me that I am more than enough. It's fun meeting artists and peering into their worlds, plus it's such treat to see the work afterwards!"
        )}
      </View>

      <View style={[styles.innerContainer, { marginTop: 50 }]}>
        {renderHeader('What do you think about when modeling?')}

        {renderParagraph(
          'Like meditation, thoughts will come in and out, but I try to stay present and listen to the sounds in the room and feel in my body as much as possible. I fear if I zone out too hard the pose will suffer. '
        )}
        {renderParagraph(
          "Sometimes I think about things I want to manifest. Little mantras. Sometimes I get in my head and think of how I could have constructed a pose better, but I quickly drop it as it's not useful."
        )}
      </View>

      <View style={[styles.innerContainer, { marginTop: 50 }]}>
        {renderHeader("What's the most difficult thing about modeling?")}

        {renderParagraph(
          "The hardest thing about modeling is when I underestimate the physical difficulty of the pose I've chosen. I try to monitor this to the best of my ability, but some days I have less gas than others. It's certainly a challenge to create a pose in a few seconds that's dynamic at every angle, but luckily I work well under pressure.  "
        )}

        {renderParagraph(
          "My favorite thing about modeling are those moments of finding the pose. It's the only true creative part, so I relish it. The rest is stamina. I think people assume being naked in front of strangers is hard. I've been doing this for a decade now and the nudity has never really bothered me. I liken figure modeling to theatre which I grew up doing. I'm very comfortable holding court. "
        )}
      </View>

      <View style={[styles.imageContainer, { marginVertical: 30 }]}>
        <Image
          style={[styles.image, styles.imageVertical]}
          source={{
            uri:
              'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/013/Nellie+-+Image+4.jpg',
          }}
        />
        <Text style={[styles.text, styles.imageDescription]}>
          Nellie Blue shot by{' '}
          <HoverableLink
            textStyle={[styles.text, styles.imageDescription]}
            linkUrl={'https://sidewalkkilla.com/main/'}
            linkType={'web'}
          >
            Sidewalkkilla
          </HoverableLink>{' '}
          in the Happy Medium studio
        </Text>
      </View>

      <View style={[styles.innerContainer, { marginTop: 50 }]}>
        {renderHeader('What do you study/practice to get better at modeling ?')}

        {renderParagraph(
          'Art history, historical costume, yoga, mindfulness, radical self love.'
        )}
      </View>

      <View style={[styles.innerContainer, { marginTop: 50 }]}>
        {renderHeader('Where do you find inspiration?')}

        {renderParagraph(
          "Just about everywhere. Lately I've been inspired by the American West and all kinds of horse culture. Burlesque and pin up art are great resources as well. The Renaissance, Christianity and the occult are also on my mind a lot."
        )}
      </View>

      <View style={[styles.innerContainer, { marginTop: 50 }]}>
        {renderHeader('Favorite prop?')}

        {renderParagraph(
          'My cat, Salem. (He models with me when I do my online sessions, and joined me once at HM for our Halloween session.)'
        )}

        {renderParagraph(
          "I also love a good hat, or a some sexy heels. I'm currently searching for the perfect prop sword."
        )}
      </View>

      <View style={[styles.innerContainer, { marginTop: 50 }]}>
        {renderHeader(
          'Paint a picture (ha) for us if you will about Nellie Blue outside of the art studio. What do you like to do? What else is important in your life?'
        )}

        {renderParagraph(
          "The last two years has been all about relearning what I like to do and what's important to me. I used to work in nightlife and the service industry, but I refuse to do those jobs anymore."
        )}

        {renderParagraph(
          'Some of the things I do often are: spending time in nature or with animals, riding horses, watching movies and television with my cat, collecting vintage clothing, writing, making jewelry, exploring NYC, coming up with movie or TV script ideas, staying sober, therapy, moving my body, eating noodles, studying astrology, tarot, and the occult, meditating, and manifesting my dream life. '
        )}
        {renderParagraph(
          "Many of my friends have left the city lately and I'm sad about that, but it's forced me to really focus on leveling up. I'm so grateful for the opportunity to work and meet new people at Happy Medium. The city really needs a space like this and I'm thrilled to be part of the community."
        )}
      </View>
      <View style={[styles.innerContainer, { marginTop: 50 }]}>
        {renderHeader('We ask everyone - favorite piece of artwork ever?')}

        {renderParagraph(
          "The Manhattan skyline, preferably with a bird's eye view. Saturn is pretty cool too."
        )}
      </View>

      <View style={[styles.imageContainer, { marginVertical: 30 }]}>
        <Image
          style={[styles.image, styles.imageHorizontal]}
          source={{
            uri:
              'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/013/Nellie+-+Image+5.jpg',
          }}
        />
        <Text style={[styles.text, styles.imageDescription]}>
          Nellie Blue shot by{' '}
          <HoverableLink
            textStyle={[styles.text, styles.imageDescription]}
            linkUrl={'https://sidewalkkilla.com/main/'}
            linkType={'web'}
          >
            Sidewalkkilla
          </HoverableLink>{' '}
          in the Happy Medium studio
        </Text>
      </View>

      <View style={[styles.innerContainer, { marginTop: 50 }]}>
        {renderHeader('Want to draw Nellie Blue?')}

        <Text style={[styles.text, styles.body]}>
          Check out all of our{' '}
          <HoverableLink
            // textStyle={[styles.text, styles.body]}
            linkUrl={'/events'}
            linkType={'internal'}
          >
            upcoming events
          </HoverableLink>{' '}
          and reserve your seat.
        </Text>
        <Image
          style={{ height: 60, width: 120, resizeMode: 'contain' }}
          source={require('@happymedium/components/assets/images/logo-hm-abbreviated-green.png')}
        />
      </View>

      <View style={[styles.imageContainer, { marginBottom: 0, marginTop: 20 }]}>
        <Image style={[styles.image, styles.squiggle]} source={blueSquiggle} />
      </View>

      <View style={{ height: 80 }} />
    </View>
  );
};
