import { StyleSheet } from "react-native";
import { greenBrand, GTAmerica } from "../libs/constants";

export const standardStyles = StyleSheet.create({
  text: {
    color: greenBrand,
    flexWrap: 'wrap',
  },
  body: {
    fontFamily: GTAmerica,
    fontWeight: '300',
    letterSpacing: 0.45,
    
    marginVertical: 10,
  },
})