import gql from 'graphql-tag';
import * as React from 'react';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHoc from '@apollo/react-hoc';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string,
  String: string,
  Boolean: boolean,
  Int: number,
  Float: number,
  DateTime: any,
  Upload: any,
};

export type Activity = {
   __typename?: 'Activity',
  id: Scalars['ID'],
  shortLink?: Maybe<Scalars['String']>,
  createdDate?: Maybe<Scalars['DateTime']>,
  updatedDate?: Maybe<Scalars['DateTime']>,
  version?: Maybe<Scalars['Float']>,
  title: Scalars['String'],
  organizer?: Maybe<Scalars['String']>,
  activityType: Array<Scalars['String']>,
  imageUrl: Scalars['String'],
  activityUrl: Scalars['String'],
  shortDescription: Scalars['String'],
  longDescription: Scalars['String'],
  isFree?: Maybe<Scalars['Boolean']>,
  price?: Maybe<Scalars['Float']>,
  startDate?: Maybe<Scalars['DateTime']>,
  startDateString?: Maybe<Scalars['String']>,
  endDate?: Maybe<Scalars['DateTime']>,
  endDateString?: Maybe<Scalars['String']>,
  fullDateString?: Maybe<Scalars['String']>,
  timeZone?: Maybe<Scalars['String']>,
  currency?: Maybe<Scalars['String']>,
  activityUrlDescriptorCopy?: Maybe<Scalars['String']>,
  activityUrlPressableCopy?: Maybe<Scalars['String']>,
  activityArtUrl?: Maybe<Scalars['String']>,
  activityArtUrlDescriptorCopy?: Maybe<Scalars['String']>,
  activityArtUrlPressableCopy?: Maybe<Scalars['String']>,
  rank?: Maybe<Scalars['Float']>,
};

export type ActivityType = {
   __typename?: 'ActivityType',
  value: Scalars['String'],
};

export type ArtSubmission = {
   __typename?: 'ArtSubmission',
  id: Scalars['ID'],
  shortLink?: Maybe<Scalars['String']>,
  createdDate?: Maybe<Scalars['DateTime']>,
  updatedDate?: Maybe<Scalars['DateTime']>,
  version?: Maybe<Scalars['Float']>,
  imageUri: Scalars['String'],
  released?: Maybe<Scalars['Boolean']>,
  title?: Maybe<Scalars['String']>,
  artistName?: Maybe<Scalars['String']>,
  artistInstaHandle?: Maybe<Scalars['String']>,
};

export type CreateActivityInput = {
  title: Scalars['String'],
  organizer?: Maybe<Scalars['String']>,
  activityType: Array<Scalars['String']>,
  imageUrl: Scalars['String'],
  activityUrl: Scalars['String'],
  shortDescription: Scalars['String'],
  longDescription: Scalars['String'],
  isFree?: Maybe<Scalars['Boolean']>,
  price?: Maybe<Scalars['Float']>,
  startDate?: Maybe<Scalars['DateTime']>,
  startDateString?: Maybe<Scalars['String']>,
  endDate?: Maybe<Scalars['DateTime']>,
  endDateString?: Maybe<Scalars['String']>,
  fullDateString?: Maybe<Scalars['String']>,
  timeZone?: Maybe<Scalars['String']>,
  currency?: Maybe<Scalars['String']>,
  activityUrlDescriptorCopy?: Maybe<Scalars['String']>,
  activityUrlPressableCopy?: Maybe<Scalars['String']>,
  activityArtUrl?: Maybe<Scalars['String']>,
  activityArtUrlDescriptorCopy?: Maybe<Scalars['String']>,
  activityArtUrlPressableCopy?: Maybe<Scalars['String']>,
};

export type CustomBaseEntity = {
   __typename?: 'CustomBaseEntity',
  id: Scalars['ID'],
  shortLink?: Maybe<Scalars['String']>,
  createdDate?: Maybe<Scalars['DateTime']>,
  updatedDate?: Maybe<Scalars['DateTime']>,
  version?: Maybe<Scalars['Float']>,
};

export type Customer = {
   __typename?: 'Customer',
  id: Scalars['ID'],
  shortLink?: Maybe<Scalars['String']>,
  createdDate?: Maybe<Scalars['DateTime']>,
  updatedDate?: Maybe<Scalars['DateTime']>,
  version?: Maybe<Scalars['Float']>,
  subscribeToNewsletter: Scalars['Boolean'],
  email?: Maybe<Scalars['String']>,
  phoneNumber?: Maybe<Scalars['String']>,
};


export type Error = {
   __typename?: 'Error',
  path: Scalars['String'],
  message: Scalars['String'],
};

export type FileUploadResponse = {
   __typename?: 'FileUploadResponse',
  id: Scalars['String'],
  url: Scalars['String'],
};

export type Mutation = {
   __typename?: 'Mutation',
  createActivity: StandardResponse,
  createSubmission: FileUploadResponse,
  updateSubmission: StandardResponse,
  createCustomer: StandardResponse,
  subscribe: StandardResponse,
  unsubscribe: StandardResponse,
};


export type MutationCreateActivityArgs = {
  input: CreateActivityInput
};


export type MutationCreateSubmissionArgs = {
  file: Scalars['Upload']
};


export type MutationUpdateSubmissionArgs = {
  input: UpdateSubmissionInput
};


export type MutationCreateCustomerArgs = {
  phoneNumber?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>
};


export type MutationSubscribeArgs = {
  email: Scalars['String']
};


export type MutationUnsubscribeArgs = {
  email: Scalars['String']
};

export type Query = {
   __typename?: 'Query',
  getActivities: Array<Activity>,
  getSubmissions: Array<ArtSubmission>,
  me: Customer,
};


export type QueryMeArgs = {
  id: Scalars['String']
};

export type StandardResponse = {
   __typename?: 'StandardResponse',
  errors?: Maybe<Array<Error>>,
  success: Scalars['Boolean'],
};

export type UpdateSubmissionInput = {
  id: Scalars['String'],
  title?: Maybe<Scalars['String']>,
  artistName?: Maybe<Scalars['String']>,
  artistInstaHandle?: Maybe<Scalars['String']>,
  released?: Maybe<Scalars['Boolean']>,
};


export type GetActivitiesQueryVariables = {};


export type GetActivitiesQuery = (
  { __typename?: 'Query' }
  & { getActivities: Array<(
    { __typename?: 'Activity' }
    & Pick<Activity, 'id' | 'title' | 'organizer' | 'activityType' | 'imageUrl' | 'activityUrl' | 'shortDescription' | 'longDescription' | 'isFree' | 'price' | 'startDate' | 'startDateString' | 'endDate' | 'endDateString' | 'fullDateString' | 'timeZone' | 'currency' | 'activityUrlPressableCopy' | 'activityUrlDescriptorCopy' | 'activityArtUrl' | 'activityArtUrlDescriptorCopy' | 'activityArtUrlPressableCopy' | 'rank'>
  )> }
);

export type CreateCustomerMutationVariables = {
  email?: Maybe<Scalars['String']>,
  phoneNumber?: Maybe<Scalars['String']>
};


export type CreateCustomerMutation = (
  { __typename?: 'Mutation' }
  & { createCustomer: (
    { __typename?: 'StandardResponse' }
    & Pick<StandardResponse, 'success'>
    & { errors: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'path' | 'message'>
    )>> }
  ) }
);

export type SubscribeNewsletterMutationVariables = {
  email: Scalars['String']
};


export type SubscribeNewsletterMutation = (
  { __typename?: 'Mutation' }
  & { subscribe: (
    { __typename?: 'StandardResponse' }
    & Pick<StandardResponse, 'success'>
    & { errors: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'path' | 'message'>
    )>> }
  ) }
);

export type UnsubscribeNewsletterMutationVariables = {
  email: Scalars['String']
};


export type UnsubscribeNewsletterMutation = (
  { __typename?: 'Mutation' }
  & { unsubscribe: (
    { __typename?: 'StandardResponse' }
    & Pick<StandardResponse, 'success'>
    & { errors: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'path' | 'message'>
    )>> }
  ) }
);

export type CreateSubmissionMutationVariables = {
  file: Scalars['Upload']
};


export type CreateSubmissionMutation = (
  { __typename?: 'Mutation' }
  & { createSubmission: (
    { __typename?: 'FileUploadResponse' }
    & Pick<FileUploadResponse, 'id' | 'url'>
  ) }
);

export type UpdateSubmissionMutationVariables = {
  input: UpdateSubmissionInput
};


export type UpdateSubmissionMutation = (
  { __typename?: 'Mutation' }
  & { updateSubmission: (
    { __typename?: 'StandardResponse' }
    & Pick<StandardResponse, 'success'>
    & { errors: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'path' | 'message'>
    )>> }
  ) }
);

export type GetSubmissionsQueryVariables = {};


export type GetSubmissionsQuery = (
  { __typename?: 'Query' }
  & { getSubmissions: Array<(
    { __typename?: 'ArtSubmission' }
    & Pick<ArtSubmission, 'id' | 'title' | 'artistName' | 'artistInstaHandle' | 'imageUri' | 'createdDate'>
  )> }
);


export const GetActivitiesDocument = gql`
    query getActivities {
  getActivities {
    id
    title
    organizer
    activityType
    imageUrl
    activityUrl
    shortDescription
    longDescription
    isFree
    price
    startDate
    startDateString
    endDate
    endDateString
    fullDateString
    timeZone
    currency
    activityUrlPressableCopy
    activityUrlDescriptorCopy
    activityArtUrl
    activityArtUrlDescriptorCopy
    activityArtUrlPressableCopy
    rank
  }
}
    `;
export type GetActivitiesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetActivitiesQuery, GetActivitiesQueryVariables>, 'query'>;

    export const GetActivitiesComponent = (props: GetActivitiesComponentProps) => (
      <ApolloReactComponents.Query<GetActivitiesQuery, GetActivitiesQueryVariables> query={GetActivitiesDocument} {...props} />
    );
    
export type GetActivitiesProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetActivitiesQuery, GetActivitiesQueryVariables> & TChildProps;
export function withGetActivities<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetActivitiesQuery,
  GetActivitiesQueryVariables,
  GetActivitiesProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetActivitiesQuery, GetActivitiesQueryVariables, GetActivitiesProps<TChildProps>>(GetActivitiesDocument, {
      alias: 'getActivities',
      ...operationOptions
    });
};

/**
 * __useGetActivitiesQuery__
 *
 * To run a query within a React component, call `useGetActivitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActivitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetActivitiesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetActivitiesQuery, GetActivitiesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetActivitiesQuery, GetActivitiesQueryVariables>(GetActivitiesDocument, baseOptions);
      }
export function useGetActivitiesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetActivitiesQuery, GetActivitiesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetActivitiesQuery, GetActivitiesQueryVariables>(GetActivitiesDocument, baseOptions);
        }
export type GetActivitiesQueryHookResult = ReturnType<typeof useGetActivitiesQuery>;
export type GetActivitiesLazyQueryHookResult = ReturnType<typeof useGetActivitiesLazyQuery>;
export type GetActivitiesQueryResult = ApolloReactCommon.QueryResult<GetActivitiesQuery, GetActivitiesQueryVariables>;
export const CreateCustomerDocument = gql`
    mutation CreateCustomer($email: String, $phoneNumber: String) {
  createCustomer(email: $email, phoneNumber: $phoneNumber) {
    success
    errors {
      path
      message
    }
  }
}
    `;
export type CreateCustomerMutationFn = ApolloReactCommon.MutationFunction<CreateCustomerMutation, CreateCustomerMutationVariables>;
export type CreateCustomerComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateCustomerMutation, CreateCustomerMutationVariables>, 'mutation'>;

    export const CreateCustomerComponent = (props: CreateCustomerComponentProps) => (
      <ApolloReactComponents.Mutation<CreateCustomerMutation, CreateCustomerMutationVariables> mutation={CreateCustomerDocument} {...props} />
    );
    
export type CreateCustomerProps<TChildProps = {}> = ApolloReactHoc.MutateProps<CreateCustomerMutation, CreateCustomerMutationVariables> & TChildProps;
export function withCreateCustomer<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateCustomerMutation,
  CreateCustomerMutationVariables,
  CreateCustomerProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, CreateCustomerMutation, CreateCustomerMutationVariables, CreateCustomerProps<TChildProps>>(CreateCustomerDocument, {
      alias: 'createCustomer',
      ...operationOptions
    });
};

/**
 * __useCreateCustomerMutation__
 *
 * To run a mutation, you first call `useCreateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerMutation, { data, loading, error }] = useCreateCustomerMutation({
 *   variables: {
 *      email: // value for 'email'
 *      phoneNumber: // value for 'phoneNumber'
 *   },
 * });
 */
export function useCreateCustomerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCustomerMutation, CreateCustomerMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCustomerMutation, CreateCustomerMutationVariables>(CreateCustomerDocument, baseOptions);
      }
export type CreateCustomerMutationHookResult = ReturnType<typeof useCreateCustomerMutation>;
export type CreateCustomerMutationResult = ApolloReactCommon.MutationResult<CreateCustomerMutation>;
export type CreateCustomerMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCustomerMutation, CreateCustomerMutationVariables>;
export const SubscribeNewsletterDocument = gql`
    mutation SubscribeNewsletter($email: String!) {
  subscribe(email: $email) {
    success
    errors {
      path
      message
    }
  }
}
    `;
export type SubscribeNewsletterMutationFn = ApolloReactCommon.MutationFunction<SubscribeNewsletterMutation, SubscribeNewsletterMutationVariables>;
export type SubscribeNewsletterComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SubscribeNewsletterMutation, SubscribeNewsletterMutationVariables>, 'mutation'>;

    export const SubscribeNewsletterComponent = (props: SubscribeNewsletterComponentProps) => (
      <ApolloReactComponents.Mutation<SubscribeNewsletterMutation, SubscribeNewsletterMutationVariables> mutation={SubscribeNewsletterDocument} {...props} />
    );
    
export type SubscribeNewsletterProps<TChildProps = {}> = ApolloReactHoc.MutateProps<SubscribeNewsletterMutation, SubscribeNewsletterMutationVariables> & TChildProps;
export function withSubscribeNewsletter<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SubscribeNewsletterMutation,
  SubscribeNewsletterMutationVariables,
  SubscribeNewsletterProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, SubscribeNewsletterMutation, SubscribeNewsletterMutationVariables, SubscribeNewsletterProps<TChildProps>>(SubscribeNewsletterDocument, {
      alias: 'subscribeNewsletter',
      ...operationOptions
    });
};

/**
 * __useSubscribeNewsletterMutation__
 *
 * To run a mutation, you first call `useSubscribeNewsletterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubscribeNewsletterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subscribeNewsletterMutation, { data, loading, error }] = useSubscribeNewsletterMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSubscribeNewsletterMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SubscribeNewsletterMutation, SubscribeNewsletterMutationVariables>) {
        return ApolloReactHooks.useMutation<SubscribeNewsletterMutation, SubscribeNewsletterMutationVariables>(SubscribeNewsletterDocument, baseOptions);
      }
export type SubscribeNewsletterMutationHookResult = ReturnType<typeof useSubscribeNewsletterMutation>;
export type SubscribeNewsletterMutationResult = ApolloReactCommon.MutationResult<SubscribeNewsletterMutation>;
export type SubscribeNewsletterMutationOptions = ApolloReactCommon.BaseMutationOptions<SubscribeNewsletterMutation, SubscribeNewsletterMutationVariables>;
export const UnsubscribeNewsletterDocument = gql`
    mutation UnsubscribeNewsletter($email: String!) {
  unsubscribe(email: $email) {
    success
    errors {
      path
      message
    }
  }
}
    `;
export type UnsubscribeNewsletterMutationFn = ApolloReactCommon.MutationFunction<UnsubscribeNewsletterMutation, UnsubscribeNewsletterMutationVariables>;
export type UnsubscribeNewsletterComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UnsubscribeNewsletterMutation, UnsubscribeNewsletterMutationVariables>, 'mutation'>;

    export const UnsubscribeNewsletterComponent = (props: UnsubscribeNewsletterComponentProps) => (
      <ApolloReactComponents.Mutation<UnsubscribeNewsletterMutation, UnsubscribeNewsletterMutationVariables> mutation={UnsubscribeNewsletterDocument} {...props} />
    );
    
export type UnsubscribeNewsletterProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UnsubscribeNewsletterMutation, UnsubscribeNewsletterMutationVariables> & TChildProps;
export function withUnsubscribeNewsletter<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UnsubscribeNewsletterMutation,
  UnsubscribeNewsletterMutationVariables,
  UnsubscribeNewsletterProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UnsubscribeNewsletterMutation, UnsubscribeNewsletterMutationVariables, UnsubscribeNewsletterProps<TChildProps>>(UnsubscribeNewsletterDocument, {
      alias: 'unsubscribeNewsletter',
      ...operationOptions
    });
};

/**
 * __useUnsubscribeNewsletterMutation__
 *
 * To run a mutation, you first call `useUnsubscribeNewsletterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnsubscribeNewsletterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unsubscribeNewsletterMutation, { data, loading, error }] = useUnsubscribeNewsletterMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useUnsubscribeNewsletterMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UnsubscribeNewsletterMutation, UnsubscribeNewsletterMutationVariables>) {
        return ApolloReactHooks.useMutation<UnsubscribeNewsletterMutation, UnsubscribeNewsletterMutationVariables>(UnsubscribeNewsletterDocument, baseOptions);
      }
export type UnsubscribeNewsletterMutationHookResult = ReturnType<typeof useUnsubscribeNewsletterMutation>;
export type UnsubscribeNewsletterMutationResult = ApolloReactCommon.MutationResult<UnsubscribeNewsletterMutation>;
export type UnsubscribeNewsletterMutationOptions = ApolloReactCommon.BaseMutationOptions<UnsubscribeNewsletterMutation, UnsubscribeNewsletterMutationVariables>;
export const CreateSubmissionDocument = gql`
    mutation CreateSubmission($file: Upload!) {
  createSubmission(file: $file) {
    id
    url
  }
}
    `;
export type CreateSubmissionMutationFn = ApolloReactCommon.MutationFunction<CreateSubmissionMutation, CreateSubmissionMutationVariables>;
export type CreateSubmissionComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateSubmissionMutation, CreateSubmissionMutationVariables>, 'mutation'>;

    export const CreateSubmissionComponent = (props: CreateSubmissionComponentProps) => (
      <ApolloReactComponents.Mutation<CreateSubmissionMutation, CreateSubmissionMutationVariables> mutation={CreateSubmissionDocument} {...props} />
    );
    
export type CreateSubmissionProps<TChildProps = {}> = ApolloReactHoc.MutateProps<CreateSubmissionMutation, CreateSubmissionMutationVariables> & TChildProps;
export function withCreateSubmission<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateSubmissionMutation,
  CreateSubmissionMutationVariables,
  CreateSubmissionProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, CreateSubmissionMutation, CreateSubmissionMutationVariables, CreateSubmissionProps<TChildProps>>(CreateSubmissionDocument, {
      alias: 'createSubmission',
      ...operationOptions
    });
};

/**
 * __useCreateSubmissionMutation__
 *
 * To run a mutation, you first call `useCreateSubmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubmissionMutation, { data, loading, error }] = useCreateSubmissionMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useCreateSubmissionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSubmissionMutation, CreateSubmissionMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSubmissionMutation, CreateSubmissionMutationVariables>(CreateSubmissionDocument, baseOptions);
      }
export type CreateSubmissionMutationHookResult = ReturnType<typeof useCreateSubmissionMutation>;
export type CreateSubmissionMutationResult = ApolloReactCommon.MutationResult<CreateSubmissionMutation>;
export type CreateSubmissionMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSubmissionMutation, CreateSubmissionMutationVariables>;
export const UpdateSubmissionDocument = gql`
    mutation UpdateSubmission($input: UpdateSubmissionInput!) {
  updateSubmission(input: $input) {
    success
    errors {
      path
      message
    }
  }
}
    `;
export type UpdateSubmissionMutationFn = ApolloReactCommon.MutationFunction<UpdateSubmissionMutation, UpdateSubmissionMutationVariables>;
export type UpdateSubmissionComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateSubmissionMutation, UpdateSubmissionMutationVariables>, 'mutation'>;

    export const UpdateSubmissionComponent = (props: UpdateSubmissionComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateSubmissionMutation, UpdateSubmissionMutationVariables> mutation={UpdateSubmissionDocument} {...props} />
    );
    
export type UpdateSubmissionProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UpdateSubmissionMutation, UpdateSubmissionMutationVariables> & TChildProps;
export function withUpdateSubmission<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateSubmissionMutation,
  UpdateSubmissionMutationVariables,
  UpdateSubmissionProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateSubmissionMutation, UpdateSubmissionMutationVariables, UpdateSubmissionProps<TChildProps>>(UpdateSubmissionDocument, {
      alias: 'updateSubmission',
      ...operationOptions
    });
};

/**
 * __useUpdateSubmissionMutation__
 *
 * To run a mutation, you first call `useUpdateSubmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubmissionMutation, { data, loading, error }] = useUpdateSubmissionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSubmissionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSubmissionMutation, UpdateSubmissionMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateSubmissionMutation, UpdateSubmissionMutationVariables>(UpdateSubmissionDocument, baseOptions);
      }
export type UpdateSubmissionMutationHookResult = ReturnType<typeof useUpdateSubmissionMutation>;
export type UpdateSubmissionMutationResult = ApolloReactCommon.MutationResult<UpdateSubmissionMutation>;
export type UpdateSubmissionMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSubmissionMutation, UpdateSubmissionMutationVariables>;
export const GetSubmissionsDocument = gql`
    query GetSubmissions {
  getSubmissions {
    id
    title
    artistName
    artistInstaHandle
    imageUri
    createdDate
  }
}
    `;
export type GetSubmissionsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetSubmissionsQuery, GetSubmissionsQueryVariables>, 'query'>;

    export const GetSubmissionsComponent = (props: GetSubmissionsComponentProps) => (
      <ApolloReactComponents.Query<GetSubmissionsQuery, GetSubmissionsQueryVariables> query={GetSubmissionsDocument} {...props} />
    );
    
export type GetSubmissionsProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetSubmissionsQuery, GetSubmissionsQueryVariables> & TChildProps;
export function withGetSubmissions<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetSubmissionsQuery,
  GetSubmissionsQueryVariables,
  GetSubmissionsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetSubmissionsQuery, GetSubmissionsQueryVariables, GetSubmissionsProps<TChildProps>>(GetSubmissionsDocument, {
      alias: 'getSubmissions',
      ...operationOptions
    });
};

/**
 * __useGetSubmissionsQuery__
 *
 * To run a query within a React component, call `useGetSubmissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubmissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubmissionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSubmissionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubmissionsQuery, GetSubmissionsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubmissionsQuery, GetSubmissionsQueryVariables>(GetSubmissionsDocument, baseOptions);
      }
export function useGetSubmissionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubmissionsQuery, GetSubmissionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubmissionsQuery, GetSubmissionsQueryVariables>(GetSubmissionsDocument, baseOptions);
        }
export type GetSubmissionsQueryHookResult = ReturnType<typeof useGetSubmissionsQuery>;
export type GetSubmissionsLazyQueryHookResult = ReturnType<typeof useGetSubmissionsLazyQuery>;
export type GetSubmissionsQueryResult = ApolloReactCommon.QueryResult<GetSubmissionsQuery, GetSubmissionsQueryVariables>;