import React from "react";
import { View, StyleSheet, Text, Linking } from "react-native";

import { useThemeState } from "../../context/ThemeContext";
import { ThemeLogo } from "../common/ThemeLogo";
import { ThemePicker } from "./ThemePicker";
import { HeaderLinks } from "./HeaderLinks";
import { useDimensions } from "../../context/DimensionsContext";
import { TouchableOpacity } from "../common/TouchableOpacity";
import { PanamaBold } from "../../libs/constants";

interface Props {
  subheader?: string;
  backgroundColor?: string;
  hideThemePicker?: boolean;
  logoColor?: string;
  linkColor?: string;
  isRow?: boolean;
}

export const CuratorHeader: React.FC<Props> = ({
  subheader,
  backgroundColor,
  hideThemePicker,
  logoColor,
  linkColor,
  isRow,
}) => {
  const { theme } = useThemeState();
  const { width } = useDimensions();
  const lgBreakpoint = 1180;
  const mdBreakpoint = 860;
  const smBreakpoint = 420;
  const styles = StyleSheet.create({
    container: {
      backgroundColor: backgroundColor
        ? backgroundColor
        : theme.backgroundColor,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-evenly",
      alignItems: "center",
      alignContent: "center",
      alignSelf: "center",
      width: "100%",
      zIndex: 0,
      height:
        width < smBreakpoint
          ? 160
          : width < mdBreakpoint
            ? 200
            : width < lgBreakpoint
              ? 180
              : 200,
    },
    border: {
      borderBottomColor: theme.foregroundColor,
      borderBottomWidth: 1,
    },
    subheader: {
      color: theme.foregroundColor,
      fontFamily: PanamaBold,
      fontSize: 28,
      letterSpacing: 1.3,
      textTransform: "uppercase",
      marginVertical: 10,
    },
  });
  return (
    <>
      <View style={styles.container}>
        <View style={{ flexDirection: 'row', alignContent: 'flex-start', width: '100%', padding: 10 }}>
          {hideThemePicker ? null : width >= mdBreakpoint ? <ThemePicker /> : null}

          <View
            style={{
              flexDirection: "column",
              alignItems: "center",
              alignSelf: 'center',
              marginTop: width >= mdBreakpoint ? 20 : 60,
              left: 0,
              right: 0,
              position: 'absolute',
              // height: width < mdBreakpoint ? 'auto' : '100%',
              justifyContent: subheader ? "flex-end" : "center",
            }}
          >
            <TouchableOpacity onPress={() => Linking.openURL("/")}>
              <ThemeLogo
                logoColor={logoColor}
                height={
                  width < smBreakpoint ? 60 : width < mdBreakpoint ? 75 : 93
                }
                width={
                  width < smBreakpoint ? 250 : width < mdBreakpoint ? 325 : 390
                }
              />
            </TouchableOpacity>
            {subheader ? <Text style={styles.subheader}>{subheader}</Text> : null}
          </View>
        </View>
        {hideThemePicker ? null : width < mdBreakpoint ? <View style={{ marginTop: 60 }}><ThemePicker /></View> : null}
        <HeaderLinks isRow={isRow} linkColor={linkColor} />
      </View>
      <View style={styles.border} />
    </>
  );
};
