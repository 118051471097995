import React from 'react';
import { View, Image, ImageURISource, StyleSheet, Text } from 'react-native';
import { Hoverable } from 'react-native-web-hooks';

import {
  greenBrand,
  PanamaBold,
  GTAmerica,
  GTAmericaCompressed,
} from '../../../libs/constants';
import { useDimensions } from '../../../context/DimensionsContext';
import { mdBreakpoint, smBreakpoint, lgBreakpoint } from '../../../constants';
import { TouchableOpacity } from '../../common/TouchableOpacity';
import { HoverableLink } from '../../common/HoverableLink';

interface SalonIssueProps { }

export const SalonIssueEleven: React.FC<SalonIssueProps> = () => {
  const { width } = useDimensions();
  const blueSquiggle = require('@happymedium/components/assets/images/squiggles-blue.png');
  const redSquiggle = require('@happymedium/components/assets/images/squiggles-red.png');

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      flexDirection: 'column',
    },
    innerContainer: {
      maxWidth: width < 900 ? '85%' : 700,
      alignSelf: 'center',
    },
    imageContainer: {
      alignItems: 'center',
      marginVertical:
        width < smBreakpoint ? 20 : width < mdBreakpoint ? 35 : 60,
    },
    text: {
      color: greenBrand,
      flexWrap: 'wrap',
    },
    header: {
      fontFamily: PanamaBold,
      letterSpacing: 1.3,
      fontSize: width < smBreakpoint ? 28 : width < mdBreakpoint ? 36 : 48,
      marginBottom: width < smBreakpoint ? 5 : width < mdBreakpoint ? 15 : 20,
    },
    body: {
      fontFamily: GTAmerica,
      fontWeight: '300',
      letterSpacing: 0.45,
      fontSize: width < smBreakpoint ? 14 : width < mdBreakpoint ? 15 : 16,
      marginVertical: 10,
      textAlign: 'justify',
    },
    imageDescription: {
      fontFamily: GTAmericaCompressed,
      fontWeight: '300',
      letterSpacing: 0.45,
      fontSize: width < smBreakpoint ? 12 : width < mdBreakpoint ? 14 : 16,
      marginVertical: width < smBreakpoint ? 5 : 8,
      textAlign: 'center',
      maxWidth: width < smBreakpoint ? '90vw' : '100%',
    },
    shareButton: {},
    arrow: {},
    squiggle: {
      height: 31,
      width: 123,
      alignSelf: 'center',
      resizeMode: 'contain',
      marginBottom: 0,
      marginTop: 25,
    },
    image: {
      marginHorizontal: 30,
      maxWidth: width < mdBreakpoint ? '80%' : '50%',
    },
    imageVertical: {
      height: width < smBreakpoint ? 400 : width < mdBreakpoint ? 500 : 550,
      width: '100%',
      alignSelf: 'center',
      resizeMode: 'contain',
    },
    imageHorizontal: {
      height:
        width < smBreakpoint
          ? 280
          : width < mdBreakpoint
            ? 350
            : width < lgBreakpoint
              ? 420
              : 550,
      width: '100%',
      alignSelf: 'center',
      resizeMode: 'contain',
    },
  });

  const renderHeader = (copy: string) => (
    <Text style={[styles.text, styles.header]}>{copy}</Text>
  );

  const renderParagraph = (copy: string) => (
    <Text style={[styles.text, styles.body]}>{copy}</Text>
  );

  const renderImageDescription = (copy: string) => (
    <Text style={[styles.text, styles.imageDescription]}>{copy}</Text>
  );

  return (
    <View style={styles.container}>
      <View
        style={{
          height: width < smBreakpoint ? 10 : width < mdBreakpoint ? 25 : 30,
        }}
      />
      <View style={{ flexDirection: width < lgBreakpoint ? 'column' : 'row' }}>
        <View style={[styles.imageContainer, { flexGrow: 1 }]}>
          <Image
            style={[
              styles.image,
              styles.imageVertical,
              {
                marginHorizontal: 0,
                maxWidth: '100%',
                height:
                  width < smBreakpoint
                    ? 320
                    : width < mdBreakpoint
                      ? 380
                      : width < lgBreakpoint
                        ? 400
                        : 480,
              },
            ]}
            source={
              'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/011/thalia+-+resize.jpg' as ImageURISource
            }
          />
        </View>
        <View
          style={{
            flexDirection: 'column',
            flexShrink: 1,
            maxWidth: width < 900 ? '85%' : 700,
            flexWrap: 'wrap',
            marginHorizontal:
              width < smBreakpoint ? undefined : width < mdBreakpoint ? 25 : 50,
            alignSelf: 'center',
          }}
        >
          {width < smBreakpoint && <View style={{ height: 30 }} />}
          {renderHeader('Happy Medium IRL')}

          {renderParagraph(
            'We’re opening an art supply store and café at 49 Market Street in NYC this week!'
          )}
          {renderParagraph(
            'If you’re wondering what compelled us to open a shop in midst of a pandemic, build all of the furniture ourselves in less than 6 weeks, and how we got two 200-pound speakers up a flight of stairs – that’s the topic of this week’s Salon. It’s been a strange, wonderful, and challenging road to open these doors, but we’re excited to share that story and tell you more about 49 Market Street.'
          )}
        </View>
      </View>
      <View
        style={[
          styles.imageContainer,
          {
            marginBottom:
              width < smBreakpoint ? 30 : width < mdBreakpoint ? 20 : 0,
            marginTop: 20,
          },
        ]}
      >
        <Image style={[styles.image, styles.squiggle]} source={redSquiggle} />
      </View>

      {/* <View style={[styles.imageContainer, { marginTop: 50 }]}>
        <Image
          style={[styles.image, styles.imageVertical]}
          source={
            'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/011/hustle+-+resize.jpg' as ImageURISource
          }
        />
        {renderImageDescription(
          'The scene from our tie dye collab with MatchaBar in 2020.'
        )}
      </View> */}

      <View style={[styles.innerContainer, { marginTop: 50 }]}>
        {/* <Text style={[styles.text, styles.body]}>
          If you live in NYC, you’ve likely seen a billboard by{' '}
          <HoverableLink
            linkUrl={'https://colossalmedia.com/'}
            linkType={'web'}
          >
            Colossal Media
          </HoverableLink>
          . Founded in 2004 and based in Brooklyn, Colossal designs memorable
          outdoor advertising campaigns for brands and hand paints each one.
          Considered a novelty today, hand painted billboards used to be the
          norm.
        </Text> */}
        {renderHeader('The Backstory')}

        {renderParagraph(
          'We always wondered why art supply stores were so boring and commercial. Why couldn’t you test all of the products? And why was there so much junk? We craved an art supply store that was as magical and exciting as FAO Schwartz was for toys or the fictional Willy Wonka Chocolate Factory for sweets.'
        )}
        {renderParagraph(
          'And if the shop was going to be cool – why not host events there, too? Figure Drawing sessions, artist talks, private parties, gallery shows, and art classes – all the communal occasions that celebrate art and co-creation. Those should be in the space too.'
        )}
        {renderParagraph(
          'Oh and the most important part – the vibe had to be right. No glaring overhead lights or drab pre-fab shelving. We needed the perfect balance of DIY and design. Hot music, good lighting, and a healthy dash of sophistication. We love craftsmanship, but we hate crafternoons – the space had to reflect that.'
        )}

        {renderParagraph(
          'As NYC began to reemerge this spring, we knew there had never been a better time to open this space. We were coming back to life and needed to find our flow again. Happy Medium was the place to do it.'
        )}

        {renderParagraph('This was 12 weeks ago.')}
      </View>

      <View style={[styles.imageContainer, { marginVertical: 30 }]}>
        <Image
          style={[styles.image, styles.imageVertical]}
          source={
            'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/011/first+sight+-+resized+.jpg' as ImageURISource
          }
        />
        {renderImageDescription('Our very first look at 49 Market Street.')}
      </View>
      <View style={styles.innerContainer}>
        {renderHeader('The Perfect Space')}
        {renderParagraph(
          '“How did you get the space?” is something we’re asked often. NYC’s commercial rental scene is full of empty storefronts kept vacant to serve as tax havens, dubious certificates of occupancy, and red tape that makes it a mysterious world for the uninitiated. But to call a piece of NYC your own, even for a short time, is an intoxicating proposition.'
        )}
        {renderParagraph(
          "In our case, it all started with a trip to Mr. Fong's in Two Bridges. We were meeting a friend for drinks before going to Dr. Clark for dinner and got to the bar early. To kill time, we did a loop around the block, passing the skate park and the soccer fields."
        )}

        {renderParagraph(
          'The block felt like our own little secret. It was beautiful, nearly empty, green, and had a view of the water. What a lovely street we thought. And then we looked up and saw a horse.'
        )}

        <View style={styles.imageContainer}>
          <Image
            style={[styles.image, styles.imageVertical]}
            source={
              'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/011/horse+head.jpg' as ImageURISource
            }
          />
          {renderImageDescription('The horse head.')}
        </View>

        {renderParagraph(
          'A week before, we had gone through the practice of manifesting the perfect space. The key to manifestation is to be as detailed as possible describing the most mundane observations in great detail. Rett had mentioned that his perfect space would have a horse head, the mark of a former stable.'
        )}
        {renderParagraph(
          'So, when we looked up at this horse head building with the handwritten “For Rent” sign, we were intrigued. A quick google searched showed that the building used to be an artist’s studio and the scene of many art parties. We had a feeling this was the one.'
        )}
        {renderParagraph(
          'At the time, we were in talks with a landlord for a space on Wooster St. That space was equal in size and used to be an old Andy Warhol haunt. It had a huge skylight in the back and an interesting split level layout. But it didn’t feel quite right.'
        )}
        {renderParagraph(
          '49 Market Street felt right, but the phone number on the “For Rent” sign went unanswered. Through property record searches, we were able to get in touch with the owners directly. After a few walk throughs, some negotiating, and a crash course in the commercial insurance industry, we were the proud tenants of a 4-month lease.'
        )}

        <View style={styles.imageContainer}>
          <Image
            style={[styles.image, styles.imageVertical]}
            source={
              'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/011/Prototyping+2.jpg' as ImageURISource
            }
          />
          {renderImageDescription('Rett prototyping a chair')}
        </View>
      </View>

      <View style={styles.innerContainer}>
        {renderHeader('The Build Out')}
        {renderParagraph(
          'We make our own furniture. We have for years. So, when it came time to build out the space, we knew we were going to build most of it ourselves. We started to gather design inspiration.'
        )}
        <Text style={[styles.text, styles.body]}>
          Three designers stood out to us in particular -{' '}
          <HoverableLink
            linkUrl={
              'https://en.wikipedia.org/wiki/Sergio_Rodrigues_(architect)'
            }
            linkType={'web'}
          >
            Sergio Rodrigues
          </HoverableLink>
          ,{' '}
          <HoverableLink
            linkUrl={'https://en.wikipedia.org/wiki/Enzo_Mari'}
            linkType={'web'}
          >
            Enzo Mari
          </HoverableLink>
          , and
          {/* <HoverableLink
            linkUrl={'https://en.wikipedia.org/wiki/Donald_Judd'}
            linkType={'web'}
          >
            Donald Judd
          </HoverableLink> */}
          . The simplicity of their construction and timelessness of their
          designs resonated with us. We decide to recreate some of their designs
          in Cherry, Mahogany, and Pine, making small alterations along the way.
        </Text>
        <Text style={[styles.text, styles.body]}>
          Next we focused on the sound system. Music is art that inspires
          movement and influences moods. We needed music and, with such a large
          space, we knew we needed large speakers to get the job done. We were
          looking for the A7 model of Altec Lansing’s Voice of the Theater
          Speakers – one of the most{' '}
          <HoverableLink
            linkUrl={
              'https://audioxpress.com/article/altec-lansing-s-voice-of-the-theatre-speakers-what-you-need-to-know'
            }
            linkType={'web'}
          >
            sought-after speakers
          </HoverableLink>
          in Japan.
        </Text>
      </View>

      <View style={styles.innerContainer}>
        {renderParagraph(
          'We found them in Southern New Jersey in the home of an audiophile collector who had run out of room. We pulled into his driveway in our massive Uhaul truck and were greeted by the massive speakers. They were blasting Lucky Man by Emerson, Lake, and Palmer so loud the neighbors had come outside for the show.'
        )}
      </View>

      <View style={styles.imageContainer}>
        <Image
          style={[styles.image, styles.imageVertical]}
          source={
            'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/011/Speaker+situ.jpg' as ImageURISource
          }
        />
        {renderImageDescription('One of the speakers, in situ.')}
      </View>

      <View style={styles.innerContainer}>
        {renderParagraph(
          'We got the speakers back to NYC without issue, but as soon as we attempted to carry the speakers up a long flight of stairs, I realized just how much 200 pounds is. We bailed on that mission and hired a couple of guys to help us the next day.'
        )}
      </View>

      <View style={styles.imageContainer}>
        <Image
          style={[styles.image, styles.imageVertical]}
          source={
            'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/011/cushions+-+resize+.jpg' as ImageURISource
          }
        />
        {renderImageDescription('Cushions and chair - all handmade.')}
      </View>

      <View style={styles.innerContainer}>
        {renderParagraph(
          'We’ve made most of the things in the space – the curtains, the bar, the cushions -  but not everything. We’ve curated a collection of art books, busts, ceramics, and other art objects. We also set out fresh flower arrangements and edible still lives every day. They fill the space with inspiration and knowledge. We want you to get carried away reading about Surrealism, copying Picasso’s early sketches, or watercoloring a still life.'
        )}
        {renderParagraph(
          'Now that the sawdust has settled and the doors are opening, you’re probably wondering what it’s like in here. When should you visit and what can you do?'
        )}
      </View>

      <View style={styles.imageContainer}>
        <Image
          style={[styles.image, styles.imageVertical]}
          source={
            'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/011/watercolor+-+resize.jpg' as ImageURISource
          }
        />
        {renderImageDescription(
          'Indulging in a watercolor afternoon at the art café.'
        )}
      </View>

      <View style={styles.innerContainer}>
        {renderHeader("What's it like?")}
        {renderParagraph(
          'The space is dynamic, but it primarily serves as a retail store and an art café.'
        )}
        {renderParagraph(
          'In our store, you’ll soon find our homemade paints, handbound notebooks, packs of willow charcoal, vintage art, and more. It’s a curated assortment of supplies and delights for the aesthetically curious.'
        )}
      </View>

      <View style={styles.imageContainer}>
        <Image
          style={[styles.image, styles.imageVertical]}
          source={
            'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/011/art+meets+design.jpg' as ImageURISource
          }
        />
        {renderImageDescription('Art meets design at 49 market St.')}
      </View>

      <View style={styles.innerContainer}>
        {renderParagraph(
          'The other half of the space is our art café – based on the Viennese coffee shop concept, the café is a place to convene and create. Come with a friend for a quick weekend coffee or share an afternoon watercoloring together. Come by for one of our Happy Hours during the week or stop by on a lazy Sunday morning.'
        )}
        {renderParagraph(
          'In addition to light snacks and sips, you can order art supplies, tableside. Charcoal starter kits and watercolor palettes, delivered tableside with everything you need to enjoy making art. When you’re done, leave it on the table. No mess, less stress.'
        )}
      </View>

      <View style={styles.imageContainer}>
        <Image
          style={[styles.image, styles.imageVertical]}
          source={
            'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/011/Stripo+Resized.jpg' as ImageURISource
          }
        />
        {renderImageDescription('The poster for our first event.')}
      </View>

      <View style={styles.innerContainer}>
        {renderParagraph(
          'We also host events in the space – some private and some open to the public. We’re hosting our first event – an evening of figure drawing with Nellie Blue - on Friday, July 30th 6-8pm. Tickets are $35 – dm us on Instagram to secure your spot before they’re gone!'
        )}

        <Text style={[styles.text, styles.body]}>
          If you’d like to book the space for a private event, email us at
          {'\n'}
          <HoverableLink
            linkUrl={'mailto:reservations@happy-medium.co'}
            linkType={'mail'}
          >
            reservations@happy-medium.co
          </HoverableLink>
          .
        </Text>
        {renderParagraph(
          'Above all, the space is a creative refuge in the concrete jungle – a place to relax, indulge in your creative side, and find your flow. We’re open for art cafe Sat-Sun, 12pm-7pm.'
        )}
        {renderParagraph('Come say hi. We’d love to meet you.')}
        <Image
          style={{ height: 60, width: 120, resizeMode: 'contain' }}
          source={require('@happymedium/components/assets/images/logo-hm-abbreviated-green.png')}
        />
      </View>

      <View style={[styles.imageContainer, { marginBottom: 0, marginTop: 20 }]}>
        <Image style={[styles.image, styles.squiggle]} source={blueSquiggle} />
      </View>

      <View style={{ height: 80 }} />
    </View>
  );
};
