import React from 'react';
import { View, Image, ImageURISource, StyleSheet, Text } from 'react-native';

import {
  greenBrand,
  PanamaBold,
  GTAmerica,
  GTAmericaCompressed,
} from '../../../libs/constants';
import { useDimensions } from '../../../context/DimensionsContext';
import { mdBreakpoint, smBreakpoint, lgBreakpoint } from '../../../constants';

interface SalonIssueOneProps {}

export const SalonIssueOne: React.FC<SalonIssueOneProps> = () => {
  const { width } = useDimensions();
  const blueSquiggle = require('@happymedium/components/assets/images/squiggles-blue.png');
  const redSquiggle = require('@happymedium/components/assets/images/squiggles-red.png');

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      flexDirection: 'column',
    },
    innerContainer: {
      maxWidth: width < 900 ? '85%' : 700,
      alignSelf: 'center',
    },
    imageContainer: {
      alignItems: 'center',
      marginVertical:
        width < smBreakpoint ? 20 : width < mdBreakpoint ? 35 : 60,
    },
    text: {
      color: greenBrand,
      flexWrap: 'wrap',
    },
    header: {
      fontFamily: PanamaBold,
      letterSpacing: 1.3,
      fontSize: width < smBreakpoint ? 28 : width < mdBreakpoint ? 36 : 48,
      marginBottom: width < smBreakpoint ? 5 : width < mdBreakpoint ? 15 : 20,
    },
    body: {
      fontFamily: GTAmerica,
      fontWeight: '300',
      letterSpacing: 0.45,
      fontSize: width < smBreakpoint ? 14 : width < mdBreakpoint ? 15 : 16,
      marginVertical: 10,
    },
    imageDescription: {
      fontFamily: GTAmericaCompressed,
      fontWeight: '300',
      letterSpacing: 0.45,
      fontSize: width < smBreakpoint ? 12 : width < mdBreakpoint ? 14 : 16,
      marginVertical: width < smBreakpoint ? 5 : 10,
      textAlign: 'center',
    },
    shareButton: {},
    arrow: {},
    squiggle: {
      height: 31,
      width: 123,
      alignSelf: 'center',
      resizeMode: 'contain',
      marginBottom: 0,
      marginTop: 25,
    },
    image: {
      marginHorizontal: 30,
      maxWidth: width < mdBreakpoint ? '80%' : '50%',
    },
    imageVertical: {
      height: width < smBreakpoint ? 400 : width < mdBreakpoint ? 500 : 550,
      width: '100%',
      alignSelf: 'center',
      resizeMode: 'contain',
    },
    imageHorizontal: {
      height:
        width < smBreakpoint
          ? 280
          : width < mdBreakpoint
          ? 350
          : width < lgBreakpoint
          ? 420
          : 550,
      width: '100%',
      alignSelf: 'center',
      resizeMode: 'contain',
    },
  });

  const renderHeader = (copy: string) => (
    <Text style={[styles.text, styles.header]}>{copy}</Text>
  );

  const renderParagraph = (copy: string) => (
    <Text style={[styles.text, styles.body]}>{copy}</Text>
  );

  const renderImageDescription = (copy: string) => (
    <Text style={[styles.text, styles.imageDescription]}>{copy}</Text>
  );

  return (
    <View style={styles.container}>
      <View
        style={{
          height: width < smBreakpoint ? 10 : width < mdBreakpoint ? 25 : 80,
        }}
      />
      <View style={{ flexDirection: width < lgBreakpoint ? 'column' : 'row' }}>
        <Image
          style={[
            styles.image,
            styles.imageHorizontal,
            {
              marginBottom:
                width <= mdBreakpoint && width > smBreakpoint
                  ? 20
                  : width < lgBreakpoint && width > mdBreakpoint
                  ? 40
                  : undefined,
            },
          ]}
          source={
            'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/001/Image+1.jpg' as ImageURISource
          }
        />
        <View
          style={{
            flexDirection: 'column',
            flexShrink: 1,
            maxWidth: width < 900 ? '85%' : 700,
            flexWrap: 'wrap',
            marginHorizontal:
              width < smBreakpoint ? undefined : width < mdBreakpoint ? 25 : 50,
            alignSelf: 'center',
          }}
        >
          {renderHeader('Welcome to The Salon')}
          {renderParagraph(
            'Hi. I’m Tayler, one of the cofounders of Happy Medium, an art supply company for the casual artist. At some point in the last year, you gave us your email address because you’re the kind of cool, creative person that inspired us to create Happy Medium in the first place.'
          )}
          {renderParagraph(
            'We wanted to start this relationship off right, so instead of selling you paint, we’re going to start by telling you stories – stories that, by knowing them, will make you the most cultured person in the room. We’re calling it The Salon – a weeklyish newsletter exploring the intersection of art, history, technology, culture, and most importantly, people.'
          )}
          {renderParagraph(
            'Our first story starts with Gertrude Stein, who is the primary inspiration behind The Salon. You probably know her as a writer, if you know her at all, but she was also an art collector and the host of a salon that acted as an incubator for some of the greatest artists and writers of the 20th century.'
          )}
          {renderParagraph('Without further ado, meet Gertrude:')}
        </View>
      </View>
      <View
        style={[
          styles.imageContainer,
          {
            marginBottom:
              width < smBreakpoint ? 30 : width < mdBreakpoint ? 20 : 0,
            marginTop: 20,
          },
        ]}
      >
        <Image style={[styles.image, styles.squiggle]} source={redSquiggle} />
      </View>
      <View style={styles.imageContainer}>
        <Image
          style={[styles.image, styles.imageVertical]}
          source={
            'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/001/Image+2.jpg' as ImageURISource
          }
        />
        {renderImageDescription('Gertrude Stein painted by Francis Picabia')}
      </View>
      <View style={[styles.innerContainer]}>
        {renderHeader('Gertrude Stein')}
        {renderParagraph(
          'Gertrude was born in 1874, the same year Levi Strauss received a patent for blue jeans. Her dad was a wealthy entrepreneur and director of San Francisco’s now iconic Market Street Railway.'
        )}
        {renderParagraph(
          'After spending most of her childhood in Oakland, CA she went to college at Harvard. Well, technically Harvard didn’t admit women at the time, so she attended Radcliffe College, Harvard’s women’s annex. Later, she studied medicine at Johns Hopkins but dropped out before completing her degree.'
        )}
        {renderParagraph(
          'Gertrude was a lesbian who didn’t conform to the gender stereotypes of the time. Her uncorseted way of dress and outspoken manner turned heads. This unattributed description of her made me chuckle – “Big and floppy and sandaled and not caring a damn.”'
        )}
        {renderParagraph(
          'Her eccentricity made her feel lonely and isolated in America so in 1902 she joined her older brother Leo in Europe. The two of them set up a shared apartment in Paris.'
        )}
        {renderParagraph(
          'While Gertrude is the most well-known Stein sibling, it was actually her brother Leo who was the original Stein art critic and collector. Using funds from their family’s trust, they started collecting art together. But not just any type of art – they collected Modern Art (gasp!'
        )}
      </View>
      <View style={styles.imageContainer}>
        <Image
          style={[styles.image, styles.imageVertical]}
          source={
            'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/001/Image+3.jpg' as ImageURISource
          }
        />
        {renderImageDescription(
          'In Gertrude’s collection:\nMusic (Sketch) by Henri Matisse, 1907'
        )}
      </View>
      <View style={styles.innerContainer}>
        {renderHeader('What is Modern Art?')}
        {renderParagraph(
          'The Steins were not the wealthiest art collectors of their time. They didn’t amass the largest collection, nor did they acquire the most expensive works. In fact, a lot of the pieces they bought were actually pretty cheap, even in today’s $$.'
        )}
        {renderParagraph(
          'What made their collection special, and arguably the reason we’re discussing them today, is because they collected Modern Art. Which begs the question –  what exactly is Modern Art? And why was it such a big deal?'
        )}
        {renderParagraph(
          'Art, like music or fashion, is a reflection of the values and experiences of the society that creates it. And just as bell bottoms and psychedelic rock are associated with the counterculture movement of the 1960s, art styles are associated with the cultural movements of their time. It’s this association that makes art interesting and worthy of our attention.'
        )}
        {renderParagraph(
          'Modern Art is the classification of all Western art produced from roughly the 1860s to the 1970s. I say roughly because this is obviously not an exact science and people still argue over the exact moment it began. It’s generally accepted that Modern Art began when Édouard Manet showed this absolute scandal of a painting at the Salon of Rejects in Paris in 1863.'
        )}
      </View>
      <View style={styles.imageContainer}>
        <Image
          style={[
            styles.image,
            styles.imageHorizontal,
            {
              marginBottom: width < smBreakpoint ? -15 : 0,
            },
          ]}
          source={
            'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/001/Image+4.jpg' as ImageURISource
          }
        />
        {renderImageDescription(
          'The Luncheon on the Grass by Édouard Manet, 1863'
        )}
      </View>
      <View style={styles.innerContainer}>
        {renderParagraph(
          'Until the 1860s, art was primarily commissioned by the church to depict religious scenes or used as a tool to capture life realistically, in lieu of photography (the first mass marketed camera didn’t appear until 1900). But with the start of the second Industrial Revolution, society began to transform rapidly into the urbanized, modern society we’ve come to know as Western Culture today. As society changed, so did the art.'
        )}
        {renderParagraph(
          'Visually, Modern Art threw all tradition aside in favor of experimentation and the results were radical. Paintings now challenged societal norms and pushed boundaries on what was even considered art. For a long time, Modern Art was rejected by the institutional art world because of its lack of convention.'
        )}
        {renderParagraph(
          'So when this oddball sibling couple from the States starts amassing a collection of this new genre of art, it was kind of a big deal and attracted lots of eyeballs. Which brings me to the Salon.'
        )}
      </View>
      <View style={styles.imageContainer}>
        <Image
          style={[
            styles.image,
            styles.imageHorizontal,
            {
              marginBottom: width < smBreakpoint ? -20 : 0,
            },
          ]}
          source={
            'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/001/Image+5.jpg' as ImageURISource
          }
        />
        {renderImageDescription('The Salon at 27 Rue de Fleurus')}
      </View>
      <View style={styles.innerContainer}>
        {renderHeader('27 Rue de Fleurus')}
        {renderParagraph(
          'Through Leo’s contacts in the art world, the Steins started collecting works of art from now well-known, but then obscure, artists like Paul Cézanne, Pablo Picasso, and Henri Matisse. Soon their apartment at 27 Rue de Fleurus was packed with so much artwork that it has been called the first museum of modern art.'
        )}
        {renderParagraph(
          'Matisse started to bring his friends over to view his work at the Steins apartment. Then his friends brought friends, and their friends brought friends and it got to the point that Gertrude had to implement a specified day and time for visitors. And thus, the Salon was born.'
        )}
        {renderParagraph(
          'Every Saturday night, artists and writers and thinkers gathered at the Stein Salon to indulge in art and conversation. Some of the most notable members of the Salon, in addition to the artists in their collection, were the writers Ernest Hemingway and F. Scott Fitzgerald.'
        )}
        {renderParagraph(
          'Gertrude’s rich friends, the Cone sisters, were also patrons of the Salon. Their family wealth came from the Cone denim mill which was the exclusive supplier to Levi’s. They too amassed a huge collection of Modern Art, often buying it directly from Gertrude and Leo. They later left most of their collection to the Baltimore Museum of Art.'
        )}
        {renderParagraph(
          'Gertrude’s older brother Michael and his wife Sarah also moved to Paris to take part in the Modern Art bonanza. Through Gertrude, they were exposed to Henri Matisse and Sarah, in particular, became very close to the artist. She provided him with emotional and financial support that ultimately allowed him to become one of the most famous artists in the world. In other words, you can thank Gertrude for the Matisse cut-out trend clogging your IG feed today.'
        )}
      </View>
      <View style={styles.imageContainer}>
        <Image
          style={[styles.image, styles.imageVertical]}
          source={
            'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/001/Image+6.jpg' as ImageURISource
          }
        />
      </View>
      <View style={styles.innerContainer}>
        {renderHeader('Mother of Modern Art')}
        {renderParagraph(
          'World War I interrupted the Salon, and the siblings split. Gertrude kept most of the Matisse and Picasso works while Leo took the Renoirs. Gertrude continued to collect and champion Modern Art for the rest of her life.'
        )}
        {renderParagraph(
          'In 1913, she lent some of her personal collection to the first Armory Show in New York. It was the show that brought Modern Art to America and it was a smashing success.'
        )}
        {renderParagraph(
          'In 1933, she wrote The Autobiography of Alice B. Toklas which tells the story of the Salon years through the eyes of her life partner, Alice. The book catapulted her to fame and forever memorialized the years when Modern Art began to pick up steam, incubated and nurtured in her very own living room.'
        )}
        {renderParagraph(
          'Gertrude Stein was drawn to Modern Art because it was an external depiction of the internal “struggle between Human Nature and the Human Mind.” She recognized and appreciated the genius of it, long before it was widely accepted.'
        )}
        {renderParagraph(
          'But in the end, when asked by a publication how she felt about Modern Art, her simple reply says it all – “I like to look at it.”'
        )}
        {renderParagraph('Ain’t that the damn truth?')}
        <Image
          style={{ height: 60, width: 120, resizeMode: 'contain' }}
          source={require('@happymedium/components/assets/images/logo-hm-abbreviated-green.png')}
        />
      </View>
      <View style={[styles.imageContainer, { marginBottom: 0, marginTop: 20 }]}>
        <Image style={[styles.image, styles.squiggle]} source={blueSquiggle} />
      </View>

      <View style={{ height: 80 }} />
    </View>
  );
};
