import React, { useContext, useReducer } from 'react';
import { useApolloClient, useQuery } from '@apollo/react-hooks';

import { defaultTheme } from '../libs/constants';
// import { GetTheme } from '../graphql/theme/queries/getTheme.query';
import { Theme } from '../styles/themes/types';
// import {theme as greenTheme} from '../styles/themes/green'
// import {theme as redTheme} from '../styles/themes/red'
// import {theme as blueTheme} from '../styles/themes/blue'
import { themes } from '../styles/themes';

// type Action =
//   | { theme: 'greenBrand' }
//   | { theme: 'blueBrand' }
//   | { theme: 'redBrand' };

export type ThemeDispatch = (action: Theme) => void;

export interface ThemeProviderProps {
  children?: React.ReactNode;
}

export interface ThemeProviderState {
  theme: Theme;
}

export const ThemeStateContext = React.createContext<ThemeProviderState>({
  theme: themes.green,
});
export const ThemeDispatchContext = React.createContext<
  ThemeDispatch | undefined
>((action) => {
  return null;
});

function themeReducer(state: ThemeProviderState, theme: Theme) {
  switch (theme.id) {
    case 'green': {
      return { theme: themes.green };
    }
    case 'blue': {
      return { theme: themes.blue };
    }
    case 'red': {
      return { theme: themes.red };
    }
    default: {
      throw new Error(`Unhandled action theme`);
    }
  }
}

export function ThemeProvider({ children }: ThemeProviderProps) {
  // const { data } = useQuery(GetTheme);
  // console.log('data2', data);
  const [themeState, themeDispatch] = useReducer(themeReducer, {
    theme: themes.green,
  });

  return (
    <ThemeStateContext.Provider value={themeState}>
      <ThemeDispatchContext.Provider value={themeDispatch}>
        {children}
      </ThemeDispatchContext.Provider>
    </ThemeStateContext.Provider>
  );
}

export function useThemeState() {
  const context = useContext(ThemeStateContext);
  if (context === undefined) {
    throw new Error('useThemeStateError');
  }
  return context;
}
export function useThemeDispatch() {
  const context = useContext(ThemeDispatchContext);
  if (context === undefined) {
    throw new Error('useThemeDispatchError');
  }
  return context;
}

export function useTheme() {
  return [useThemeState(), useThemeDispatch()];
}
