import React from 'react';
import { Image, StyleSheet } from 'react-native';
import { useThemeState } from '../../context/ThemeContext';

interface Props {
  height?: number;
  width?: number;
  logoColor?: string;
}

export const ThemeLogo: React.FC<Props> = React.memo(
  ({ height, width, logoColor }) => {
    const { theme } = useThemeState();
    const logoColorSelection = logoColor ? logoColor : theme.logoColor;
    const logo = require(`@happymedium/components/assets/images/logo-${logoColorSelection}@200x.png`);
    const styles = StyleSheet.create({
      logo: {
        height: height ? height : 93,
        width: width ? width : 390,
        // alignSelf: 'center',
      },
    });
    return (
      <Image
        accessibilityLabel="logo"
        style={styles.logo}
        source={logo}
        accessible={true}
      />
    );
  }
);
