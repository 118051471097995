import React from 'react';
import { Image, StyleSheet } from 'react-native';

import { useThemeState } from '../../context/ThemeContext';

interface Props {
  height?: number;
  width?: number;
}

export const ThemedRefreshIcon: React.FC<Props> = ({ height, width }) => {
  const { theme } = useThemeState();
  const styles = StyleSheet.create({
    icon: {
      height: height ? height : 18,
      width: width ? width : 20,
      cursor: 'pointer',
    },
  });
  const refreshIcon = require(`@happymedium/components/assets/images/arrow-refresh-${
    theme.logoColor
  }@2x.png`);
  return <Image style={styles.icon} source={refreshIcon} />;
};
