import React, { useRef, RefObject, useEffect, useState } from 'react';
import { GLView, ExpoWebGLRenderingContext, GLViewProps } from 'expo-gl';
import {takeSnapshotAsync} from 'expo';
import {
  PanResponder,
  PixelRatio,
  NativeTouchEvent,
  Text,
  View,
  Image,
  StyleSheet,
  ActivityIndicator,
} from 'react-native';
import * as PIXI from 'pixi.js';
import Slider from '@react-native-community/slider';

import { createPixiApp } from './application';
import { blueBrand, redBrand, GTAmerica, GTAmericaCompressed, greenBrand } from '../constants';
import { ColorPicker } from './ColorPicker';
import { useThemeState } from '../../context/ThemeContext';
import { TouchableOpacity } from '../../components/common/TouchableOpacity';
import { useMutation } from '@apollo/react-hooks';
import { CreateSubmissionDocument } from '../../components/ApolloComponents';

interface Props extends Partial<GLViewProps> {
  // strokeColor: number;
  strokeWidth: number;
  strokeAlpha: number;
  onChange: (
    url: string,
    id: string
  ) => Promise<void>;
  onReady: (context?: ExpoWebGLRenderingContext) => void;
  initialLines?: Array<Line>;
  ref?: any;
}

type Line = {
  points: Array<{ x: number; y: number }>;
  color: number;
  width: number;
  alpha: number;
};

type Point = {
  x: number;
  y: number;
  color: number;
  width: number;
  alpha: number;
};

export const Sketch: React.FC<Props> = React.memo(
  React.forwardRef((props, ref) => {
    const {theme} = useThemeState()
    const [submit, {data, loading}] = useMutation(CreateSubmissionDocument)
    const {
      initialLines,
      strokeAlpha,
      // strokeColor,
      // strokeWidth,
      onChange,
    } = props;
    const [localStrokeColor, _setLocalStrokeColor] = useState(0x008965)
    const localStrokeColorRef = React.useRef(localStrokeColor)
    const setLocalStrokeColor = (localStrokeState: number) => {
      localStrokeColorRef.current = localStrokeState
      _setLocalStrokeColor(localStrokeState)
    }
    const [strokeWidth, _setStrokeWidth] = useState(15)
    const strokeWidthRef = React.useRef(strokeWidth);
    const setStrokeWidth =  (strokeState: number) => {
      strokeWidthRef.current = strokeState
      _setStrokeWidth(strokeState)
    }
    const colors = [
      {name: 'red', hex: 0xFD5F25},
      {name: 'green', hex: 0x008965},
      {name: 'blue', hex: 0x064FAC}
    ]
    const handleColorSelect = (color: number) => {
      setLocalStrokeColor(color);
    }
    const palette = require(`@happymedium/components/assets/images/palette-gradient.svg`);
    const greenBrush = require(`@happymedium/components/assets/images/paintbrush-green.png`);

    const [imageURI, setImageURI] = useState<string | Blob | null>(null)
    
    let pixiApp: PIXI.Application | undefined = undefined;
    let stage: PIXI.Container = React.useRef(new PIXI.Container()).current;
    let renderer: PIXI.CanvasRenderer | PIXI.WebGLRenderer | PIXI.Renderer;
    // const renderer = pixiApp.renderer;
    let graphics = new PIXI.Graphics();
    // stage.addChild(graphics);
    let scale = PixelRatio.get();
    let lastTime: number;
    let ease: number = 0.3;
    let delay: number = 10;
    let points: Array<Point> = [];
    let pts: Array<PIXI.Point> = [];
    let lastPoint: Point | null = null;
    let lines: any[] = [];
    let pixiContext: ExpoWebGLRenderingContext | null = null;
    let hue = 0;
    let sat = 0;
    let val = 1;
    let hex = 0xffffff;

    //////////SLIDER\\\\\\\\\\\\\\
    const handleOnSlidingComplete = (currentValue: number) => {
      setStrokeWidth(currentValue)
    }
    const handleOnSlidingStart = (value: number) => {
    }
    const handleOnValueChange = (value: number) => {
    }
    //////////SLIDER\\\\\\\\\\\\\\


    function rgbToHex(r: number, g: number, b: number) {
      return "#" + (0x1000000 + (r << 16) + (g << 8) + b).toString(16).slice(1);
    }

    const _glRef = useRef<GLView>(null);
    const glRef = (ref as RefObject<GLView>) || _glRef;

    let pixiCanvas = document.getElementById('one');
    
    const scaled = ({ locationX: x, locationY: y }: any) => {
      return {
        x: x * scale,
        y: y * scale,
        color: localStrokeColor,
        width: strokeWidthRef.current,
        alpha: strokeAlpha,
      };
    };

    const onEnd = (event: NativeTouchEvent) => {
      const tgt = event.target as unknown as HTMLElement
      if (tgt.localName !== 'canvas') return
      drawLine(scaled(event), false);
    };

    const panResponder = React.useRef(
      PanResponder.create({
        onStartShouldSetPanResponder: () => true,
        onStartShouldSetPanResponderCapture: () => true,
        onMoveShouldSetPanResponder: (evt, gestureState) => true,
        onPanResponderGrant: ({ nativeEvent }) => {
          const { x, y } = scaled(nativeEvent);
          drawLine(
            {
              x,
              y,
              color: localStrokeColorRef.current,
              width: 1,
              alpha: 1,
            },
            true
          );
        },
        onPanResponderMove: ({ nativeEvent }) => {
          const { x, y } = scaled(nativeEvent);
          // throttle updates: once every 10ms
          const time = Date.now();
          const delta = time - lastTime;
          if (delta < delay) return;
          const tgt = nativeEvent.target as unknown as HTMLElement
          if (tgt.localName !== 'canvas') return
          lastTime = time;
          // console.log(lastPoint!.x + ease * (x - lastPoint!.x), lastPoint!.y + ease * (y - lastPoint!.y), nativeEvent, tgt.localName !== 'canvas')
          drawLine(
            {
              x: lastPoint!.x + ease * (x - lastPoint!.x),
              y: lastPoint!.y + ease * (y - lastPoint!.y),
              color: localStrokeColorRef.current,
              width: strokeWidthRef.current,
              alpha: strokeAlpha,
            },
            false
          );
        },
        onPanResponderRelease: ({ nativeEvent }) => {
          onEnd(nativeEvent);
          // console.log('pixiAppOnRelease', pixiApp);
          // console.log('onPanResponderRelease', nativeEvent);
        },
        onPanResponderTerminate: ({ nativeEvent }) => {
          onEnd(nativeEvent);
          // console.log('onPanResponderTerminate', nativeEvent);
        },
      })
    ).current;

    // const renderUpdate = () => {
    //   renderer?.render(stage);
    //   if (pixiContext) {
    //     pixiContext.endFrameEXP();
    //     console.log('PIXIContextRenderUpdate', pixiContext);
    //   }
    // };

    const onReady = (context?: ExpoWebGLRenderingContext) => {
      // console.log('onReady', context);
    };

    const persistStroke = () => {
      // console.log('PERSIST_STROKE__LINES', lines)
      if (graphics) {
        // graphics = points;
        // graphics.beginFill(localStrokeColor, 1);
        // console.log('graphicsLineStylePersistStroke', graphics.line);
        // add points to graphics...
        lines.push(graphics);
        // console.log('linesPersistStroke', lines);
        // console.log('graphics', graphics);
        // console.log('pointsPersistStroke', points);
      }
      lastTime = 0;
      points = [];
      // console.log('persistStroke', graphics);
    };
   
    const drawLine = (point: Point, newLine: boolean) => {
      if (!renderer || (!newLine && !graphics)) {
        // console.log('stop1DrawLine');
        return;
      }

      if (newLine) {
        const { x, y } = point;
        const pt = new PIXI.Point(point.x, point.y);
        pts.push(pt);
        graphics = new PIXI.Graphics();
        // graphics.beginFill(color, 0.5);
        // graphics.lineStyle(3, color, 1, 0.5, true);
        stage.addChild(graphics);
        lastPoint = point;
        points = [point];
        persistStroke();
        return;
      }
      lastPoint = point;
      points.push(point);

      graphics.clear();
      for (let i = 0; i < points.length; i++) {
        const { x, y, color, width, alpha } = points[i];
        if (i === 0) {
          graphics.lineStyle(
            width || strokeWidthRef.current || 10,
            color || localStrokeColorRef.current || 0x1b1b1b,
            alpha || strokeAlpha || 1
          );
          // console.log('moveto', x, y)
          graphics.moveTo(x, y);
        } else {
          // console.log('lineto', x, y)
          graphics.lineTo(x, y);
        }
      }
      graphics.endFill();
    };

    const undo = React.useRef((): any => {
      if (!renderer) {
        renderer = PIXI.autoDetectRenderer({
          antialias: true,
          transparent: true,
        });
        // renderer = pixiApp.renderer
        return null;
      }
      // setLocalStrokeColor(0xff0d0d);

      const { children } = stage;
      // console.log('stage!$$$$', stage)
      if (children.length > 0) {
        const child = children[children.length - 1];
        stage.removeChild(child);
        // renderer._update();
        // TODO: This doesn't really work :/
        // setTimeout(() => onChange && onChange(renderer), 2);
        return child;
      } else if (points.length > 0) {
        persistStroke();
        // console.log('returnUndo')
        // return undo();
      }
    }).current;

    const clear = React.useRef(() => {
      if (!renderer) {
        return null;
      }

      if (stage.children.length > 0) {
        stage.removeChildren();
        // renderer._update();
      }
      return null;
    }).current;
    const [saturationValue, setSaturationValue] = useState(0)
    const [hueValue, setHueValue] = useState(0)
    const [valueValue, setValueValue] = useState(1)

    const onSatValPickerChange = ({ saturation, value }: {saturation: number, value: number}) => {
      setSaturationValue(saturation)
      setValueValue(value)
    }

  
    const onHuePickerChange = ({ hue }: {hue: number}) => {
      setHueValue(hue)
    }

    const onContextCreate = async (context: ExpoWebGLRenderingContext) => {
      pixiContext = context;
      // stage = new PIXI.Container();

      const getAttributes = context.getContextAttributes || (() => ({}));
      context.getContextAttributes = () => {
        const contextAttributes = getAttributes();
        return {
          ...contextAttributes,
          stencil: true,
        };
      };

      pixiApp = createPixiApp({
        context,
        height: context.drawingBufferHeight,
        width: context.drawingBufferWidth,
        backgroundColor: 0xffffff,
        transparent: true,
        resolution: 1,
        preserveDrawingBuffer: true,
        // antialias: true,
        // autoStart: true,
      });
      // console.log('IXIIXIX', ixiApp)
      // pixiApp && pixiApp.ticker.add(() => {
      //   // console.log('TICKER', app.ticker);
      //   context.endFrameEXP();
      // });
      // setPixiApp(ixiApp)
      const c = context.canvas.getContext('webgl2');
      // console.log('CCCCCC', c);
      if (pixiApp) {
        
        // console.log('APP', pixiApp.view);
        stage = pixiApp.stage;
        pixiApp.stage.interactive = true;
        // console.log('pa', pixiApp);
        renderer = pixiApp.renderer;
      }

      // renderer.height = context.drawingBufferHeight
      renderer = PIXI.autoDetectRenderer({
        // height: context.drawingBufferHeight,
        // width: context.drawingBufferWidth,
        // backgroundColor: 0xff00f0,
        // context,
        preserveDrawingBuffer: true,
        transparent: true,
        antialias: true,
      });
      
      let renderContext: any = document.getElementsByTagName('canvas');
      renderContext = renderContext[0];
      
      const parentNode = renderContext.parentNode;
      if (pixiApp) {
        parentNode?.insertBefore(pixiApp.view, renderContext);
        document.getElementsByTagName('canvas')[0].style.maxHeight = '100%';
        document.getElementsByTagName('canvas')[0].id = 'one'
        pixiCanvas = document.getElementById('one');
        const gl = (pixiCanvas as HTMLCanvasElement).getContext('webgl2', {preserveDrawingBuffer: true})
        document.getElementsByTagName('canvas')[1].style.maxHeight = '100%';
        document.getElementsByTagName('canvas')[1].id = 'two'
        document.getElementsByTagName('canvas')[0].style.maxWidth = '100%';
        document.getElementsByTagName('canvas')[1].style.maxWidth = '100%';
        document.getElementsByTagName('canvas')[1].style.backgroundColor = 'white';
        document.getElementsByTagName('canvas')[0].style.zIndex = '10';
        // console.log('RENDERER', renderer.view);
      }

      const updateRenderer = () => {
        renderer?.render(stage);
        context.endFrameEXP();
      };
      updateRenderer();

      // console.log('rendererOnContextCreate', renderer);
      // console.log('rendererPIXIOnContextCreate', pixiApp?.renderer);

      // renderUpdate();

      onReady && onReady(context);

      if (initialLines) {
        for (let line of initialLines) {
          buildLine(line);
          // console.log('buildLine', line);
          // console.log('rendererBuildLine', renderer);
        }
        lines = initialLines;
      }
    };

    const buildLine = ({ points, color, alpha, width }: Line) => {
      for (let i = 0; i < points.length; i++) {
        // console.log('buildLinePoints', points);
        // console.log('buildLineColor', color);
        // console.log('buildLineAlpha', alpha);
        // console.log('buildLineWidth', width);
        drawLine({ color, alpha, width, ...points[i] }, i === 0);
      }
    };

    const onLayout = ({
      nativeEvent: {
        layout: { width, height },
      },
    }: {
      nativeEvent: { layout: { width: number; height: number } };
    }) => {
      if (renderer) {
        // pixiApp.renderer = renderer as PIXI.Renderer;
        const scale = PixelRatio.get();
        renderer.resize(width * scale, height * scale);
        renderer.render(stage);
        // console.log('stageOnLayout', stage);
        // console.log('rendererOnLayout', renderer);
        // console.log('appOnLayout', pixiApp);
      }
    };
    // const glRef = useRef<GLView>(null);
    // console.log(`glRef`, glRef);
    // console.log(`glRefStage`, stage);

    const [disableSubmit, setDisableSubmit] = useState(false);

    const save = React.useRef(async () => {
      setDisableSubmit(true)
      const dataUrl = document.getElementById('one');
      const uuuRL = (dataUrl as HTMLCanvasElement).toDataURL();
      const result = await submit({
        variables: {
          file: uuuRL
        }
      });
      setDisableSubmit(false)
      onChange(result.data.createSubmission.url, result.data.createSubmission.id)
    }).current

    const [displayColorPicker, toggleDisplayColorPicker] = useState(false);

    const styles = StyleSheet.create({
      controller: {
        fontFamily: GTAmericaCompressed,
        fontWeight: '400',
        fontSize: 21,
        letterSpacing: 2.1,
        textTransform: 'uppercase',
      },
      controllerRow: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        height: 150,
        width: '50%',
        alignContent: 'center',
        alignItems: 'center'
      },
      colorPickerBorder: {
        height: 29, 
        width: 29, 
        borderRadius: 14.5, 
        borderWidth: 2, 
        borderColor: theme.foregroundColor, 
        display: 'flex', 
        alignItems: "center", 
        alignContent: 'center', 
        justifyContent: 'center'
      },
      colorPickerInner: {
        height: 21, 
        width: 21, 
        borderRadius: 10.5,
      },
      redColorPicker: {
        borderWidth: localStrokeColorRef.current === colors[0].hex ? 2 : 0
      },
      greenColorPicker: {
        borderWidth: localStrokeColorRef.current === colors[1].hex ? 2 : 0
      },
      blueColorPicker: {
        borderWidth: localStrokeColorRef.current === colors[2].hex ? 2 : 0
      },
      sliderLabel: {
        color: theme.foregroundColor, 
        fontSize: 12, 
        textTransform: 'uppercase', 
        fontFamily: GTAmericaCompressed, 
        textAlign: 'center', 
        letterSpacing: 2.14
      }
    })

    return (
      <View style={{flex: 1}}>
        <GLView
          style={{height: 400, minHeight: 400, backgroundColor: 'white', zIndex: -10}}
          onLayout={onLayout}
          {...panResponder.panHandlers}
          onContextCreate={onContextCreate}
          ref={glRef}
          {...props}
        />
        <View style={{flexDirection: 'row', marginHorizontal: 25}}>
        <View style={styles.controllerRow}>
          <View style={{flexDirection: 'row', width: '100%', justifyContent: 'space-around'}}>
            <TouchableOpacity onPress={() => setLocalStrokeColor(colors[0].hex)}>
              <View style={[styles.colorPickerBorder, styles.redColorPicker]}>
                <View style={[styles.colorPickerInner, { backgroundColor: redBrand}]}></View>
              </View>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => setLocalStrokeColor(colors[1].hex)}>
              <View style={[styles.colorPickerBorder, styles.greenColorPicker]}>
                <View style={[styles.colorPickerInner, { backgroundColor: greenBrand}]}></View>
              </View>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => setLocalStrokeColor(colors[2].hex)}>
              <View style={[styles.colorPickerBorder, styles.blueColorPicker]}>
                <View style={[styles.colorPickerInner, { backgroundColor: blueBrand}]}></View>
              </View>
            </TouchableOpacity>
          </View>
          <View style={{display: "flex", flexDirection: 'column', justifyContent: "center", width: '100%', paddingHorizontal: '10%'}}>
            <Slider 
              value={strokeWidthRef.current} 
              thumbTintColor={theme.foregroundColor}
              thumbImage={greenBrush}
              minimumTrackTintColor={theme.foregroundColor} 
              onSlidingComplete={handleOnSlidingComplete} 
              onSlidingStart={handleOnSlidingStart}
              onValueChange={handleOnValueChange}
              minimumValue={1} 
              maximumValue={50} 
              style={{ height: 30, paddingRight: 5}} 
              step={1} 
            />
            <Text style={styles.sliderLabel}>brush size</Text>
          </View>
        </View>
        <View
          style={[styles.controllerRow, {paddingVertical: 10}]}
        >
          {!disableSubmit && <TouchableOpacity onPress={() => clear()}>
            <Text
              style={[styles.controller, { color: redBrand}]}
            >
              clear
            </Text>
          </TouchableOpacity>}
          {!disableSubmit && <TouchableOpacity onPress={() => undo()}>
            <Text
              style={[styles.controller, { color: greenBrand }]}
            >
              undo
            </Text>
          </TouchableOpacity>}
          {disableSubmit ? (<ActivityIndicator color={theme.foregroundColor} />) : <TouchableOpacity
            onPress={() => save()}
            disabled={disableSubmit}
          >
            <Text style={[styles.controller, { color: blueBrand }]}>
            submit
            </Text>
          </TouchableOpacity>}
          {/* <Text>{localStrokeColor}</Text> */}
          {/* <TouchableOpacity onPress={() => toggleDisplayColorPicker(!displayColorPicker)}> */}
            {/* <Image style={{height: 40, width: 40}} source={palette} /> */}
            {/* <View style={{borderWidth: 2, borderColor: theme.foregroundColor, height: 25, width: 25, backgroundColor: PIXI.utils.hex2string(localStrokeColorRef.current)}}></View> */}
          {/* </TouchableOpacity> */}
        </View>
        </View>

        
        {/* {submissionUrl && <Image style={{height: 500, width: 400}} source={submissionUrl!} />} */}
      </View>
    );
  })
);
