import { H2 } from "@expo/html-elements";
import React from "react";
import {
    Image,
    Linking,
    ScrollView,
    StyleSheet,
    Text,
    View,
} from "react-native";
import { Hoverable } from "react-native-web-hooks";
import { HoverableLink } from "../components/common/HoverableLink";
import { PrimaryButton } from "../components/common/PrimaryButton";
import { TouchableOpacity } from "../components/common/TouchableOpacity";
import { CuratorHeader } from "../components/curator/CuratorHeader";
import { SalonSignup } from "../components/salon/SalonSignup";
import { smBreakpoint } from "../constants";
import { useDimensions } from "../context/DimensionsContext";
import { useThemeState } from "../context/ThemeContext";
import {
    blackBrand,
    GTAmerica,
    GTAmericaCompressed,
    mdbreakpoint,
    PanamaBold,
    redBrand,
    TrailersDemiBold,
    wineBrand,
} from "../libs/constants";

interface Props { }

export const NudgeScreen: React.FC<Props> = () => {
    const { theme } = useThemeState();
    const { width, height } = useDimensions();
    const styles = StyleSheet.create({
        text: {
            color: theme.foregroundColor,
        },
        secondaryHeader: {
            fontFamily: GTAmericaCompressed,
            textTransform: "uppercase",
            color: theme.foregroundColor,
            fontSize: width < smBreakpoint ? 20 : width < mdbreakpoint ? 24 : 28,
        },
        body: {
            fontFamily: GTAmerica,
            fontWeight: "300",
            fontSize: width < smBreakpoint ? 12 : width < mdbreakpoint ? 14 : 16,
        },
        infoText: {
            fontFamily: GTAmericaCompressed,
            fontSize: width < smBreakpoint ? 14 : width < mdbreakpoint ? 16 : 18,
            letterSpacing: 1.02,
        },
    });
    return (
        <ScrollView
            style={{
                flex: 1,
                backgroundColor: theme.backgroundColor,
            }}
        >
            <CuratorHeader />
            <Image
                resizeMode="cover"
                source={{ uri: width < mdbreakpoint ? "https://49market.s3.amazonaws.com/IMG_6384.jpg" : "https://49market.s3.amazonaws.com/garden.jpg" }}
                style={{
                    width: width,
                    height: width / 1.5,
                    maxWidth: 1560,
                    maxHeight: 1170,
                    alignSelf: "center",
                }}
            />
            <View style={{ height: 40 }} />
            <View
                style={{
                    justifyContent: "center",
                    width: 700,
                    maxWidth: "90vw",
                    alignSelf: "center",
                }}
            >
                <Text
                    style={{
                        fontFamily: PanamaBold,
                        color: theme.foregroundColor,
                        textTransform: "uppercase",
                        fontSize:
                            width < smBreakpoint ? 22 : width < mdbreakpoint ? 32 : 48,
                        letterSpacing: 1.02,
                        lineHeight:
                            width < smBreakpoint ? 24 : width < mdbreakpoint ? 34 : 50,
                    }}
                >
                    Figure Drawing
                </Text>
                <Text style={[styles.text, styles.body]}>
                    Join the Nudge in Happy Medium's Greenpoint Garden for an
                    evening of figure drawing. Each ticket includes admission for one (1) person, a sketchbook, willow charcoal, one (1) non-alcoholic beverage, and
                    all the inspiration you could want.
                    {"\n\n"}
                </Text>
                <View style={{ height: 20 }} />
                <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                    <View style={{ flexDirection: "column", justifyContent: "center" }}>
                        <Text style={styles.secondaryHeader}>Tuesday, August 6th</Text>
                        <Text
                            style={{
                                fontFamily: GTAmerica,
                                fontWeight: "300",
                                color: theme.foregroundColor,
                                fontSize:
                                    width < smBreakpoint ? 12 : width < mdbreakpoint ? 14 : 16,
                                letterSpacing: 1.07,
                            }}
                        >
                            7:00pm - 9:00pm
                        </Text>

                    </View>
                    <View>
                        <HoverableLink
                            disabled={true}
                            linkType="web"
                            linkUrl={"https://buy.stripe.com/3cs3fS5CQ7ee5QAcEF"}
                        >
                            <Hoverable>
                                {(isHovered) => (
                                    <View
                                        style={{
                                            backgroundColor: theme.foregroundColor,
                                            paddingVertical: 8,
                                            borderRadius: 30,
                                            opacity: 0.3,
                                            alignItems: "center",
                                            marginTop: 5,
                                            // width: '100%',
                                            // width: 250,
                                            paddingHorizontal: 20,
                                            // transform: [{ scale: isHovered ? 1.05 : 1 }],
                                            shadowColor: blackBrand,
                                            shadowOffset: { width: 5, height: 5 },
                                            shadowOpacity: 0.4,
                                            shadowRadius: 10,
                                        }}
                                    >
                                        <Text
                                            style={[
                                                styles.infoText,
                                                {
                                                    textTransform: "uppercase",
                                                    fontWeight: "400",
                                                    color: theme.backgroundColor,
                                                },
                                            ]}
                                        >
                                            Buy Tickets
                                        </Text>
                                        {/* <Text
                                            style={[
                                                styles.infoText,
                                                {
                                                    textTransform: "uppercase",
                                                    fontWeight: "400",
                                                    color: theme.backgroundColor,
                                                },
                                            ]}
                                        >
                                            Sold Out
                                        </Text> */}
                                    </View>
                                )}
                            </Hoverable>
                        </HoverableLink>
                    </View>
                </View>
                <View style={{ height: 40 }} />
                <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                    <View style={{ flexDirection: "column", justifyContent: "center" }}>
                        <Text style={styles.secondaryHeader}>Tuesday, August 27th</Text>
                        <Text
                            style={{
                                fontFamily: GTAmerica,
                                fontWeight: "300",
                                color: theme.foregroundColor,
                                fontSize:
                                    width < smBreakpoint ? 12 : width < mdbreakpoint ? 14 : 16,
                                letterSpacing: 1.07,
                            }}
                        >
                            7:00pm - 9:00pm
                        </Text>

                    </View>
                    <View>
                        <HoverableLink
                            disabled={false}
                            linkType="web"
                            linkUrl={"https://buy.stripe.com/28o4jW3uI0PQ3Is6gi"}
                        >
                            <Hoverable>
                                {(isHovered) => (
                                    <View
                                        style={{
                                            backgroundColor: theme.foregroundColor,
                                            paddingVertical: 8,
                                            borderRadius: 30,
                                            opacity: 1,
                                            alignItems: "center",
                                            marginTop: 5,
                                            // width: '100%',
                                            // width: 250,
                                            paddingHorizontal: 20,
                                            // transform: [{ scale: isHovered ? 1.05 : 1 }],
                                            shadowColor: blackBrand,
                                            shadowOffset: { width: 5, height: 5 },
                                            shadowOpacity: 0.4,
                                            shadowRadius: 10,
                                        }}
                                    >
                                        <Text
                                            style={[
                                                styles.infoText,
                                                {
                                                    textTransform: "uppercase",
                                                    fontWeight: "400",
                                                    color: theme.backgroundColor,
                                                },
                                            ]}
                                        >
                                            Buy Tickets
                                        </Text>
                                        {/* <Text
                                            style={[
                                                styles.infoText,
                                                {
                                                    textTransform: "uppercase",
                                                    fontWeight: "400",
                                                    color: theme.backgroundColor,
                                                },
                                            ]}
                                        >
                                            Sold Out
                                        </Text> */}
                                    </View>
                                )}
                            </Hoverable>
                        </HoverableLink>
                    </View>
                </View>
                <View style={{ height: 40 }} />
                <View>
                    <Text
                        style={{
                            fontFamily: GTAmerica,
                            fontWeight: "300",
                            color: theme.foregroundColor,
                            fontSize:
                                width < smBreakpoint ? 12 : width < mdbreakpoint ? 14 : 16,
                            letterSpacing: 1.07,
                        }}
                    >
                        224 Franklin St{"\n"}
                        Brooklyn, NY 11222
                    </Text>
                </View>
                <View style={{ height: 20 }} />

                {/* <H2
                    style={{
                        fontFamily: PanamaBold,
                        textTransform: "uppercase",
                        color: theme.foregroundColor,
                        fontSize:
                            width < smBreakpoint ? 22 : width < mdbreakpoint ? 28 : 32,
                    }}
                >
                    The Details
                </H2>
                <Text style={[styles.text, styles.body]}>
                    Nervous about making art? You're in the right spot. Happy Medium is an
                    arts club for casual artists and designed specifically with beginners
                    in mind. We'll send you all the tips you need beforehand so you can
                    walk in feeling like a pro.
                    {"\n\n"}
                </Text> */}
                <H2
                    style={{
                        fontFamily: PanamaBold,
                        textTransform: "uppercase",
                        color: theme.foregroundColor,
                        fontSize:
                            width < smBreakpoint ? 22 : width < mdbreakpoint ? 28 : 32,
                    }}
                >
                    FAQ
                </H2>
                <Text style={styles.secondaryHeader}>IS THIS TICKET REFUNDABLE?</Text>
                <Text style={[styles.text, styles.body]}>
                    No, but we're happy to offer you a credit to be applied to any future
                    events, products, or art cafe as long as you let us know you won't be
                    able to make it at least 24 hours before the event start time.{"\n\n"}
                </Text>
                <Text style={styles.secondaryHeader}>IS THIS TICKET Transferable?</Text>
                <Text style={[styles.text, styles.body]}>
                    Yes! If you can't make it but want to give your spot to a friend just
                    send us an email at reservations@happy-medium.co and we'll be happy to
                    help.{"\n\n"}
                </Text>
                <Text style={styles.secondaryHeader}>
                    What's the address?
                </Text>
                <Text style={[styles.text, styles.body]}>
                    224 Franklin St, Brooklyn, NY 11222{"\n\n"}
                </Text>
                <Text style={styles.secondaryHeader}>
                    What if it rains?
                </Text>
                <Text style={[styles.text, styles.body]}>
                    This event is rain or shine. Weather permitting, it will be held outside in our garden but, should it rain, we will be meeting inside. {"\n\n"}
                </Text>

                <Text style={styles.secondaryHeader}>
                    Will there be tickets available at the door?
                </Text>
                <Text style={[styles.text, styles.body]}>
                    No. Tickets must be reserved in advance.{"\n\n"}
                </Text>
                <Text style={styles.secondaryHeader}>
                    Do I need to know how to draw?
                </Text>
                <Text style={[styles.text, styles.body]}>
                    Absolutely not - Happy Medium is designed for beginners and,
                    specifically, adult beginners. We will send all of our best tips ahead
                    of time so you feel like a pro!{"\n\n"}
                </Text>
                <Text style={styles.secondaryHeader}>Is this a class?</Text>
                <Text style={[styles.text, styles.body]}>
                    No - this is a super casual, self-guided figure drawing event.{"\n\n"}
                </Text>
                <Text style={styles.secondaryHeader}>What happens if it rains?</Text>
                <Text style={[styles.text, styles.body]}>
                    In the event of rain figure drawing will be hosted indoors.{"\n\n"}
                </Text>
                <Text style={styles.secondaryHeader}>Can I COME BY MYSELF?</Text>
                <Text style={[styles.text, styles.body]}>
                    Yes! Singles are great and we love friends too!{"\n\n"}
                </Text>
                <Text style={styles.secondaryHeader}>MORE QUESTIONS?</Text>
                <Text style={[styles.text, styles.body]}>
                    Email us at reservations@happy-medium.co and we'd be happy to help!
                    {"\n\n"}
                </Text>
                <View style={{ height: 60 }} />
            </View>
            <View style={{ height: 60 }} />
            <SalonSignup />
        </ScrollView>
    );
};
