import React, { Dispatch, SetStateAction } from 'react';
import { View, StyleSheet, Text } from 'react-native';

import { GTAmerica } from '../../libs/constants';
import { RadioButton } from '../common/RadioButton';
import { useThemeState } from '../../context/ThemeContext';
import { toggleArrayValue } from '../../utils/shared';
import { useDimensions } from '../../context/DimensionsContext';

interface Props {
  filters: string[];
  selectedFilters: string[];
  setSelectedFilters: Dispatch<SetStateAction<string[]>>;
}

export const ActivitiesFilter: React.FC<Props> = ({
  filters,
  selectedFilters,
  setSelectedFilters,
}) => {
  const lgBreakpoint = 1180;
  const mdBreakpoint = 860;
  const smBreakpoint = 420;
  const { width } = useDimensions();
  const { theme } = useThemeState();
  const styles = StyleSheet.create({
    label: {
      fontFamily: GTAmerica,
      fontWeight: '400',
      letterSpacing: 0.9,
      fontSize: 18,
      paddingLeft: '10%',
      alignSelf: 'center',
      width: '100%',
      height: '100%',
    },
    container: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap:
        width > lgBreakpoint
          ? 'wrap'
          : width < lgBreakpoint && width > mdBreakpoint
          ? 'nowrap'
          : 'wrap',
      // flexGrow: 1,
    },
    radioButtonContainer: {
      flex: 2,
      height:
        width > lgBreakpoint
          ? '100%'
          : width < lgBreakpoint && width > mdBreakpoint
          ? '100%'
          : '50%',
      borderColor: theme.foregroundColor,
      borderWidth: width > mdBreakpoint ? 2 : 1,
      borderBottomWidth:
        width < smBreakpoint ? 1 : width < lgBreakpoint ? 1 : 2,
      borderLeftWidth: width > mdBreakpoint ? 0 : 1,
      borderTopWidth: width < mdBreakpoint || width > lgBreakpoint ? 1 : 2,
    },
  });

  return (
    <View style={styles.container}>
      {filters.map((f, i) => (
        <RadioButton
          checked={selectedFilters.includes(f)}
          key={`filter-${f}`}
          containerStyle={[
            styles.radioButtonContainer,
            {
              borderRightWidth:
                filters.length === i + 1 && width > mdBreakpoint
                  ? 0
                  : undefined,
            },
          ]}
          labelStyle={styles.label}
          label={f}
          analyticsLabel={undefined}
          defaultValue={false}
          onChange={(value) => {
            setSelectedFilters(toggleArrayValue(selectedFilters, `${f}`));
          }}
        />
      ))}
    </View>
  );
};
