import React from 'react';
import { View, ScrollView, Text, StyleSheet } from 'react-native';

import { SalonIssueHeader } from '../components/salon/IssueHeader';
import { RouteComponentProps } from '../navigation';
import { SalonIssueRenderer } from '../components/salon/SalonIssueRenderer';
import { SalonSignup } from '../components/salon/SalonSignup';
import { SalonIssueSeven } from '../components/salon/issues/SalonIssueSeven';
import { useThemeState } from '../context/ThemeContext';
import { Helmet } from 'react-helmet';
import { HoverableLink } from '../components/common/HoverableLink';
import { salonIssuePreviewData } from '../data/SalonIssues';
import { PanamaBold } from '../libs/constants';

interface MatchParams {
  issueNumber: string;
  filter?: string;
}

interface Props extends RouteComponentProps<MatchParams> {}

export const SalonIssueScreen: React.FC<Props> = (props) => {
  const { theme } = useThemeState();
  const {
    match: {
      params: { issueNumber, filter },
    },
  } = props;
  console.log(issueNumber, Number(issueNumber));

  const styles = StyleSheet.create({
    text: {
      fontFamily: PanamaBold,
      textTransform: 'uppercase',
      fontSize: 24,
    },
  });
  // document.title = `Happy Medium | The Salon Issue No. ${issueNumber}`;
  return (
    <ScrollView style={{ backgroundColor: theme.backgroundColor }}>
      <Helmet>
        <title>{`Happy Medium | The Salon Issue No. ${issueNumber}`}</title>
        <meta
          name="twitter:title"
          content={`Happy Medium | The Salon Issue No. ${issueNumber}`}
        />
        <meta
          property="twitter:card"
          content="summary_large_image"
          key="twtrcard"
        />
        <meta
          name="twitter:description"
          content="To keep you inspired over the holidays, we’ve compiled our 10 favorite art-ish books and movies for you to indulge in over the break."
        />
        <meta
          property="twitter:site"
          content="@gethappymedium"
          key="twtrsite"
        />
        <meta
          property="twitter:url"
          content="https://happy-medium.co/the-salon/7"
          key="twtrurl"
        />
        <meta
          property="twitter:image"
          content="https://thesalonnewsletter.s3.us-east-2.amazonaws.com/007/movies/10m1resize.jpg"
          key="twtrimg"
        />
        <meta
          property="twitter:image:alt"
          content="Tilda Swinton in I Am Love"
          key="twtrimgalt"
        />
      </Helmet>
      <SalonIssueHeader issueNumber={Number(issueNumber)} />
      {issueNumber === '7' ? (
        <SalonIssueSeven filter={filter ? filter : 'read'} />
      ) : (
        <SalonIssueRenderer issueNumber={Number(issueNumber)} />
      )}
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-around',
          marginVertical: 30,
        }}
      >
        <View>
          <HoverableLink
            linkType={'internal'}
            linkUrl={`/the-salon/${Number(issueNumber) - 1}`}
            disabled={Number(issueNumber) === 1}
            textStyle={styles.text}
          >
            Prev
          </HoverableLink>
        </View>
        <View>
          <HoverableLink
            linkType={'internal'}
            linkUrl={`/the-salon/${Number(issueNumber) + 1}`}
            disabled={Number(issueNumber) === salonIssuePreviewData.length}
            textStyle={styles.text}
          >
            Next
          </HoverableLink>
        </View>
      </View>
      <SalonSignup />
    </ScrollView>
  );
};
