import { A, H1, H2, H3, P } from '@expo/html-elements';
import { TextProps } from '@expo/html-elements/build/primitives/Text';
import React, { useEffect } from 'react';
import { useState } from 'react';
import {
  Image,
  Linking,
  ScrollView,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import { Hoverable } from 'react-native-web-hooks';
import { HoverableLink } from '../components/common/HoverableLink';
import { ImageCarousel } from '../components/common/ImageCarousel';
import { PrimaryButton } from '../components/common/PrimaryButton';
import { TouchableOpacity } from '../components/common/TouchableOpacity';
import { CuratorHeader } from '../components/curator/CuratorHeader';
import { PhoneNumberSignupForm } from '../components/forms/PhoneNumberSignupForm';
import { SalonSignup } from '../components/salon/SalonSignup';
import { lgBreakpoint, smBreakpoint } from '../constants';
import { chairAssets } from '../constants/assets';
import { useDimensions } from '../context/DimensionsContext';
import { useThemeState } from '../context/ThemeContext';
import {
  blackBrand,
  GTAmerica,
  GTAmericaCompressed,
  mdbreakpoint,
  PanamaBold,
  redBrand,
  TrailersDemiBold,
  wineBrand,
} from '../libs/constants';

interface Props { }

export type ProductAsset = {
  id: string;
  url: string;
  height: number;
  width: number;
  caption?: string;
};

export const BuildAChairScreen: React.FC<Props> = () => {
  document.title = '"Build-a-Chair" Workshop | Happy Medium';
  const { theme } = useThemeState();
  const { width, height } = useDimensions();
  const styles = StyleSheet.create({
    text: {
      color: theme.foregroundColor,
    },
    secondaryHeader: {
      fontFamily: GTAmericaCompressed,
      textTransform: 'uppercase',
      color: theme.foregroundColor,
      fontSize: width < smBreakpoint ? 20 : width < mdbreakpoint ? 24 : 28,
    },
    body: {
      fontFamily: GTAmerica,
      fontWeight: '300',
      fontSize: width < smBreakpoint ? 16 : width < mdbreakpoint ? 16 : 18,
      letterSpacing: 1.07,
    },
    infoText: {
      fontFamily: GTAmericaCompressed,
      fontSize: width < smBreakpoint ? 14 : width < mdbreakpoint ? 16 : 18,
      letterSpacing: 1.02,
    },
    header: {
      fontFamily: PanamaBold,
      color: theme.foregroundColor,
      textTransform: 'uppercase',
      alignSelf: 'center',
      fontSize: width < smBreakpoint ? 34 : width < mdbreakpoint ? 42 : 48,
      letterSpacing: 1.02,
      lineHeight: width < smBreakpoint ? 38 : width < mdbreakpoint ? 48 : 54,
      textAlign: 'center',
    },
    h2: {
      fontSize: width < smBreakpoint ? 28 : width < mdbreakpoint ? 34 : 40,
    },
    h3: {
      fontSize: width < smBreakpoint ? 22 : width < mdbreakpoint ? 24 : 28,
    },
    pushButton: {
      borderWidth: 1,
      borderColor: theme.foregroundColor,
      borderRadius: 5,
      // padding: 5,
      justifyContent: 'center',
      height: 100,
      // width: 222,
      marginHorizontal: width < mdbreakpoint ? 0 : 5,
      marginVertical: 5
    },
    timeSlot: { width: width < lgBreakpoint ? 140 : 222 },
    item: { width: 222 }
  });

  type Design = 'HMBench' | 'CP' | 'BFDaybed' | 'PetalChair';
  type Wood = 'Douglas Fir' | 'Mahogany' | 'Clear Pine';
  type Time = '6';
  type Size = 'Small' | 'Medium' | 'Large'
  type Day = '11/5' | '11/6' | '11/7' | '12/3' | '12/4' | '12/5';
  type Location = 'Manhattan' | 'Brooklyn';
  type Slot = { time: Time; day: Day; location: Location };
  type PaymentLink = {
    design: Design;
    wood: Wood;
    // time: Time;
    // day: Day;
    slot: Slot;
    paymentLink: string;
    price: string;
    isAvailable?: boolean;
    size?: string
  };


  const [selectedDesign, setSelectedDesign] = useState<Design>('HMBench');
  const [selectedWood, setSelectedWood] = useState<Wood>(selectedDesign === 'BFDaybed' ? 'Clear Pine' : 'Douglas Fir');
  const [selectedSize, setSelectedSize] = useState<Size>('Medium');
  const [selectedTime, setSelectedTime] = useState<Time>('6');
  const [selectedDay, setSelectedDay] = useState<Day>('12/3');
  const [selectedLocation, setSelectedLocation] = useState<Location>('Manhattan');

  useEffect(() => {
    setSelectedWood(selectedDesign === 'BFDaybed' ? 'Clear Pine' : 'Douglas Fir')
    setSelectedSize('Medium')
  }, [selectedDesign])
  const soldOutTimeSlots: Slot[] = [
    { day: '11/6', time: '6', location: 'Manhattan' },
    { day: '11/5', time: '6', location: 'Manhattan' },
    { day: '11/7', time: '6', location: 'Manhattan' },
  ];

  const containsObject = (obj: Slot, list: Slot[]) => {
    return list.some((e) => JSON.stringify(e) === JSON.stringify(obj));
  };

  const data = [
    {
      product: 'BFDaybed',
      name: 'Best Friend Daybed',
      description: 'Our take on a classic daybed design, scaled down for your bestie.\n\nThe Best Friend Day Bed is the perfect platform for your pet\'s bed, getting them off the ground and making your home a little ~cooler~. Constructed in solid wood and featuring a sleek box joint, simply set your pet\'s favorite bed on top. Matress + bolster set show in image available for purchase separately in green and blue waxed canvas (email us for pricing).\n\nThe Best Friend Day Bed is offered in 3 sizes - small for pets up to 25lbs, medium for pets 25-60lbs, and large for pets 60-90lbs. Technically fits a human too, just saying.\n\nDimensions: small - 25.75" D x 20.75" W x 5" H; medium - 36.75" D x 28.75" W x 5" H; large - 52.75" D x 44.75" W x 5" H; and fits most standard god beds\n\nAvailable in solid Mahogany and Clear Pine.',
      assets: [
        {
          id: 'bfdb1',
          url:
            'https://49market.s3.amazonaws.com/best+friend+bed.png',
          height: 565,
          width: 1005,
          caption: 'The Best Friend Daybed in Mahogany finished with danish oil.',
        },
        {
          id: 'bfdb2',
          url:
            'https://49market.s3.amazonaws.com/best+friend+bed+2.png',
          height: 565,
          width: 1005,
          caption: 'The Best Friend Daybed in Mahogany finished with danish oil.',
        },
        {
          id: 'bfdb3',
          url:
            'https://49market.s3.amazonaws.com/best+friend+bed+3.png',
          height: 565,
          width: 1005,
          caption: 'The Best Friend Daybed in Mahogany finished with danish oil.',
        },
      ]
    },
    // {
    //   product: 'ArchDesk',
    //   name: 'Architecture Desk',
    //   description: 'Flexing easily from desk to dining table, the Architecture Desk is the perfect single surface solution for the space-constrained. 24"W x 48"L x 30"H',
    //   assets: [
    //     {
    //       id: 'ad1',
    //       url:
    //         'https://hmproductcatalog.s3.amazonaws.com/AD_1.png',
    //       height: 565,
    //       width: 1005,
    //       caption: '',
    //     },
    //     {
    //       id: 'ad2',
    //       url:
    //         'https://hmproductcatalog.s3.amazonaws.com/AD_3.png',
    //       height: 565,
    //       width: 1005,
    //       caption: '',
    //     },
    //     {
    //       id: 'ad3',
    //       url:
    //         'https://hmproductcatalog.s3.amazonaws.com/AD_4.png',
    //       height: 565,
    //       width: 1005,
    //       caption: '',
    //     },
    //   ]
    // },
    // {
    //   product: 'HMArtDesk',
    //   name: 'Happy Medium Art Desk',
    //   description: `This is our first take on the perfect desk to facilitate creativity in your own home.\n\nThe Happy Medium Art Desk comes with a flush mounted power outlet, dimmable LED lighting. The Happy Medium Art Desk measures 37.5in.(w) x 18.75in.(d) x 34in.(h). The double height 18" x 36" desktop gives you all the space you need while maintaining a small footprint.\n\nWe recommend: Douglas fir finished with danish oil.

    //   `,
    //   id: '4',
    //   paymentLink: '',
    //   assets: [
    //     {
    //       id: 'hmad1',
    //       url:
    //         'https://49market.s3.amazonaws.com/Happy+Medium+Art+Desk_1.jpg',
    //       height: 715,
    //       width: 1272,
    //       caption: 'The Happy Medium Art Desk shown in unfinished Douglas Fir.',
    //     },
    //     {
    //       id: 'hmad1',
    //       url:
    //         'https://49market.s3.amazonaws.com/Happy+Medium+Art+Desk_3.jpg',
    //       height: 715,
    //       width: 1272,
    //       caption: 'Detailed view of recessed power outlet + 2 USB ports (A + C)',
    //     },
    //     {
    //       id: 'hmad4',
    //       url: 'https://49market.s3.amazonaws.com/Swatch.png',
    //       height: 715,
    //       width: 1272,
    //     },
    //   ],
    // },
    {
      product: 'HMBench',
      name: 'Happy Medium Bench',
      description: `Happy Medium co-founder Rett designed this bench in 2022. It's the Swiss army knife of furniture - the perfect blend of storage and seating designed to fit into any room.\n\nThe bench is constructed from 4 pieces of wood and features a bench top on top of an interlocking-leg base. The Happy Medium Bench measures 60in.(l) x 15in.(d) x 18in.(h). Seats up to 2 as a bench or can be used as a low shelf to display books, artwork, and art objects.\n\nWe recommend: Douglas fir, left unfinished
      
      `,
      id: '3',
      paymentLink: '',
      assets: [
        {
          id: 'hm1',
          url:
            'https://49market.s3.amazonaws.com/Happy+Medium+Bench+1+desktop.png',
          height: 715,
          width: 1272,
          caption: 'The Happy Medium Bench shown in unfinished Douglas Fir.',
        },
        {
          id: 'hm2',
          url:
            'https://49market.s3.amazonaws.com/Happy+Medium+Bench+2+desktop.png',
          height: 715,
          width: 1272,
        },
        {
          id: 'hm3',
          url:
            'https://49market.s3.amazonaws.com/Happy+Medium+Bench++3+mobile.png',
          height: 715,
          width: 1272,
        },
        {
          id: 'hm4',
          url: 'https://49market.s3.amazonaws.com/Swatch.png',
          height: 715,
          width: 1272,
        },
      ],
    },
    {
      product: 'PetalChair',
      name: 'Petal Chair',
      description: `Inspired by the soft curves of tulips in our new garden, the Happy Medium team designed this chair in 2024. With a convenient shelf, decorative back detail, and dramatic splayed legs, this chair is equal parts beauty and function. The chair is 33" H x 15" D x 15" W and has a seat height of 18".`,
      id: '1',
      paymentLink: '',
      assets: [
        {
          id: 'p1',
          url: 'https://49market.s3.amazonaws.com/petal.png',
          height: 715,
          width: 1272,
          caption:
            '',
        },
        {
          id: 'p2',
          url: 'https://49market.s3.amazonaws.com/opt+1.png',
          height: 715,
          width: 1272,
        },
        {
          id: 'p3',
          url: 'https://49market.s3.amazonaws.com/opt+2.png',
          height: 715,
          width: 1272,
        },
      ],
    },
    {
      product: 'CP',
      name: 'Charlotte Stool',
      description: `Similar in design to a 1950's piece by Charlotte Perriand, this floating top stool is the epitome of mid—century cool.\n\nThe stool is constructed from 3 pieces of wood and features a floating top on an “X” base. It measures 14.25in(h) x 15.5in(w) x 15.5in.(d). Use as a actual stool, a chair, a side table, or art object. Pairs well with your favorite throw seat cushion.\n\nWe recommend: Mahogany, finished with Danish Oil and edge banding
      
      `,
      id: '2',
      paymentLink: '',
      assets: [
        {
          id: 'cp1',
          url:
            'https://49market.s3.amazonaws.com/Charlotte+Stool+1+desktop.png',
          height: 715,
          width: 1272,
          caption:
            'The Charlotte Stool shown in Mahogany finished with Danish oil.',
        },
        {
          id: 'cp2',
          url:
            'https://49market.s3.amazonaws.com/Charlotte+Stool+2+desktop.png',
          height: 715,
          width: 1272,
        },
        {
          id: 'cp3',
          url: 'https://49market.s3.amazonaws.com/Swatch.png',
          height: 715,
          width: 1271,
        },
      ],
    },
  ];

  const paymentLinks: PaymentLink[] = [

    {
      design: 'PetalChair',
      wood: 'Douglas Fir',
      slot: { day: '11/5', time: '6', location: 'Manhattan' },
      paymentLink: 'https://buy.stripe.com/28o9Eg9T6gOO6UE3bO',
      price: '$350',
      isAvailable: true,
      size: 'Medium',
    },
    {
      design: 'PetalChair',
      wood: 'Douglas Fir',
      slot: { day: '11/6', time: '6', location: 'Manhattan' },
      paymentLink: 'https://buy.stripe.com/6oEaIkaXa5663Is7si',
      price: '$350',
      isAvailable: true,
      size: 'Medium',
    },
    {
      design: 'PetalChair',
      wood: 'Douglas Fir',
      slot: { day: '11/7', time: '6', location: 'Manhattan' },
      paymentLink: 'https://buy.stripe.com/8wM7w86GU9mmen7eUY',
      price: '$350',
      isAvailable: true,
      size: 'Medium',
    },
    // {
    //   design: 'PetalChair',
    //   wood: 'Douglas Fir',
    //   slot: { day: '6/20', time: '6', location: 'Brooklyn' },
    //   paymentLink: 'https://buy.stripe.com/7sI03G1mAaqq3Is51i',
    //   price: '$350',
    //   isAvailable: true,
    //   size: 'Medium',
    // },
    {
      design: 'PetalChair',
      wood: 'Mahogany',
      slot: { day: '11/5', time: '6', location: 'Manhattan' },
      paymentLink: 'https://buy.stripe.com/fZedUw6GUaqq6UE3bP',
      price: '$450',
      isAvailable: true,
      size: 'Medium',
    },
    {
      design: 'PetalChair',
      wood: 'Mahogany',
      slot: { day: '11/6', time: '6', location: 'Manhattan' },
      paymentLink: 'https://buy.stripe.com/3cseYAaXa1TU2EoeUL',
      price: '$450',
      isAvailable: true,
      size: 'Medium',
    },
    {
      design: 'PetalChair',
      wood: 'Mahogany',
      slot: { day: '11/7', time: '6', location: 'Manhattan' },
      paymentLink: 'https://buy.stripe.com/eVa9Ege9m2XY92N28d',
      price: '$450',
      isAvailable: true,
      size: 'Medium',
    },
    // {
    //   design: 'PetalChair',
    //   wood: 'Mahogany',
    //   slot: { day: '6/20', time: '6', location: 'Brooklyn' },
    //   paymentLink: 'https://buy.stripe.com/fZe6s49T60PQ0wgeBV',
    //   price: '$450',
    //   isAvailable: true,
    //   size: 'Medium',
    // },
    {
      design: 'CP',
      wood: 'Douglas Fir',
      slot: { day: '11/5', time: '6', location: 'Manhattan' },
      paymentLink: 'https://buy.stripe.com/4gwbMo4yMfKKfra9Ae',
      price: '$400',
      isAvailable: true,
      size: 'Medium',
    },
    {
      design: 'CP',
      wood: 'Douglas Fir',
      slot: { day: '11/6', time: '6', location: 'Manhattan' },
      paymentLink: 'https://buy.stripe.com/4gw2bOaXa1TUen68wo',
      price: '$400',
      isAvailable: true,
      size: 'Medium',
    },
    {
      design: 'CP',
      wood: 'Douglas Fir',
      slot: { day: '11/7', time: '6', location: 'Manhattan' },
      paymentLink: 'https://buy.stripe.com/4gw2bOaXa1TUen68wo',
      price: '$400',
      isAvailable: true,
      size: 'Medium',
    },
    // {
    //   design: 'CP',
    //   wood: 'Douglas Fir',
    //   slot: { day: '6/20', time: '6', location: 'Brooklyn' },
    //   paymentLink: 'https://buy.stripe.com/00g3fS3uIfKKbaU8dA',
    //   price: '$400',
    //   isAvailable: true,
    //   size: 'Medium',
    // },
    {
      design: 'CP',
      wood: 'Mahogany',
      slot: { day: '11/5', time: '6', location: 'Manhattan' },
      paymentLink: 'https://buy.stripe.com/eVa5o0fdq56692Mh2H',
      price: '$450',
      isAvailable: true,
      size: 'Medium',
    },
    {
      design: 'CP',
      wood: 'Mahogany',
      slot: { day: '11/6', time: '6', location: 'Manhattan' },
      paymentLink: 'https://buy.stripe.com/7sIg2E2qEeGG0wgcMF',
      price: '$450',
      isAvailable: true,
      size: 'Medium',
    },
    {
      design: 'CP',
      wood: 'Mahogany',
      slot: { day: '11/7', time: '6', location: 'Manhattan' },
      paymentLink: 'https://buy.stripe.com/cN2g2E2qE2XY1Al3cj',
      price: '$450',
      isAvailable: true,
      size: 'Medium',
    },
    // {
    //   design: 'CP',
    //   wood: 'Mahogany',
    //   slot: { day: '6/20', time: '6', location: 'Brooklyn' },
    //   paymentLink: 'https://buy.stripe.com/14k03G8P25664MwbpP',
    //   price: '$450',
    //   isAvailable: true,
    //   size: 'Medium',
    // },
    {
      design: 'HMBench',
      wood: 'Douglas Fir',
      slot: { day: '11/5', time: '6', location: 'Manhattan' },
      paymentLink: 'https://buy.stripe.com/cN28Acfdq422ceYbIi',
      price: '$300',
      isAvailable: true,
      size: 'Medium',
    },
    {
      design: 'HMBench',
      wood: 'Douglas Fir',
      slot: { day: '11/6', time: '6', location: 'Manhattan' },
      paymentLink: 'https://buy.stripe.com/8wM03G1mA422gveaEs',
      price: '$300',
      isAvailable: true,
      size: 'Medium',
    },
    {
      design: 'HMBench',
      wood: 'Douglas Fir',
      slot: { day: '11/7', time: '6', location: 'Manhattan' },
      paymentLink: 'https://buy.stripe.com/4gwg2Eghucyy1Al7su',
      price: '$300',
      isAvailable: true,
      size: 'Medium',
    },
    // {
    //   design: 'HMBench',
    //   wood: 'Douglas Fir',
    //   slot: { day: '6/20', time: '6', location: 'Brooklyn' },
    //   paymentLink: 'https://buy.stripe.com/00g7w8aXacyy4Mw1P0',
    //   price: '$300',
    //   isAvailable: true,
    //   size: 'Medium',
    // },
    {
      design: 'HMBench',
      wood: 'Mahogany',
      slot: { day: '11/5', time: '6', location: 'Manhattan' },
      paymentLink: 'https://buy.stripe.com/eVa4jW8P2gOOdj24fR',
      price: '$350',
      isAvailable: true,
      size: 'Medium',
    },
    {
      design: 'HMBench',
      wood: 'Mahogany',
      slot: { day: '11/6', time: '6', location: 'Manhattan' },
      paymentLink: 'https://buy.stripe.com/bIY2bOghu6aaen67sh',
      price: '$350',
      isAvailable: true,
      size: 'Medium',
    },
    {
      design: 'HMBench',
      wood: 'Mahogany',
      slot: { day: '11/7', time: '6', location: 'Manhattan' },
      paymentLink: 'https://buy.stripe.com/8wM3fS8P2gOO6UF28b',
      price: '$350',
      isAvailable: true,
      size: 'Medium',
    },
    // {
    //   design: 'HMBench',
    //   wood: 'Mahogany',
    //   slot: { day: '6/20', time: '6', location: 'Brooklyn' },
    //   paymentLink: 'https://buy.stripe.com/bIYdUw6GU9mmbaUfFT',
    //   price: '$350',
    //   isAvailable: true,
    //   size: 'Medium',
    // },
    {
      design: 'BFDaybed',
      wood: 'Clear Pine',
      slot: { day: '11/5', time: '6', location: 'Manhattan' },
      paymentLink: 'https://buy.stripe.com/9AQaIk6GUbuua6QeUC',
      size: 'Small',
      price: '$200',
      isAvailable: true,
    },
    {
      design: 'BFDaybed',
      wood: 'Mahogany',
      slot: { day: '11/5', time: '6', location: 'Manhattan' },
      paymentLink: 'https://buy.stripe.com/5kA03Gc1eeGGbaU9Aj',
      size: 'Small',
      price: '$200',
      isAvailable: true,
    },
    {
      design: 'BFDaybed',
      wood: 'Clear Pine',
      slot: { day: '11/6', time: '6', location: 'Manhattan' },
      paymentLink: 'https://buy.stripe.com/cN26s4e9mcyy7YI7so',
      size: 'Small',
      price: '$200',
      isAvailable: true,
    },
    {
      design: 'BFDaybed',
      wood: 'Mahogany',
      slot: { day: '11/6', time: '6', location: 'Manhattan' },
      paymentLink: 'https://buy.stripe.com/4gw4jWaXa566baUbIF',
      size: 'Small',
      price: '$200',
      isAvailable: true,
    },
    {
      design: 'BFDaybed',
      wood: 'Clear Pine',
      slot: { day: '11/7', time: '6', location: 'Manhattan' },
      paymentLink: 'https://buy.stripe.com/cN2cQs6GUaqq5QB6oy',
      size: 'Small',
      price: '$200',
      isAvailable: true,
    },
    {
      design: 'BFDaybed',
      wood: 'Mahogany',
      slot: { day: '11/7', time: '6', location: 'Manhattan' },
      paymentLink: 'https://buy.stripe.com/00g17Kd5i8ii92NfZ9',
      size: 'Small',
      price: '$200',
      isAvailable: true,
    },
    // {
    //   design: 'BFDaybed',
    //   wood: 'Clear Pine',
    //   slot: { day: '6/20', time: '6', location: 'Brooklyn' },
    //   paymentLink: 'https://buy.stripe.com/5kAbMo9T68ii7YIeC4',
    //   size: 'Small',
    //   price: '$200',
    //   isAvailable: true,
    // },
    // {
    //   design: 'BFDaybed',
    //   wood: 'Mahogany',
    //   slot: { day: '6/20', time: '6', location: 'Brooklyn' },
    //   paymentLink: 'https://buy.stripe.com/6oEg2E8P2aqqdj28dJ',
    //   size: 'Small',
    //   price: '$200',
    //   isAvailable: true,
    // },
    {
      design: 'BFDaybed',
      wood: 'Clear Pine',
      slot: { day: '11/5', time: '6', location: 'Manhattan' },
      paymentLink: 'https://buy.stripe.com/00g03G1mAgOOa6Q5k4',
      size: 'Medium',
      price: '$300',
      isAvailable: true,
    },
    {
      design: 'BFDaybed',
      wood: 'Mahogany',
      slot: { day: '11/5', time: '6', location: 'Manhattan' },
      paymentLink: 'https://buy.stripe.com/14kaIkc1egOO3IsbIt',
      size: 'Medium',
      price: '$300',
      isAvailable: true,
    },
    {
      design: 'BFDaybed',
      wood: 'Clear Pine',
      slot: { day: '11/6', time: '6', location: 'Manhattan' },
      paymentLink: 'https://buy.stripe.com/5kA03G7KYeGGceY142',
      size: 'Medium',
      price: '$300',
      isAvailable: true,
    },
    {
      design: 'BFDaybed',
      wood: 'Mahogany',
      slot: { day: '11/6', time: '6', location: 'Manhattan' },
      paymentLink: 'https://buy.stripe.com/5kAdUw1mA2XY1Ak5kj',
      size: 'Medium',
      price: '$300',
      isAvailable: true,
    },
    {
      design: 'BFDaybed',
      wood: 'Clear Pine',
      slot: { day: '11/7', time: '6', location: 'Manhattan' },
      paymentLink: 'https://buy.stripe.com/28oeYA2qE1TU6UFdR2',
      size: 'Medium',
      price: '$300',
      isAvailable: true,
    },
    {
      design: 'BFDaybed',
      wood: 'Mahogany',
      slot: { day: '11/7', time: '6', location: 'Manhattan' },
      paymentLink: 'https://buy.stripe.com/dR68Acc1e0PQa6ReV7',
      size: 'Medium',
      price: '$300',
      isAvailable: true,
    },
    // {
    //   design: 'BFDaybed',
    //   wood: 'Clear Pine',
    //   slot: { day: '6/20', time: '6', location: 'Brooklyn' },
    //   paymentLink: 'https://buy.stripe.com/9AQeYA6GUdCC2Eody6',
    //   size: 'Medium',
    //   price: '$300',
    //   isAvailable: true,
    // },
    // {
    //   design: 'BFDaybed',
    //   wood: 'Mahogany',
    //   slot: { day: '6/20', time: '6', location: 'Brooklyn' },
    //   paymentLink: 'https://buy.stripe.com/4gw03G9T64226UE1Pr',
    //   size: 'Medium',
    //   price: '$300',
    //   isAvailable: true,
    // },
    {
      design: 'BFDaybed',
      wood: 'Clear Pine',
      slot: { day: '11/5', time: '6', location: 'Manhattan' },
      paymentLink: 'https://buy.stripe.com/eVabMod5i9mm0wgaEq',
      size: 'Large',
      price: '$400',
      isAvailable: true,
    },
    {
      design: 'BFDaybed',
      wood: 'Mahogany',
      slot: { day: '11/5', time: '6', location: 'Manhattan' },
      paymentLink: 'https://buy.stripe.com/aEUcQsc1eaqq7YIbIv',
      size: 'Large',
      price: '$400',
      isAvailable: true,
    },
    {
      design: 'BFDaybed',
      wood: 'Clear Pine',
      slot: { day: '11/6', time: '6', location: 'Manhattan' },
      paymentLink: 'https://buy.stripe.com/dR63fS3uI8ii5QB288',
      size: 'Large',
      price: '$400',
      isAvailable: true,
    },
    {
      design: 'BFDaybed',
      wood: 'Mahogany',
      slot: { day: '11/6', time: '6', location: 'Manhattan' },
      paymentLink: 'https://buy.stripe.com/7sI7w8c1efKK3It7st',
      size: 'Large',
      price: '$400',
      isAvailable: true,
    },
    {
      design: 'BFDaybed',
      wood: 'Clear Pine',
      slot: { day: '11/7', time: '6', location: 'Manhattan' },
      paymentLink: 'https://buy.stripe.com/5kAaIkghuaqq2Ep4gu',
      size: 'Large',
      price: '$400',
      isAvailable: true,
    },
    {
      design: 'BFDaybed',
      wood: 'Mahogany',
      slot: { day: '11/7', time: '6', location: 'Manhattan' },
      paymentLink: 'https://buy.stripe.com/00gg2E3uIdCCbaV8wL',
      size: 'Large',
      price: '$400',
      isAvailable: true,
    },
    // {
    //   design: 'BFDaybed',
    //   wood: 'Clear Pine',
    //   slot: { day: '6/20', time: '6', location: 'Brooklyn' },
    //   paymentLink: 'https://buy.stripe.com/3cs7w8aXagOO1Ak9hW',
    //   size: 'Large',
    //   price: '$400',
    //   isAvailable: true,
    // },
    // {
    //   design: 'BFDaybed',
    //   wood: 'Mahogany',
    //   slot: { day: '6/20', time: '6', location: 'Brooklyn' },
    //   paymentLink: 'https://buy.stripe.com/cN2g2EaXa6aagve3XF',
    //   size: 'Large',
    //   price: '$400',
    //   isAvailable: true,
    // },


    {
      design: 'PetalChair',
      wood: 'Douglas Fir',
      slot: { day: '12/3', time: '6', location: 'Manhattan' },
      paymentLink: 'https://buy.stripe.com/eVa2bO7KY4223ItaFx',
      price: '$350',
      isAvailable: true,
      size: 'Medium',
    },
    {
      design: 'PetalChair',
      wood: 'Douglas Fir',
      slot: { day: '12/4', time: '6', location: 'Manhattan' },
      paymentLink: 'https://buy.stripe.com/14k7w89T68ii0whbJP',
      price: '$350',
      isAvailable: true,
      size: 'Medium',
    },
    {
      design: 'PetalChair',
      wood: 'Douglas Fir',
      slot: { day: '12/5', time: '6', location: 'Manhattan' },
      paymentLink: 'https://buy.stripe.com/fZe4jW1mAeGGfrbaG0',
      price: '$350',
      isAvailable: true,
      size: 'Medium',
    },
    // {
    //   design: 'PetalChair',
    //   wood: 'Douglas Fir',
    //   slot: { day: '6/20', time: '6', location: 'Brooklyn' },
    //   paymentLink: 'https://buy.stripe.com/7sI03G1mAaqq3Is51i',
    //   price: '$350',
    //   isAvailable: true,
    //   size: 'Medium',
    // },
    {
      design: 'PetalChair',
      wood: 'Mahogany',
      slot: { day: '12/3', time: '6', location: 'Manhattan' },
      paymentLink: 'https://buy.stripe.com/fZebMoe9maqq2EpaFy',
      price: '$450',
      isAvailable: true,
      size: 'Medium',
    },
    {
      design: 'PetalChair',
      wood: 'Mahogany',
      slot: { day: '12/4', time: '6', location: 'Manhattan' },
      paymentLink: 'https://buy.stripe.com/eVaaIk6GUcyy3It6pw',
      price: '$450',
      isAvailable: true,
      size: 'Medium',
    },
    {
      design: 'PetalChair',
      wood: 'Mahogany',
      slot: { day: '12/5', time: '6', location: 'Manhattan' },
      paymentLink: 'https://buy.stripe.com/5kA03Ge9m9mma6RdSd',
      price: '$450',
      isAvailable: true,
      size: 'Medium',
    },
    // {
    //   design: 'PetalChair',
    //   wood: 'Mahogany',
    //   slot: { day: '6/20', time: '6', location: 'Brooklyn' },
    //   paymentLink: 'https://buy.stripe.com/fZe6s49T60PQ0wgeBV',
    //   price: '$450',
    //   isAvailable: true,
    //   size: 'Medium',
    // },
    {
      design: 'CP',
      wood: 'Douglas Fir',
      slot: { day: '12/3', time: '6', location: 'Manhattan' },
      paymentLink: 'https://buy.stripe.com/5kAcQsghu1TUdj39Bv',
      price: '$400',
      isAvailable: true,
      size: 'Medium',
    },
    {
      design: 'CP',
      wood: 'Douglas Fir',
      slot: { day: '12/4', time: '6', location: 'Manhattan' },
      paymentLink: 'https://buy.stripe.com/00gbMo4yMdCCdj35lt',
      price: '$400',
      isAvailable: true,
      size: 'Medium',
    },
    {
      design: 'CP',
      wood: 'Douglas Fir',
      slot: { day: '12/5', time: '6', location: 'Manhattan' },
      paymentLink: 'https://buy.stripe.com/cN23fSe9m9mm0wh7tQ',
      price: '$400',
      isAvailable: true,
      size: 'Medium',
    },
    // {
    //   design: 'CP',
    //   wood: 'Douglas Fir',
    //   slot: { day: '6/20', time: '6', location: 'Brooklyn' },
    //   paymentLink: 'https://buy.stripe.com/00g3fS3uIfKKbaU8dA',
    //   price: '$400',
    //   isAvailable: true,
    //   size: 'Medium',
    // },
    {
      design: 'CP',
      wood: 'Mahogany',
      slot: { day: '12/3', time: '6', location: 'Manhattan' },
      paymentLink: 'https://buy.stripe.com/00g4jW2qEcyy1AleVQ',
      price: '$450',
      isAvailable: true,
      size: 'Medium',
    },
    {
      design: 'CP',
      wood: 'Mahogany',
      slot: { day: '12/4', time: '6', location: 'Manhattan' },
      paymentLink: 'https://buy.stripe.com/9AQ3fSaXadCC5QBcNW',
      price: '$450',
      isAvailable: true,
      size: 'Medium',
    },
    {
      design: 'CP',
      wood: 'Mahogany',
      slot: { day: '12/5', time: '6', location: 'Manhattan' },
      paymentLink: 'https://buy.stripe.com/aEUbMoaXacyy7YJg0n',
      price: '$450',
      isAvailable: true,
      size: 'Medium',
    },
    // {
    //   design: 'CP',
    //   wood: 'Mahogany',
    //   slot: { day: '6/20', time: '6', location: 'Brooklyn' },
    //   paymentLink: 'https://buy.stripe.com/14k03G8P25664MwbpP',
    //   price: '$450',
    //   isAvailable: true,
    //   size: 'Medium',
    // },
    {
      design: 'HMBench',
      wood: 'Douglas Fir',
      slot: { day: '12/3', time: '6', location: 'Manhattan' },
      paymentLink: 'https://buy.stripe.com/14k7w80iw0PQgvf00R',
      price: '$300',
      isAvailable: true,
      size: 'Medium',
    },
    {
      design: 'HMBench',
      wood: 'Douglas Fir',
      slot: { day: '12/4', time: '6', location: 'Manhattan' },
      paymentLink: 'https://buy.stripe.com/3cs3fSd5igOOdj3cNR',
      price: '$300',
      isAvailable: true,
      size: 'Medium',
    },
    {
      design: 'HMBench',
      wood: 'Douglas Fir',
      slot: { day: '12/5', time: '6', location: 'Manhattan' },
      paymentLink: 'https://buy.stripe.com/4gw3fS5CQaqqen7aFY',
      price: '$300',
      isAvailable: true,
      size: 'Medium',
    },
    // {
    //   design: 'HMBench',
    //   wood: 'Douglas Fir',
    //   slot: { day: '6/20', time: '6', location: 'Brooklyn' },
    //   paymentLink: 'https://buy.stripe.com/00g7w8aXacyy4Mw1P0',
    //   price: '$300',
    //   isAvailable: true,
    //   size: 'Medium',
    // },
    {
      design: 'HMBench',
      wood: 'Mahogany',
      slot: { day: '12/3', time: '6', location: 'Manhattan' },
      paymentLink: 'https://buy.stripe.com/7sI5o06GUbuua6R3d4',
      price: '$350',
      isAvailable: true,
      size: 'Medium',
    },
    {
      design: 'HMBench',
      wood: 'Mahogany',
      slot: { day: '12/4', time: '6', location: 'Manhattan' },
      paymentLink: 'https://buy.stripe.com/3cscQs7KYaqq92N5lq',
      price: '$350',
      isAvailable: true,
      size: 'Medium',
    },
    {
      design: 'HMBench',
      wood: 'Mahogany',
      slot: { day: '12/5', time: '6', location: 'Manhattan' },
      paymentLink: 'https://buy.stripe.com/6oE9Eg3uI9mmen7bK3',
      price: '$350',
      isAvailable: true,
      size: 'Medium',
    },
    // {
    //   design: 'HMBench',
    //   wood: 'Mahogany',
    //   slot: { day: '6/20', time: '6', location: 'Brooklyn' },
    //   paymentLink: 'https://buy.stripe.com/bIYdUw6GU9mmbaUfFT',
    //   price: '$350',
    //   isAvailable: true,
    //   size: 'Medium',
    // },
    {
      design: 'BFDaybed',
      wood: 'Clear Pine',
      slot: { day: '12/3', time: '6', location: 'Manhattan' },
      paymentLink: 'https://buy.stripe.com/3cs5o0ghu422frbdRP',
      size: 'Small',
      price: '$200',
      isAvailable: true,
    },
    {
      design: 'BFDaybed',
      wood: 'Mahogany',
      slot: { day: '12/3', time: '6', location: 'Manhattan' },
      paymentLink: 'https://buy.stripe.com/5kAeYA8P2gOO3ItaFE',
      size: 'Small',
      price: '$200',
      isAvailable: true,
    },
    {
      design: 'BFDaybed',
      wood: 'Clear Pine',
      slot: { day: '12/4', time: '6', location: 'Manhattan' },
      paymentLink: 'https://buy.stripe.com/00g17Kd5igOO92NbJV',
      size: 'Small',
      price: '$200',
      isAvailable: true,
    },
    {
      design: 'BFDaybed',
      wood: 'Mahogany',
      slot: { day: '12/4', time: '6', location: 'Manhattan' },
      paymentLink: 'https://buy.stripe.com/4gwcQsfdqgOOgvfdS4',
      size: 'Small',
      price: '$200',
      isAvailable: true,
    },
    {
      design: 'BFDaybed',
      wood: 'Clear Pine',
      slot: { day: '12/5', time: '6', location: 'Manhattan' },
      paymentLink: 'https://buy.stripe.com/7sI03G6GUbuu6UFbKa',
      size: 'Small',
      price: '$200',
      isAvailable: true,
    },
    {
      design: 'BFDaybed',
      wood: 'Mahogany',
      slot: { day: '12/5', time: '6', location: 'Manhattan' },
      paymentLink: 'https://buy.stripe.com/14k8Ac8P2dCCgvf15x',
      size: 'Small',
      price: '$200',
      isAvailable: true,
    },
    // {
    //   design: 'BFDaybed',
    //   wood: 'Clear Pine',
    //   slot: { day: '6/20', time: '6', location: 'Brooklyn' },
    //   paymentLink: 'https://buy.stripe.com/5kAbMo9T68ii7YIeC4',
    //   size: 'Small',
    //   price: '$200',
    //   isAvailable: true,
    // },
    // {
    //   design: 'BFDaybed',
    //   wood: 'Mahogany',
    //   slot: { day: '6/20', time: '6', location: 'Brooklyn' },
    //   paymentLink: 'https://buy.stripe.com/6oEg2E8P2aqqdj28dJ',
    //   size: 'Small',
    //   price: '$200',
    //   isAvailable: true,
    // },
    {
      design: 'BFDaybed',
      wood: 'Clear Pine',
      slot: { day: '12/3', time: '6', location: 'Manhattan' },
      paymentLink: 'https://buy.stripe.com/9AQ3fS7KYcyyen7011',
      size: 'Medium',
      price: '$300',
      isAvailable: true,
    },
    {
      design: 'BFDaybed',
      wood: 'Mahogany',
      slot: { day: '12/3', time: '6', location: 'Manhattan' },
      paymentLink: 'https://buy.stripe.com/14k5o06GU6aaen73de',
      size: 'Medium',
      price: '$300',
      isAvailable: true,
    },
    {
      design: 'BFDaybed',
      wood: 'Clear Pine',
      slot: { day: '12/4', time: '6', location: 'Manhattan' },
      paymentLink: 'https://buy.stripe.com/28o4jW0iwfKKceZaFT',
      size: 'Medium',
      price: '$300',
      isAvailable: true,
    },
    {
      design: 'BFDaybed',
      wood: 'Mahogany',
      slot: { day: '12/4', time: '6', location: 'Manhattan' },
      paymentLink: 'https://buy.stripe.com/5kA5o02qE5663It6pE',
      size: 'Medium',
      price: '$300',
      isAvailable: true,
    },
    {
      design: 'BFDaybed',
      wood: 'Clear Pine',
      slot: { day: '12/5', time: '6', location: 'Manhattan' },
      paymentLink: 'https://buy.stripe.com/cN203Gghubuu4MxdSk',
      size: 'Medium',
      price: '$300',
      isAvailable: true,
    },
    {
      design: 'BFDaybed',
      wood: 'Mahogany',
      slot: { day: '12/5', time: '6', location: 'Manhattan' },
      paymentLink: 'https://buy.stripe.com/9AQ5o0e9meGGen7eWp',
      size: 'Medium',
      price: '$300',
      isAvailable: true,
    },
    // {
    //   design: 'BFDaybed',
    //   wood: 'Clear Pine',
    //   slot: { day: '6/20', time: '6', location: 'Brooklyn' },
    //   paymentLink: 'https://buy.stripe.com/9AQeYA6GUdCC2Eody6',
    //   size: 'Medium',
    //   price: '$300',
    //   isAvailable: true,
    // },
    // {
    //   design: 'BFDaybed',
    //   wood: 'Mahogany',
    //   slot: { day: '6/20', time: '6', location: 'Brooklyn' },
    //   paymentLink: 'https://buy.stripe.com/4gw03G9T64226UE1Pr',
    //   size: 'Medium',
    //   price: '$300',
    //   isAvailable: true,
    // },
    {
      design: 'BFDaybed',
      wood: 'Clear Pine',
      slot: { day: '12/3', time: '6', location: 'Manhattan' },
      paymentLink: 'https://buy.stripe.com/aEU03G2qEeGG0wh013',
      size: 'Large',
      price: '$400',
      isAvailable: true,
    },
    {
      design: 'BFDaybed',
      wood: 'Mahogany',
      slot: { day: '12/3', time: '6', location: 'Manhattan' },
      paymentLink: 'https://buy.stripe.com/28odUwfdq7ee4Mx8xA',
      size: 'Large',
      price: '$400',
      isAvailable: true,
    },
    {
      design: 'BFDaybed',
      wood: 'Clear Pine',
      slot: { day: '12/4', time: '6', location: 'Manhattan' },
      paymentLink: 'https://buy.stripe.com/9AQ03G2qE422a6R01h',
      size: 'Large',
      price: '$400',
      isAvailable: true,
    },
    {
      design: 'BFDaybed',
      wood: 'Mahogany',
      slot: { day: '12/4', time: '6', location: 'Manhattan' },
      paymentLink: 'https://buy.stripe.com/bIYbMo7KY8ii92N5lC',
      size: 'Large',
      price: '$400',
      isAvailable: true,
    },
    {
      design: 'BFDaybed',
      wood: 'Clear Pine',
      slot: { day: '12/5', time: '6', location: 'Manhattan' },
      paymentLink: 'https://buy.stripe.com/cN24jWghu0PQ5QB4hM',
      size: 'Large',
      price: '$400',
      isAvailable: true,
    },
    {
      design: 'BFDaybed',
      wood: 'Mahogany',
      slot: { day: '12/5', time: '6', location: 'Manhattan' },
      paymentLink: 'https://buy.stripe.com/8wMg2E8P28iia6R29F',
      size: 'Large',
      price: '$400',
      isAvailable: true,
    },
    // {
    //   design: 'BFDaybed',
    //   wood: 'Clear Pine',
    //   slot: { day: '6/20', time: '6', location: 'Brooklyn' },
    //   paymentLink: 'https://buy.stripe.com/3cs7w8aXagOO1Ak9hW',
    //   size: 'Large',
    //   price: '$400',
    //   isAvailable: true,
    // },
    // {
    //   design: 'BFDaybed',
    //   wood: 'Mahogany',
    //   slot: { day: '6/20', time: '6', location: 'Brooklyn' },
    //   paymentLink: 'https://buy.stripe.com/cN2g2EaXa6aagve3XF',
    //   size: 'Large',
    //   price: '$400',
    //   isAvailable: true,
    // },


    // {
    //   design: 'ArchDesk',
    //   wood: 'Douglas Fir',
    //   slot: { day: '3/12', time: '6' },
    //   paymentLink: 'https://buy.stripe.com/00g5o0d5iaqqfragC0',
    //   price: '$950',
    //   isAvailable: true,
    //   size: 'Medium',
    // },
    // {
    //   design: 'ArchDesk',
    //   wood: 'Douglas Fir',
    //   slot: { day: '3/13', time: '6' },
    //   paymentLink: 'https://buy.stripe.com/aEU5o0aXaaqqfra3Pf',
    //   price: '$950',
    //   isAvailable: true,
    //   size: 'Medium',
    // },
    // {
    //   design: 'ArchDesk',
    //   wood: 'Douglas Fir',
    //   slot: { day: '2/15', time: '6' },
    //   paymentLink: 'https://buy.stripe.com/4gwdUw6GU6aa92Mdlh',
    //   price: '$950',
    //   isAvailable: true,
    //   size: 'Medium',
    // },
    // {
    //   design: 'ArchDesk',
    //   wood: 'Mahogany',
    //   slot: { day: '3/12', time: '6' },
    //   paymentLink: 'https://buy.stripe.com/fZeg2Efdq8iien699A',
    //   price: '$1400',
    //   isAvailable: true,
    //   size: 'Medium',
    // },
    // {
    //   design: 'ArchDesk',
    //   wood: 'Mahogany',
    //   slot: { day: '3/13', time: '6' },
    //   paymentLink: 'https://buy.stripe.com/4gw3fSaXa6aagve99B',
    //   price: '$1400',
    //   isAvailable: true,
    //   size: 'Medium',
    // },
    // {
    //   design: 'ArchDesk',
    //   wood: 'Mahogany',
    //   slot: { day: '2/15', time: '6' },
    //   paymentLink: 'https://buy.stripe.com/28obMo9T64224Mwfts',
    //   price: '$1400',
    //   isAvailable: true,
    //   size: 'Medium',
    // },
    // {
    //   design: 'Judd',
    //   wood: 'Douglas Fir',
    //   slot: { day: '2/13', time: '6' },
    //   paymentLink: 'https://buy.stripe.com/4gw3fS6GU8iibaUbcl',
    //   price: '$350',
    //   isAvailable: true,
    //   size: 'Medium',
    // },
    // {
    //   design: 'Judd',
    //   wood: 'Douglas Fir',
    //   slot: { day: '2/14', time: '6' },
    //   paymentLink: 'https://buy.stripe.com/cN27w8ghubuua6Q5S2',
    //   price: '$350',
    //   isAvailable: true,
    //   size: 'Medium',
    // },
    // {
    //   design: 'Judd',
    //   wood: 'Douglas Fir',
    //   slot: { day: '2/15', time: '6' },
    //   paymentLink: 'https://buy.stripe.com/8wM6s4aXa2XY2Eobcn',
    //   price: '$350',
    //   isAvailable: true,
    //   size: 'Medium',
    // },
    // {
    //   design: 'Judd',
    //   wood: 'Mahogany',
    //   slot: { day: '2/13', time: '6' },
    //   paymentLink: 'https://buy.stripe.com/eVabMoghu6aa5QA1BO',
    //   price: '$400',
    //   isAvailable: true,
    //   size: 'Medium',
    // },
    // {
    //   design: 'Judd',
    //   wood: 'Mahogany',
    //   slot: { day: '2/14', time: '6' },
    //   paymentLink: 'https://buy.stripe.com/6oE5o0c1e0PQdj2eoB',
    //   price: '$400',
    //   isAvailable: true,
    //   size: 'Medium',
    // },
    // {
    //   design: 'Judd',
    //   wood: 'Mahogany',
    //   slot: { day: '2/15', time: '6' },
    //   paymentLink: 'https://buy.stripe.com/fZe6s4d5i2XY7YI0xM',
    //   price: '$400',
    //   isAvailable: true,
    //   size: 'Medium',
    // },
    // {
    //   design: 'ArchDesk',
    //   wood: 'Douglas Fir',
    //   slot: { day: '2/13', time: '6' },
    //   paymentLink: 'https://buy.stripe.com/fZebMo8P28iifraa93',
    //   price: '$950',
    //   isAvailable: true,
    //   size: 'Medium',
    // },
    // {
    //   design: 'ArchDesk',
    //   wood: 'Douglas Fir',
    //   slot: { day: '2/14', time: '6' },
    //   paymentLink: 'https://buy.stripe.com/cN28Acd5ibuu4Mw5SO',
    //   price: '$950',
    //   isAvailable: true,
    //   size: 'Medium',
    // },
    // {
    //   design: 'ArchDesk',
    //   wood: 'Douglas Fir',
    //   slot: { day: '2/15', time: '6' },
    //   paymentLink: 'https://buy.stripe.com/4gwdUw6GU6aa92Mdlh',
    //   price: '$950',
    //   isAvailable: true,
    //   size: 'Medium',
    // },
    // {
    //   design: 'ArchDesk',
    //   wood: 'Mahogany',
    //   slot: { day: '2/13', time: '6' },
    //   paymentLink: 'https://buy.stripe.com/3cs2bO7KY9mm2Eo4OM',
    //   price: '$1400',
    //   isAvailable: true,
    //   size: 'Medium',
    // },
    // {
    //   design: 'ArchDesk',
    //   wood: 'Mahogany',
    //   slot: { day: '2/14', time: '6' },
    //   paymentLink: 'https://buy.stripe.com/dR62bO4yM7ee5QA0yx',
    //   price: '$1400',
    //   isAvailable: true,
    //   size: 'Medium',
    // },
    // {
    //   design: 'ArchDesk',
    //   wood: 'Mahogany',
    //   slot: { day: '2/15', time: '6' },
    //   paymentLink: 'https://buy.stripe.com/28obMo9T64224Mwfts',
    //   price: '$1400',
    //   isAvailable: true,
    //   size: 'Medium',
    // },

  ];

  // const JuddInfo = () => <View style={{ flex: 1 }} />;
  const CPInfo = () => <View style={{ flex: 1 }} />;
  const HMBenchInfo = () => <View style={{ flex: 1 }} />;

  interface TimeSlotProps {
    day: Day;
    time: Time;
    slot: Slot;
    location?: Location;
    isAvailable?: boolean;
  }

  const TimeSlot = ({ day, time, slot, location, isAvailable = true }: TimeSlotProps) => (
    <TouchableOpacity
      disabled={!isAvailable || containsObject(slot, soldOutTimeSlots) || slot.location !== selectedLocation}
      style={{ margin: 5, maxWidth: '100%' }}
      onPress={() => {
        setSelectedDay(day);
        setSelectedTime(time);
      }}
    >
      <View
        style={[
          styles.pushButton,
          styles.timeSlot,
          {
            opacity: isAvailable === false ? 0.5 : 1,
            backgroundColor:
              selectedDay === day && selectedTime === time
                ? theme.foregroundColor
                : theme.backgroundColor,
          },
        ]}
      >
        <P
          style={[
            styles.text,
            styles.body,
            {
              color:
                selectedDay === day && selectedTime === time
                  ? theme.backgroundColor
                  : theme.foregroundColor,
              textAlign: 'center',
            },
          ]}
        >
          {containsObject(slot, soldOutTimeSlots) || isAvailable === false
            ? 'Sold Out'
            : `${time}:00 in ${slot.location}`}
          {/* {!isAvailable} */}
        </P>
      </View>
    </TouchableOpacity>
  );

  return (
    <>
      <ScrollView
        style={{
          flex: 1,
          backgroundColor: theme.backgroundColor,
        }}
      >
        <CuratorHeader />
        <View style={{ height: width < mdbreakpoint ? 20 : 40 }} />
        <View
          style={{
            justifyContent: 'center',
            width: 700,
            maxWidth: '90vw',
            alignSelf: 'center',
          }}
        >
          <H1 style={[styles.header, { marginBottom: 0 }]}>
            "Build-a-Chair" Workshop
          </H1>
          <P
            style={[
              styles.text,
              styles.body,
              {
                alignSelf: 'center',
                marginTop: 0,
                letterSpacing: 1.2,
                marginBottom: 0,
                // width: 280,
                textAlign: 'center',
              },
            ]}
          >
            November 5th, 6th, and 7th{'\n'}
            December 3rd, 4th, and 5th
          </P>
          <Text
            style={[
              styles.text,
              styles.body,
              {
                alignSelf: 'center',
                textAlign: 'center',
                textTransform: 'uppercase',
                marginTop: 0,
                letterSpacing: 1,
                fontSize: 10,
              },
            ]}
          >
            49 Market St, NY, NY 10002
          </Text>
          {/* <P style={[styles.text, styles.body]}>
            Back soon with info and tickets! Leave your number below to be
            the first to know when info and tickets are released :)
          </P>
          <View style={{ alignSelf: 'center' }}>
            <PhoneNumberSignupForm />
          </View> */}
          <View style={{ height: 20 }} />

          <P style={[styles.text, styles.body]}>
            In this workshop, you will assemble and customize your very own piece
            of furniture.
          </P>
          <P style={[styles.text, styles.body]}>
            Choose between a bench, stool, chair, or pet day bed offered in both a light and dark wood.
          </P>
          <P style={[styles.text, styles.body]}>
            Assembly and finishing takes ~2 hours and your new piece can be taken home the same day. Each ticket admits up to two people. No experience necessary.
          </P>
          <P style={[styles.text, styles.body]}>
            To reserve your spot, please select your design, wood type, location, and time slot. See ya soon :)
          </P>
          <P style={[styles.text, styles.body, { fontStyle: 'italic', fontSize: 14 }]}>
            **Please note, our tickets are non-refundable. You must notify us at reservations@happy-medium.co at least 72 hours before your time slot in order to be eligible to reschedule. Otherwise, we will build your item for you and you can pick it up 1 week after the event.**
          </P>
          <H2 style={[styles.header, styles.h2, { marginBottom: 0 }]}>Step 1</H2>
          <P
            style={[
              styles.text,
              styles.body,
              { alignSelf: 'center', marginTop: 0, letterSpacing: 1.2 },
            ]}
          >
            Choose your design
          </P>
          <View
            style={{
              flexDirection: width < 800 ? 'column' : 'row',
              justifyContent: 'space-around',
              width: '98vw',
              alignContent: 'center',
              flexWrap: 'wrap',
              alignSelf: 'center',
            }}
          >
            <TouchableOpacity style={{ alignSelf: 'center' }} onPress={() => setSelectedDesign('HMBench')}>
              <View
                style={[styles.pushButton, styles.item, {
                  backgroundColor:
                    selectedDesign === 'HMBench'
                      ? theme.foregroundColor
                      : theme.backgroundColor,
                }]}
              >
                <P
                  style={[
                    styles.text,
                    styles.body,
                    {
                      textAlign: 'center',
                      color:
                        selectedDesign === 'HMBench'
                          ? theme.backgroundColor
                          : theme.foregroundColor,
                    },
                  ]}
                >
                  Happy Medium Bench
                </P>
              </View>
            </TouchableOpacity>
            <TouchableOpacity style={{ alignSelf: 'center' }} onPress={() => setSelectedDesign('BFDaybed')}>
              <View
                style={[styles.pushButton, styles.item, {
                  backgroundColor:
                    selectedDesign === 'BFDaybed'
                      ? theme.foregroundColor
                      : theme.backgroundColor,
                }]}
              >
                <P
                  style={[
                    styles.text,
                    styles.body,
                    {
                      textAlign: 'center',
                      color:
                        selectedDesign === 'BFDaybed'
                          ? theme.backgroundColor
                          : theme.foregroundColor,
                    },
                  ]}
                >
                  Best Friend Daybed
                </P>
              </View>
            </TouchableOpacity>

            <TouchableOpacity style={{ alignSelf: 'center' }} onPress={() => setSelectedDesign('PetalChair')}>
              <View
                style={[styles.pushButton, styles.item, {
                  backgroundColor:
                    selectedDesign === 'PetalChair'
                      ? theme.foregroundColor
                      : theme.backgroundColor,
                }]}
              >
                <P
                  style={[
                    styles.text,
                    styles.body,
                    {
                      color:
                        selectedDesign === 'PetalChair'
                          ? theme.backgroundColor
                          : theme.foregroundColor,
                      textAlign: 'center'
                    },
                  ]}
                >
                  Petal Chair
                </P>
              </View>
            </TouchableOpacity>
            {"\n"}
            <TouchableOpacity style={{ alignSelf: 'center' }} onPress={() => setSelectedDesign('CP')}>
              <View
                style={[styles.pushButton, styles.item, {
                  backgroundColor:
                    selectedDesign === 'CP'
                      ? theme.foregroundColor
                      : theme.backgroundColor,
                }]}
              >
                <P
                  style={[
                    styles.text,
                    styles.body,
                    {
                      textAlign: 'center',
                      color:
                        selectedDesign === 'CP'
                          ? theme.backgroundColor
                          : theme.foregroundColor,
                    },
                  ]}
                >
                  Charlotte Stool
                </P>
              </View>
            </TouchableOpacity>
          </View>
        </View>
        <View style={{ height: 20 }} />

        <ImageCarousel assets={data.filter(i => i.product === selectedDesign)?.flatMap(i => i.assets)} />

        <View
          style={{
            justifyContent: 'center',
            width: 700,
            maxWidth: '90vw',
            alignSelf: 'center',
          }}
        >
          <H1 style={[styles.header, { alignSelf: 'center', marginBottom: 0 }]}>
            {data.find((i) => i.product === selectedDesign)?.name}
          </H1>
          <P style={[styles.text, styles.body]}>{data.find((i) => i.product === selectedDesign)?.description}</P>
          <H2 style={[styles.header, styles.h2, { marginBottom: 0 }]}>Step 2</H2>
          <P
            style={[
              styles.text,
              styles.body,
              { alignSelf: 'center', marginTop: 0, letterSpacing: 1.2 },
            ]}
          >
            Choose your wood
          </P>
          <View style={{ flexDirection: 'row', width: '100%', justifyContent: 'center' }}>
            {selectedDesign === 'BFDaybed' ?
              <TouchableOpacity style={{ margin: 5 }} onPress={() => setSelectedWood('Clear Pine')}>
                <View
                  style={{
                    borderWidth: 1,
                    borderColor: theme.foregroundColor,
                    backgroundColor:
                      selectedWood === 'Clear Pine'
                        ? theme.foregroundColor
                        : theme.backgroundColor,
                    borderRadius: 5,
                    padding: 10,
                    width: 140
                  }}
                >
                  <P
                    style={[
                      styles.text,
                      styles.body,
                      {
                        color:
                          selectedWood === 'Clear Pine'
                            ? theme.backgroundColor
                            : theme.foregroundColor,
                        textAlign: 'center'
                      },
                    ]}
                  >
                    Clear Pine{'\n'}
                    <Text style={[styles.text, styles.body, {
                      fontSize: 12, color:
                        selectedWood === 'Clear Pine'
                          ? theme.backgroundColor
                          : theme.foregroundColor,
                    }]}>(light)</Text>
                  </P>
                </View>
              </TouchableOpacity>

              : <TouchableOpacity style={{ margin: 5 }} onPress={() => setSelectedWood('Douglas Fir')}>
                <View
                  style={{
                    borderWidth: 1,
                    borderColor: theme.foregroundColor,
                    backgroundColor:
                      selectedWood === 'Douglas Fir'
                        ? theme.foregroundColor
                        : theme.backgroundColor,
                    borderRadius: 5,
                    padding: 10,
                    width: 140
                  }}
                >
                  <P
                    style={[
                      styles.text,
                      styles.body,
                      {
                        color:
                          selectedWood === 'Douglas Fir'
                            ? theme.backgroundColor
                            : theme.foregroundColor,
                        textAlign: 'center'
                      },
                    ]}
                  >
                    Douglas Fir{'\n'}
                    <Text style={[styles.text, styles.body, {
                      fontSize: 12, color:
                        selectedWood === 'Douglas Fir'
                          ? theme.backgroundColor
                          : theme.foregroundColor,
                    }]}>(light)</Text>
                  </P>
                </View>
              </TouchableOpacity>}
            <TouchableOpacity style={{ margin: 5 }} onPress={() => setSelectedWood('Mahogany')}>
              <View
                style={{
                  borderWidth: 1,
                  borderColor: theme.foregroundColor,
                  backgroundColor:
                    selectedWood === 'Mahogany'
                      ? theme.foregroundColor
                      : theme.backgroundColor,
                  borderRadius: 5,
                  padding: 10,
                  width: 140
                }}
              >
                <P
                  style={[
                    styles.text,
                    styles.body,
                    {
                      color:
                        selectedWood === 'Mahogany'
                          ? theme.backgroundColor
                          : theme.foregroundColor,
                      textAlign: 'center'
                    },
                  ]}
                >
                  Mahogany{'\n'}
                  <Text style={[styles.text, styles.body, {
                    fontSize: 12, color:
                      selectedWood === 'Mahogany'
                        ? theme.backgroundColor
                        : theme.foregroundColor,
                  }]}>(dark)</Text>
                </P>
              </View>
            </TouchableOpacity>
          </View>


          <H2 style={[styles.header, styles.h2, { marginBottom: 0 }]}>Step 3</H2>
          <P
            style={[
              styles.text,
              styles.body,
              { alignSelf: 'center', marginTop: 0, letterSpacing: 1.2 },
            ]}
          >
            Choose your location
          </P>
          <View style={{ flexDirection: 'row', width: '100%', justifyContent: 'center' }}>
            <TouchableOpacity style={{ margin: 5 }} onPress={() => {
              setSelectedLocation('Manhattan')
              setSelectedDay('11/5')
            }}>
              <View
                style={{
                  borderWidth: 1,
                  borderColor: theme.foregroundColor,
                  backgroundColor:
                    selectedLocation === 'Manhattan'
                      ? theme.foregroundColor
                      : theme.backgroundColor,
                  borderRadius: 5,
                  padding: 10,
                  width: 140
                }}
              >
                <P
                  style={[
                    styles.text,
                    styles.body,
                    {
                      color:
                        selectedLocation === 'Manhattan'
                          ? theme.backgroundColor
                          : theme.foregroundColor,
                      textAlign: 'center'
                    },
                  ]}
                >
                  Manhattan{'\n'}
                  {/* <Text style={[styles.text, styles.body, {
                      fontSize: 12, color:
                        selectedLocation === 'Manhattan'
                          ? theme.backgroundColor
                          : theme.foregroundColor,
                    }]}>(light)</Text> */}
                </P>
              </View>
            </TouchableOpacity>
            {/* <TouchableOpacity style={{ margin: 5 }} onPress={() => {
              setSelectedLocation('Brooklyn')
              setSelectedDay('8/20')
            }}>
              <View
                style={{
                  borderWidth: 1,
                  borderColor: theme.foregroundColor,
                  backgroundColor:
                    selectedLocation === 'Brooklyn'
                      ? theme.foregroundColor
                      : theme.backgroundColor,
                  borderRadius: 5,
                  padding: 10,
                  width: 140
                }}
              >
                <P
                  style={[
                    styles.text,
                    styles.body,
                    {
                      color:
                        selectedLocation === 'Brooklyn'
                          ? theme.backgroundColor
                          : theme.foregroundColor,
                      textAlign: 'center'
                    },
                  ]}
                >
                  Brooklyn{'\n'}
                </P>
              </View>
            </TouchableOpacity> */}
          </View>
          {selectedDesign === 'BFDaybed' &&
            <>
              <View style={{ height: 60 }} />
              <P
                style={[
                  styles.text,
                  styles.body,
                  { alignSelf: 'center', marginTop: 0, letterSpacing: 1.2 },
                ]}
              >
                Choose your size
              </P>
              <View style={{ flexDirection: 'row', width: '100%', justifyContent: 'center' }}>
                <TouchableOpacity style={{ margin: 5 }} onPress={() => setSelectedSize('Small')}>
                  <View
                    style={{
                      borderWidth: 1,
                      borderColor: theme.foregroundColor,
                      backgroundColor:
                        selectedSize === 'Small'
                          ? theme.foregroundColor
                          : theme.backgroundColor,
                      borderRadius: 5,
                      padding: 10,
                      width: 140
                    }}
                  >
                    <P
                      style={[
                        styles.text,
                        styles.body,
                        {
                          color:
                            selectedSize === 'Small'
                              ? theme.backgroundColor
                              : theme.foregroundColor,
                          textAlign: 'center'
                        },
                      ]}
                    >
                      Small
                    </P>
                  </View>
                </TouchableOpacity>
                <TouchableOpacity style={{ margin: 5 }} onPress={() => setSelectedSize('Medium')}>
                  <View
                    style={{
                      borderWidth: 1,
                      borderColor: theme.foregroundColor,
                      backgroundColor:
                        selectedSize === 'Medium'
                          ? theme.foregroundColor
                          : theme.backgroundColor,
                      borderRadius: 5,
                      padding: 10,
                      width: 140
                    }}
                  >
                    <P
                      style={[
                        styles.text,
                        styles.body,
                        {
                          color:
                            selectedSize === 'Medium'
                              ? theme.backgroundColor
                              : theme.foregroundColor,
                          textAlign: 'center'
                        },
                      ]}
                    >
                      Medium
                    </P>
                  </View>
                </TouchableOpacity>
                <TouchableOpacity style={{ margin: 5 }} onPress={() => setSelectedSize('Large')}>
                  <View
                    style={{
                      borderWidth: 1,
                      borderColor: theme.foregroundColor,
                      backgroundColor:
                        selectedSize === 'Large'
                          ? theme.foregroundColor
                          : theme.backgroundColor,
                      borderRadius: 5,
                      padding: 10,
                      width: 140
                    }}
                  >
                    <P
                      style={[
                        styles.text,
                        styles.body,
                        {
                          color:
                            selectedSize === 'Large'
                              ? theme.backgroundColor
                              : theme.foregroundColor,
                          textAlign: 'center'
                        },
                      ]}
                    >
                      Large
                    </P>
                  </View>
                </TouchableOpacity>
              </View>
            </>
          }
          <View style={{ height: 60 }} />
          <H2 style={[styles.header, styles.h2, { marginBottom: 0 }]}>Step 3</H2>
          <P
            style={[
              styles.text,
              styles.body,
              { textAlign: 'center', alignSelf: 'center', marginTop: 0, letterSpacing: 1.2 },
            ]}
          >
            Choose your day and time{'\n'}
            <Text style={[styles.text, styles.body, { fontSize: 12 }]}>(each experience takes ~2 hours)</Text>
          </P>

          <View style={{ flexDirection: width < lgBreakpoint ? 'column' : 'row', width: '98vw', justifyContent: 'center', alignSelf: 'center', flexWrap: 'wrap', alignContent: 'center' }}>
            <View style={{ flexDirection: 'column', maxWidth: '85%', alignItems: 'center' }}>
              <H3 style={[styles.header, styles.h3, { margin: 20, marginBottom: 0 }]} >Tuesday 11/5</H3>
              {paymentLinks.filter(l => l.design === selectedDesign && l.wood === selectedWood && l.size === selectedSize && l.slot.day === '11/5').map(l => {
                return <TimeSlot key={`${l.slot.day}-${l.slot.time}-${l.design}-${l.wood}`} slot={l.slot} day={l.slot.day} time={l.slot.time} isAvailable={l.isAvailable} location={l.slot.location} />
              })}
            </View>
            <View style={{ flexDirection: 'column', maxWidth: '85%', alignItems: 'center' }}>
              <H3 style={[styles.header, styles.h3, { margin: 20, marginBottom: 0 }]} >Wednesday 11/6</H3>
              {paymentLinks.filter(l => l.design === selectedDesign && l.wood === selectedWood && l.size === selectedSize && l.slot.day === '11/6').map(l => {
                return <TimeSlot key={`${l.slot.day}-${l.slot.time}-${l.design}-${l.wood}`} slot={l.slot} day={l.slot.day} time={l.slot.time} isAvailable={l.isAvailable} location={l.slot.location} />
              })}
            </View>
            <View style={{ flexDirection: 'column', maxWidth: '85%', alignItems: 'center' }}>
              <H3 style={[styles.header, styles.h3, { margin: 20, marginBottom: 0 }]} >Thursday 11/7</H3>
              {paymentLinks.filter(l => l.design === selectedDesign && l.wood === selectedWood && l.size === selectedSize && l.slot.day === '11/7').map(l => {
                return <TimeSlot key={`${l.slot.day}-${l.slot.time}-${l.design}-${l.wood}`} slot={l.slot} day={l.slot.day} time={l.slot.time} isAvailable={l.isAvailable} location={l.slot.location} />
              })}
            </View>
          </View>

          <View style={{ flexDirection: width < lgBreakpoint ? 'column' : 'row', width: '98vw', justifyContent: 'center', alignSelf: 'center', flexWrap: 'wrap', alignContent: 'center' }}>
            <View style={{ flexDirection: 'column', maxWidth: '85%', alignItems: 'center' }}>
              <H3 style={[styles.header, styles.h3, { margin: 20, marginBottom: 0 }]} >Tuesday 12/3</H3>
              {paymentLinks.filter(l => l.design === selectedDesign && l.wood === selectedWood && l.size === selectedSize && l.slot.day === '12/3').map(l => {
                return <TimeSlot key={`${l.slot.day}-${l.slot.time}-${l.design}-${l.wood}`} slot={l.slot} day={l.slot.day} time={l.slot.time} isAvailable={l.isAvailable} location={l.slot.location} />
              })}
            </View>
            <View style={{ flexDirection: 'column', maxWidth: '85%', alignItems: 'center' }}>
              <H3 style={[styles.header, styles.h3, { margin: 20, marginBottom: 0 }]} >Wednesday 12/4</H3>
              {paymentLinks.filter(l => l.design === selectedDesign && l.wood === selectedWood && l.size === selectedSize && l.slot.day === '12/4').map(l => {
                return <TimeSlot key={`${l.slot.day}-${l.slot.time}-${l.design}-${l.wood}`} slot={l.slot} day={l.slot.day} time={l.slot.time} isAvailable={l.isAvailable} location={l.slot.location} />
              })}
            </View>
            <View style={{ flexDirection: 'column', maxWidth: '85%', alignItems: 'center' }}>
              <H3 style={[styles.header, styles.h3, { margin: 20, marginBottom: 0 }]} >Thursday 12/5</H3>
              {paymentLinks.filter(l => l.design === selectedDesign && l.wood === selectedWood && l.size === selectedSize && l.slot.day === '12/5').map(l => {
                return <TimeSlot key={`${l.slot.day}-${l.slot.time}-${l.design}-${l.wood}`} slot={l.slot} day={l.slot.day} time={l.slot.time} isAvailable={l.isAvailable} location={l.slot.location} />
              })}
            </View>
          </View>


          <View style={{ height: 40 }} />

        </View>

        <View style={{ height: 60 }} />
      </ScrollView>

      <TouchableOpacity disabled={false} onPress={() => window.open(paymentLinks.find(l => l.design === selectedDesign && l.wood === selectedWood && l.slot.day === selectedDay && l.size === selectedSize && l.slot.time === selectedTime)?.paymentLink, '__blank')}>
        <View style={{ position: 'absolute', bottom: 0, right: width < mdbreakpoint ? undefined : 0, backgroundColor: theme.foregroundColor, borderRadius: 50, flexDirection: 'row', justifyContent: 'space-between', padding: 15, paddingHorizontal: 60, margin: 20, width: 320, alignSelf: 'center', borderWidth: 1, borderColor: theme.backgroundColor }}>
          {/* <Text style={[styles.text, styles.h3, { color: theme.backgroundColor, textTransform: 'uppercase', fontFamily: GTAmericaCompressed, letterSpacing: 1 }]}>Sold out</Text> */}

          <Text style={[styles.text, styles.h3, { color: theme.backgroundColor, textTransform: 'uppercase', fontFamily: GTAmericaCompressed, letterSpacing: 1 }]}>Checkout</Text>
          <Text style={[styles.text, styles.h3, { color: theme.backgroundColor, textTransform: 'uppercase', fontFamily: GTAmericaCompressed, letterSpacing: 1 }]}>{paymentLinks.find(l => l.design === selectedDesign && l.wood === selectedWood && l.slot.day === selectedDay && l.size === selectedSize && l.slot.time === selectedTime)?.price}</Text>
        </View>
      </TouchableOpacity>
    </>
  );
};
