import React from 'react';
import { View, Image, Text, StyleSheet, Linking } from 'react-native';

import { IssuePreview } from '../../data/SalonIssues';
import { TouchableOpacity } from '../common/TouchableOpacity';
import {
  greenBrand,
  PanamaBold,
  GTAmerica,
  whiteBrand,
  flax,
} from '../../libs/constants';
import { useDimensions } from '../../context/DimensionsContext';
import { smBreakpoint, mdBreakpoint } from '../../constants';
import { Hoverable } from 'react-native-web-hooks';

interface Props {
  issuePreview: IssuePreview;
}

export const SalonIssuePreview: React.FC<Props> = ({ issuePreview }) => {
  const rightArrow = require('@happymedium/components/assets/images/arrow-right-green@300x.png');
  const { width } = useDimensions();
  const {
    issueNumber,
    previewImageUrl,
    title,
    lede,
    publishedDate,
  } = issuePreview;
  const styles = StyleSheet.create({
    container: {
      flex: 1,
      flexDirection: width <= mdBreakpoint ? 'column' : 'row',
      justifyContent: 'center',
      alignItems: 'center',
      alignContent: 'center',
      marginVertical: 30,
    },
    previewImage: {
      width: width < smBreakpoint ? 310 : 533,
      height: width < smBreakpoint ? 210 : 400,
      resizeMode: 'contain',
    },
    text: {
      color: greenBrand,
      textAlign: width <= mdBreakpoint ? 'center' : 'left',
    },
    issueTitle: {
      fontFamily: PanamaBold,
      fontSize: width < smBreakpoint ? 22 : width < mdBreakpoint ? 24 : 28,
      letterSpacing: 1.3,
    },
    publishedDate: {
      fontFamily: GTAmerica,
      fontSize: width < smBreakpoint ? 10 : 14,
      letterSpacing: 0.45,
      fontWeight: '300',
    },
    lede: {
      fontFamily: GTAmerica,
      fontSize: width < smBreakpoint ? 14 : 18,
      letterSpacing: 0.45,
      fontWeight: '300',
      marginTop: 10,
      marginBottom: width <= mdBreakpoint ? 5 : 40,
      marginHorizontal: width < smBreakpoint ? 30 : 0,
    },
    cta: {
      fontFamily: PanamaBold,
      fontSize: 18,
      letterSpacing: 0.9,
    },
    arrow: {
      width: 21,
      height: 19,
      resizeMode: 'contain',
      marginLeft: width <= mdBreakpoint ? 5 : 20,
      marginBottom: -10,
    },
  });
  const renderTitle = () => (
    <View style={{ flexDirection: width <= mdBreakpoint ? 'column' : 'row' }}>
      <Text style={[styles.text, styles.issueTitle]}>
        Issue No. {issueNumber}:
      </Text>
      {width <= mdBreakpoint ? null : <Text> </Text>}
      <Text
        style={[styles.text, styles.issueTitle, { textTransform: 'uppercase' }]}
      >
        {title}
      </Text>
    </View>
  );
  return (
    <Hoverable>
      {(isHovered) => (
        <TouchableOpacity
          style={{ backgroundColor: isHovered ? flax : whiteBrand }}
          onPress={() => Linking.openURL(`/the-salon/${issueNumber}`)}
        >
          <View
            key={`${issuePreview.publishedDate}`}
            style={[styles.container]}
          >
            {width <= mdBreakpoint && renderTitle()}
            {width <= mdBreakpoint && <View style={{ height: 20 }} />}
            <Image style={styles.previewImage} source={previewImageUrl} />
            <View
              style={{
                flexDirection: 'column',
                marginLeft: width <= mdBreakpoint ? 0 : 20,
                width: width <= mdBreakpoint ? '100%' : '50%',
              }}
            >
              {width > mdBreakpoint && renderTitle()}
              <View
                style={{
                  flexDirection:
                    width <= mdBreakpoint ? 'column-reverse' : 'column',
                  marginBottom: width <= mdBreakpoint ? 15 : 0,
                }}
              >
                <Text style={[styles.text, styles.publishedDate]}>
                  {publishedDate}
                </Text>
                <Text style={[styles.text, styles.lede]}>{lede}</Text>
              </View>

              <View
                style={{
                  flexDirection: 'row',
                  justifyContent:
                    width < mdBreakpoint ? 'center' : 'flex-start',
                }}
              >
                <Text style={[styles.text, styles.cta]}>Read It</Text>
                <Image style={styles.arrow} source={rightArrow} />
              </View>
            </View>
          </View>
        </TouchableOpacity>
      )}
    </Hoverable>
  );
};
