import { A, H2 } from '@expo/html-elements';
import { TextProps } from '@expo/html-elements/build/primitives/Text';
import React from 'react';
import { useState } from 'react';
import {
  Image,
  Linking,
  ScrollView,
  StyleSheet,
  Text,
  View,
  Picker,
} from 'react-native';
import { Hoverable } from 'react-native-web-hooks';
import { HoverableLink } from '../components/common/HoverableLink';
import { PrimaryButton } from '../components/common/PrimaryButton';
import { TouchableOpacity } from '../components/common/TouchableOpacity';
import { CuratorHeader } from '../components/curator/CuratorHeader';
import { SalonSignup } from '../components/salon/SalonSignup';
import { smBreakpoint } from '../constants';
import { useDimensions } from '../context/DimensionsContext';
import { useThemeState } from '../context/ThemeContext';
import {
  blackBrand,
  greenBrand,
  GTAmerica,
  GTAmericaCompressed,
  mdbreakpoint,
  PanamaBold,
  redBrand,
  TrailersDemiBold,
  whiteBrand,
  wineBrand,
} from '../libs/constants';

interface Props { }

export const GiftCardScreen: React.FC<Props> = () => {
  const { theme } = useThemeState();
  const { width, height } = useDimensions();
  type Card = {
    value: string;
    link: string;
    copy: string;
  };
  const cardVariants = [
    {
      value: '$35',
      link: 'https://buy.stripe.com/aEU3fS2qEgOO0wg5sd',
      copy: 'Perfect for our signature figure drawing event.',
    },
    {
      value: '$70',
      link: 'https://buy.stripe.com/fZeg2E4yMeGG4MwaMy',
      copy: 'Great for a pair of figure drawing tickets.',
    },
    {
      value: '$90',
      link: 'https://buy.stripe.com/cN217K6GUaqqbaU1bZ',
      copy: 'This covers most of our classes.',
    },
    {
      value: '$120',
      link: 'https://buy.stripe.com/14kaIke9m6aafra2rB',
      copy: 'Just right for one of our premium classes.',
    },
    {
      value: '$160',
      link: 'https://buy.stripe.com/fZe5o0aXa2XY1Akg6U',
      copy: 'A whole lot of Happy Medium :)',
    },
    {
      value: 'Customize',
      link: 'https://buy.stripe.com/cN2g2E2qEdCC5QA5sh',
      copy: 'Get specific with it.',
    },
  ];
  const [selectedCard, setSelectedCard] = useState(cardVariants[0].value);
  const styles = StyleSheet.create({
    text: {
      color: theme.foregroundColor,
    },
    secondaryHeader: {
      fontFamily: GTAmericaCompressed,
      textTransform: 'uppercase',
      color: theme.foregroundColor,
      fontSize: width < smBreakpoint ? 20 : width < mdbreakpoint ? 24 : 28,
    },
    body: {
      fontFamily: GTAmerica,
      fontWeight: '300',
      fontSize: width < smBreakpoint ? 12 : width < mdbreakpoint ? 14 : 16,
    },
    infoText: {
      fontFamily: GTAmericaCompressed,
      fontSize: width < smBreakpoint ? 14 : width < mdbreakpoint ? 16 : 18,
      letterSpacing: 1.02,
    },
    finePrint: {
      fontSize: width < smBreakpoint ? 10 : width < mdbreakpoint ? 11 : 12,
    },
    h3: {
      fontSize: width < smBreakpoint ? 22 : width < mdbreakpoint ? 24 : 28,
    },
  });
  return (
    <ScrollView
      style={{
        flex: 1,
        backgroundColor: theme.backgroundColor,
      }}
    >
      <CuratorHeader />

      <View style={{ height: 40 }} />
      <View
        style={{
          justifyContent: 'center',
          width: 600,
          maxWidth: '90vw',
          alignSelf: 'center',
        }}
      >
        <Text
          style={{
            fontFamily: PanamaBold,
            color: theme.foregroundColor,
            textTransform: 'uppercase',
            fontSize:
              width < smBreakpoint ? 22 : width < mdbreakpoint ? 32 : 48,
            letterSpacing: 1.02,
            lineHeight:
              width < smBreakpoint ? 24 : width < mdbreakpoint ? 34 : 50,
            alignSelf: 'center',
          }}
        >
          Happy Medium Gift Card
        </Text>

        <View style={{ height: 20 }} />
        <Image
          style={{ width: 263, height: 152, alignSelf: 'center' }}
          source={{ uri: 'https://49market.s3.amazonaws.com/Example.png' }}
        />
        <View style={{ height: 40 }} />
        <Text style={[styles.text, styles.body]}>
          The Happy Medium Gift Card is the perfect gift for quite literally
          everyone in your life. Give the gift of creativity and earn major
          friend points along the way.{'\n\n'}
        </Text>
        <Text style={[styles.text, styles.body]}>
          The Happy Medium gift card is redeemable against everything we offer -
          classes, events, art cafe, and products. After purchasing, we will
          email you a custom gift card with instructions for how to redeem.
          {'\n\n'}
        </Text>
        <Text style={[styles.text, styles.body]}>
          Please allow up to 24 hours for digital gift card delivery :) {'\n\n'}
        </Text>
        <Text style={[styles.text, styles.body, { textTransform: 'uppercase', fontWeight: 'bold' }]}>
          Gift cards are non-refundable. Gift cards are valid for 9 years from the date of purchase.{'\n\n'}
        </Text>
        <Text
          style={[
            styles.text,
            styles.body,
            { textTransform: 'uppercase', letterSpacing: 1.1, marginBottom: 5 },
          ]}
        >
          Select Amount
        </Text>
        <Picker
          selectedValue={selectedCard}
          onValueChange={(v) => {
            console.log('V', v);
            setSelectedCard(v);
          }}
          style={{
            backgroundColor: theme.backgroundColor,
            borderColor: theme.foregroundColor,
            borderTopColor: theme.foregroundColor,
            color: theme.foregroundColor,
            fontFamily: GTAmerica,
            fontSize: 16,
            height: 38,
            padding: 2,
            borderRadius: 4,
            width: 250,
          }}
        >
          {cardVariants.map((v) => (
            <Picker.Item key={v.value} value={v.value} label={v.value} />
          ))}
        </Picker>
        <Text style={[styles.text, styles.body, styles.finePrint, { marginTop: 5 }]}>{cardVariants.find(c => c.value === selectedCard)?.copy}</Text>
        <View style={{ height: width < smBreakpoint ? 20 : 60 }} />
        <TouchableOpacity style={{}} onPress={() => window.open(cardVariants.find(v => v.value === selectedCard)?.link, '__blank')}>
          <View style={{ backgroundColor: theme.foregroundColor, borderRadius: 50, flexDirection: 'row', justifyContent: 'space-between', padding: 15, paddingHorizontal: 40, margin: 20, width: 340, maxWidth: '95%', alignSelf: 'center', borderWidth: 1, borderColor: theme.backgroundColor }}>
            <Text style={[styles.text, styles.h3, { color: theme.backgroundColor, textTransform: 'uppercase', fontFamily: GTAmericaCompressed, letterSpacing: 1 }]}>Check Out</Text>
            <Text style={[styles.text, styles.h3, { color: theme.backgroundColor, textTransform: 'uppercase', fontFamily: GTAmericaCompressed, letterSpacing: 1 }]}>{selectedCard}</Text>
          </View>
        </TouchableOpacity>
      </View>
      <View style={{ height: 60 }} />
      <SalonSignup />
    </ScrollView>
  );
};
