import React from 'react';
import { View, Image, StyleSheet, Text } from 'react-native';

import { smBreakpoint, mdBreakpoint } from '../../constants';
import { NewsletterSubscribeForm } from '../forms/NewsletterSubscribeForm';
import {
  greenBrand,
  whiteBrand,
  PanamaBold,
  GTAmerica,
} from '../../libs/constants';
import { useDimensions } from '../../context/DimensionsContext';

interface Props {}

export const SalonSignup: React.FC<Props> = () => {
  const { width } = useDimensions();
  const styles = StyleSheet.create({
    container: {
      backgroundColor: greenBrand,
      // flex: 1,
      flexDirection: 'column',
      alignContent: 'center',
      alignItems: 'center',
      paddingVertical: width < mdBreakpoint ? 30 : 60,
    },
    copy: { color: whiteBrand, width: '85%', textAlign: 'center' },
    header: {
      fontFamily: PanamaBold,
      fontSize: width < mdBreakpoint ? 24 : 48,
      letterSpacing: 1.01,
      textTransform: 'uppercase',
    },
    secondaryCopy: {
      fontFamily: GTAmerica,
      fontWeight: '500',
      fontSize: width < mdBreakpoint ? 16 : 24,
      letterSpacing: 0.84,
      marginTop: 20,
    },
  });
  return (
    <View style={styles.container}>
      <Text style={[styles.copy, styles.header]}>
        Be the most cultured person in the room
      </Text>
      <Text style={[styles.copy, styles.secondaryCopy]}>
        Join The Salon and get weekly stories about art, culture, and
        creativity.{' '}
      </Text>
      <View style={{ height: width < mdBreakpoint ? 30 : 80 }} />
      <NewsletterSubscribeForm />
    </View>
  );
};
