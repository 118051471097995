import React from 'react';
import { View, Image, ImageURISource, StyleSheet, Text } from 'react-native';
import { Hoverable } from 'react-native-web-hooks';

import {
    greenBrand,
    PanamaBold,
    GTAmerica,
    GTAmericaCompressed,
} from '../../../libs/constants';
import { useDimensions } from '../../../context/DimensionsContext';
import { mdBreakpoint, smBreakpoint, lgBreakpoint } from '../../../constants';
import { TouchableOpacity } from '../../common/TouchableOpacity';
import { HoverableLink } from '../../common/HoverableLink';

interface SalonIssueProps { }

export const HMIRLBK: React.FC<SalonIssueProps> = () => {
    const { width } = useDimensions();
    const blueSquiggle = require('@happymedium/components/assets/images/squiggles-blue.png');
    const redSquiggle = require('@happymedium/components/assets/images/squiggles-red.png');

    const styles = StyleSheet.create({
        container: {
            flex: 1,
            flexDirection: 'column',
        },
        innerContainer: {
            maxWidth: width < 900 ? '85%' : 700,
            alignSelf: 'center',
        },
        imageContainer: {
            alignItems: 'center',
            marginVertical:
                width < smBreakpoint ? 20 : width < mdBreakpoint ? 35 : 60,
        },
        text: {
            color: greenBrand,
            flexWrap: 'wrap',
        },
        header: {
            fontFamily: PanamaBold,
            letterSpacing: 1.3,
            fontSize: width < smBreakpoint ? 28 : width < mdBreakpoint ? 36 : 48,
            marginBottom: width < smBreakpoint ? 5 : width < mdBreakpoint ? 15 : 20,
        },
        body: {
            fontFamily: GTAmerica,
            fontWeight: '300',
            letterSpacing: 0.45,
            fontSize: width < smBreakpoint ? 14 : width < mdBreakpoint ? 15 : 16,
            marginVertical: 10,
            textAlign: 'justify',
        },
        imageDescription: {
            fontFamily: GTAmericaCompressed,
            fontWeight: '300',
            letterSpacing: 0.45,
            fontSize: width < smBreakpoint ? 12 : width < mdBreakpoint ? 14 : 16,
            marginVertical: width < smBreakpoint ? 5 : 8,
            textAlign: 'center',
            maxWidth: width < smBreakpoint ? '90vw' : '100%',
        },
        shareButton: {},
        arrow: {},
        squiggle: {
            height: 31,
            width: 123,
            alignSelf: 'center',
            resizeMode: 'contain',
            marginBottom: 0,
            marginTop: 25,
        },
        image: {
            marginHorizontal: 30,
            maxWidth: width < mdBreakpoint ? '80%' : '50%',
        },
        imageVertical: {
            height: width < smBreakpoint ? 400 : width < mdBreakpoint ? 500 : 550,
            width: '100%',
            alignSelf: 'center',
            resizeMode: 'contain',
        },
        imageHorizontal: {
            height:
                width < smBreakpoint
                    ? 280
                    : width < mdBreakpoint
                        ? 350
                        : width < lgBreakpoint
                            ? 420
                            : 550,
            width: '100%',
            alignSelf: 'center',
            resizeMode: 'contain',
        },
    });

    const renderHeader = (copy: string) => (
        <Text style={[styles.text, styles.header]}>{copy}</Text>
    );

    const renderParagraph = (copy: string) => (
        <Text style={[styles.text, styles.body]}>{copy}</Text>
    );

    const renderImageDescription = (copy: string) => (
        <Text style={[styles.text, styles.imageDescription]}>{copy}</Text>
    );

    return (
        <View style={styles.container}>
            <View
                style={{
                    height: width < smBreakpoint ? 10 : width < mdBreakpoint ? 25 : 30,
                }}
            />
            <View style={{ flexDirection: width < lgBreakpoint ? 'column' : 'row' }}>
                <View style={[styles.imageContainer, { flexGrow: 1 }]}>
                    <Image
                        style={[
                            styles.image,
                            styles.imageVertical,
                            {
                                marginHorizontal: 0,
                                maxWidth: '100%',
                                height:
                                    width < smBreakpoint
                                        ? 320
                                        : width < mdBreakpoint
                                            ? 380
                                            : width < lgBreakpoint
                                                ? 400
                                                : 480,
                            },
                        ]}
                        source={
                            'https://49market.s3.amazonaws.com/IMG_8102.jpg' as ImageURISource
                        }
                    />
                </View>
                <View
                    style={{
                        flexDirection: 'column',
                        flexShrink: 1,
                        maxWidth: width < 900 ? '85%' : 700,
                        flexWrap: 'wrap',
                        marginHorizontal:
                            width < smBreakpoint ? undefined : width < mdBreakpoint ? 25 : 50,
                        alignSelf: 'center',
                    }}
                >
                    {width < smBreakpoint && <View style={{ height: 30 }} />}
                    {renderHeader('Happy Medium IRL 2.0')}

                    {renderParagraph(
                        'Our second location is opening at 224 Franklin St in Brooklyn this week!'
                    )}
                    {/* {renderParagraph(
                        'If you’re wondering what compelled us to open a shop in midst of a pandemic, build all of the furniture ourselves in less than 6 weeks, and how we got two 200-pound speakers up a flight of stairs – that’s the topic of this week’s Salon. It’s been a strange, wonderful, and challenging road to open these doors, but we’re excited to share that story and tell you more about 49 Market Street.'
                    )} */}
                </View>
            </View>
            <View
                style={[
                    styles.imageContainer,
                    {
                        marginBottom:
                            width < smBreakpoint ? 30 : width < mdBreakpoint ? 20 : 0,
                        marginTop: 20,
                    },
                ]}
            >
                <Image style={[styles.image, styles.squiggle]} source={redSquiggle} />
            </View>


            <View style={[styles.innerContainer, { marginTop: 50 }]}>
                <Text style={[styles.text, styles.body]}>3 years ago we toyed around with the idea of opening a physical location. Before the pandemic, we had a lot of fun hosting art events in our home and with other brands. When the world started to open up we thought, why not? Let’s give this a try. You can read our post about how all of that came to be <HoverableLink linkType='internal' linkUrl='https://happy-medium.co/the-salon/11'>here</HoverableLink>.</Text>
                {renderParagraph(
                    'Fast forward to today and we’ve grown from just our 2 founders kicking around some ideas on the 2nd floor of an otherwise abandoned building to a full team of almost 50 operating a pottery, woodworking workshop, and hosting events every day out of the entire building. It’s been a wild ride to say the least.'
                )}
                {renderParagraph(
                    'We’ve found it works best when we let the business pull us where it needs to go. For months, we’ve felt the pull to open another location because we’re constantly sold out at our Manhattan location. A great business problem to have for sure, but one that actually makes us uncomfortable. We want Happy Medium to be a place you can drop in on a whim (when inspiration strikes so to speak) for a dose of art.'
                )}

                {renderParagraph(
                    'We started looking for a Brooklyn spot at the end of last year - on December 29th to be exact. We drove around and snapped a pic of our favorite spot - one that was not on the market at the time - at 224 Franklin St. Nothing else excited us, but we figured hey - sometimes this stuff takes a while.'
                )}

            </View>

            <View style={[styles.imageContainer, { marginVertical: 30 }]}>
                <Image
                    style={[styles.image, styles.imageVertical]}
                    source={
                        'https://49market.s3.amazonaws.com/IMG_1048.jpg' as ImageURISource
                    }
                />
                {renderImageDescription('The pic we took on our first scouting trip, before the space was available for lease')}
            </View>
            <View style={styles.innerContainer}>
                {/* {renderHeader('The Perfect Space')} */}
                {renderParagraph(
                    'About a week later while perusing rental sites, we noticed our spot! For lease! Available in just a few weeks! When things like this happen, we take it as a nod from the universe that it’s meant to be. We jumped on it and signed a lease the next week.'
                )}
                {renderParagraph(
                    "One thing we’ve learned about opening anything new - launch it before you feel like you’re ready. When we opened 49 Market St. we had a vague game plan and seating for 20. When we opened the pottery, the interior wasn’t close to being finished but we had some wheels!"
                )}

                {renderParagraph(
                    'We’re following the same playbook in Greenpoint - opening before we feel ready. Why? Because we like to leave some space to design with you. We want to see you use the space, move in the space, and most importantly, we want you to contribute to the space. You fill it with art and soul in a way that we could never do on our own.'
                )}

                <View style={styles.imageContainer}>
                    <Image
                        style={[styles.image, styles.imageVertical]}
                        source={
                            'https://49market.s3.amazonaws.com/IMG_3076.jpg' as ImageURISource
                        }
                    />
                    {/* {renderImageDescription('The horse head.')} */}
                </View>

                {renderParagraph(
                    'So here’s the plan: we’re starting with Art Cafe this weekend and we’d love to see you there. We will be open Saturday and Sunday from 12-9pm. Our full menu will be available, including Paint-a-Pots. From there, we will continue to add on some of our most loved events, and maybe a few new things as well :)'
                )}
                {renderParagraph(
                    'But the real best is yet to come - the backyard. We’ve dreamed of having an outdoor space and now we finally do. It will be open just in time for summer and we can’t wait to show you that too.'
                )}
                {renderParagraph(
                    'So if you’re in Greenpoint this weekend, come say hi. We’d love to meet you.'
                )}
                {/* {renderParagraph(
                    '49 Market Street felt right, but the phone number on the “For Rent” sign went unanswered. Through property record searches, we were able to get in touch with the owners directly. After a few walk throughs, some negotiating, and a crash course in the commercial insurance industry, we were the proud tenants of a 4-month lease.'
                )} */}

                {/* <View style={styles.imageContainer}>
                    <Image
                        style={[styles.image, styles.imageVertical]}
                        source={
                            'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/011/Prototyping+2.jpg' as ImageURISource
                        }
                    />
                    {renderImageDescription('Rett prototyping a chair')}
                </View> */}
            </View>

            {/* <View style={styles.innerContainer}>
                {renderHeader('The Build Out')}
                <Text style={[styles.text, styles.body]}>
                    Three designers stood out to us in particular -{' '}
                    <HoverableLink
                        linkUrl={
                            'https://en.wikipedia.org/wiki/Sergio_Rodrigues_(architect)'
                        }
                        linkType={'web'}
                    >
                        Sergio Rodrigues
                    </HoverableLink>
                    ,{' '}
                    <HoverableLink
                        linkUrl={'https://en.wikipedia.org/wiki/Enzo_Mari'}
                        linkType={'web'}
                    >
                        Enzo Mari
                    </HoverableLink>
                    , and
                    <HoverableLink
                        linkUrl={'https://en.wikipedia.org/wiki/Donald_Judd'}
                        linkType={'web'}
                    >
                        Donald Judd
                    </HoverableLink>
                    . The simplicity of their construction and timelessness of their
                    designs resonated with us. We decide to recreate some of their designs
                    in Cherry, Mahogany, and Pine, making small alterations along the way.
                </Text>
                <Text style={[styles.text, styles.body]}>
                    Next we focused on the sound system. Music is art that inspires
                    movement and influences moods. We needed music and, with such a large
                    space, we knew we needed large speakers to get the job done. We were
                    looking for the A7 model of Altec Lansing’s Voice of the Theater
                    Speakers – one of the most{' '}
                    <HoverableLink
                        linkUrl={
                            'https://audioxpress.com/article/altec-lansing-s-voice-of-the-theatre-speakers-what-you-need-to-know'
                        }
                        linkType={'web'}
                    >
                        sought-after speakers
                    </HoverableLink>
                    in Japan.
                </Text>
            </View> */}

            {/* <View style={styles.innerContainer}>
                {renderParagraph(
                    'We found them in Southern New Jersey in the home of an audiophile collector who had run out of room. We pulled into his driveway in our massive Uhaul truck and were greeted by the massive speakers. They were blasting Lucky Man by Emerson, Lake, and Palmer so loud the neighbors had come outside for the show.'
                )}
            </View>

            <View style={styles.imageContainer}>
                <Image
                    style={[styles.image, styles.imageVertical]}
                    source={
                        'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/011/Speaker+situ.jpg' as ImageURISource
                    }
                />
                {renderImageDescription('One of the speakers, in situ.')}
            </View>

            <View style={styles.innerContainer}>
                {renderParagraph(
                    'We got the speakers back to NYC without issue, but as soon as we attempted to carry the speakers up a long flight of stairs, I realized just how much 200 pounds is. We bailed on that mission and hired a couple of guys to help us the next day.'
                )}
            </View>

            <View style={styles.imageContainer}>
                <Image
                    style={[styles.image, styles.imageVertical]}
                    source={
                        'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/011/cushions+-+resize+.jpg' as ImageURISource
                    }
                />
                {renderImageDescription('Cushions and chair - all handmade.')}
            </View>

            <View style={styles.innerContainer}>
                {renderParagraph(
                    'We’ve made most of the things in the space – the curtains, the bar, the cushions -  but not everything. We’ve curated a collection of art books, busts, ceramics, and other art objects. We also set out fresh flower arrangements and edible still lives every day. They fill the space with inspiration and knowledge. We want you to get carried away reading about Surrealism, copying Picasso’s early sketches, or watercoloring a still life.'
                )}
                {renderParagraph(
                    'Now that the sawdust has settled and the doors are opening, you’re probably wondering what it’s like in here. When should you visit and what can you do?'
                )}
            </View>

            <View style={styles.imageContainer}>
                <Image
                    style={[styles.image, styles.imageVertical]}
                    source={
                        'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/011/watercolor+-+resize.jpg' as ImageURISource
                    }
                />
                {renderImageDescription(
                    'Indulging in a watercolor afternoon at the art café.'
                )}
            </View>

            <View style={styles.innerContainer}>
                {renderHeader("What's it like?")}
                {renderParagraph(
                    'The space is dynamic, but it primarily serves as a retail store and an art café.'
                )}
                {renderParagraph(
                    'In our store, you’ll soon find our homemade paints, handbound notebooks, packs of willow charcoal, vintage art, and more. It’s a curated assortment of supplies and delights for the aesthetically curious.'
                )}
            </View>

            <View style={styles.imageContainer}>
                <Image
                    style={[styles.image, styles.imageVertical]}
                    source={
                        'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/011/art+meets+design.jpg' as ImageURISource
                    }
                />
                {renderImageDescription('Art meets design at 49 market St.')}
            </View>

            <View style={styles.innerContainer}>
                {renderParagraph(
                    'The other half of the space is our art café – based on the Viennese coffee shop concept, the café is a place to convene and create. Come with a friend for a quick weekend coffee or share an afternoon watercoloring together. Come by for one of our Happy Hours during the week or stop by on a lazy Sunday morning.'
                )}
                {renderParagraph(
                    'In addition to light snacks and sips, you can order art supplies, tableside. Charcoal starter kits and watercolor palettes, delivered tableside with everything you need to enjoy making art. When you’re done, leave it on the table. No mess, less stress.'
                )}
            </View>

            <View style={styles.imageContainer}>
                <Image
                    style={[styles.image, styles.imageVertical]}
                    source={
                        'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/011/Stripo+Resized.jpg' as ImageURISource
                    }
                />
                {renderImageDescription('The poster for our first event.')}
            </View>

            <View style={styles.innerContainer}>
                {renderParagraph(
                    'We also host events in the space – some private and some open to the public. We’re hosting our first event – an evening of figure drawing with Nellie Blue - on Friday, July 30th 6-8pm. Tickets are $35 – dm us on Instagram to secure your spot before they’re gone!'
                )}

                <Text style={[styles.text, styles.body]}>
                    If you’d like to book the space for a private event, email us at
                    {'\n'}
                    <HoverableLink
                        linkUrl={'mailto:reservations@happy-medium.co'}
                        linkType={'mail'}
                    >
                        reservations@happy-medium.co
                    </HoverableLink>
                    .
                </Text>
                {renderParagraph(
                    'Above all, the space is a creative refuge in the concrete jungle – a place to relax, indulge in your creative side, and find your flow. We’re open for art cafe Sat-Sun, 12pm-7pm.'
                )}
                {renderParagraph('Come say hi. We’d love to meet you.')}
                <Image
                    style={{ height: 60, width: 120, resizeMode: 'contain' }}
                    source={require('@happymedium/components/assets/images/logo-hm-abbreviated-green.png')}
                />
            </View> */}

            <View style={[styles.imageContainer, { marginBottom: 0, marginTop: 20 }]}>
                <Image style={[styles.image, styles.squiggle]} source={blueSquiggle} />
            </View>

            <View style={{ height: 80 }} />
        </View>
    );
};
