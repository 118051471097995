import React, { useState } from 'react';
import { View, TextInput, StyleSheet, Text, Image } from 'react-native';
import { Formik } from 'formik';
import { useMutation } from '@apollo/react-hooks';

import {
  oatmeal,
  LowdragNarrow,
  mint,
  blackCherry,
  justBlue,
  Reforma1969NegraItalica,
  GrandSlangBSide,
  GrandSlangItalic,
  Reforma1969Gris,
  cajunShrimp,
  emerald,
} from '../../libs/constants';
import { PrimaryButton } from '../common/PrimaryButton';
import { SubscribeNewsletterDocument } from '../ApolloComponents';

interface Props {
  email: string;
}

interface FormValues {
  email: string;
}

const styles = StyleSheet.create({
  container: {
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100vh',
    display: 'flex',
    backgroundColor: '#231E20',
  },
  subscribeForm: {
    marginVertical: 10,
    marginHorizontal: 0,
    paddingHorizontal: 15,
    width: '100%',
    flexGrow: 1,
    maxWidth: 500,
    flexDirection: 'column',
    // flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#008E63',
    borderRadius: 10,
    paddingVertical: 20,
  },
  emailInput: {
    minWidth: '50%',
    backgroundColor: '#fff',
    paddingLeft: 5,
    fontFamily: Reforma1969Gris,
    textTransform: 'lowercase',
    fontSize: 20,
    height: 58,
    flexGrow: 1,
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: 8,
    borderColor: blackCherry,
    width: 275,
    marginBottom: 10,
  },
  subscribeButton: {
    width: 90,
    // flex: 1,
    backgroundColor: 'white',
    // color: '#FF4800',
    // fontFamily: Reforma1969Gris,
    // textAlignVertical: true,
    height: 40,
    borderRadius: 10,
  },
});

export const NewSubscribeForm: React.FC<Props> = ({ email }) => {
  const [subscribe, { data }] = useMutation(SubscribeNewsletterDocument);
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [displayError, setDisplayError] = useState(false);
  const [displayDup, setDisplayDup] = useState(false);
  const [hideForm, setHideForm] = useState(false);
  const beret = require('@happymedium/components/assets/images/beret.png');

  return (
    <View style={styles.container}>
      <Formik<FormValues>
        initialValues={{ email: email }}
        onSubmit={async (values, { resetForm }) => {
          setDisabled(true);
          setLoading(true);
          const result = await subscribe({
            variables: {
              email: values.email,
            },
          });
          if (result.data.subscribe.success === true) {
            setHideForm(true);
            setDisabled(false);
            setLoading(false);
            resetForm({ values: { email: '' } });
          }
          if (
            result.data.subscribe.success === false &&
            result.data.subscribe.errors[0].message === 'Already in the squad'
          ) {
            setDisplayDup(true);
            setLoading(false);
            setDisabled(false);
            // console.log('squad');
          }
          // console.log('result', result);
          // console.log('data', data);
        }}
      >
        {({ handleBlur, handleChange, handleSubmit, values }) => (
          <View style={{ width: '100%' }}>
            {hideForm ? (
              <View style={{ width: '100%' }}>
                <Text
                  style={{
                    textAlign: 'center',
                    fontFamily: GrandSlangItalic,
                    textTransform: 'uppercase',
                    color: 'white',
                    fontSize: 36,
                  }}
                >
                  Thanks for signing up!
                </Text>
              </View>
            ) : (
              <View style={{ alignItems: 'center', display: 'flex' }}>
                <Image
                  style={{
                    height: 45,
                    minWidth: 70,
                    marginLeft: 200,
                    marginBottom: -15,
                    display: 'flex',
                    // alignSelf: 'flex-end',
                  }}
                  source={beret}
                />
                <Text
                  style={{
                    textAlign: 'center',
                    fontFamily: GrandSlangBSide,
                    color: 'white',
                    fontSize: 36,
                  }}
                >
                  Saturday Salon
                </Text>
                <View style={styles.subscribeForm}>
                  <Text
                    style={{
                      textAlign: 'center',
                      fontFamily: Reforma1969Gris,
                      color: 'white',
                      marginBottom: 10,
                    }}
                  >
                    A weekly-ish dose of art, culture, and curiosities curated
                    by the Happy Medium team and delivered straight to your
                    inbox.
                  </Text>

                  <TextInput
                    onChangeText={handleChange('email')}
                    onBlur={handleBlur('email')}
                    value={values.email}
                    style={styles.emailInput}
                    placeholder="you@email.com"
                  />

                  <View
                    style={{
                      borderStyle: 'solid',
                      backgroundColor: 'white',
                      borderRadius: 14,
                      borderWidth: 1,
                      padding: 2,
                      shadowColor: blackCherry,
                      shadowOffset: { width: 5, height: 5 },
                      shadowOpacity: 10,
                      shadowRadius: 10,
                    }}
                  >
                    <View
                      style={{
                        borderStyle: 'solid',
                        backgroundColor: 'white',
                        borderRadius: 12,
                        borderWidth: 1,
                        padding: 2,
                      }}
                    >
                      <View
                        style={{
                          borderStyle: 'solid',
                          backgroundColor: 'white',
                          borderRadius: 10,
                          borderWidth: 1,
                          padding: 2,
                        }}
                      >
                        <PrimaryButton
                          loading={loading}
                          disabled={disabled}
                          onPress={() => {
                            // console.log('value', values.email);
                            setDisplayDup(false);
                            setDisplayError(false);
                            if (values.email === null || values.email === '') {
                              setDisplayError(true);
                              return;
                            }
                            handleSubmit();
                          }}
                          children="OUI"
                          color="#FF4800"
                          backgroundColor="white"
                          style={[styles.subscribeButton, {}]}
                        />
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            )}
            {displayError ? (
              <View>
                <Text
                  style={{
                    textAlign: 'center',
                    color: cajunShrimp,
                    fontFamily: Reforma1969Gris,
                  }}
                >
                  ***please fill out ze form***
                </Text>
              </View>
            ) : null}
            {displayDup ? (
              <View>
                <Text
                  style={{
                    textAlign: 'center',
                    color: cajunShrimp,
                    fontFamily: Reforma1969Gris,
                  }}
                >
                  ***you're already in the squad***
                </Text>
              </View>
            ) : null}
          </View>
        )}
      </Formik>
    </View>
  );
};
