import 'react-app-polyfill/stable';
import 'resize-observer-polyfill/dist/ResizeObserver.global';
import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();

import { AppRegistry } from 'react-native';

import { App } from '@happymedium/components/src/App';

import '../public/index.css';

AppRegistry.registerComponent('happymedium', () => App);
AppRegistry.runApplication('happymedium', {
  rootTag: document.getElementById('root'),
});
