import React from 'react';
import { View, Image, ImageURISource, StyleSheet, Text } from 'react-native';

import {
  greenBrand,
  PanamaBold,
  GTAmerica,
  GTAmericaCompressed,
} from '../../../libs/constants';
import { useDimensions } from '../../../context/DimensionsContext';
import { mdBreakpoint, smBreakpoint, lgBreakpoint } from '../../../constants';
import { Hoverable } from 'react-native-web-hooks';
import { TouchableOpacity } from '../../common/TouchableOpacity';

interface SalonIssueOneProps {}

export const SalonIssueFour: React.FC<SalonIssueOneProps> = () => {
  const { width } = useDimensions();
  const blueSquiggle = require('@happymedium/components/assets/images/squiggles-blue.png');
  const redSquiggle = require('@happymedium/components/assets/images/squiggles-red.png');

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      flexDirection: 'column',
    },
    innerContainer: {
      maxWidth: width < 900 ? '85%' : 700,
      alignSelf: 'center',
    },
    imageContainer: {
      alignItems: 'center',
      marginVertical:
        width < smBreakpoint ? 20 : width < mdBreakpoint ? 35 : 60,
    },
    text: {
      color: greenBrand,
      flexWrap: 'wrap',
    },
    header: {
      fontFamily: PanamaBold,
      letterSpacing: 1.3,
      fontSize: width < smBreakpoint ? 28 : width < mdBreakpoint ? 36 : 48,
      marginBottom: width < smBreakpoint ? 5 : width < mdBreakpoint ? 15 : 20,
    },
    body: {
      fontFamily: GTAmerica,
      fontWeight: '300',
      letterSpacing: 0.45,
      fontSize: width < smBreakpoint ? 14 : width < mdBreakpoint ? 15 : 16,
      marginVertical: 10,
    },
    imageDescription: {
      fontFamily: GTAmericaCompressed,
      fontWeight: '300',
      letterSpacing: 0.45,
      fontSize: width < smBreakpoint ? 12 : width < mdBreakpoint ? 14 : 16,
      marginVertical: width < smBreakpoint ? 5 : 8,
      textAlign: 'center',
      maxWidth: width < smBreakpoint ? '90vw' : '100%',
    },
    shareButton: {},
    arrow: {},
    squiggle: {
      height: 31,
      width: 123,
      alignSelf: 'center',
      resizeMode: 'contain',
      marginBottom: 0,
      marginTop: 25,
    },
    image: {
      marginHorizontal: 30,
      maxWidth: width < mdBreakpoint ? '80%' : '50%',
    },
    imageVertical: {
      height: width < smBreakpoint ? 400 : width < mdBreakpoint ? 500 : 550,
      width: '100%',
      alignSelf: 'center',
      resizeMode: 'contain',
    },
    imageHorizontal: {
      height:
        width < smBreakpoint
          ? 280
          : width < mdBreakpoint
          ? 350
          : width < lgBreakpoint
          ? 420
          : 550,
      width: '100%',
      alignSelf: 'center',
      resizeMode: 'contain',
    },
  });

  const renderHeader = (copy: string) => (
    <Text style={[styles.text, styles.header]}>{copy}</Text>
  );

  const renderParagraph = (copy: string) => (
    <Text style={[styles.text, styles.body]}>{copy}</Text>
  );

  const renderImageDescription = (copy: string) => (
    <Text style={[styles.text, styles.imageDescription]}>{copy}</Text>
  );

  return (
    <View style={styles.container}>
      <View
        style={{
          height: width < smBreakpoint ? 10 : width < mdBreakpoint ? 25 : 30,
        }}
      />
      <View style={{ flexDirection: width < lgBreakpoint ? 'column' : 'row' }}>
        <View style={[styles.imageContainer, { flexGrow: 1 }]}>
          <Image
            style={[
              styles.image,
              styles.imageHorizontal,
              {
                marginHorizontal: 0,
                maxWidth: '100%',
                height:
                  width < smBreakpoint
                    ? 280
                    : width < mdBreakpoint
                    ? 380
                    : width < lgBreakpoint
                    ? 400
                    : 480,
              },
            ]}
            source={
              'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/004/3+-+resize+.jpg' as ImageURISource
            }
          />
          {renderImageDescription(
            'The Madonna Crowned by an Angel by Albrecht Dürer'
          )}
        </View>
        <View
          style={{
            flexDirection: 'column',
            flexShrink: 1,
            maxWidth: width < 900 ? '85%' : 700,
            flexWrap: 'wrap',
            marginHorizontal:
              width < smBreakpoint ? undefined : width < mdBreakpoint ? 25 : 50,
            alignSelf: 'center',
          }}
        >
          {width < smBreakpoint && <View style={{ height: 30 }} />}
          {renderHeader('Hidden Gems')}

          {renderParagraph(
            "When you're looking to add to your art collection, the flea market is a good place to look. Not only can you find amazing, one of a kind pieces, sometimes you can actually strike gold. This week, we're learning about 3 recent cases of striking flea market gold."
          )}
        </View>
      </View>
      <View
        style={[
          styles.imageContainer,
          {
            marginBottom:
              width < smBreakpoint ? 30 : width < mdBreakpoint ? 20 : 0,
            marginTop: 20,
          },
        ]}
      >
        <Image style={[styles.image, styles.squiggle]} source={redSquiggle} />
      </View>
      <View style={[styles.innerContainer]}>
        <View style={{ height: 40 }} />
        {renderHeader('Lost Dürer Discovered At a Parisian Brocante')}
        {renderParagraph(
          'Albrecht Dürer was born ~20 years before Columbus arrived in America. He was a famous artist of the German Renaissance, pen pals with his counterparts in Italy - Raphael, Giovanni Bellini, and da Vinci - and one of the first to ever use the new technology of printmaking in art.'
        )}
        {renderParagraph(
          'One of his engravings, The Madonna Crowned by an Angel (pictured above), was part of the collection at the Staatsgalerie Stuttgart but disappeared after WWII. A retired archeologist rummaging at a flea market in France in 2016 discovered the engraving and bought it for a few Euros. Ever the gentleman, he decided to return it to the museum.'
        )}
        {renderParagraph(
          'How this priceless artifact of Renaissance art came to be at the flea market is unknown. It was allegedly owned by a local mayor in the years before the sale and was in pristine condition and likely taken care of. After WWII, many artworks went missing, quietly stolen from the walls of galleries and museums in the chaotic aftermath of the war.'
        )}
      </View>
      <View style={styles.innerContainer}>
        <View style={{ height: 60 }} />
        {renderHeader('$160MM De Kooning At An Estate Sale')}
      </View>
      <View style={[styles.imageContainer, { marginVertical: 30 }]}>
        <Image
          style={[styles.image, styles.imageVertical]}
          source={
            'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/004/5+-+resize+.jpg' as ImageURISource
          }
        />
        {renderImageDescription('Woman-Ochre by Willem de Kooning, 1955')}
      </View>
      <View style={styles.innerContainer}>
        {renderParagraph(
          'Woman-Ochre by Willem de Kooning almost made it to the aisles of a New Mexico thrift store after the shop owner acquired it in the estate of a recently deceased woman. The painting had been stolen from an Arizona art museum in the 80s and was about to be put up for sale when a customer, who was also an artist, noticed it in the shop and commented that he thought it might be a real de Kooning.'
        )}
        {renderParagraph(
          'After doing some research, the shop owner called the museum and offered to return it. 48 hours later the FBI showed up and escorted the $160 million dollar painting back to its rightful home.'
        )}
      </View>

      <View style={styles.imageContainer}>
        <Image
          style={[
            styles.image,
            styles.imageHorizontal,
            {
              height:
                width < smBreakpoint
                  ? 240
                  : width < mdBreakpoint
                  ? 280
                  : width < lgBreakpoint
                  ? 340
                  : 430,
            },
          ]}
          source={
            'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/004/4+-+resizez.jpg' as ImageURISource
          }
        />
        {renderImageDescription('Woman-Ochre as found in the estate sale')}
      </View>

      <View style={styles.innerContainer}>
        {renderHeader('Goodwill Gold')}
        {renderParagraph(
          'Vertical Diamond by Ilya Bolotowsky arrived at a North Carolina Goodwill one day in late April, 2012. The painting had been owned by Burlington Industries since the 70s. When Burling Industries went under, the work was sold in a liquidation sale and purchased by a local couple who wanted to use it to decorate their basement.  It was too big for the space so they tried to resell it at a church sale for $75. No one bought it so they dropped it off at Goodwill. Later that day, Beth Feeback bought it for $9.99. She didn’t like the painting but wanted to reuse the canvas.'
        )}
        {renderParagraph(
          'Luckily, she noticed some important looking stickers on the back of the painting from Weatherspoon Art Museum – a local museum that had been loaned the painting for an exhibit in 1979. After doing some research, she realized that the artist was actually quite famous and contacted Sotheby’s who offered to help with the sale. It sold for $34k at auction a few months later.'
        )}
      </View>

      <View style={styles.imageContainer}>
        <Image
          style={[
            styles.image,
            styles.imageHorizontal,
            {
              height:
                width < smBreakpoint
                  ? 240
                  : width < mdBreakpoint
                  ? 280
                  : width < lgBreakpoint
                  ? 340
                  : 430,
            },
          ]}
          source={
            'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/004/1+-+resize.jpg' as ImageURISource
          }
        />
        {renderImageDescription(
          'Beth Feeback with her find – Vertical Diamond by Ilya Bolotowsky'
        )}
      </View>

      <View style={styles.innerContainer}>
        {renderParagraph(
          'These types of stories have always fascinated me and I’ve long dreamed about finding a masterpiece amongst the discarded art at Goodwill. The art section is the one section I never skip in a thrift store. So far, my best luck has been on the street – I acquired Ghost Crab (pictured below) on a curb in Manhattan in 2012 and it remains one of my favorite pieces of art. I have no idea who the artist is, or if it’s valuable, but I love it and that’s really all that matters.'
        )}
        <Image
          style={{ height: 60, width: 120, resizeMode: 'contain' }}
          source={require('@happymedium/components/assets/images/logo-hm-abbreviated-green.png')}
        />
      </View>

      <View style={styles.imageContainer}>
        <Image
          style={[styles.image, styles.imageVertical]}
          source={
            'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/004/6+-+resize+.jpg' as ImageURISource
          }
        />
        {renderImageDescription('Ghost Crab, found 2012')}
      </View>

      <View style={[styles.imageContainer, { marginBottom: 0, marginTop: 20 }]}>
        <Image style={[styles.image, styles.squiggle]} source={blueSquiggle} />
      </View>

      <View style={{ height: 80 }} />
    </View>
  );
};
