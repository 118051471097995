import { Activity } from '../components/ApolloComponents';

export const salonSevenData: Activity[] = [
  {
    id: '1',
    rank: 1,
    title: '#1 - Just Kids',
    organizer: 'Patti Smith',
    activityType: ['read'],
    imageUrl:
      'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/007/Books/1+resize.jpg',
    activityUrl: 'https://www.mcnallyjackson.com/book/9780060936228',
    shortDescription: '',
    longDescription:
      'In a nutshell, this is an autobiographical account of Patti Smith and her partner Robert Mapplethorpe’s first few years trying to make it in New York.\n\nBut in a lobster shell, this is the story of two lovers struggling, clawing, scraping their way to discover their true selves set against the backdrop of golden age of art and music in New York City.\n\nIt’s a fascinating insider look at a cast of characters who would come to define the cultureal zeitgeist - Mapplethorpe, Shepard, Warhol, Joplin, Henrdix, and more.\n\nI recommend this book to everyone I meet and reread it often myself.',
    activityUrlDescriptorCopy: 'Buy it ',
    activityUrlPressableCopy: 'here',
  },
  {
    id: '2',
    rank: 2,
    title: '#2 - The Lives of the Artists',
    organizer: 'Vasari',
    activityType: ['read'],
    imageUrl:
      'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/007/Books/2+resize.jpg',
    activityUrl: 'https://www.mcnallyjackson.com/book/9780140445008',
    shortDescription: '',
    longDescription:
      'Vasari is the first recognized art historian and the first to ever publish the word “Renaissance” in regards to the art movement happening in Italy in the 15th century. He was an artist himself but then got really interested in other working artists, their techniques, and body of works.\n\nSo he started sniffing around, interviewed artists, friends of artists, gathered gossip and local legends, and then out it all into an encyclopedic series officially called “Lives of the Most Excellent Painters, Sculptors, and Architects.”\n\nWe know that not all of it is totally true, but it’s the most definitive and complete history we have of Renaissance-era artists and covers everyone from Michelangelo to Boticelli (pictured here).',
    activityUrlDescriptorCopy: 'Buy Volume 1 ',
    activityUrlPressableCopy: 'here',
  },
  {
    id: '3',
    rank: 3,
    title: '#3 - Autobiography of Alice Tolkas',
    organizer: 'Gertrude Stein',
    activityType: ['read'],
    imageUrl:
      'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/007/Books/3+resize.jpg',
    activityUrl:
      'https://www.amazon.com/Autobiography-Alice-B-Toklas/dp/067972463X',
    shortDescription: '',
    longDescription:
      'If you’ve been with us since our first salon, then you know that this book is Gertrude Stein’s magnum opus - it brought her world renown fame after a life of literary obscurity.\n\nIn the same way Just Kids captures the late 60/ early 70s NYC art scene, The Autobiography captures turn of the century Paris through the eyes of Steins partner, Alice Tolkas.\n\nAlice had a front row seat to the lives of artists Picasso, Matisse, Apollinaire and writers Sherwood Anderson, T.S. Eliot, and Ernest Hemmingway - all of whom make appearances in the book.',
    activityUrlDescriptorCopy: 'Buy it ',
    activityUrlPressableCopy: 'here',
  },
  {
    id: '4',
    rank: 4,
    title: '#4 - Leonardo da Vinci',
    organizer: 'Walter Isaacson',
    activityType: ['read'],
    imageUrl:
      'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/007/Books/4+resize.jpg',
    activityUrl: 'https://www.mcnallyjackson.com/book/9781501139161',
    shortDescription: '',
    longDescription:
      'Honestly, you might need 3 holiday breaks to get through this one, but it is so worth it.\n\nWalter Isaacson is the preeminent biographer of our day. So much so that Steve Jobs personally chose him to write his biography.\n\nIsaacson turns his impecible investigative and storytelling talents towards Leonardo, in the process, and brings this mythical legend to life.',
    activityUrlDescriptorCopy: 'Buy it ',
    activityUrlPressableCopy: 'here',
  },
  {
    id: '5',
    rank: 5,
    title: '#5 - Life with Picasso',
    organizer: 'Françoise Gilot',
    activityType: ['read'],
    imageUrl:
      'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/007/Books/5+resize+.jpg',
    activityUrl: 'https://www.mcnallyjackson.com/book/9781681373195',
    shortDescription: '',
    longDescription:
      'Francçoise is an artist and former muse of Picasso. She is still alive and exhibited her work as recently as last fall in New York.\n\nIn this memoir, she recounts her life with Picasso, a notorious womanizer. I’ve yet to read it, but have long been fascinated by Picasso’s relationship with women - his first muses, dealers, and patrons were all women.\n\nThis book gives a rare, unfiltered look at what life was like with the most recognizable artist of the 20th century.',
    activityUrlDescriptorCopy: 'Buy it ',
    activityUrlPressableCopy: 'here',
  },
  {
    id: '6',
    rank: 6,
    title: '#6 - The Rape of Europa',
    organizer: 'Lynn H. Nicholas',
    activityType: ['read'],
    imageUrl:
      'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/007/Books/6+resize.+jpg.jpg',
    activityUrl: 'https://www.mcnallyjackson.com/book/9780679756866',
    shortDescription: '',
    longDescription:
      "This book explores the Nazi plunder of looted art during WWII, the results of which are still acutely felt today.\n\nYou can watch the documentary, but nothing replaces the level of detail in the book, which won the author the Légion d'Honneur in France.",
    activityUrlDescriptorCopy: 'Buy it ',
    activityUrlPressableCopy: 'here',
  },
  {
    id: '7',
    rank: 7,
    title: '#7 - The Goldfinch',
    organizer: 'Donna Tartt',
    activityType: ['read'],
    imageUrl:
      'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/007/Books/7+resize.jpg',
    activityUrl: 'https://www.mcnallyjackson.com/book/9780316055420',
    shortDescription: '',
    longDescription:
      'Theo, the main character, loses his mother in a terrorist bombing at an art museum and as he flees the scene he grabs a small Dutch Golden Age painting to take with him.\n\nThe rest of the novel follows his life with the painting as he struggles to come to terms with the loss of his mom.\n\nWhen the book was published, it sparked renewed interested into the actual Goldfinch painting - a work created by Rembrandt pupil  Carel Fabrititus who lost his own life in an explosion in 1654.\n\nThe book was turned into a movie if that’s more your speed.',
    activityUrlDescriptorCopy: 'Buy it ',
    activityUrlPressableCopy: 'here',
  },
  {
    id: '8',
    rank: 8,
    title: '#8 - The Girl with a Pearl Earring',
    organizer: 'Tracy Chevalier',
    activityType: ['read'],
    imageUrl:
      'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/007/Books/8+resaved.jpg',
    activityUrl: 'https://www.mcnallyjackson.com/book/9780452282155',
    shortDescription: '',
    longDescription:
      'This is the fictitional story of the muse behind Johannes Vermeer’s Girl with a Pearl Earring.\n\nThe author was inspired by a poster of the actual painting, which she hung in her house for 16 years.\n\nThe story recounts the fictitional lives of the artist and his muse in Delft in 1664.\n\nThis one was also made into a movie, which you can stream on Amazon.',
    activityUrlDescriptorCopy: 'Buy it ',
    activityUrlPressableCopy: 'here',
  },
  {
    id: '9',
    rank: 9,
    title: '#9  - The Artist’s Way',
    organizer: 'Julia Cameron',
    activityType: ['read'],
    imageUrl:
      'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/007/Books/9+resize.jpg',
    activityUrl: 'https://www.mcnallyjackson.com/book/9780143129257',
    shortDescription: '',
    longDescription:
      "The OG artist self-help book.\n\nDon't be turned off by the corny title, there is real power in this book which guides you through a 12-week journey of creative self-discovery. The course goes something like this - you read one chapter each week and tackle a new obstacle that stands between you and the improved version of yourself.\n\nThere is homework, both daily and weekly, but most of it is fun. We're a big fan of the requisite Artist Dates, which involve spending 2 hours of time alone doing something indulgent.\n\nAlicia Keys, Pete Townshend, Elizabeth Gilbert, and Tim Ferriss are all fans.",
    activityUrlDescriptorCopy: 'Buy it ',
    activityUrlPressableCopy: 'here',
  },
  {
    id: '10',
    rank: 10,
    title: '#10 - The War of Art',
    organizer: 'Steven Pressfield',
    activityType: ['read'],
    imageUrl:
      'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/007/Books/10+resize.jpg',
    activityUrl: 'https://www.mcnallyjackson.com/book/9781936891023',
    shortDescription: '',
    longDescription:
      'Of the same ilk as Artist’s Way, this book is designed to help you break through your creative block.\n\nIt’s a classic and one I’ll be revisiting this holiday.\n\nFollow up with Do the Work by the same author.',
    activityUrlDescriptorCopy: 'Buy it ',
    activityUrlPressableCopy: 'here',
  },
  {
    id: '11',
    rank: 11,
    title: '#1 - Beyond the Visible',
    activityType: ['watch'],
    imageUrl:
      'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/007/movies/1mresize.jpg',
    activityUrl:
      'https://www.amazon.com/Beyond-Visible-Hilma-af-Klint/dp/B08BCSTXFW',
    shortDescription: '',
    longDescription:
      'Hilma af Klint was an abstract artist before the term existed: a visionary, trailblazing figure who—inspired by spiritualism, modern science, and the riches of the natural world around her—began in 1906 to reel out a series of huge, colorful, sensual, strange works without precedent in painting.\n\nThe subject of a recent smash retrospective at the Guggenheim Museum, af Klint was for years an all-but-forgotten figure in art historical discourse, before her long-delayed rediscovery.',
    activityUrlDescriptorCopy: 'Watch the documentary ',
    activityUrlPressableCopy: 'here',
  },
  {
    id: '12',
    rank: 12,
    title: '#2 - Finding Vivian Maier',
    activityType: ['watch'],
    imageUrl:
      'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/007/movies/2mresize.jpg',
    activityUrl:
      'https://www.amazon.com/Finding-Vivian-Maier-John-Maloof/dp/B00LLHJSUO',
    shortDescription: '',
    longDescription:
      'A look at the life and career of nanny Vivian Maier and her unbelievable collections of 100,000 photographs, which were purchased at auction after her death.',
    activityUrlDescriptorCopy: 'Watch the documentary ',
    activityUrlPressableCopy: 'here',
  },
  {
    id: '13',
    rank: 13,
    title: '#3 - Big Eyes',
    activityType: ['watch'],
    imageUrl:
      'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/007/movies/3m.resizejpg.jpg',
    activityUrl: 'https://www.amazon.com/Big-Eyes-Amy-Adams/dp/B00RFHU2ZO',
    shortDescription: '',
    longDescription:
      "Big Eyes is about the life of American artist Margaret Keane—famous for painting and drawing portraits of people with big eyes.\n\nIt follows the story of Margaret and her husband, Walter Keane, who took credit for Margaret's phenomenally successful and popular paintings in the 1950s and 1960s. It follows the lawsuit and trial between Margaret and Walter, after Margaret reveals she is the true artist behind the paintings.",
    activityUrlDescriptorCopy: 'Watch the movie ',
    activityUrlPressableCopy: 'here',
  },
  {
    id: '14',
    rank: 14,
    title: '#4 - Renoir',
    activityType: ['watch'],
    imageUrl:
      'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/007/movies/4mresize.jpg',
    activityUrl: 'https://www.youtube.com/watch?v=3Cv9KxLIHAE',
    shortDescription: '',
    longDescription:
      'Based on the last years of Pierre-Auguste Renoir at Cagnes-sur-Mer during World War I, the film tells the forgotten story of Andrée Heuschling, also known as Catherine Hessling, who was the last model of impressionist painter Pierre-Auguste Renoir and the first actress in the films of his son, the film director Jean Renoir.\n\nAndrée was the link between two famous and widely acclaimed artists, a father and son. While the father is at the end of his brilliant career, the son is still searching for himself, his great career as one of the most celebrated movie directors having not yet begun.',
    activityUrlDescriptorCopy: 'Watch the movie ',
    activityUrlPressableCopy: 'here',
  },
  {
    id: '15',
    rank: 15,
    title: '#5 - Frida',
    activityType: ['watch'],
    imageUrl:
      'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/007/movies/5mresize.jpg',
    activityUrl: 'https://www.netflix.com/title/60024997',
    shortDescription: '',
    longDescription:
      "After a near-fatal accident, Frida Kahlo discovers her life's passion through painting as a turbulent marriage and political turmoil fuels her art.\n\nSalma Hayek stars as the artist in this 2002 drama based on the artist’s life.",
    activityUrlDescriptorCopy: 'Watch the movie ',
    activityUrlPressableCopy: 'here',
  },
  {
    id: '16',
    rank: 16,
    title: '#6 - At Eternity’s Gate',
    activityType: ['watch'],
    imageUrl:
      'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/007/movies/6mresize.jpg',
    activityUrl:
      'https://www.amazon.com/At-Eternitys-Gate-Willem-DaFoe/dp/B07N47X646',
    shortDescription: '',
    longDescription:
      "Julian Schnabel's At Eternity’s Gate is a journey inside the world and mind of a person who, despite skepticism, ridicule, and illness, created some of the world's most beloved and stunning works of art.\n\nWillem Dafoe stars as Vincent Van Gogh in this 2018 biopic.",
    activityUrlDescriptorCopy: 'Watch the movie ',
    activityUrlPressableCopy: 'here',
  },
  {
    id: '17',
    rank: 17,
    title: '#7 - The Thomas Crown Affair',
    activityType: ['watch'],
    imageUrl:
      'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/007/movies/7mresave.jpg',
    activityUrl:
      'https://www.amazon.com/Thomas-Crown-Affair-Pierce-Brosnan/dp/B000RLHDUM',
    shortDescription: '',
    longDescription:
      'Dreamboat Pierce Brosnan (aka James Bond) stars in this 90s remake about a very rich and successful playboy amuses himself by stealing artwork, but may have met his match in a seductive detective.',
    activityUrlDescriptorCopy: 'Watch the movie ',
    activityUrlPressableCopy: 'here',
  },
  {
    id: '18',
    rank: 18,
    title: '#8 - Midnight in Paris',
    activityType: ['watch'],
    imageUrl:
      'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/007/movies/8mresize.jpg',
    activityUrl:
      'https://www.amazon.com/Midnight-Paris-Kathy-Bates/dp/B006AN78XS',
    shortDescription: '',
    longDescription:
      'Set in Paris, the film follows Gil Pender, a screenwriter, who is forced to confront the shortcomings of his relationship with his materialistic fiancée and their divergent goals, which become increasingly exaggerated as he travels back in time each night at midnight.',
    activityUrlDescriptorCopy: 'Watch the movie ',
    activityUrlPressableCopy: 'here',
  },
  {
    id: '19',
    rank: 19,
    title: '#9 - Mona Lisa Smile',
    activityType: ['watch'],
    imageUrl:
      'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/007/movies/9mresize.jpg',
    activityUrl:
      'https://www.amazon.com/Mona-Lisa-Smile-Ginnifer-Goodwin/dp/B000JD1CBY',
    shortDescription: '',
    longDescription:
      'A free-thinking art professor teaches conservative 1950s Wellesley girls to question their traditional social roles.',
    activityUrlDescriptorCopy: 'Watch the movie ',
    activityUrlPressableCopy: 'here',
  },
  {
    id: '20',
    rank: 20,
    title: '#10 - I Am Love',
    organizer: 'The Desire Trilogy',
    activityType: ['watch'],
    imageUrl:
      'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/007/movies/10m1resize.jpg',
    activityUrl: 'https://www.amazon.com/dp/B0046XJH5Q',
    shortDescription: '',
    longDescription:
      "At the heart of the wealthy Recchi family is Tancredi Recchi's wife Emma (Tilda Swinton), whose existence is shocked to the core when she embarks on a passionate love affair that will change her family forever.",
    activityUrlDescriptorCopy: 'Watch the movie ',
    activityUrlPressableCopy: 'here',
  },
  {
    id: '21',
    rank: 21,
    title: '#11 - A Bigger Splash',
    organizer: 'The Desire Trilogy',
    activityType: ['watch'],
    imageUrl:
      'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/007/movies/10m2resize.jpg',
    activityUrl:
      'https://www.amazon.com/Bigger-Splash-Ralph-Fiennes/dp/B01IDXQJ1O',
    shortDescription: '',
    longDescription:
      'Tilda Swinton, Ralph Fiennes & Dakota Johnson star in this provocative story of a couple caught in a whirlwind of passion and jealousy on an Italian island.',
    activityUrlDescriptorCopy: 'Watch the movie ',
    activityUrlPressableCopy: 'here',
  },
  {
    id: '22',
    rank: 22,
    title: '#12 - Call Me By Your Name',
    organizer: 'The Desire Trilogy',
    activityType: ['watch'],
    imageUrl:
      'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/007/movies/10m3resize.jpg',
    activityUrl:
      'https://www.amazon.com/Call-Your-Name-Armie-Hammer/dp/B0791VJLVB',
    shortDescription: '',
    longDescription:
      'A teenage boy becomes enamored with an American student who comes to stay with his family in Northern Italy. Together they share an unforgettable summer of discovery and romance.',
    activityUrlDescriptorCopy: 'Watch the movie ',
    activityUrlPressableCopy: 'here',
  },
];
