import React, { useState } from 'react';
import {
  View,
  TextInput,
  StyleSheet,
  Text,
  Image,
  TouchableOpacity,
  ActivityIndicator,
  Linking,
} from 'react-native';
import { Formik } from 'formik';
import { useMutation } from '@apollo/react-hooks';
import { Hoverable } from 'react-native-web-hooks';

import { GTAmerica, PanamaBold } from '../../libs/constants';
import { SubscribeNewsletterDocument } from '../ApolloComponents';
import { useThemeState } from '../../context/ThemeContext';
import { useDimensions } from '../../context/DimensionsContext';
import {
  emailRegex,
  invalidEmailErrorMessage,
  existingEmailAddressErrorMessage,
} from '../../constants';

interface Props {}

interface FormValues {
  email: string;
}

export const ThemedSubscribeForm: React.FC<Props> = () => {
  const { theme } = useThemeState();
  const lgBreakpoint = 1180;
  const mdBreakpoint = 860;
  const smBreakpoint = 420;

  const { width } = useDimensions();
  const styles = StyleSheet.create({
    container: {
      alignContent: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      backgroundColor: theme.backgroundColor,
      // marginVertical: 20,
      borderBottomColor: theme.foregroundColor,
      borderBottomWidth: 0,
      height:
        width < smBreakpoint
          ? 160
          : width < mdBreakpoint
          ? 200
          : width < lgBreakpoint
          ? 180
          : 200,
    },
    subscribeForm: {
      marginTop: 20,
      marginBottom: 20,
      marginHorizontal: 0,
      paddingHorizontal: 15,
      width: '100%',
      flexGrow: 1,
      maxWidth: 500,
      flexDirection: 'row',
      // flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
    },
    emailInput: {
      minWidth: '50%',
      maxWidth: '90%',
      backgroundColor: theme.backgroundColor,
      fontFamily: PanamaBold,
      height: 29,
      fontSize: 18,
      letterSpacing: 0.9,
      paddingLeft: 2,
      color: theme.foregroundColor,
      // placeholderTextColor: theme.foregroundColor, // TODOD: throwing an error?
      flexGrow: 1,
      borderBottomWidth: 1,
      borderBottomColor: theme.foregroundColor,
    },

    submitArrow: {
      width: 15,
      height: 10,
      left: -15,
    },
  });
  const submitArrow = require(`@happymedium/components/assets/images/arrow-right-${
    theme.logoColor
  }@300x.png`);
  const [subscribe, { data }] = useMutation(SubscribeNewsletterDocument);
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [displayError, setDisplayError] = useState(false);
  const [displayDup, setDisplayDup] = useState(false);
  const [hideForm, setHideForm] = useState(false);
  const handleOnSubmit = (values: FormValues, handleSubmit: () => void) => {
    setDisplayDup(false);
    setDisplayError(false);
    if (
      values.email === null ||
      values.email === '' ||
      !emailRegex.test(values.email)
    ) {
      setDisplayError(true);
      return;
    }
    handleSubmit();
  };
  return (
    <View style={styles.container}>
      <Formik<FormValues>
        initialValues={{ email: '' }}
        onSubmit={async (values, { resetForm }) => {
          setDisabled(true);
          setLoading(true);
          const result = await subscribe({
            variables: {
              email: values.email,
            },
          });
          if (result.data.subscribe.success === true) {
            setHideForm(true);
            setDisabled(false);
            setLoading(false);
            resetForm({ values: { email: '' } });
          }
          if (
            result.data.subscribe.success === false &&
            result.data.subscribe.errors[0].message === 'Already in the squad'
          ) {
            setDisplayDup(true);
            setLoading(false);
            setDisabled(false);
            // console.log('squad');
          }
          // console.log('result', result);
          // console.log('data', data);
        }}
      >
        {({
          handleBlur,
          handleChange,
          handleSubmit,
          values,
          dirty,
          touched,
        }) => (
          <View style={{ width: '100%' }}>
            {hideForm ? (
              <View style={{ width: '100%' }}>
                <Text
                  style={{
                    textAlign: 'center',
                    fontFamily: GTAmerica,
                    textTransform: 'uppercase',
                    color: theme.foregroundColor,
                    fontSize: 24,
                  }}
                >
                  Thanks for signing up!
                </Text>
              </View>
            ) : (
              <View style={{ alignItems: 'center' }}>
                <Text
                  style={{
                    textAlign: 'center',
                    fontFamily: PanamaBold,
                    // fontWeight: '300',
                    fontSize: 28,
                    letterSpacing: 1.1,
                    color: theme.foregroundColor,
                    // marginBottom: 5,
                  }}
                >
                  Join The Salon
                </Text>
                <Text
                  style={{
                    textAlign: 'center',
                    fontFamily: GTAmerica,
                    fontWeight: '300',
                    fontSize: 14,
                    letterSpacing: 1,
                    color: theme.foregroundColor,
                    marginBottom: 10,
                  }}
                >
                  Weekly stories about art, culture, and creativity.
                </Text>

                <View style={styles.subscribeForm}>
                  <TextInput
                    onChangeText={handleChange('email')}
                    onBlur={handleBlur('email')}
                    value={values.email}
                    onSubmitEditing={() => {
                      handleOnSubmit(values, handleSubmit);
                    }}
                    style={[styles.emailInput, {}]}
                    placeholder="Your email"
                    keyboardType="email-address"
                  />
                  {loading ? <ActivityIndicator /> : null}
                  <TouchableOpacity
                    // loading={loading}
                    disabled={disabled}
                    onPress={() => {
                      handleOnSubmit(values, handleSubmit);
                    }}
                  >
                    <Image source={submitArrow} style={styles.submitArrow} />
                  </TouchableOpacity>
                </View>
              </View>
            )}
            {displayError ? (
              <View>
                <Text
                  style={{
                    textAlign: 'center',
                    color: theme.foregroundColor,
                    fontFamily: GTAmerica,
                  }}
                >
                  {invalidEmailErrorMessage}
                </Text>
              </View>
            ) : null}
            {displayDup ? (
              <View>
                <Text
                  style={{
                    textAlign: 'center',
                    color: theme.foregroundColor,
                    fontFamily: GTAmerica,
                  }}
                >
                  {existingEmailAddressErrorMessage}
                </Text>
              </View>
            ) : null}
            {!hideForm && (
              <Hoverable>
                {(isHovered) => (
                  <TouchableOpacity
                    onPress={() => Linking.openURL('/join-the-salon')}
                  >
                    <Text
                      style={{
                        textTransform: 'uppercase',
                        fontFamily: PanamaBold,
                        fontSize: 14,
                        letterSpacing: 1.3,
                        color: theme.foregroundColor,
                        textDecorationLine: isHovered ? 'underline' : 'none',
                        textAlign: 'center',
                      }}
                    >
                      Read it first
                    </Text>
                  </TouchableOpacity>
                )}
              </Hoverable>
            )}
          </View>
        )}
      </Formik>
    </View>
  );
};
