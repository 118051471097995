import React from 'react';
import { View, StyleSheet, Text, Image, ScrollView } from 'react-native';
import { CuratorHeader } from '../components/curator/CuratorHeader';
import { useThemeState } from '../context/ThemeContext';
import { GTAmerica, PanamaBold } from '../libs/constants';
import { useDimensions } from '../context/DimensionsContext';
import { SalonSignup } from '../components/salon/SalonSignup';
import { HoverableLink } from '../components/common/HoverableLink';
import { RouteComponentProps } from '../navigation';
import { useRef } from 'react';
import { smBreakpoint } from '../constants';
import { H1 } from '@expo/html-elements';

interface Props {}

export const WhereIsMyPaintAPotScreen: React.FC<
  RouteComponentProps<{
    menu: string;
  }>
> = (props: RouteComponentProps<{ menu: string }>) => {
  document.title = 'Where Is My Paint-a-Pot | Happy Medium';
  const { theme } = useThemeState();
  const { width } = useDimensions();

  const {
    match: {
      params: { menu },
    },
  } = props;

  const scrollToMenu = menu === 'menu';

  const scrollRef = useRef<ScrollView>(null);

  // if (scrollToMenu) {
  //   scrollRef.current && scrollRef.current.scrollToEnd({ animated: true });
  // }

  const mdBreakpoint = 860;
  const flowers = require('@happymedium/components/assets/images/arrangement.png');
  const styles = StyleSheet.create({
    container: {
      backgroundColor: theme.backgroundColor,
      flex: 1,
    },
    body: {
      color: theme.foregroundColor,
      fontFamily: GTAmerica,
      fontSize: width < mdBreakpoint ? 12 : 14,
    },
    header: {
      color: theme.foregroundColor,
      fontFamily: PanamaBold,
      fontSize: width < mdBreakpoint ? 20 : 22,
      letterSpacing: 1.2,
      textTransform: 'uppercase',
    },
  });
  return (
    <ScrollView
      ref={scrollRef}
      style={{ backgroundColor: theme.primaryBackgroundColor }}
      onContentSizeChange={() => {
        if (scrollToMenu) {

          scrollRef.current?.scrollTo(width < smBreakpoint ? 2000 : 3000)
        }
      }}
    >
      <CuratorHeader />
      <View style={[styles.container]}>

        <View
          style={{
            maxWidth: width < mdBreakpoint ? '90%' : 540,
            marginTop: width < mdBreakpoint ? 20 : 60,
            alignSelf: 'center',
            backgroundColor: theme.backgroundColor,
          }}
        >
          <H1
            style={{
                fontFamily: GTAmerica,
                fontWeight: "300",
                fontSize: width < smBreakpoint ? 12 : width < mdBreakpoint ? 14 : 16,
            }}
          >
           
          </H1>
          <View style={{ height: 20 }} />
          
          <Text style={styles.body}>
          Hi!
          {"\n\n"}
Thanks so much for painting with us!{"\n\n"}
"Paint-a-Pots" are ready and 2-3 weeks and we're open for pickups Friday and Satruday from 12-9pm and Sunday from 12-7pm. {"\n\n"}
 Use this page to track your piece(s) as it goes through a few steps to become a gorgeous, waterproof piece of ceramic: 
{"\n\n"}
-	Final Glaze – After you paint it, our team coats it in a clear glaze.  {"\n\n"}
-	Ready! – After being glazed, they are fired a second time but this time to melt the glaze, which is glass, onto your pottery. This process can take up to 5 days depending on the kiln size from loading, to firing, cooldown, and unpacking.  {"\n\n"}
- Archived - After 2 weeks, we move pieces to basement storage. We cannot guarantee that pieces that are archived are still available. To retrieve a piece out of the archive, there is a $15 fee. {"\n\n"}
To find a status of your piece, please identify the weekend you came in the cohort table below, and then find it in our pottery tracker below! 
{"\n\n"}
Weekends to Cohort table: {"\n"}
6/3-6/4- Archived {"\n"}
6/10-6/11 – Archived {"\n"}
6/17-6/18 – Archived{"\n"}
6/24-6/25 – Archived {"\n"}
7/1-7/2 – Archived{"\n"}
7/7-7/9 – Archived{"\n"}
7/14-7/16 – Archived{"\n"}
7/21-7/22 – Archived{"\n"}
7/28-7/30 – Archived{"\n"}
8/4-8/6 – Archived{"\n"}
8/11-8/13 – Archived {"\n"}
8/18-8/20 – Archived{"\n"}
8/25-8/27 – Archived{"\n"}
9/1-9/3 – Ready{"\n"}
9/8-9/10 – Ready{"\n"}
9/15-9/17 – Ready {"\n"}
9/22-9/24 – Ready {"\n"}
9/29-10/1 – Ready{"\n"}
10/6-10/8 – Ready{"\n"}
10/13- 10/15 -  Ready {"\n"}
10/20- 10/22 -  Ready{"\n"}
10/28 - 10/30 -  Ready {"\n"}
11/3 - 11/5 -  Small delay {"\n"}
11/10 - 11/12 -  On Track {"\n"}
11/17 - 11/19 - On Track{"\n"}
        </Text>
          
          <View style={{ height: 80 }} />
        </View>
      </View>
      <SalonSignup />
    </ScrollView>
  );
};
