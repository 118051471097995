import React from 'react';
import { View, Text, Image, ImageURISource, StyleSheet } from 'react-native';
import { format } from 'date-fns';

import { ArtSubmission } from '../ApolloComponents';
import { useDimensions } from '../../context/DimensionsContext';
import { useThemeState } from '../../context/ThemeContext';
import { GTAmerica, whiteBrand } from '../../libs/constants';
import { Linking } from 'react-native';
import { Platform } from '../../libs/platform';

interface Props {
  drawing: Partial<ArtSubmission>;
  index: number;
}

export const SubmittedDrawing: React.FC<Props> = ({ drawing }) => {
  const lgBreakpoint = 1180;
  const mdBreakpoint = 860;
  const smBreakpoint = 420;
  const { title, artistName, artistInstaHandle, createdDate } = drawing;
  const { width } = useDimensions();
  const { theme } = useThemeState();
  const styles = StyleSheet.create({
    container: {
      flex: 1,
      justifyContent: 'space-evenly',
      alignItems: 'center',
      alignContent: 'center',
      borderRightWidth: 2,
      borderBottomWidth: 2,
      borderColor: theme.foregroundColor,
      backgroundColor: whiteBrand,
      width:
        width < mdBreakpoint
          ? width
          : width < lgBreakpoint
          ? width / 2
          : width / 3,
      height: 550,
      paddingBottom: 20,
    },
    drawing: {
      height: 400,
      resizeMode: 'contain',
      width: 430,
    },
    infoContainer: {
      width: '100%',
      height: 80,
      justifyContent: width < smBreakpoint ? 'center' : 'flex-start',
      paddingLeft: width < smBreakpoint ? 0 : width < mdBreakpoint ? 60 : 90,
      alignContent: width < smBreakpoint ? 'center' : 'flex-start',
      alignItems: width < smBreakpoint ? 'center' : 'flex-start',
    },
    info: {
      color: theme.foregroundColor,
      fontFamily: GTAmerica,
      fontSize: 16,
      fontWeight: '400',
      marginBottom: 10,
    },
  });

  const handlePress = (url: string) => {
    if (Platform.OS === 'web') {
      window.open(url, '__blank');
    } else {
      if (url) {
        Linking.openURL(url);
      }
    }
  };

  return (
    <View collapsable={false} style={styles.container}>
      <Image
        style={styles.drawing}
        source={drawing.imageUri as ImageURISource}
      />
      <View style={styles.infoContainer}>
        <View style={{ flexDirection: 'row' }}>
          {title ? (
            <Text style={styles.info}>{title}</Text>
          ) : (
            <Text style={styles.info}>Untitled</Text>
          )}
          {artistName ? (
            <Text style={styles.info}> by {artistName}</Text>
          ) : (
            <Text style={styles.info}> by Unknown</Text>
          )}
        </View>
        {artistInstaHandle ? (
          <Text style={styles.info}>
            IG:{' '}
            <Text
              style={{ textDecorationLine: 'underline' }}
              onPress={() =>
                handlePress(
                  `https://www.instagram.com/${artistInstaHandle.split('@')[1]}`
                )
              }
            >
              {artistInstaHandle}
            </Text>
          </Text>
        ) : null}
        <Text style={styles.info}>
          {format(new Date(createdDate), 'M.dd.yyyy')}
        </Text>
      </View>
    </View>
  );
};
