import React from 'react';
import { View, Image, ImageURISource, StyleSheet, Text } from 'react-native';
import { Hoverable } from 'react-native-web-hooks';

import {
  greenBrand,
  PanamaBold,
  GTAmerica,
  GTAmericaCompressed,
} from '../../../libs/constants';
import { useDimensions } from '../../../context/DimensionsContext';
import { mdBreakpoint, smBreakpoint, lgBreakpoint } from '../../../constants';
import { TouchableOpacity } from '../../common/TouchableOpacity';
import { HoverableLink } from '../../common/HoverableLink';

interface SalonIssueProps {}

export const FigureDrawingByEdith: React.FC<SalonIssueProps> = () => {
  const { width } = useDimensions();
  const blueSquiggle = require('@happymedium/components/assets/images/squiggles-blue.png');
  const redSquiggle = require('@happymedium/components/assets/images/squiggles-red.png');

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      flexDirection: 'column',
    },
    innerContainer: {
      maxWidth: width < 900 ? '85%' : 700,
      alignSelf: 'center',
    },
    imageContainer: {
      alignItems: 'center',
      marginVertical:
        width < smBreakpoint ? 20 : width < mdBreakpoint ? 35 : 60,
    },
    text: {
      color: greenBrand,
      flexWrap: 'wrap',
    },
    header: {
      fontFamily: PanamaBold,
      letterSpacing: 1.3,
      fontSize: width < smBreakpoint ? 28 : width < mdBreakpoint ? 36 : 48,
      marginBottom: width < smBreakpoint ? 5 : width < mdBreakpoint ? 15 : 20,
    },
    body: {
      fontFamily: GTAmerica,
      fontWeight: '300',
      letterSpacing: 0.45,
      fontSize: width < smBreakpoint ? 14 : width < mdBreakpoint ? 15 : 16,
      marginVertical: 10,
      textAlign: 'justify',
    },
    imageDescription: {
      fontFamily: GTAmericaCompressed,
      fontWeight: '300',
      letterSpacing: 0.45,
      fontSize: width < smBreakpoint ? 12 : width < mdBreakpoint ? 14 : 16,
      marginVertical: width < smBreakpoint ? 5 : 8,
      textAlign: 'center',
      maxWidth: width < smBreakpoint ? '90vw' : '100%',
    },
    shareButton: {},
    arrow: {},
    squiggle: {
      height: 31,
      width: 123,
      alignSelf: 'center',
      resizeMode: 'contain',
      marginBottom: 0,
      marginTop: 25,
    },
    image: {
      marginHorizontal: 30,
      maxWidth: width < mdBreakpoint ? '80%' : '50%',
    },
    imageVertical: {
      // height: width < smBreakpoint ? 400 : width < mdBreakpoint ? 500 : 550,
      width: '100%',
      maxWidth: '100%',
      marginHorizontal: 0,
      height:
        width < smBreakpoint
          ? 1200
          : width < mdBreakpoint
          ? 1400
          : width < lgBreakpoint
          ? 1500
          : 2000,
      alignSelf: 'center',
      resizeMode: 'contain',
    },
    imageHorizontal: {
      height:
        width < smBreakpoint
          ? 280
          : width < mdBreakpoint
          ? 350
          : width < lgBreakpoint
          ? 420
          : 550,
      width: '100%',
      alignSelf: 'center',
      resizeMode: 'contain',
    },
  });

  const renderHeader = (copy: string) => (
    <Text style={[styles.text, styles.header]}>{copy}</Text>
  );

  const renderParagraph = (copy: string) => (
    <Text style={[styles.text, styles.body]}>{copy}</Text>
  );

  const renderImageDescription = (copy: string) => (
    <Text style={[styles.text, styles.imageDescription]}>{copy}</Text>
  );

  return (
    <View style={styles.container}>
      <Image
        style={[
          styles.image,
          styles.imageVertical,
          {
            marginHorizontal: 0,
            maxWidth: '100%',
          },
        ]}
        source={{
          uri:
            'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/014/Happy+Medium_Figure+Drawing_Summer+2022+Visual+Essay_August+11.jpg',
        }}
      />
      <Image
        style={[styles.image, styles.imageVertical]}
        source={{
          uri:
            'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/014/Happy+Medium_Figure+Drawing_Summer+2022+Visual+Essay_August+112b.jpg',
        }}
      />
      <Image
        style={[styles.image, styles.imageVertical]}
        source={{
          uri:
            'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/014/Happy+Medium_Figure+Drawing_Summer+2022+Visual+Essay_August+113.jpg',
        }}
      />
      <Image
        style={[styles.image, styles.imageVertical]}
        source={{
          uri:
            'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/014/Happy+Medium_Figure+Drawing_Summer+2022+Visual+Essay_August+114.jpg',
        }}
      />
      <Image
        style={[styles.image, styles.imageVertical]}
        source={{
          uri:
            'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/014/Happy+Medium_Figure+Drawing_Summer+2022+Visual+Essay_August+115.jpg',
        }}
      />
      <Image
        style={[styles.image, styles.imageVertical]}
        source={{
          uri:
            'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/014/Happy+Medium_Figure+Drawing_Summer+2022+Visual+Essay_August+116.jpg',
        }}
      />
      <Image
        style={[styles.image, styles.imageVertical]}
        source={{
          uri:
            'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/014/Happy+Medium_Figure+Drawing_Summer+2022+Visual+Essay_August+117.jpg',
        }}
      />
    </View>
  );
};
