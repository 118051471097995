import React, { useState } from 'react';
import { View, Text, Image, StyleSheet, Linking, Platform } from 'react-native';

import { GTAmerica } from '../../libs/constants';
import { useThemeState } from '../../context/ThemeContext';
import { Activity } from '../ApolloComponents';
import { CuratedHeader } from './CuratedActivityHeader';
import { useDimensions } from '../../context/DimensionsContext';
import { TouchableOpacity } from '../common/TouchableOpacity';

interface AcitvityProps {
  activity: Partial<Activity>;
  index: number;
}
interface Props {}

export const CuratedActivity: React.FC<AcitvityProps> = React.memo(
  ({ activity, index }) => {
    const lgBreakpoint = 1180;
    const mdBreakpoint = 860;
    const smBreakpoint = 420;
    const { theme } = useThemeState();
    const { width } = useDimensions();
    // const hilma = require('@happymedium/components/assets/images/hilma.png');
    const styles = StyleSheet.create({
      container: {
        display: 'flex',
        flexDirection: 'column',
        marginTop:
          width < smBreakpoint && index !== 0
            ? 50
            : width < smBreakpoint && index === 0
            ? 0
            : index !== 0
            ? 80
            : 20,
        marginBottom: width < smBreakpoint ? 40 : 100,
        backgroundColor: theme.backgroundColor,
        // flex: 1,
      },
      poster: {
        height: width < mdBreakpoint ? 450 : 387,
        resizeMode: 'cover',
        aspectRatio: 2,
        width: width < smBreakpoint ? width : 324,
      },
      bodyContainer: {
        display: 'flex',
        flexDirection: width < mdBreakpoint ? 'column' : 'row',
        marginTop: width < smBreakpoint ? 0 : 25,
        backgroundColor: theme.backgroundColor,
      },
      posterContainer: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        width: width < mdBreakpoint ? '100%' : '45%',
      },
      infoContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: width < mdBreakpoint ? '100%' : '55%',
        paddingRight: width < mdBreakpoint ? 0 : 60,
        alignItems: width < mdBreakpoint ? 'center' : 'flex-start',
      },
      infoRow: {
        display: 'flex',
        flexDirection: 'row',
        paddingTop: width < mdBreakpoint ? 34 : 20,
        paddingBottom: width < mdBreakpoint ? 18 : 20,
      },
      longDescription: {
        color: theme.foregroundColor,
        fontFamily: GTAmerica,
        fontWeight: '300',
        fontSize: 18,
        letterSpacing: 0.45,
        textAlign: width < mdBreakpoint ? 'center' : 'left',
        // flexWrap: 'wrap',
      },
      datePriceInfo: {
        fontFamily: GTAmerica,
        fontWeight: '500',
        letterSpacing: 0.7,
        color: theme.foregroundColor,
        textTransform: 'uppercase',
      },
      activityCTACopy: {
        color: theme.foregroundColor,
        fontWeight: '400',
        fontFamily: GTAmerica,
        letterSpacing: 0.7,
        marginVertical: 20,
        textAlign: width < mdBreakpoint ? 'center' : 'left',
      },
      link: {
        textDecorationLine: 'underline',
      },
      artAttribution: {
        color: theme.foregroundColor,
        fontWeight: '400',
        fontFamily: GTAmerica,
        letterSpacing: 0.7,
        marginVertical: 10,
        fontSize: 14,
      },
      artAttributionLink: {
        textDecorationLine: 'underline',
      },
    });
    const [displayLongDescription, toggleDisplayLongDescription] = useState(
      false
    );

    const handlePress = (url: string) => {
      if (Platform.OS === 'web') {
        window.open(url, '__blank');
      } else {
        if (url) {
          Linking.openURL(url);
        }
      }
    };

    const currencySymbol = activity.currency === 'GBP' ? '£' : '$';
    return (
      <View style={styles.container}>
        <CuratedHeader activity={activity} />
        <View style={styles.bodyContainer}>
          <View style={styles.posterContainer}>
            {activity.imageUrl ? (
              <Image style={styles.poster} source={activity.imageUrl as any} />
            ) : null}
            {activity.activityArtUrl ? (
              <View style={{ flex: 1, flexDirection: 'row' }}>
                <Text style={[styles.artAttribution]}>
                  {activity.activityArtUrlDescriptorCopy}
                </Text>
                <TouchableOpacity
                  onPress={() => {
                    activity.activityArtUrl &&
                      handlePress(activity.activityArtUrl);
                  }}
                >
                  <Text
                    style={[styles.artAttribution, styles.artAttributionLink]}
                  >
                    {activity.activityArtUrlPressableCopy}
                  </Text>
                </TouchableOpacity>
              </View>
            ) : null}
          </View>
          <View style={styles.infoContainer}>
            {width < mdBreakpoint ? (
              <>
                <View style={styles.infoRow}>
                  {activity.fullDateString ? (
                    <Text style={styles.datePriceInfo}>
                      {activity.fullDateString}
                    </Text>
                  ) : null}
                  {activity.price ? (
                    <Text
                      style={[
                        styles.datePriceInfo,
                        { marginLeft: activity.fullDateString ? 10 : 0 },
                      ]}
                    >
                      {currencySymbol + activity.price}
                    </Text>
                  ) : null}
                </View>
                <View
                  style={{ flex: 1, maxWidth: '100%', paddingHorizontal: 30 }}
                >
                  {activity.shortDescription ? (
                    <Text
                      style={{
                        color: theme.foregroundColor,
                        textTransform: 'capitalize',
                        fontFamily: GTAmerica,
                        fontWeight: '400',
                        fontSize: 16,
                        letterSpacing: 0.4,
                        textAlign: 'center',
                        // marginVertical: 10,
                      }}
                    >
                      {activity.shortDescription}
                    </Text>
                  ) : null}
                  <Text
                    style={{
                      color: theme.foregroundColor,
                      textTransform: 'uppercase',
                      fontFamily: GTAmerica,
                      fontWeight: '500',
                      fontSize: 14,
                      letterSpacing: 0.35,
                      textAlign: 'center',
                      paddingTop: 18,
                      paddingBottom: 34,
                    }}
                    onPress={() => {
                      toggleDisplayLongDescription(!displayLongDescription);
                    }}
                  >
                    {displayLongDescription ? 'Less -' : 'More +'}
                  </Text>
                  {displayLongDescription ? (
                    <View style={{ flex: 1, maxWidth: '100%' }}>
                      <View
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                        }}
                      >
                        <Text
                          style={[
                            styles.longDescription,
                            { flex: 1, flexWrap: 'wrap', maxWidth: '100%' },
                          ]}
                        >
                          {activity.longDescription}
                        </Text>
                      </View>
                      {activity.activityUrl ? (
                        <View
                          style={{ flexDirection: 'row', alignSelf: 'center' }}
                        >
                          <Text style={[styles.activityCTACopy]}>
                            {activity.activityUrlDescriptorCopy}
                          </Text>
                          <TouchableOpacity
                            onPress={() => {
                              activity.activityUrl &&
                                handlePress(activity.activityUrl);
                            }}
                          >
                            <Text style={[styles.activityCTACopy, styles.link]}>
                              {activity.activityUrlPressableCopy}
                            </Text>
                          </TouchableOpacity>
                        </View>
                      ) : null}
                    </View>
                  ) : null}
                </View>
              </>
            ) : null}
            {width > mdBreakpoint ? (
              <>
                <Text
                  style={[
                    styles.longDescription,
                    { marginBottom: 10, fontWeight: '500' },
                  ]}
                >
                  {activity.shortDescription}
                </Text>
                <Text style={styles.longDescription}>
                  {activity.longDescription}
                </Text>
                <View style={styles.infoRow}>
                  {activity.fullDateString ? (
                    <Text style={styles.datePriceInfo}>
                      {activity.fullDateString}
                    </Text>
                  ) : null}
                  {activity.price ? (
                    <Text
                      style={[
                        styles.datePriceInfo,
                        { marginLeft: activity.fullDateString ? 10 : 0 },
                      ]}
                    >
                      {currencySymbol + activity.price}
                    </Text>
                  ) : null}
                </View>
                {activity.activityUrl ? (
                  <View style={{ flexDirection: 'row' }}>
                    <Text style={[styles.activityCTACopy]}>
                      {activity.activityUrlDescriptorCopy}
                    </Text>
                    <TouchableOpacity
                      onPress={() => {
                        activity.activityUrl &&
                          handlePress(activity.activityUrl);
                      }}
                    >
                      <Text style={[styles.activityCTACopy, styles.link]}>
                        {activity.activityUrlPressableCopy}
                      </Text>
                    </TouchableOpacity>
                  </View>
                ) : null}
              </>
            ) : null}
          </View>
        </View>
      </View>
    );
  }
);
