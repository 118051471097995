import React, { useState } from 'react';
import { Text, View, StyleSheet } from 'react-native';

import { Activities } from '../../curator/Activities';
import { salonSevenData } from '../../../data/SalonSevenData';
import { ActivitiesFilter } from '../../curator/ActivitiesFilter';
import {
  PanamaBold,
  greenBrand,
  whiteBrand,
  GTAmerica,
} from '../../../libs/constants';
import { useThemeState } from '../../../context/ThemeContext';
import { useHistory } from '../../../navigation';
import { TouchableOpacity } from '../../common/TouchableOpacity';

interface Props {
  filter?: string;
}

export const SalonIssueSeven: React.FC<Props> = ({ filter }) => {
  const history = useHistory();
  const activityTypes = ['watch', 'read'];
  const { theme } = useThemeState();
  const [selectedFilters, setSelectedFilters] = useState(
    new Array<string>(`${filter}`)
  );
  const [selectedFilter, setSelectedFilter] = useState(
    `${filter ? filter : 'read'}`
  );
  const styles = StyleSheet.create({
    container: {
      backgroundColor: theme.backgroundColor,
    },
    header: {
      fontFamily: PanamaBold,
      fontSize: 32,
      textTransform: 'uppercase',
      textAlign: 'center',
      color: theme.foregroundColor,
    },
    filter: {
      backgroundColor: theme.backgroundColor,
      flexGrow: 1,
      justifyContent: 'center',
      alignContent: 'center',
      alignItems: 'center',
      paddingVertical: 20,
      borderColor: theme.foregroundColor,
      borderBottomWidth: 2,
    },
    filterLabel: {
      fontFamily: GTAmerica,
      fontSize: 22,
      color: theme.foregroundColor,
    },
  });
  return (
    <View style={styles.container}>
      {/* <ActivitiesFilter
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        filters={activityTypes}
      /> */}
      <View
        style={{
          flexGrow: 1,
          flexDirection: 'row',
          justifyContent: 'space-around',
        }}
      >
        <TouchableOpacity
          onPress={() => {
            setSelectedFilter('watch');
            setSelectedFilters(['watch']);
            history.push({
              pathname: '/the-salon/7/watch',
            });
          }}
          style={[
            styles.filter,
            {
              backgroundColor:
                selectedFilter === 'watch' ? greenBrand : theme.backgroundColor,
            },
          ]}
        >
          <Text
            style={[
              styles.filterLabel,
              {
                color:
                  selectedFilter === 'watch'
                    ? whiteBrand
                    : theme.foregroundColor,
              },
            ]}
          >
            Watch
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => {
            setSelectedFilter('read');
            setSelectedFilters(['read']);
            history.push({
              pathname: '/the-salon/7/read',
            });
          }}
          style={[
            styles.filter,
            {
              backgroundColor:
                selectedFilter === 'read' ? greenBrand : theme.backgroundColor,
            },
          ]}
        >
          <Text
            style={[
              styles.filterLabel,
              {
                color:
                  selectedFilter === 'read'
                    ? whiteBrand
                    : theme.foregroundColor,
              },
            ]}
          >
            Read
          </Text>
        </TouchableOpacity>
      </View>
      <View style={{ marginVertical: 20 }}>
        {selectedFilters[0] === 'read' ? (
          <Text style={styles.header}>What to read</Text>
        ) : selectedFilters[0] === 'watch' ? (
          <Text style={styles.header}>What to watch</Text>
        ) : null}
      </View>
      <Activities
        activities={salonSevenData}
        activityTypes={activityTypes}
        selectedFilters={selectedFilters}
      />
    </View>
  );
};
