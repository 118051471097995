import React from 'react';
import { View, Image, ImageURISource, StyleSheet, Text } from 'react-native';
import { Hoverable } from 'react-native-web-hooks';

import {
  greenBrand,
  PanamaBold,
  GTAmerica,
  GTAmericaCompressed,
} from '../../../libs/constants';
import { useDimensions } from '../../../context/DimensionsContext';
import { mdBreakpoint, smBreakpoint, lgBreakpoint } from '../../../constants';
import { TouchableOpacity } from '../../common/TouchableOpacity';

interface SalonIssueOneProps {}

export const SalonIssueSix: React.FC<SalonIssueOneProps> = () => {
  const { width } = useDimensions();
  const blueSquiggle = require('@happymedium/components/assets/images/squiggles-blue.png');
  const redSquiggle = require('@happymedium/components/assets/images/squiggles-red.png');

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      flexDirection: 'column',
    },
    innerContainer: {
      maxWidth: width < 900 ? '85%' : 700,
      alignSelf: 'center',
    },
    imageContainer: {
      alignItems: 'center',
      marginVertical:
        width < smBreakpoint ? 20 : width < mdBreakpoint ? 35 : 60,
    },
    text: {
      color: greenBrand,
      flexWrap: 'wrap',
    },
    header: {
      fontFamily: PanamaBold,
      letterSpacing: 1.3,
      fontSize: width < smBreakpoint ? 28 : width < mdBreakpoint ? 36 : 48,
      marginBottom: width < smBreakpoint ? 5 : width < mdBreakpoint ? 15 : 20,
    },
    body: {
      fontFamily: GTAmerica,
      fontWeight: '300',
      letterSpacing: 0.45,
      fontSize: width < smBreakpoint ? 14 : width < mdBreakpoint ? 15 : 16,
      marginVertical: 10,
    },
    imageDescription: {
      fontFamily: GTAmericaCompressed,
      fontWeight: '300',
      letterSpacing: 0.45,
      fontSize: width < smBreakpoint ? 12 : width < mdBreakpoint ? 14 : 16,
      marginVertical: width < smBreakpoint ? 5 : 8,
      textAlign: 'center',
      maxWidth: width < smBreakpoint ? '90vw' : '100%',
    },
    shareButton: {},
    arrow: {},
    squiggle: {
      height: 31,
      width: 123,
      alignSelf: 'center',
      resizeMode: 'contain',
      marginBottom: 0,
      marginTop: 25,
    },
    image: {
      marginHorizontal: 30,
      maxWidth: width < mdBreakpoint ? '80%' : '50%',
    },
    imageVertical: {
      height: width < smBreakpoint ? 400 : width < mdBreakpoint ? 500 : 550,
      width: '100%',
      alignSelf: 'center',
      resizeMode: 'contain',
    },
    imageHorizontal: {
      height:
        width < smBreakpoint
          ? 280
          : width < mdBreakpoint
          ? 350
          : width < lgBreakpoint
          ? 420
          : 550,
      width: '100%',
      alignSelf: 'center',
      resizeMode: 'contain',
    },
  });

  const renderHeader = (copy: string) => (
    <Text style={[styles.text, styles.header]}>{copy}</Text>
  );

  const renderParagraph = (copy: string) => (
    <Text style={[styles.text, styles.body]}>{copy}</Text>
  );

  const renderImageDescription = (copy: string) => (
    <Text style={[styles.text, styles.imageDescription]}>{copy}</Text>
  );

  return (
    <View style={styles.container}>
      <View
        style={{
          height: width < smBreakpoint ? 10 : width < mdBreakpoint ? 25 : 30,
        }}
      />
      <View style={{ flexDirection: width < lgBreakpoint ? 'column' : 'row' }}>
        <View style={[styles.imageContainer, { flexGrow: 1 }]}>
          <Image
            style={[
              styles.image,
              styles.imageVertical,
              {
                marginHorizontal: 0,
                maxWidth: '100%',
                height:
                  width < smBreakpoint
                    ? 320
                    : width < mdBreakpoint
                    ? 380
                    : width < lgBreakpoint
                    ? 400
                    : 480,
              },
            ]}
            source={
              'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/006/img+1.jpg' as ImageURISource
            }
          />
          {renderImageDescription('')}
        </View>
        <View
          style={{
            flexDirection: 'column',
            flexShrink: 1,
            maxWidth: width < 900 ? '85%' : 700,
            flexWrap: 'wrap',
            marginHorizontal:
              width < smBreakpoint ? undefined : width < mdBreakpoint ? 25 : 50,
            alignSelf: 'center',
          }}
        >
          {width < smBreakpoint && <View style={{ height: 30 }} />}
          {renderHeader('Using Art to Tap into Universal Energy')}

          {renderParagraph(
            'The innate desire and ability to create is present in every single one of us from birth. We, as humans, are at the same time acts of creation and creative forces on the world, acting on the endless sea of energy that surrounds us at every moment.'
          )}
        </View>
      </View>
      <View
        style={[
          styles.imageContainer,
          {
            marginBottom:
              width < smBreakpoint ? 30 : width < mdBreakpoint ? 20 : 0,
            marginTop: 20,
          },
        ]}
      >
        <Image style={[styles.image, styles.squiggle]} source={redSquiggle} />
      </View>
      <View style={[styles.innerContainer]}>
        <View style={{ height: 40 }} />
        {renderParagraph(
          'As children, we act on this creative impulse without hesitation. But as we continue on our earthly journey, we become more cautious, influenced by our parents, peers, or society and slowly become more contained and less creative, less connected with the universal energy that allows creative forces to flow in and through us.'
        )}
        {renderParagraph(
          'The loss of this metaphysical connection manifests itself in our physical lives. It’s what we feel when we feel blocked, depressed, anxious, nervous, unsatisfied, and unfulfilled. Most of us are searching for our path back to universal energy, whether we know it or not.'
        )}
        {renderParagraph(
          'One of the surest ways back is through the act of creation, most commonly in the form of art. Making art forces a certain stillness similar to the stillness of meditation or prayer. In this stillness, a connection can be made and through this connection, our art is propelled in a fantastic cycle of creation.'
        )}
        {renderParagraph(
          'Sound crazy? It shouldn’t. Some of the greatest artists, musicians, and inventors in history have been nothing more than vessels, allowing creative forces to flor through them and create masterpieces that have stood the test of time.'
        )}
      </View>

      <View style={[styles.imageContainer, { marginVertical: 30 }]}>
        <Image
          style={[
            styles.image,
            styles.imageHorizontal,
            {
              height:
                width < smBreakpoint
                  ? 200
                  : width < mdBreakpoint
                  ? 270
                  : width < lgBreakpoint
                  ? 300
                  : 430,
            },
          ]}
          source={
            'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/006/img+2.jpg' as ImageURISource
          }
        />
        {renderImageDescription(
          'Installation view, Hilma af Klint: Paintings for the Future, Solomon R. Guggenheim Museum, New York, October 12, 2018–February'
        )}
      </View>
      <View style={styles.innerContainer}>
        {renderParagraph(
          'Hilma af Klint, the celebrated Swedish artist whose 2019 exhibition at the Guggenheim broke the attendance record, was a mystic who often participated in séances. She painted her most important works while under a spell of sorts, saying “These pictures were painted directly through me, without any preliminary drawings, and with great force. I had no idea what the paintings were supposed to depict; nevertheless I worked swiftly and surely, without changing a single brush stroke.”'
        )}
      </View>

      <View style={styles.imageContainer}>
        <Image
          style={[
            styles.image,
            styles.imageHorizontal,
            {
              height:
                width < smBreakpoint
                  ? 225
                  : width < mdBreakpoint
                  ? 270
                  : width < lgBreakpoint
                  ? 340
                  : 430,
            },
          ]}
          source={
            'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/006/img+3.jpg' as ImageURISource
          }
        />
        {renderImageDescription('Several Circles by Kandinsky, 1926')}
      </View>

      <View style={styles.innerContainer}>
        {renderParagraph(
          'Catalogue sales from the af Klint exhibit broke the selling record previously set by an exhibition of Wassily Kandinsky, a fellow spiritual artist who wrote a book called Concerning the Spiritual in Art. He was inspired by the Theosophical Movement, a religious movement based on divine wisdom and the commonality of human culture. Af Klint was also part of the Theosophical Society, as was the artist Piet Mondrian who noted that “the position of the artist is humble. He is essentially a channel.”'
        )}
      </View>

      <View style={styles.imageContainer}>
        <Image
          style={[
            styles.image,
            styles.imageHorizontal,
            {
              height:
                width < smBreakpoint
                  ? 225
                  : width < mdBreakpoint
                  ? 270
                  : width < lgBreakpoint
                  ? 340
                  : 430,
            },
          ]}
          source={
            'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/006/img+5.jpg' as ImageURISource
          }
        />
        {renderImageDescription('The Ancient of Days by William Blake, 1794')}
      </View>

      <View style={styles.innerContainer}>
        {renderParagraph(
          'In earlier times, these creative forces were synonymous with God. Going back to the late 1700s, the celebrated artist William Blake noted “I myself do nothing. The Holy Spirit accomplishes all through me,” and the Renaissance-era painter Fra Angelico thought his work to have been made by God himself. He was so sure of this that he never retouched any of it, for doing so would mean correcting the work of God. '
        )}
        {renderParagraph(
          'The same forces that moved these artists are available to each and every human and, if you want, you can start on your journey now. In order to break out of your constricted space you need to make a decision and develop new habits. But you don’t have to do it alone. The universe is teeming with energy that can be harnessed to move you closer to your fully realized creative self.'
        )}
        {renderParagraph('Along for the ride? Let’s go.')}
      </View>

      <View style={styles.imageContainer}>
        <Image
          style={[
            styles.image,
            styles.imageHorizontal,
            {
              height:
                width < smBreakpoint
                  ? 120
                  : width < mdBreakpoint
                  ? 180
                  : width < lgBreakpoint
                  ? 240
                  : 330,
            },
          ]}
          source={
            'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/006/img+6.jpg' as ImageURISource
          }
        />
      </View>

      <View style={styles.innerContainer}>
        {renderHeader('Transcend your Ego')}
        {renderParagraph(
          'Transcending the ego is the only way to get out of our current state, enabling us to drop into the universal creative energy. When you make art in service of something greater than yourself, your ego can melt away. When it’s no longer your hand moving the paintbrush, you’re less concerned with assigning value to the output. When we are successful in leaving our large, cumbersome egos behind, the universal creative energy is allowed to flow freely.'
        )}
      </View>

      <View style={styles.imageContainer}>
        <Image
          style={[
            styles.image,
            styles.imageHorizontal,
            {
              height:
                width < smBreakpoint
                  ? 240
                  : width < mdBreakpoint
                  ? 280
                  : width < lgBreakpoint
                  ? 340
                  : 430,
            },
          ]}
          source={
            'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/006/img+7.jpg' as ImageURISource
          }
        />
        {renderImageDescription('Bob Dylan by Milton Glaser')}
      </View>

      <View style={styles.innerContainer}>
        {renderHeader('Create your Environment')}
        {renderParagraph(
          'Bob Dylan, a lyrical artist, once noted “for me, the environment to write the song is extremely important. The environment has to bring something out in me that wants to be brought out.” The same is true for visual art. Your physical environment can amplify your vibrations and create a stronger connection to your creative force. Be intentional and carve out a physical space so you can transcend from the conscious to the subconscious.'
        )}
      </View>
      <View style={{ height: 50 }} />
      <View style={styles.innerContainer}>
        {renderHeader('Commit to a Practice')}
        {renderParagraph(
          'Until now, you’ve been courageous enough to start the journey. You’ve let go of your ego and the inherent judgement that comes with doing something new. And now it’s time for persistent action. Like all good things, re-establishing connection to universal energy can take time. On the way, you might feel skepticism, confusion, boredom – but stick with it. There’s a reason they call it an art practice.'
        )}
      </View>

      <View style={styles.imageContainer}>
        <Image
          style={[styles.image, styles.imageVertical]}
          source={
            'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/006/img+8.jpg' as ImageURISource
          }
        />
        {renderImageDescription('Tableau I by Piet Mondrian, 1921')}
      </View>

      <View style={styles.innerContainer}>
        {renderHeader('What’s in it for you?')}
        {renderParagraph('Well, everything, of course.')}
        {renderParagraph(
          'The future possibilities are endless. Becoming the type of person who creates, is creative, and allows the universal force of creative energy to flow through them is one of the most rewarding decisions you can make as a creative being on the path to realizing your full potential. Unlocking these possibilities will have far reaching rewards in every corner of your existence, but the journey is never over. Our natural tendency is to revert to what we know, what is comfortable. And that’s okay. The key is to develop habits that support your journey and renew your decision daily.'
        )}
        <Image
          style={{ height: 60, width: 120, resizeMode: 'contain' }}
          source={require('@happymedium/components/assets/images/logo-hm-abbreviated-green.png')}
        />
      </View>

      <View style={[styles.imageContainer, { marginBottom: 0, marginTop: 20 }]}>
        <Image style={[styles.image, styles.squiggle]} source={blueSquiggle} />
      </View>

      <View style={{ height: 80 }} />
    </View>
  );
};
