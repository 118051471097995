import React from 'react';
import { View, Image, ImageURISource, StyleSheet, Text } from 'react-native';

import {
  greenBrand,
  PanamaBold,
  GTAmerica,
  GTAmericaCompressed,
} from '../../../libs/constants';
import { useDimensions } from '../../../context/DimensionsContext';
import { mdBreakpoint, smBreakpoint, lgBreakpoint } from '../../../constants';

interface SalonIssueOneProps {}

export const SalonIssueTwo: React.FC<SalonIssueOneProps> = () => {
  const { width } = useDimensions();
  const blueSquiggle = require('@happymedium/components/assets/images/squiggles-blue.png');
  const redSquiggle = require('@happymedium/components/assets/images/squiggles-red.png');

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      flexDirection: 'column',
    },
    innerContainer: {
      maxWidth: width < 900 ? '85%' : 700,
      alignSelf: 'center',
    },
    imageContainer: {
      alignItems: 'center',
      marginVertical:
        width < smBreakpoint ? 20 : width < mdBreakpoint ? 35 : 60,
    },
    text: {
      color: greenBrand,
      flexWrap: 'wrap',
    },
    header: {
      fontFamily: PanamaBold,
      letterSpacing: 1.3,
      fontSize: width < smBreakpoint ? 28 : width < mdBreakpoint ? 36 : 48,
      marginBottom: width < smBreakpoint ? 5 : width < mdBreakpoint ? 15 : 20,
    },
    body: {
      fontFamily: GTAmerica,
      fontWeight: '300',
      letterSpacing: 0.45,
      fontSize: width < smBreakpoint ? 14 : width < mdBreakpoint ? 15 : 16,
      marginVertical: 10,
    },
    imageDescription: {
      fontFamily: GTAmericaCompressed,
      fontWeight: '300',
      letterSpacing: 0.45,
      fontSize: width < smBreakpoint ? 12 : width < mdBreakpoint ? 14 : 16,
      marginVertical: width < smBreakpoint ? 5 : 10,
      textAlign: 'center',
      maxWidth: width < smBreakpoint ? '90vw' : '100%',
    },
    shareButton: {},
    arrow: {},
    squiggle: {
      height: 31,
      width: 123,
      alignSelf: 'center',
      resizeMode: 'contain',
      marginBottom: 0,
      marginTop: 25,
    },
    image: {
      marginHorizontal: 30,
      maxWidth: width < mdBreakpoint ? '80%' : '50%',
    },
    imageVertical: {
      height: width < smBreakpoint ? 400 : width < mdBreakpoint ? 500 : 550,
      width: '100%',
      alignSelf: 'center',
      resizeMode: 'contain',
    },
    imageHorizontal: {
      height:
        width < smBreakpoint
          ? 280
          : width < mdBreakpoint
          ? 350
          : width < lgBreakpoint
          ? 420
          : 550,
      width: '100%',
      alignSelf: 'center',
      resizeMode: 'contain',
    },
  });

  const renderHeader = (copy: string) => (
    <Text style={[styles.text, styles.header]}>{copy}</Text>
  );

  const renderParagraph = (copy: string) => (
    <Text style={[styles.text, styles.body]}>{copy}</Text>
  );

  const renderImageDescription = (copy: string) => (
    <Text style={[styles.text, styles.imageDescription]}>{copy}</Text>
  );

  return (
    <View style={styles.container}>
      <View
        style={{
          height: width < smBreakpoint ? 10 : width < mdBreakpoint ? 25 : 80,
        }}
      />
      <View style={{ flexDirection: width < lgBreakpoint ? 'column' : 'row' }}>
        <Image
          style={[
            styles.image,
            styles.imageHorizontal,
            {
              marginBottom:
                width <= mdBreakpoint && width > smBreakpoint
                  ? 20
                  : width < lgBreakpoint && width > mdBreakpoint
                  ? 40
                  : undefined,
            },
          ]}
          source={
            'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/002/Image+1.jpg' as ImageURISource
          }
        />
        <View
          style={{
            flexDirection: 'column',
            flexShrink: 1,
            maxWidth: width < 900 ? '85%' : 700,
            flexWrap: 'wrap',
            marginHorizontal:
              width < smBreakpoint ? undefined : width < mdBreakpoint ? 25 : 50,
            alignSelf: 'center',
          }}
        >
          {width < smBreakpoint && <View style={{ height: 30 }} />}
          {renderHeader('Send Nudes')}
          {renderParagraph(
            "Last week, we talked about the birth of Modern art and how one painting in particular is known as the first Modern artwork – Édouard Manet’s Le Déjeuner sur l'herbe (The Luncheon on the Grass)."
          )}
          {renderParagraph(
            'Today, we’re diving a bit deeper into Manet and why Le Déjeuner was so radical.'
          )}
        </View>
      </View>
      <View
        style={[
          styles.imageContainer,
          {
            marginBottom:
              width < smBreakpoint ? 30 : width < mdBreakpoint ? 20 : 0,
            marginTop: 20,
          },
        ]}
      >
        <Image style={[styles.image, styles.squiggle]} source={redSquiggle} />
      </View>
      <View style={styles.imageContainer}>
        <Image
          style={[styles.image, styles.imageVertical]}
          source={
            'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/002/Image+2.jpg' as ImageURISource
          }
        />
        {renderImageDescription('Édouard Manet')}
      </View>
      <View style={[styles.innerContainer]}>
        {renderHeader('Who was Édouard Manet')}
        {renderParagraph(
          'Manet was a French painter born in 1832, the same year Darwin arrived in South America and started research that became the backbone of his evolutionary theory. Manet was born into a wealthy, well-connected family who wanted him to be a lawyer, but he was more interested in art, so he became a painter instead.'
        )}
        {renderParagraph(
          'His career began in 1861 when his first painting was accepted into the Paris Salon, a prestigious, state-sponsored art exhibition held every year. The Paris Salon was basically a debutante ball of art and getting accepted to the Salon meant you were a legit artist.'
        )}
        {renderParagraph(
          "In 1863, Manet submitted Le Déjeuner sur l'herbe for consideration at the Paris Salon, but it was rejected. Almost half of all submissions were rejected so the French president, Napoleon III (nephew of Napoleon Bonaparte), set up a separate Salon for the rejected art, aptly named Salon des Refusés, or Salon of Rejects."
        )}
        {renderParagraph(
          'Not all rejected artists agreed to participate, but Manet did, and it changed the course of his life. Le Déjeuner caused quite the uproar, for reasons we’ll explore in a moment, and pushed him to the forefront of the budding modernist movement.'
        )}
        {renderParagraph(
          'For the rest of his days, he enjoyed painting modern life, particularly scenes of leisure. He was friends with other famous artists like Degas, Renoir, Cézanne, Pissarro, and Monet (different person, painted waterlilies in Giverny). He married his piano teacher, who was also possibly his dad’s mistress, and died in 1883 of complications from syphilis. Though he created a few seminal works over his lifetime, Le Déjeuner remains his most influential and controversial.'
        )}
      </View>
      <View style={styles.imageContainer}>
        <Image
          style={[
            styles.image,
            styles.imageHorizontal,
            {
              height:
                width < smBreakpoint
                  ? 230
                  : width < mdBreakpoint
                  ? 250
                  : width < lgBreakpoint
                  ? 310
                  : 400,
            },
          ]}
          source={
            'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/002/Image+3.jpg' as ImageURISource
          }
        />
        {renderImageDescription("Le Déjeuner sur l'herbe, 1863")}
      </View>
      <View style={styles.innerContainer}>
        {renderHeader('Why was Le Déjeuner so controversial?')}
        {renderParagraph(
          'There were a few technical reasons that made Le Déjeuner controversial. For one, the scale of the painting is massive (6’8” x 8’ 6”). Canvases of that size were reserved for historic or allegorical paintings, but Le Déjeuner was neither.'
        )}
        {renderParagraph(
          'His brushwork was also a problem. At the time, artists worked their paintings to achieve an almost airbrushed look. Manet left his brushwork unfinished and left traces of his hand as it danced across the canvas.'
        )}
        {renderParagraph(
          'Lastly, the perspective is off. The woman in the background is way too big. Manet chose to forgo details that would have given the painting more depth, leaving a very flat appearance.'
        )}
        {renderParagraph(
          'But what really got people going was the naked woman.'
        )}
      </View>
      <View style={styles.imageContainer}>
        <Image
          style={[
            styles.image,
            styles.imageHorizontal,
            {
              height:
                width < smBreakpoint
                  ? 180
                  : width < mdBreakpoint
                  ? 250
                  : width < lgBreakpoint
                  ? 310
                  : 400,
            },
          ]}
          source={
            'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/002/Image+4.jpg' as ImageURISource
          }
        />
        {renderImageDescription(
          'Attitude towards female nudity in Western art'
        )}
      </View>
      <View style={styles.innerContainer}>
        {renderHeader('Naked vs. Nekkid')}
        {renderParagraph(
          'In the U.S., there’s a colloquial difference between being naked and being nekkid. When you’re naked, you’re simply without clothes. When you’re nekkid, you’re without clothes and up to no good. The distinction lies solely in the perception of the act.'
        )}
        {renderParagraph(
          'The long, yo-yoing history of female nudity in Western art reminds me of the subjectiveness of naked vs nekkid. It has been either completely appropriate or wildly inappropriate to depict female nudity depending on cultural norms, women’s societal standing, and the context of the work.'
        )}
      </View>
      <View
        style={[
          styles.innerContainer,
          {
            flexDirection: 'row',
            width: '100%',
            justifyContent: width > 620 ? 'space-between' : 'center',
            alignItems: 'center',
          },
        ]}
      >
        <View style={styles.imageContainer}>
          <Image
            style={[
              styles.image,
              styles.imageHorizontal,
              {
                width: '100%',
                marginBottom: width < smBreakpoint ? -20 : 0,
                maxWidth: '100%',
                height:
                  width < smBreakpoint ? 300 : width < mdBreakpoint ? 300 : 370,
              },
            ]}
            source={
              'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/002/Image+5.jpg' as ImageURISource
            }
          />
          {renderImageDescription('The oldest known Venus figurine')}
        </View>
        {width > 620 && (
          <View style={styles.imageContainer}>
            <Image
              style={[
                styles.image,
                styles.imageHorizontal,
                {
                  marginBottom: width < smBreakpoint ? -20 : 0,
                  maxWidth: '100%',
                },
              ]}
              source={
                'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/002/Image+6.jpg' as ImageURISource
              }
            />
            {renderImageDescription(
              'A copy of the original Aphrodite of Knidos'
            )}
          </View>
        )}
      </View>
      <View style={styles.innerContainer}>
        {renderParagraph(
          'For almost as long as there has been art, there has been female nudity in art. The earliest examples are Venus figurines, which date back at least 35,000 years, around the time dogs were first domesticated.'
        )}
        {renderParagraph(
          'In Ancient Greek culture, around 6,000 years ago, female nudity was considered shameful and never depicted in art until the sculptor Praxiteles blew everyone’s mind by creating a nude statue of Aphrodite for a temple in Knidos, where it became a local tourist attraction.'
        )}
        {renderParagraph(
          'From then on, it was generally acceptable to depict female goddesses in the nude but only in an idealized way – beautified features, perfect proportions, lack of defined pubic area, etc.'
        )}
        {width <= 620 && (
          <View style={styles.imageContainer}>
            <Image
              style={[
                styles.image,
                styles.imageHorizontal,
                {
                  maxWidth: '100%',
                },
              ]}
              source={
                'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/002/Image+6.jpg' as ImageURISource
              }
            />
            {renderImageDescription(
              'A copy of the original Aphrodite of Knidos'
            )}
          </View>
        )}
        {renderParagraph(
          'In the Roman Repiblic, around 2,000 years ago, the attitude towards sexuality was even more relaxed than it is today, and this was reflected in art. In addition to depicting nude goddesses, erotic art was not uncommon, as evidenced by the graphic murals in Pompeiian brothels.'
        )}
        {renderParagraph(
          'But as Christianity spread and chastity was idealized, female nudity disappeared from art. This changed again during the Renaissance in the 1400s when a revival of interest in Ancient Greek and Roman art prompted a resurgence in god-like, idealized female nudes.'
        )}
      </View>
      <View style={styles.imageContainer}>
        <Image
          style={[
            styles.image,
            styles.imageHorizontal,
            {
              height:
                width < smBreakpoint
                  ? 180
                  : width < mdBreakpoint
                  ? 250
                  : width < lgBreakpoint
                  ? 310
                  : 400,
            },
          ]}
          source={
            'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/002/Image+7.jpg' as ImageURISource
          }
        />
        {renderImageDescription(
          'The Birth of Venus by Alexandre Cabanel, 1863 \nThis was shown in the Paris Salon the same year Le Déjeuner was in the Salon of Rejects.'
        )}
      </View>
      <View style={styles.innerContainer}>
        {renderParagraph(
          'By the time Manet painted Le Déjeuner, religious and classical depictions of idealized female nudity were quite popular. Because of  the French Imperialistic sentiment of the time, it was also acceptable to depict Eastern women in the nude, typically as prostitutes or slaves. Women could also be depicted in the nude if they were victims of a violent crime.'
        )}
        {renderParagraph(
          'The female nude in Le Déjeuner was radical because she was none of these things. She was a distinctly modern, French woman who had removed her clothing – you can see them in the bottom left of the painting. She was not a mythological creature, nor was she god-like in her beauty. She was a woman that could have easily been seen on the streets of Paris.'
        )}
        {renderParagraph(
          'Her gaze also caused a kerfuffle. Unlike classical female nudes, she is looking out at the viewer in a nonchalant way. Nothing like this had ever been exhibited before. Reportedly, the painting prompted laughter from viewers as a way to break the sexual tension.'
        )}
      </View>
      <View style={styles.imageContainer}>
        <Image
          style={[
            styles.image,
            styles.imageHorizontal,
            {
              height:
                width < smBreakpoint
                  ? 180
                  : width < mdBreakpoint
                  ? 250
                  : width < lgBreakpoint
                  ? 310
                  : 400,
            },
          ]}
          source={
            'https://thesalonnewsletter.s3.us-east-2.amazonaws.com/002/Image+8.jpg' as ImageURISource
          }
        />
        {renderImageDescription(
          "Protesters at Musée d'Orsay in September, 2020"
        )}
      </View>
      <View style={styles.innerContainer}>
        {renderHeader('Free the Nipple')}
        {renderParagraph(
          'At the end of the day, I think Le Déjeuner was an early feminist work. It was the first time in modern history that a real woman was depicted naked on her own accord. She wasn’t a slave, she wasn’t a goddess, she wasn’t a victim, and she wasn’t ashamed. This type of woman had never been exhibited before. It challenged convention and opened the door for the gender equality movement.'
        )}
        {renderParagraph(
          '157 years after Le Déjeuner first riled viewers, the debate about the appropriateness of female nudity in art continues. The most famous example is the #freethenipple campaign which gained popularity thanks to Instagram’s policy of censoring photographs of female nipples.'
        )}
        {renderParagraph(
          'In perhaps the most deliciously ironic turn of events, the Musée d’Orsay in Paris, which is the permanent home of Le Déjeuner, came under fire just two months ago for refusing entry to a museum-goer because of her low-cut dress. In response, topless feminist protestors hit the museum a few days later. And the debate goes on.'
        )}
        <Image
          style={{ height: 60, width: 120, resizeMode: 'contain' }}
          source={require('@happymedium/components/assets/images/logo-hm-abbreviated-green.png')}
        />
      </View>

      <View style={[styles.imageContainer, { marginBottom: 0, marginTop: 20 }]}>
        <Image style={[styles.image, styles.squiggle]} source={blueSquiggle} />
      </View>

      <View style={{ height: 80 }} />
    </View>
  );
};
